import React, {useState} from 'react';
import ModalContainer from '../../../../uiToolkit/ModalContainer';
import {addMChoiceWizardText} from '../addMChoiceWizardText';
import './styles.css';
import {
  aIassistantSmallIc,
  checkGreenIcon,
  deleteIcon,
  mChoiceModalIc,
} from '../../../../assets/images';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';
import YesNoOption from '../../../../uiToolkit/Inputs/YesNoOption';
import {LessonContentMChoiceAnswer} from '../../../../data/models/LessonContent/LessonContentBase';
import {
  GeneratedQuestionAnswer,
  GeneratedQuestions,
} from '../../../../data/models/QuestionGeneration/GeneratedQuestions';
import Button from '../../../../uiToolkit/Buttons/Button';
import AILoader from '../../../../uiToolkit/AILoader';
import Dropdown from '../../../../uiToolkit/Inputs/Dropdown';
import {GradeWeights} from '../../../../data/models/LessonContent/GradeWeights';
import Tooltip from '../../../../uiToolkit/Tooltip';

const MAX_AMOUNT_OF_ANSWERS = 4;

interface Props {
  onCancel: () => void;
  isLoading: boolean;
  saveChanges: (
    question: string,
    answers: LessonContentMChoiceAnswer[],
    gradeWeight: number,
  ) => void;
  isLoadingAi: boolean;
  onGenerateQuestions: (topic: string) => void;
  generatedQuestions?: GeneratedQuestions;
  canGenerateQuestions: boolean;
}

const AddMChoiceWizardUI = React.memo(
  ({
    onCancel,
    isLoading,
    saveChanges,
    isLoadingAi,
    onGenerateQuestions,
    generatedQuestions,
    canGenerateQuestions,
  }: Props) => {
    const [question, setQuestion] = useState('');
    const [answers, setAnswers] = useState<
      {answer: string; isCorrect: boolean}[]
    >([{answer: '', isCorrect: false}]);
    const [openAssistant, setOpenAssistant] = useState(false);
    const [generationTopic, setGenerationTopic] = useState('');
    const [selectedQuestion, setSelectedQuestion] =
      useState<GeneratedQuestionAnswer>();
    const [gradeWeight, setGradeWeight] = useState('1');

    const onGenerate = () => {
      onGenerateQuestions(generationTopic);
    };

    const onChangeAnswer = (index: number) => (answer: string) => {
      const newAnswers = [...answers];
      newAnswers[index].answer = answer;
      setAnswers(newAnswers);
    };

    const setIsCorrect = (index: number) => (value: boolean) => {
      const newAnswers = [...answers];
      newAnswers[index].isCorrect = value;
      setAnswers(newAnswers);
    };

    const onAddAnswer = () => {
      if (answers.length < MAX_AMOUNT_OF_ANSWERS) {
        const newAnswers = [...answers];
        newAnswers.push({answer: '', isCorrect: false});
        setAnswers(newAnswers);
      }
    };

    const onRemove = (index: number) => {
      const newAnswers = [...answers];
      newAnswers.splice(index, 1);
      setAnswers(newAnswers);
    };

    const onSave = () => {
      if (isValid()) {
        saveChanges(
          question,
          answers.map(item => ({
            value: item.answer,
            isCorrect: item.isCorrect,
          })),
          parseInt(gradeWeight, 10),
        );
      }
    };

    const isValid = () => {
      let allAnswersFilled = true;
      let oneAnswerCorrect = false;
      let have2sameAnswers = false;
      answers.forEach((answer, index) => {
        if (answer.answer.trim() === '') {
          allAnswersFilled = false;
        }
        if (answer.isCorrect) {
          oneAnswerCorrect = true;
        }
        if (
          answers.find(
            (x, xIndex) => x.answer === answer.answer && xIndex !== index,
          )
        ) {
          have2sameAnswers = true;
        }
      });
      return (
        question.trim() !== '' &&
        answers.length > 0 &&
        allAnswersFilled &&
        !have2sameAnswers &&
        oneAnswerCorrect
      );
    };

    const onFillQuestion = () => {
      if (selectedQuestion) {
        setQuestion(selectedQuestion.question);
        setAnswers([
          {
            answer: selectedQuestion.choices.A,
            isCorrect: selectedQuestion.answer === 'A',
          },
          {
            answer: selectedQuestion.choices.B,
            isCorrect: selectedQuestion.answer === 'B',
          },
          {
            answer: selectedQuestion.choices.C,
            isCorrect: selectedQuestion.answer === 'C',
          },
          {
            answer: selectedQuestion.choices.D,
            isCorrect: selectedQuestion.answer === 'D',
          },
        ]);
      }
    };

    return (
      <ModalContainer onClose={onCancel}>
        <div className="AddMChoiceWizardContainer">
          <div className="Content">
            <div className="HeaderContainer">
              <div className="Row">
                <img src={mChoiceModalIc} />
                <p className="Header">{addMChoiceWizardText.title}</p>
              </div>
              {!openAssistant ? (
                <Tooltip
                  greenTooltip
                  tooltipText={
                    !canGenerateQuestions
                      ? addMChoiceWizardText.cantGenerate
                      : addMChoiceWizardText.openAIAssistant
                  }>
                  <button
                    className="OpenAssistant"
                    disabled={!canGenerateQuestions}
                    onClick={() => setOpenAssistant(true)}>
                    {addMChoiceWizardText.generateWithAI}
                  </button>
                </Tooltip>
              ) : (
                <div />
              )}
            </div>
            <Dropdown
              options={GradeWeights}
              selectedOption={gradeWeight}
              setSelectedOption={setGradeWeight}
              heading={addMChoiceWizardText.gradeWeight}
              className="AnswersInput"
            />
            <TextInput
              value={question}
              onChange={setQuestion}
              heading={addMChoiceWizardText.questionTitle}
              placeholder={addMChoiceWizardText.questionPlaceholder}
              className="AnswersInput"
              maxCharacters={200}
              isMandatory
            />
            {answers.map((item, index) => (
              <div className="Section" key={index.toString()}>
                <TextInput
                  value={item.answer}
                  onChange={onChangeAnswer(index)}
                  heading={addMChoiceWizardText.answerTitle}
                  placeholder={addMChoiceWizardText.answerPlaceholder}
                  className="AnswersInput"
                  maxCharacters={140}
                  isMandatory
                />
                <YesNoOption
                  title={addMChoiceWizardText.isCorrectTitle}
                  value={item.isCorrect}
                  onSelect={setIsCorrect(index)}
                />
                <button className="DeleteItem" onClick={() => onRemove(index)}>
                  <img src={deleteIcon} />
                </button>
              </div>
            ))}
            <Button
              title={addMChoiceWizardText.addAnswer}
              onClick={onAddAnswer}
              disabled={answers.length === MAX_AMOUNT_OF_ANSWERS}
              uiType="secondary"
              icon="icon-typePlus-icon"
              infoText={addMChoiceWizardText.Disclamer}
              className="AddAnswerButton"
            />
            <div className="Footer">
              <Button
                title={addMChoiceWizardText.cancel}
                onClick={onCancel}
                uiType="text"
              />
              <Button
                title={addMChoiceWizardText.accept}
                onClick={onSave}
                disabled={!isValid()}
                isLoading={isLoading}
                uiType="action"
              />
            </div>
          </div>
          {openAssistant && (
            <div className="AssistandContainer">
              <div className="AssistandHeader">
                <img src={aIassistantSmallIc} />
                <p>{addMChoiceWizardText.assistant}</p>
              </div>
              <div className="Content">
                {isLoadingAi ? (
                  <AILoader />
                ) : generatedQuestions ? (
                  <div className="GeneratedQuestionsContainer">
                    <p>{addMChoiceWizardText.chooseQuestion}</p>
                    {generatedQuestions.question_answers.map(item => (
                      <div key={item.id} className="GeneratedQuestionContainer">
                        <div onClick={() => setSelectedQuestion(item)}>
                          <div className="QuestionSelect">
                            {selectedQuestion?.id === item.id && (
                              <div className="QuestionSelected" />
                            )}
                          </div>
                          <span className="Title">{item.question}</span>
                          <ul>
                            <li>
                              {item.choices.A}
                              {item.answer === 'A' && (
                                <img src={checkGreenIcon} />
                              )}
                            </li>
                            <li>
                              {item.choices.B}{' '}
                              {item.answer === 'B' && (
                                <img src={checkGreenIcon} />
                              )}
                            </li>
                            <li>
                              {item.choices.C}{' '}
                              {item.answer === 'C' && (
                                <img src={checkGreenIcon} />
                              )}
                            </li>
                            <li>
                              {item.choices.D}{' '}
                              {item.answer === 'D' && (
                                <img src={checkGreenIcon} />
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <TextInput
                    value={generationTopic}
                    onChange={setGenerationTopic}
                    heading={addMChoiceWizardText.topicHeading}
                    placeholder={addMChoiceWizardText.topicPlaceholder}
                  />
                )}
              </div>
              {!isLoadingAi && !generatedQuestions && (
                <div className="Footer">
                  <Button
                    title={addMChoiceWizardText.cancel}
                    onClick={() => setOpenAssistant(false)}
                    uiType="text"
                    className="Cancel"
                  />
                  <Button
                    title={addMChoiceWizardText.continue}
                    onClick={onGenerate}
                    disabled={generationTopic.trim().length === 0}
                    uiType="action"
                  />
                </div>
              )}
              {!isLoadingAi && generatedQuestions && (
                <div className="Footer">
                  <Button
                    title={addMChoiceWizardText.cancel}
                    onClick={() => setOpenAssistant(false)}
                    className="Cancel"
                    uiType="text"
                  />
                  <Button
                    title={addMChoiceWizardText.select}
                    onClick={onFillQuestion}
                    disabled={!selectedQuestion}
                    uiType="action"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </ModalContainer>
    );
  },
);

export default AddMChoiceWizardUI;
