import React, {useState} from 'react';
import './styles.css';
import {settingsText} from '../../settingsText';
import Button from '../../../../uiToolkit/Buttons/Button';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';
import TipBox from '../../../../uiToolkit/TipBox';

interface Props {
  onSaveSecret: (secret: string) => void;
  hasIntegration?: boolean;
  onRemoveIntegration: () => void;
}

const StorageIntegrationSettingsUI = React.memo(
  ({onSaveSecret, hasIntegration, onRemoveIntegration}: Props) => {
    const [secret, setSecret] = useState('');

    const onSave = () => {
      onSaveSecret(secret);
      setSecret('');
    };

    return (
      <div className="StorageSettingsContainer">
        <div>
          <TipBox
            tip={
              <>
                {settingsText.vimeoClientSecretInstructions}
                <a
                  href="https://help.tapybl.com/support/solutions/articles/153000197386-integrate-your-vimeo-content-with-tapybl"
                  target="_blank"
                  rel="noopener noreferrer">
                  Vimeo Setup Guide
                </a>
                .
              </>
            }
          />
          <p className="StoragePrompt">{settingsText.vimeoPrompt}</p>

          {hasIntegration === undefined ? null : !hasIntegration ? (
            <>
              <TextInput
                heading={settingsText.vimeoClientSecret}
                placeholder={settingsText.vimeoClientPlaceholder}
                value={secret}
                onChange={setSecret}
                type="password"
              />
              <Button
                onClick={onSave}
                title={settingsText.setupVimeoStorage}
                isLoading={false}
                className="VimeoSetupButton"
                disabled={!secret}
              />
            </>
          ) : (
            <div>
              <p className="StoragePrompt">
                <span className="icon-typeCheck"></span>{' '}
                {settingsText.vimeoConfigured}
              </p>
              <Button
                title={settingsText.removeIntegration}
                onClick={onRemoveIntegration}
                uiType="text_danger"
              />
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default StorageIntegrationSettingsUI;
