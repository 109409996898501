import React from 'react';
import {inject, observer} from 'mobx-react';
import AddChoiceTextOptionWizardUI from '../view/AddChoiceTextOptionWizardUI';
import AddChoiceTextOptionStore from '../store/AddChoiceTextOptionWizardStore';
import {useParams} from 'react-router-dom';

interface Props {
  addChoiceTextOptionWizardStore?: AddChoiceTextOptionStore;
}

const AddChoiceTextOptionWizardContainer = inject(
  'addChoiceTextOptionWizardStore',
)(
  observer(({addChoiceTextOptionWizardStore}: Props) => {
    const {organizationId, lessonId} = useParams();

    const onCancel = () => {
      addChoiceTextOptionWizardStore!.cancelWizard();
    };

    const onCreate = (question: string) => {
      if (organizationId && lessonId) {
        addChoiceTextOptionWizardStore!.createOption(
          organizationId,
          lessonId,
          question,
        );
      }
    };
    return addChoiceTextOptionWizardStore!.isVisible() ? (
      <AddChoiceTextOptionWizardUI onCancel={onCancel} onCreate={onCreate} />
    ) : null;
  }),
);

export default AddChoiceTextOptionWizardContainer;
