import React from 'react';
import {logoText} from '../../assets/images';
import './styles.css';
import {Link} from 'react-router-dom';

interface Props {
  title: string;
  subtitle: string;
}

const AuthHeader = React.memo(({title, subtitle}: Props) => {
  return (
    <div>
      <Link to="/auth/login">
        <img src={logoText} alt="logo-text" className="AuthHeaderTextLogo" />
      </Link>
      <h2 className="AuthHeaderTitle">{title}</h2>
      <h6 className="AuthHeaderSubtitle">{subtitle}</h6>
    </div>
  );
});

export default AuthHeader;
