import {
  GeneratedQuestions,
  GeneratedQuestionAnswer,
} from '../data/models/QuestionGeneration/GeneratedQuestions';

export const movePunctuationToStart = (data: GeneratedQuestions) => {
  const arabicHebrewRegex =
    /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF\u0590-\u05FF]/;

  data.question_answers.forEach((item: GeneratedQuestionAnswer) => {
    const question = item.question;
    const lastChar = question[question.length - 1];

    if (
      (lastChar === '?' || lastChar === '.' || lastChar === '!') &&
      // (lastChar === '?' || lastChar === '.' || lastChar === '!' || lastChar === '؟') &&
      arabicHebrewRegex.test(question)
    ) {
      item.question = lastChar + question.slice(0, -1);
    }
  });

  return data;
};
