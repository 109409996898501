import React, {useState} from 'react';
import {lessonEditorText} from '../../../lessonEditorText';
import Button from '../../../../../uiToolkit/Buttons/Button';
import {appText} from '../../../../../assets/text/appText';
import Tooltip from '../../../../../uiToolkit/Tooltip';
import './styles.css';
import {LessonContentVideoBackgorund} from '../../../../../data/models/LessonContent/LessonContentBase';
import YesNoOption from '../../../../../uiToolkit/Inputs/YesNoOption';
import {BaseContainerForwardProps} from '../../BaseOptionsContainer';
import ReplaceNode from '../../ReplaceNode';

interface Props extends BaseContainerForwardProps {
  entityToEdit: LessonContentVideoBackgorund;
}

const VideoBackgroundOptionsUI = React.memo(
  ({isLoading, onDelete, isPublished, entityToEdit, onEdit}: Props) => {
    const [isCorrect, setIsCorrect] = useState(
      (entityToEdit as LessonContentVideoBackgorund).isCorrect,
    );

    const onEditVideoBackground = () => {
      onEdit({
        ...entityToEdit,
        isCorrect,
      } as LessonContentVideoBackgorund);
    };

    return (
      <div className="TextOptions">
        {isCorrect !== null && (
          <YesNoOption
            value={isCorrect}
            onSelect={setIsCorrect}
            title={lessonEditorText.isCorrect}
            uiType="box"
            className="mb-3"
          />
        )}
        <ReplaceNode currentNode={entityToEdit} />
        {isCorrect !== null && (
          <Button
            title={lessonEditorText.save}
            onClick={onEditVideoBackground}
            uiType="box"
            disabled={isCorrect === entityToEdit.isCorrect}
          />
        )}
        {isPublished ? (
          <Tooltip tooltipText={appText.lessonAlreadyPublished} greenTooltip>
            <div>
              <Button
                title={lessonEditorText.delete}
                onClick={onDelete}
                uiType="text_danger"
                isLoading={isLoading}
                disabled={isPublished}
              />
            </div>
          </Tooltip>
        ) : (
          <Button
            title={appText.deleteThisNodeAndChildren}
            onClick={onDelete}
            uiType="text_danger"
            isLoading={isLoading}
            disabled={isPublished}
          />
        )}
      </div>
    );
  },
);

export default VideoBackgroundOptionsUI;
