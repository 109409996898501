import React from 'react';
import {logoTextWhite} from '../../../../assets/images';
import {
  getVideoTypeText,
  isImageFile,
  isVideoFile,
} from '../../../../data/models/LibraryFile/VideoSourceShort';
import {MediaFile} from '../../../../data/models/LibraryFile/MediaFile';

interface Props {
  item: MediaFile;
  onItemClick: (item: MediaFile) => void;
}

const RecentFileItem = React.memo(({item, onItemClick}: Props) => {
  const getPlaceholder = () => {
    if (isVideoFile(item.fileType)) {
      return item.placeholderUrl || logoTextWhite;
    } else if (isImageFile(item.fileType)) {
      return item.fileUrl || logoTextWhite;
    }
    return logoTextWhite;
  };

  return (
    <div className="ItemContainer" onClick={() => onItemClick(item)}>
      <h5>{item.fileName}</h5>
      <div
        className={`PlaceholderContainer ${
          getPlaceholder() === logoTextWhite ? 'LogoPlaceholder' : ''
        }`}>
        <img src={getPlaceholder()} alt="placeholder" />
      </div>
      {isVideoFile(item.fileType) ? (
        <div className="FooterInfoContainer">
          <div className="FooterInfoContainerType">
            <span className="icon-typeVideo" />
            <span>{getVideoTypeText(item.fileType)}</span>
          </div>
          <span>{item.fileSize} MB</span>
        </div>
      ) : (
        <div className="FooterInfoContainer">
          <div className="FooterInfoContainerType">
            <span className="icon-typeImage" />
            <span>jpg</span>
          </div>
          <span>{0}MB</span>
        </div>
      )}
    </div>
  );
});

export default RecentFileItem;
