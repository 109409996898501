import React, {useEffect, useState} from 'react';
import SendForgotPasswordUI from '../view/SendForgotPasswordUI';
import ForgotPasswordStore from '../store/ForgotPasswordStore';
import {inject, observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';

interface Props {
  forgotPasswordStore?: ForgotPasswordStore;
}
const SendForgotPasswordContainer = inject('forgotPasswordStore')(
  observer(({forgotPasswordStore}: Props) => {
    const [sent, setSent] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
      return () => {
        forgotPasswordStore!.clearErrors();
      };
    }, []);

    const onSendForgotPassword = async (email: string) => {
      const result = await forgotPasswordStore!.sendPasswordReset(email);
      if (result) {
        setSent(true);
      }
    };

    const onBack = () => {
      navigate('/auth/login', {replace: true});
    };

    const clearErorrs = () => {
      forgotPasswordStore!.clearErrors();
    };

    return (
      <SendForgotPasswordUI
        errors={forgotPasswordStore!.errors}
        onSendForgotPassword={onSendForgotPassword}
        isLoading={forgotPasswordStore!.isLoading}
        onBack={onBack}
        sent={sent}
        clearErorrs={clearErorrs}
      />
    );
  }),
);

export default SendForgotPasswordContainer;
