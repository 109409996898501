import React from 'react';
import {courseEditText} from '../../courseEditText';
import Button from '../../../../uiToolkit/Buttons/Button';
import './styles.css';
import {useNavigate, useParams} from 'react-router-dom';

interface Props {
  subtitle: string;
}

const CourseNotPublished = React.memo(({subtitle}: Props) => {
  const navigate = useNavigate();
  const {organizationId, courseId} = useParams();

  const onOpenPublish = () => {
    navigate(`/preview/${organizationId}/course/${courseId}`);
  };

  return (
    <div className="CourseNotPublished">
      <p className="Title">{courseEditText.courseNotPublished}</p>
      <p className="Subtitle">{subtitle}</p>
      <Button title={courseEditText.publishCourse} onClick={onOpenPublish} />
    </div>
  );
});

export default CourseNotPublished;
