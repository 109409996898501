import React from 'react';
import DataTable from 'react-data-table-component';
import {downloadTranscript, logoTextWhite} from '../../../../assets/images';
import {MediaFile} from '../../../../data/models/LibraryFile/MediaFile';
import TablePagination from '../../../../uiToolkit/TablePagination';
import {videoSourcesText} from '../../videoSourcesText';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {isImageFile} from '../../../../data/models/LibraryFile/VideoSourceShort';
import {formatDateTimeTextFormat} from '../../../../utils/notificationTimeTextConvertor';
import VideoDownloadButton from '../../Root/components/VideoDownloadButton';
import {SUBSCRIPTION_PERMISSIONS} from '../../../../data/permissions';
import {getLocalizationBasedOnLang} from '../../../../data/models/User/UserInfo';

const tooltipDelete = <Tooltip>{videoSourcesText.fileIsUsed}</Tooltip>;
const tooltipEdit = <Tooltip>{videoSourcesText.fileIsUsedEdit}</Tooltip>;
const transcriptTooltip = (
  <Tooltip>{videoSourcesText.downloadTranscript}</Tooltip>
);
const tooltipDeleteGenerating = (
  <Tooltip>{videoSourcesText.fileIsGenerating}</Tooltip>
);

const columns = (
  onDelete: (itemId: string) => void,
  onPreview: (file: MediaFile) => void,
  onEditVideo: (file: MediaFile) => void,
  onDownloadTranscript: (id: string) => void,
  workspacePermissions: any,
) => [
  {
    name: getLocalizationBasedOnLang('Filename', 'Enw Ffeil'),
    selector: (row: MediaFile) => row.fileName,
    sortable: true,
    sortField: 'file_name',
    cell: (row: MediaFile) => (
      <div className="TableNameContainer" data-tag="allowRowEvents">
        <div className="TableNameContainerThumb">
          {isImageFile(row.fileType) ? (
            row.fileUrl && <img src={row.fileUrl} />
          ) : row.placeholderUrl ? (
            <img
              src={row.placeholderUrl}
              alt="placeholder"
              data-tag="allowRowEvents"
            />
          ) : (
            <img
              src={logoTextWhite}
              alt="placeholder"
              className="NoPlaceholderImage"
              data-tag="allowRowEvents"
            />
          )}
        </div>

        <h5
          className={row.fileStatus === 0 ? 'TableText' : 'TableTextNotReady'}
          data-tag="allowRowEvents">
          {row.fileStatus === 1
            ? '(In Progress) ' + row.fileName
            : row.fileName}
        </h5>
      </div>
    ),
    grow: 4,
  },
  {
    name: getLocalizationBasedOnLang('Uploaded Date', 'Dyddiad a Lwythwyd'),
    sortable: true,
    sortField: 'created_date',
    selector: (row: MediaFile) => row.dateCreated.toString(),
    cell: (row: MediaFile) => (
      <h5
        className={row.fileStatus === 0 ? 'TableText' : 'TableTextNotReady'}
        data-tag="allowRowEvents">
        {formatDateTimeTextFormat(row.dateCreated.toString())}
      </h5>
    ),
    id: 'created_date',
  },
  {
    name: getLocalizationBasedOnLang('Size', 'Maint'),
    sortable: true,
    sortField: 'size',
    selector: (row: MediaFile) => row.dateCreated.toString(),
    cell: (row: MediaFile) => (
      <h5
        className={row.fileStatus === 0 ? 'TableText' : 'TableTextNotReady'}
        data-tag="allowRowEvents">
        {row.fileSize} MB
      </h5>
    ),
    id: 'size',
  },
  {
    name: getLocalizationBasedOnLang('Actions', 'Camau'),
    cell: (row: MediaFile) => (
      <>
        {row.hasTranscript ? (
          <OverlayTrigger placement="bottom" overlay={transcriptTooltip}>
            <div
              className="Transcript"
              onClick={() => onDownloadTranscript(row.id)}>
              <img src={downloadTranscript} />
            </div>
          </OverlayTrigger>
        ) : null}
        <button className="TableDeleteButton">
          {row.fileStatus !== 0 ? (
            <OverlayTrigger
              placement="bottom"
              overlay={tooltipDeleteGenerating}>
              <div className="DeleteDisabled">
                <span className="icon-typeTrash" />
              </div>
            </OverlayTrigger>
          ) : row.isUsed ? (
            <OverlayTrigger placement="bottom" overlay={tooltipDelete}>
              <div className="DeleteDisabled">
                <span className="icon-typeTrash" />
              </div>
            </OverlayTrigger>
          ) : (
            <div className="Delete" onClick={() => onDelete(row.id)}>
              <span className="icon-typeTrash" />
            </div>
          )}
        </button>
        {row.fileStatus === 0 && (
          <div className="Preview" onClick={() => onPreview(row)}>
            <span className="icon-typeEye" />
          </div>
        )}
        {row.fileUrl && row.fileStatus === 0 && (
          <VideoDownloadButton
            fileName={row.fileName}
            fileUrl={row.fileUrl || ''}
          />
        )}
        {row.fileUrl &&
          (row.fileStatus === 0 || row.fileStatus === 2) &&
          !row.isUsed && (
            <div className="Preview" onClick={() => onEditVideo(row)}>
              <span className="icon-typeEdit-without-square" />
            </div>
          )}
        {row.fileUrl &&
          workspacePermissions[SUBSCRIPTION_PERMISSIONS.AI_ASSISTANT] &&
          (row.fileStatus === 0 || row.fileStatus === 2) &&
          row.isUsed && (
            <OverlayTrigger placement="bottom" overlay={tooltipEdit}>
              <div className="DeleteDisabled">
                <span className="icon-typeEdit-without-square" />
              </div>
            </OverlayTrigger>
          )}
      </>
    ),
    id: 'actions',
    grow: 1.4,
  },
];

interface Props {
  sources: MediaFile[];
  pages: number;
  onPageChange: (page: number) => void;
  onDelete: (itemId: string) => void;
  onSort: (selectedColumn: any, sortDirection: any) => void;
  onPreview: (file: MediaFile) => void;
  onEditVideo: (file: MediaFile) => void;
  onDownloadTranscript: (id: string) => void;
  workspacePermissions: any;
}

const AiGeneratedFilesTable = React.memo(
  ({
    sources,
    pages,
    onPageChange,
    onDelete,
    onSort,
    onPreview,
    onEditVideo,
    workspacePermissions,
    onDownloadTranscript,
  }: Props) => {
    return (
      <DataTable
        className="Table"
        columns={columns(
          onDelete,
          onPreview,
          onEditVideo,
          onDownloadTranscript,
          workspacePermissions,
        )}
        data={sources}
        noDataComponent={<div />}
        pointerOnHover
        highlightOnHover
        onChangePage={onPageChange}
        pagination
        paginationServer
        onSort={onSort}
        paginationPerPage={10}
        paginationComponent={TablePagination}
        paginationTotalRows={pages * 10}
        striped
        sortServer
        theme="custom-stripes"
      />
    );
  },
);

export default AiGeneratedFilesTable;
