import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const englishAiGenText = {
  title: 'Generate Lesson Using AI Assistant',
  includeBranching: 'Include Branching?',
  includeGrading: 'Include Grading?',
  passingScore: 'Passing Score',
  generate: 'Generate',
  source: 'Source:',
  lessonIsBeingGenerated:
    'Lesson generation can take up to 5 minutes. Please refresh this page to see updates.',
  lessonIsGenerated: 'Your lesson is generated',
};

export const welshAiGenText = {
  title: 'Cynhyrchwch Gwers gan ddefnyddio Cynorthwy-ydd AI',
  includeBranching: 'Cynnwys Canghennog?',
  includeGrading: 'Cynnwys Graddio?',
  passingScore: "Sgôr i'r Basio",
  generate: 'Cynhyrchu',
  source: 'Ffynhonnell',
  lessonIsBeingGenerated:
    'Gall cynhyrchu gwersi gymryd hyd at bum munud. Adnewyddwch y dudalen hon i weld diweddariadau.',
  lessonIsGenerated: 'Cynhyrchir eich gwers',
};

export const aiLessonGeneratorText: any = getLocalizationBasedOnLang(
  englishAiGenText,
  welshAiGenText,
);
