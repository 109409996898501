import React from 'react';
import {lessonCompletedIc, lessonFailedIc} from '../../../../assets/images';
import {GradeResult} from '../../models';
import Button from '../../../../uiToolkit/Buttons/Button';
import './styles.css';
import {tapyblPlayerText} from '../../tapyblPlayerText';

interface Props {
  gradable?: boolean;
  gradeResult?: GradeResult;
  onCourseHome: () => void;
  onContinue: () => void;
  preview?: boolean;
  showResults: () => void;
  isLoading?: boolean;
  nextLessonIsTheSame?: boolean;
  skipFinishButtons?: boolean;
  showScormFinish?: boolean;
  nextLessonId?: string;
}

const EndingOverlay = React.memo(
  ({
    gradeResult,
    onCourseHome,
    onContinue,
    preview,
    showResults,
    isLoading,
    nextLessonIsTheSame,
    skipFinishButtons,
    nextLessonId,
    showScormFinish,
  }: Props) => {
    const renderAnswersSection = () =>
      gradeResult ? (
        <div className="QuestionsListContainer">
          <div className="Total">
            <span>{tapyblPlayerText.total}</span>
            <span>
              {gradeResult.correctAnswers}/{gradeResult.totalQuestions}
            </span>
          </div>
          <div className="Actions">
            <Button
              title={tapyblPlayerText.viewResults}
              onClick={showResults}
              className="ResultsButton"
              uiType="hollow"
            />
            {!skipFinishButtons && (
              <Button
                title={
                  preview
                    ? tapyblPlayerText.back
                    : nextLessonIsTheSame
                    ? tapyblPlayerText.retry
                    : nextLessonId
                    ? tapyblPlayerText.continue
                    : tapyblPlayerText.courseHome
                }
                onClick={nextLessonId ? onContinue : onCourseHome}
                uiType="secondary"
              />
            )}
            {showScormFinish && (
              <Button
                title={tapyblPlayerText.finish}
                onClick={onContinue}
                uiType="secondary"
              />
            )}
          </div>
        </div>
      ) : null;

    return !isLoading ? (
      <div className="EndingOverlay">
        {!gradeResult || gradeResult?.totalQuestions === 0 ? (
          <>
            <img src={lessonCompletedIc} />
            <p className="Title">{tapyblPlayerText.lessonCompleted}</p>
            {!skipFinishButtons && (
              <div className="Footer">
                {preview ? (
                  <Button
                    title={tapyblPlayerText.back}
                    onClick={onContinue}
                    uiType="secondary"
                  />
                ) : (
                  [
                    <Button
                      title={tapyblPlayerText.courseHome}
                      onClick={onCourseHome}
                      key="home"
                      uiType="hollow"
                    />,
                    nextLessonId ? (
                      <Button
                        title={tapyblPlayerText.nextLesson}
                        onClick={onContinue}
                        key="continue"
                        uiType="secondary"
                      />
                    ) : null,
                  ]
                )}
              </div>
            )}
            {showScormFinish && (
              <Button
                title={tapyblPlayerText.finish}
                onClick={onContinue}
                uiType="secondary"
              />
            )}
          </>
        ) : (
          <>
            {gradeResult.passingScore > Math.round(gradeResult.earnedScore) ? (
              <>
                <img src={lessonFailedIc} />
                <p className="Title" style={{marginBottom: '0px'}}>
                  {tapyblPlayerText.tryAgain}
                </p>
                <span className="Description">
                  {tapyblPlayerText.finalScore}{' '}
                  <span className="Fail">
                    {Math.round(gradeResult.earnedScore)}%
                  </span>
                </span>
                {renderAnswersSection()}
              </>
            ) : (
              <>
                <img src={lessonCompletedIc} />
                <p className="Title" style={{marginBottom: '0px'}}>
                  {tapyblPlayerText.wellDone}
                </p>
                <span className="Description">
                  {tapyblPlayerText.finalScore}{' '}
                  <span className="Success">
                    {Math.round(gradeResult.earnedScore)}%
                  </span>
                </span>
                {renderAnswersSection()}
              </>
            )}
          </>
        )}
      </div>
    ) : null;
  },
);

export default EndingOverlay;
