import {makeAutoObservable} from 'mobx';
import SubscriptionRepository from '../../../../data/repositories/SubscriptionRepository';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../../data/errorCodes';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import LoadingModule from '../../../../modules/LoadingModule/LoadingModule';
import OrganizationRepository from '../../../../data/repositories/OrganizationRepository';

class MarketplaceStore {
  private subscriptionRepo = new SubscriptionRepository();
  private organizationRepo = new OrganizationRepository();

  private loadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async getSubscription(organizationId: string) {
    const result =
      await this.subscriptionRepo.getOrganizationSubscription(organizationId);
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async fetchDashboardDetails(organizationId: string) {
    this.loadingModule.startLoading();
    const result =
      await this.organizationRepo.getOrganizationDashboardDetails(
        organizationId,
      );
    this.loadingModule.endLoading();

    if (result.success && result.data) {
      return result.data;
    }
  }

  public async purchaseAddons(organizationId: string, addons: any) {
    this.loadingModule.startLoading();
    const result = await this.subscriptionRepo.purchaseAddons(
      addons,
      organizationId,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    } else {
      toast.error(getErrorFromCode(''), toastConfig);
    }
  }

  public async purchaseRecurringAddons(
    organizationId: string,
    addons: any,
    tier: number,
  ) {
    this.loadingModule.startLoading();
    const result = await this.subscriptionRepo.upgradeSubscription(
      organizationId,
      tier,
      addons,
    );
    this.loadingModule.endLoading();
    console.log('res', result);
    if (result.success && result.data) {
      return result.data;
    } else {
      toast.error(getErrorFromCode(''), toastConfig);
      return result;
    }
  }
}

export default MarketplaceStore;
