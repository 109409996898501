import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  welcome: 'Welcome',
  inThisCourse: 'In this course',
  lessons: 'Lessons',
  minApprox: 'min. Approx.',
  minutes: 'Minutes',
  minute: 'Minute',
  courses: 'Courses',
  readyForIt: 'Get Started Today',
  daysLeft: (amountOfDays: number) =>
    `${amountOfDays === 1 ? 'Day' : 'Days'} left`,
  lessonsAmount: (amountOfLessons: number) =>
    `${amountOfLessons === 1 ? 'Lesson' : 'Lessons'}`,
  continue: 'Start Lesson',
  start: 'Enroll Now',
};

const welsh = {
  welcome: 'Croeso',
  inThisCourse: 'Yn y cwrs hwn',
  lessons: 'Gwersi',
  minApprox: 'isaf. Amcangyfrif.',
  minutes: 'Munudau',
  minute: 'Munud',
  courses: 'Cyrsiau',
  readyForIt: 'Dechreuwch Heddiw',
  daysLeft: (amountOfDays: number) =>
    `${amountOfDays === 1 ? 'Dydd yn Ar ôl' : 'Dyddiau yn Ar ôl'}`,
  lessonsAmount: (amountOfLessons: number) =>
    `${amountOfLessons === 1 ? 'Gwers' : 'Gwersi'}`,
  continue: 'Dechreuwch y Gwers',
  start: 'Cofrestrwch Nawr',
};

export const courseHomeText: any = getLocalizationBasedOnLang(english, welsh);
