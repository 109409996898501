import React, {useEffect, useState} from 'react';
import {lessonEditorText} from '../../../lessonEditorText';
import {LessonContentFreeformShapeChoice} from '../../../../../data/models/LessonContent/LessonContentBase';
import EditorOptionsFooter from '../../../../../uiToolkit/Editor/EditorOptionsFooter';
import TextInput from '../../../../../uiToolkit/Inputs/TextInput';
import Button from '../../../../../uiToolkit/Buttons/Button';
import YesNoOption from '../../../../../uiToolkit/Inputs/YesNoOption';
import ReplaceNode from '../../ReplaceNode';

interface Props {
  choiceShape: LessonContentFreeformShapeChoice;
  onSave: (title: string, isCorrect: boolean | null) => void;
  isLoading: boolean;
  onDelete: () => void;
  isPublished?: boolean;
  openEditOverlay: () => void;
}

const FreeformShapeOptionsUI = React.memo(
  ({
    choiceShape,
    onSave,
    isLoading,
    onDelete,
    isPublished,
    openEditOverlay,
  }: Props) => {
    const [title, setTitle] = useState(
      (choiceShape as LessonContentFreeformShapeChoice).title,
    );
    const [isCorrect, setIsCorrect] = useState(
      (choiceShape as LessonContentFreeformShapeChoice).isCorrect,
    );

    useEffect(() => {
      setTitle((choiceShape as LessonContentFreeformShapeChoice).title);
      setIsCorrect((choiceShape as LessonContentFreeformShapeChoice).isCorrect);
    }, [choiceShape]);

    return (
      <div>
        <TextInput
          heading={lessonEditorText.title}
          value={title}
          onChange={setTitle}
          placeholder={lessonEditorText.titlePlaceholder}
          uiType="box"
          className="OptionsInput"
        />
        {isCorrect !== null && (
          <YesNoOption
            value={isCorrect}
            onSelect={setIsCorrect}
            title={lessonEditorText.isCorrect}
            uiType="box"
            className="mb-3"
          />
        )}
        <Button
          title={lessonEditorText.editOverlay}
          onClick={openEditOverlay}
          uiType="box"
          className="w-100"
        />
        <ReplaceNode currentNode={choiceShape} />
        <EditorOptionsFooter
          onSave={() => onSave(title, isCorrect)}
          saveEnabled={title.trim().length > 0}
          onDelete={onDelete}
          isLoading={isLoading}
          deleteDisabled={isPublished}
        />
      </div>
    );
  },
);

export default FreeformShapeOptionsUI;
