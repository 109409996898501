import * as Icons from '../../../assets/images';
import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';
import {APP_PERMISSIONS} from '../../../data/permissions';
import {isSandbox} from '../../../utils/envHelper';

export const navConfig = (id?: string) => [
  {
    icon: Icons.homeIcon,
    title: getLocalizationBasedOnLang('Workspace', 'Gweithle'),
    route: `/manage/${id}/workspace`,
    activeIcon: 'Home',
    createOption: false,
    reqireSubscription: false,
    permission: APP_PERMISSIONS.ORG_ACCESS_DASHBOARD,
  },
  {
    icon: Icons.fileIcon,
    title: getLocalizationBasedOnLang('Library', 'Llyfrgell'),
    route: `/manage/${id}/library`,
    activeIcon: 'Page',
    createOption: false,
    permission: APP_PERMISSIONS.ORG_ACCESS_LIBRARY,
    reqireSubscription: true,
  },
  {
    icon: Icons.bookIc,
    title: getLocalizationBasedOnLang('Courses', 'Cyrsiau'),
    route: `/manage/${id}/courses`,
    activeIcon: 'Course',
    createOption: true,
    permission: APP_PERMISSIONS.ORG_LIST_COURSES,
    createPermission: APP_PERMISSIONS.ORG_CREATE_COURSES,
    createOptionTooltip: getLocalizationBasedOnLang(
      'Create Course',
      'Creuwch Gyrsiau',
    ),
    reqireSubscription: true,
  },
  {
    icon: Icons.usersNavIcon,
    title: getLocalizationBasedOnLang('Learners', 'Dysgwyr'),
    route: `/manage/${id}/learners/general`,
    overrideCreateLink: `/manage/${id}/learners/create`,
    activeIcon: 'Users',
    createOption: isSandbox ? false : true,
    permission: APP_PERMISSIONS.ORG_LIST_LEARNERS,
    createPermission: APP_PERMISSIONS.ORG_CREATE_LEARNERS,
    createOptionTooltip: getLocalizationBasedOnLang(
      'Add Learners',
      'Ychwanegwch Ddysgwyr',
    ),
    reqireSubscription: true,
  },
  {
    icon: Icons.settingsIcon,
    title: getLocalizationBasedOnLang('Settings', 'Gosodiadau'),
    route: `/manage/${id}/settings/general`,
    activeIcon: 'Settings',
    createOption: false,
    reqireSubscription: false,
    permission: APP_PERMISSIONS.ORG_MANAGE_SETTINGS,
  },
];

export const helpConfig = {
  icon: Icons.helpIc,
  title: getLocalizationBasedOnLang('Help & Tutorials', 'Cymorth a Thiwtorïau'),
  route: `https://help.tapybl.com/support/home`,
  activeIcon: 'Help',
  createOption: false,
};

export const contactConfig = {
  icon: Icons.helpIc,
  title: getLocalizationBasedOnLang('Contact us', 'Cysylltwch â Ni'),
  route: `https://help.tapybl.com/support/tickets/new?ticket_form=ask_a_question`,
  activeIcon: 'Mail',
  createOption: false,
};
