import React from 'react';
import './styles.css';

interface Props {
  avatar?: string;
  name?: string;
}

const OrganizationBadge = React.memo(({avatar, name}: Props) => {
  return (
    <div className="OrganizationBadge">
      {avatar && <img src={avatar} />}
      <p>{name}</p>
    </div>
  );
});

export default OrganizationBadge;
