import React, {useEffect, useState} from 'react';
import {appText} from '../../assets/text/appText';
import Button from '../Buttons/Button';
import './styles.css';
import Checkbox from '../Inputs/Checkbox';

interface Props {
  title: string | React.ReactElement;
  onAccept: () => void;
  onCancel: () => void;
  shown: boolean;
  acceptText?: string;
  cacheAliasForDontShow?: string;
}

const ConfirmationModal = React.memo(
  ({
    title,
    onAccept,
    onCancel,
    shown,
    acceptText,
    cacheAliasForDontShow,
  }: Props) => {
    const [dontShow, setDontShow] = useState(false);
    const [ready, setReady] = useState(false);

    useEffect(() => {
      if (shown && cacheAliasForDontShow) {
        const cachedDontShow = localStorage.getItem(cacheAliasForDontShow);
        if (cachedDontShow === 'true') {
          accept();
        } else {
          setReady(true);
        }
      } else if (shown) {
        setReady(true);
      }
    }, [shown]);

    const accept = () => {
      setReady(false);
      onAccept();
    };

    const onCancelClick = () => {
      if (cacheAliasForDontShow) {
        localStorage.removeItem(cacheAliasForDontShow);
      }
      onCancel();
    };

    const onToggleShow = () => {
      if (cacheAliasForDontShow) {
        localStorage.setItem(cacheAliasForDontShow, (!dontShow).toString());
        setDontShow(!dontShow);
      }
    };

    return shown && ready ? (
      <div className="ConfirmationModal">
        <div className="ConfirmationModalContainer">
          <h2>{title}</h2>
          <div className="d-flex flex-row justify-content-center">
            {cacheAliasForDontShow && (
              <Checkbox
                text={appText.dontShowAgain}
                checked={dontShow}
                onCheck={onToggleShow}
                slim
              />
            )}
          </div>
          <div className="ButtonsContainer">
            <Button
              title={appText.cancel}
              onClick={onCancelClick}
              uiType="text"
            />
            <Button
              title={acceptText ? acceptText : appText.accept}
              onClick={accept}
              className="AcceptButton"
              uiType="action"
            />
          </div>
        </div>
      </div>
    ) : (
      <div />
    );
  },
);

export default ConfirmationModal;
