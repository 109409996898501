import {makeAutoObservable, runInAction} from 'mobx';
import OrganizationRepository from '../../../data/repositories/OrganizationRepository';
import LoadingModule from '../../../modules/LoadingModule/LoadingModule';
import {DashboardData} from '../../../data/models/Workspace/Dashboard';

class WorkspaceStore {
  private organizationRepo = new OrganizationRepository();

  private loadingModule = new LoadingModule();

  private dashboardDataCache?: DashboardData;

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public get dashboardData() {
    return this.dashboardDataCache;
  }

  public async fetchDashboardDetails(organizationId: string) {
    this.loadingModule.startLoading();
    const result =
      await this.organizationRepo.getOrganizationDashboardDetails(
        organizationId,
      );
    this.loadingModule.endLoading();
    runInAction(() => {
      if (result.success && result.data) {
        this.dashboardDataCache = result.data;
      }
    });
  }
}

export default WorkspaceStore;
