export interface SubscriptionPermissions {
  permissions: {
    [key: string]: boolean;
  };
  storageLimit: number;
  userQty: number;
  maxResolution: number;
}

export const GRADABLE_LESSONS_FEATURE = 'gradable_lessons';
export const WHITE_LABEL_CUSTOMIZATION =
  'white_label_branding_workspace_customization';
export const JUMP_TO_SUBSCRIPTION_FEATURE = 'jump_to';
export const SCORM_RUNTIME_SUB_FEATURE = 'scorm_runtime';
export const LTI_SUB_FEATURE = 'scorm_runtime';
export const STORAGE_INTEGRATION_SUB_FEATURE =
  'content_provider_integration_vimeo_yt';

export const organizationHasFeature = (permissions: any, feature: string) => {
  return permissions[feature];
};
