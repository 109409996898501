import React, {useCallback, useEffect, useState} from 'react';
import PathSvgHandles from './handles';
import {Point} from '../../../../data/models/LessonContent/ContentFreeform';
import {MIN_SHAPE_POINTS} from './utils';

interface Props {
  shapePoints: Point[];
  updateShapePoints?: (points: Point[]) => void;
  onClick?: () => void;
  hideShape?: boolean;
}

const EditorSvgPath = React.memo(
  ({shapePoints, updateShapePoints, onClick, hideShape}: Props) => {
    const [points, setPoints] = useState<{[key: number]: Point}>([]);
    const [position, setPosition] = React.useState({
      active: false,
      offset: {x: 0, y: 0},
    });

    useEffect(() => {
      if (!position.active) {
        notifyParentAboutPointsChange();
      }
    }, [position]);

    useEffect(() => {
      if (shapePoints.length) {
        const pointsObj = {};
        Object.assign(pointsObj, shapePoints);
        setPoints(pointsObj);
      }
    }, [shapePoints]);

    const onPathClick = (e: any) => {
      if (onClick) {
        onClick();
        e.stopPropagation();
      }
    };

    const handlePointerDown = useCallback((e: any) => {
      if (!updateShapePoints) return;
      const el = e.target;
      const bbox = e.target.getBoundingClientRect();
      const x = e.clientX - bbox.left;
      const y = e.clientY - bbox.top;
      el.setPointerCapture(e.pointerId);
      setPosition({
        ...position,
        active: true,
        offset: {
          x,
          y,
        },
      });
    }, []);

    const handlePointerMove = (e: any) => {
      const bbox = e.target.getBoundingClientRect();
      const x = e.clientX - bbox.left;
      const y = e.clientY - bbox.top;
      if (position.active) {
        let newPoints = {...points};
        const handles = Object.keys(points);
        handles.forEach((handle: string) => {
          newPoints = {
            ...newPoints,
            [parseInt(handle, 10)]: {
              x: points[parseInt(handle, 10)].x - (position.offset.x - x),
              y: points[parseInt(handle, 10)].y - (position.offset.y - y),
            },
          };
        });
        setPoints(newPoints);
      }
    };

    const handlePointerUp = useCallback(() => {
      setPosition({
        ...position,
        active: false,
      });
    }, []);

    const notifyParentAboutPointsChange = () => {
      if (!updateShapePoints) return;
      if (Object.keys(points).length >= MIN_SHAPE_POINTS) {
        updateShapePoints(
          Object.keys(points)
            .sort((x, y) => (x < y ? 1 : -1))
            .map(key => points[parseInt(key, 10)]),
        );
      }
    };

    const getObjectPath = useCallback(() => {
      let path = 'M';
      if (Object.keys(points).length === 0) {
        return '';
      }

      Object.keys(points)
        .sort((x, y) => (x < y ? 1 : -1))
        .forEach((handle: string) => {
          if (points[parseInt(handle, 10)]) {
            path +=
              points[parseInt(handle, 10)].x +
              ' ' +
              points[parseInt(handle, 10)].y +
              ' ';
          }
        });
      path += 'z';
      return path;
    }, [points]);

    return (
      <g>
        <path
          fillOpacity={0.4}
          fill={hideShape ? 'transparent' : '#3DA2FF'}
          d={getObjectPath()}
          onPointerDown={handlePointerDown}
          onPointerUp={handlePointerUp}
          onPointerMove={handlePointerMove}
          onClick={onPathClick}
        />
        {updateShapePoints && (
          <PathSvgHandles
            points={points}
            setPoints={setPoints}
            onDragFinished={notifyParentAboutPointsChange}
          />
        )}
      </g>
    );
  },
);

export default EditorSvgPath;
