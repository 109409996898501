import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  save: 'Save',
  cancel: 'Cancel',
  edit: 'Edit',
  editRole: 'Edit user role',
  deleteUser: 'Delete user',
  activateUser: 'Activate user',
  areYouSure: 'Are you sure you want to delete ',
  username: 'Name',
  email: 'Email',
  type: 'Type',
  status: 'Status',
  actions: 'Actions',
  invite: 'Invite new users to your workspace',
  inviteSubtitle: 'Add users and assign their roles.',
  userEmail: 'User Email',
  role: 'Role',
  selectRole: 'Select user role',
  inviteAction: 'Invite',
  youSureDeleteMember: 'Are you sure you want to delete this Workspace member?',
  staffEditSubtitle: 'Assign user a different role',
  memberDeleted: 'User deleted',
  memberActivated: 'User activated',
  somethingWentWrong: 'Something went wrong',
  ltiPrompt:
    'Enhance your educational experience effortlessly with our LTI integration. Seamlessly connect external tools and resources to your learning management system, simplifying access and fostering a cohesive learning environment for both educators and students.',
  setupLti: 'Setup LTI Integration now',
  newLtiIntegration: 'New LTI Platform integration',
  ltiName: 'Name',
  ltiNamePlaceholder: 'Platform Name',
  platformType: 'LMS System',
  platformTypePlaceholder: 'Select your LMS system',
  accessTokenUrl: 'Access Token Url',
  accessTokenPlaceholder: 'Access token Url (can be found in your LMS)',
  authorizeUrl: 'Authorize Url',
  authorizeUrlPlaceholder: 'Authorize Url (can be found in your LMS)',
  issuer: 'Issuer',
  issuerPlaceholder: 'Issuer (can be found in your LMS)',
  jwkSetUrl: 'JWK Set Url',
  jwkSetUrlPlaceholder: 'JWK Set Url (can be found in your LMS)',
  clientId: 'Client Id',
  clientIdPlaceholder: 'Client Id (can be found in your LMS)',
  toolDataTitle: 'Tapybl LTI Tool',
  loginUrl: 'Login URL:',
  redirectionUrl: 'Redirection URL:',
  publicKeySetUrl: 'Public Key Set URL:',
  yourPlatforms: 'Your platforms:',
  addNewPlatform: 'Add new platform',
  noPlatforms: "Looks like you don't have any platforms configured yet",
  ltiConnection: 'LTI connection',
  upgradeNow: 'Upgrade now!',
  editIntegration: 'Edit Integration',
  deleteIntegration: 'Delete Integration',
  areYouSureDeletePlatform: 'Are you sure you want to delete this platform?',
  delete: 'Delete',
  copyEmail: 'Copy Email',
  expirationDate: 'Subscription expiration date',
  cardDetails: 'Card details',
  unscubscribeInfo:
    'If you wish, you have the option to unsubscribe from your current Plan.',
  unsubscribe: 'Unsubscribe',
  areYouSureCancelSubscription:
    'Are you sure you want to cancel your subscription?',
  trialSubscription: 'Trial Subscription',
  subscriptionTier: 'Subscription Tier',
  upgradeToUseLti: 'Upgrade Subscription Plan To Use Lti',
  changePaymentMethodInfo:
    'If you need to update your card or change it, click the Link below',
  changePaymentMethod: 'Update Payment Method',
  invoices: 'Invoices',
  otherActions: 'Other Actions',
  viewPdf: 'View PDF',
  setupVimeoStorage: 'Setup Vimeo Integration',
  vimeoClientSecret: 'Vimeo Access Token',
  vimeoClientPlaceholder: 'Access Token',
  vimeoAdded: 'Vimeo Integration Setup Success',
  vimeoPrompt:
    'Easily access and manage your Vimeo-hosted videos with our seamless integration system. Streamline your experience by effortlessly browsing and selecting your video content directly from Vimeo, all within our platform. Enjoy the convenience of centralized management while maximizing the potential of your Vimeo library.',
  vimeoConfigured: 'Your key has been added',
  invitationSent: 'Invitation Sent',
  detailsUpdated: 'Workspace details updated',
  vimeoClientSecretInstructions:
    'For help configuring your Vimeo integration, check out our setup guide: ',
  ltiClientSecretInstructions:
    'For help configuring your LTI integration, check out our setup guide: ',
  removeIntegration: 'Remove Integration',
  removeVimeoIntegration:
    'Are you sure you want to remove your current Vimeo integration?',
  removeVimeoIntegrationWarning:
    'Warning: This will remove all Vimeo-connected videos in your lessons.',
  featuresList: 'Full List of Features',
  editConfirmation: 'Are you sure you want to change the role for this user?',
  editRoleSuccess: 'User role changed',
};

const welsh = {
  save: 'Cadw',
  cancel: 'Canslo',
  edit: 'Golygu',
  editRole: 'Golygu rôl y defnyddiwr',
  deleteUser: 'Dileu defnyddiwr',
  activateUser: 'Gweithredu defnyddiwr',
  areYouSure: "Ydych chi'n siŵr eich bod am ddileu?",
  username: 'Enw',
  email: 'e-bost',
  type: 'Math',
  status: 'Statws',
  actions: 'Gweithredoedd',
  invite: "Gwahodd defnyddwyr newydd i'ch Gweithle",
  inviteSubtitle: 'Ychwanegwch ddefnyddwyr a dyrannwch eu rôl.',
  userEmail: 'E-bost y defnyddiwr',
  role: 'Rôl',
  selectRole: 'Dewis rôl y defnyddiwr',
  inviteAction: 'Gwahodd',
  youSureDeleteMember:
    "Ydych chi'n siŵr eich bod am ddileu aelod y Gweithle hwn?",
  staffEditSubtitle: "Dyrannwch rôl wahanol i'r defnyddiwr",
  memberDeleted: "Defnyddiwr wedi'i ddileu",
  memberActivated: "Defnyddiwr wedi'i weithredu",
  somethingWentWrong: "Rhoddodd rhywbeth o'i le",
  ltiPrompt:
    "Gwella eich profiad addysgol yn ddi-dor gyda'n hymintegraeth LTI. Cysylltwch yn ddi-dor â'ch system rheoli dysgu â'r offer ac adnoddau allanol, gan simplifio'r mynediad a meithrin amgylchedd dysgu cydlynol i'r athrawon a'r myfyrwyr.",
  setupLti: 'Sefydlwythwch Integreiddio LTI nawr',
  newLtiIntegration: 'ntegreiddio llwyfan LTI newydd',
  ltiName: 'Enw',
  ltiNamePlaceholder: 'Llwyfan Enw',
  platformType: 'System LMS',
  platformTypePlaceholder: 'Dewiswch eich system LMS',
  accessTokenUrl: 'URL y Tocyn Mynediad',
  accessTokenPlaceholder:
    'URL y Tocyn Mynediad (gallwch ei ddod o hyd iddo yn eich system LMS)',
  authorizeUrl: 'URL Awdurdodi',
  authorizeUrlPlaceholder:
    'URL Awdurdodi (gallwch ei ddod o hyd iddo yn eich system LMS)',
  issuer: 'Cyhoeddwr',
  issuerPlaceholder:
    'Cyhoeddwr (gallwch ei ddod o hyd iddo yn eich system LMS)',
  jwkSetUrl: 'URL Set JWK',
  jwkSetUrlPlaceholder:
    'URL Set JWK (gallwch ei ddod o hyd iddo yn eich system LMS)',
  clientId: 'ID y Cwsmer',
  clientIdPlaceholder:
    'ID y Cwsmer (gallwch ei ddod o hyd iddo yn eich system LMS)',
  toolDataTitle: 'Tapybl Teclyn LTI',
  loginUrl: 'URL Mewngofnodi',
  redirectionUrl: 'URL Cyfeirio',
  publicKeySetUrl: 'URL Set Allweddol Gyhoeddus',
  yourPlatforms: 'Eich Llwyfannau',
  addNewPlatform: 'Ychwanegwch llwyfan newydd',
  noPlatforms:
    "Mae'n edrych fel nad oes gennych unrhyw lwyfannau wedi'u ffurfweddu eto",
  ltiConnection: 'Cysylltiad LTI',
  upgradeNow: 'Uwchraddio nawr!',
  editIntegration: "Golygu'r integreiddio",
  deleteIntegration: 'Dileu integreiddio',
  areYouSureDeletePlatform:
    "Ydych chi'n siŵr eich bod am ddileu'r llwyfan hwn?",
  delete: 'Dileu',
  copyEmail: 'Copïo e-bost',
  expirationDate: 'Dyddiad dod i ben y tanysgrifiad',
  cardDetails: 'Manylion y cerdyn',
  unscubscribeInfo:
    "Os dymunwch, mae gennych y dewis i ddad-gofrestru o'ch Cynllun cyfredol",
  unsubscribe: 'Dad-gofrestru',
  areYouSureCancelSubscription:
    "Ydych chi'n siŵr eich bod am ganslo'ch tanysgrifiad?",
  trialSubscription: 'Tanysgrifiad Treial',
  upgradeToUseLti: "Gwella'ch Cynllun Tanysgrifiad i Ddefnyddio LTI",
  changePaymentMethodInfo:
    'Os oes angen i chi ddiweddaru eich cerdyn neu ei newid, cliciwch ar y ddolen isod',
  changePaymentMethod: "Diweddaru'r Dull Taliad",
  invoices: 'Anfonebau',
  otherActions: 'Gweithredoedd Eraill',
  viewPdf: 'Gweld PDF',
  setupVimeoStorage: 'Sefydlwch Integreiddio Vimeo',
  vimeoClientSecret: 'Tocyn Mynediad Vimeo',
  vimeoClientPlaceholder: 'Tocyn Mynediad',
  vimeoAdded: 'Llwyddiant Gweithredu Integreiddio Vimeo',
  vimeoPrompt:
    "Cyrchwch a rheolwch eich fideos sydd wedi'u cynnal ar Vimeo yn hawdd gyda'n system integreiddio ddi-dor. Gwella eich profiad trwy grwydro a dewis eich cynnwys fideo yn ddi-dor yn uniongyrchol o Vimeo, popeth o fewn ein platfform. Mwynhewch y cyfleustra o reoli canolog tra'n gwella potensial eich llyfrgell Vimeo.",
  vimeoConfigured: "Mae eich allwedd wedi'i hychwanegu",
  invitationSent: "Gwahoddiad wedi'i anfon",
  detailsUpdated: "Manylion y Gweithle wedi'u diweddaru",
  vimeoClientSecretInstructions:
    'Am gymorth gyda chyflunio eich integreiddio Vimeo, edrychwch ar ein canllaw gosod ',
  ltiClientSecretInstructions:
    'Am gymorth gyda chyflunio eich integreiddio LTI, edrychwch ar ein canllaw gosod ',
  removeIntegration: "Tynnu'r Integreiddio",
  removeVimeoIntegration:
    "Ydych chi'n siŵr eich bod am dynnu'ch integreiddio Vimeo cyfredol?",
  removeVimeoIntegrationWarning:
    "Rhybudd: Bydd hyn yn tynnu pob fideo sydd wedi'u cysylltu â Vimeo yn eich gwersi",
  editConfirmation: 'Are you sure you want to change the role for this user?',
  editRoleSuccess: 'User role changed',
};

export const settingsText: any = getLocalizationBasedOnLang(english, welsh);
