import {RecentUserSignup} from '../models/Analytics';
import {PaginatedApiReponse} from '../models/ApiResponse';
import {OrgLastAccessedModel} from '../models/Oganization/OrgLastAccessedModel';
import {apiClient} from './baseApiClient';

class AnalyticsApi {
  public async getAdminAnalytics() {
    return apiClient.get(`/admin/statistic`);
  }

  public async getLastAccessData(
    page: number,
    pageSize: number,
  ): Promise<PaginatedApiReponse<OrgLastAccessedModel>> {
    return apiClient.post(`admin/organizations/last_access`, {page, pageSize});
  }

  public async getRecentSignups(
    page: number,
    pageSize: number,
  ): Promise<PaginatedApiReponse<RecentUserSignup>> {
    return apiClient.post(`admin/users`, {page, pageSize});
  }
}

export default AnalyticsApi;
