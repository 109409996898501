import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  learner: 'Learner',
  email: 'Email',
  finished: 'Completed',
  accepted: 'Accepted',
  notPublished: 'To view learners results you need to publish this course',
};

const welsh = {
  learner: 'Dysgwyr',
  email: 'Email',
  finished: "wedi'i cwblhau",
  accepted: "wedi'i derbyn",
  notPublished:
    "I weld canlyniadau'r dysgwyr, mae angen i chi gyhoeddi'r cwrs hwn",
};

export const courseGradebookText: any = getLocalizationBasedOnLang(
  english,
  welsh,
);
