import React, {useEffect, useState} from 'react';
import StaffInviteUI from '../view/StaffInviteUI';
import {inject, observer} from 'mobx-react';
import {useNavigate, useParams} from 'react-router-dom';
import {Role} from '../../../../data/models/Oganization/Role';
import OrganizationStaffStore from '../../store/OrganizationStaffStore';
import NavigationStore from '../../../../navigation/store/NavigationStore';
import {toast} from 'react-toastify';
import {settingsText} from '../../settingsText';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';

interface Props {
  organizationStaffStore?: OrganizationStaffStore;
  navigationStore?: NavigationStore;
}

const StaffInviteContainer = inject(
  'organizationStaffStore',
  'navigationStore',
)(
  observer(({organizationStaffStore, navigationStore}: Props) => {
    const [roles, setRoles] = useState<Role[]>([]);
    const {organizationId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      if (organizationId) {
        organizationStaffStore!
          .getOrganizationRoles(organizationId)
          .then(setRoles);
      }
      return () => {
        organizationStaffStore!.clearErrors();
      };
    }, []);

    const onSubmit = async (email: string, roleId: string) => {
      if (organizationId) {
        const success = await organizationStaffStore!.inviteOrgStaffMember(
          organizationId,
          email,
          roleId,
        );
        if (success) {
          toast.success(settingsText.invitationSent, toastConfig);
          navigationStore!.getOrganizationDashboardAnalytics(organizationId);
          onCancel();
        }
      }
    };

    const onCancel = () => {
      navigate(`/manage/${organizationId}/settings/users`);
    };

    const clearErrors = () => {
      organizationStaffStore!.clearErrors();
    };

    return (
      <StaffInviteUI
        onSubmit={onSubmit}
        onCancel={onCancel}
        isLoading={organizationStaffStore!.isLoading}
        errors={organizationStaffStore!.errors}
        roles={roles}
        clearErrors={clearErrors}
      />
    );
  }),
);

export default StaffInviteContainer;
