import {redirect} from 'react-router-dom';
import AuthRepository from '../data/repositories/AuthRepository';
import {APP_PERMISSIONS} from '../data/permissions';
import {
  ORGANIZATION_NO_SUBSCRIPTION,
  ORGANIZATION_SUBSCRIPTION_ACTIVE,
  ORGANIZATION_USER_LEARNER,
  ORGANIZATION_USER_STAFF,
  OrganizationDetails,
} from '../data/models/Oganization/Organization';
import OrganizationRepository from '../data/repositories/OrganizationRepository';

const landingLoader = async () => {
  const repo = new AuthRepository();
  const user = repo.getUser();
  if (user) {
    throw redirect('/home');
  }
  return {user};
};

const rootLoader = async () => {
  const repo = new AuthRepository();
  const user = repo.getUser();
  if (user) {
    throw redirect('/home');
  } else {
    throw redirect('/auth/login');
  }
  return {user};
};

const secureRouteLoader = () => {
  const repo = new AuthRepository();
  const user = repo.getUser();
  if (!user) {
    throw redirect('/auth/login');
  }
  if (!user.emailConfirmed) {
    throw redirect('/auth/confirm_email');
  }
  return {user};
};

const homeLoader =
  (userPermissions: string[], userOrganization?: OrganizationDetails) =>
  async () => {
    const repo = new AuthRepository();
    const user = repo.getUser();
    if (!user) {
      throw redirect('/auth/login');
    }
    if (!user.emailConfirmed) {
      throw redirect('/auth/confirm_email');
    }

    if (userPermissions.length === 0) {
      const orgRepo = new OrganizationRepository();
      const newPermissions = await orgRepo.getMyOrganization();
      if (!newPermissions.success || !newPermissions.data) {
        return {user};
      } else {
        if (newPermissions.data.userRolePermissions.length === 0) {
          return {user};
        } else {
          throw redirect(
            `/manage/${newPermissions.data.organization.id}/workspace`,
          );
        }
      }
    } else if (userOrganization) {
      throw redirect(`/manage/${userOrganization.id}/workspace`);
    } else {
      return {user};
    }
  };

const exploreRouteLoader =
  (userOrganization?: OrganizationDetails) => async () => {
    const repo = new AuthRepository();
    const user = repo.getUser();
    if (!user) {
      throw redirect('/auth/login');
    }
    if (!user.emailConfirmed) {
      throw redirect('/auth/confirm_email');
    }
    if (!userOrganization) {
      const orgRepo = new OrganizationRepository();
      const userOrg = await orgRepo.getMyOrganization();
      if (
        userOrg.success &&
        userOrg.data &&
        userOrg.data.organization &&
        userOrg.data.organization.userType === ORGANIZATION_USER_LEARNER
      ) {
        throw redirect('/home');
      }
    } else if (
      userOrganization &&
      userOrganization.userType === ORGANIZATION_USER_LEARNER
    ) {
      throw redirect('/home');
    }
    return {user};
  };

const workspaceRouteLoader =
  (subscriptionStatus: number, userOrganization?: OrganizationDetails) =>
  async ({params}: any) => {
    const repo = new AuthRepository();
    const user = repo.getUser();
    if (!user) {
      throw redirect('/auth/login');
    }
    if (!user.emailConfirmed) {
      throw redirect('/auth/confirm_email');
    }
    if (userOrganization && userOrganization?.id !== params.organizationId) {
      throw redirect(`/manage/${userOrganization?.id}/workspace`);
    }
    if (!userOrganization) {
      const orgRepo = new OrganizationRepository();
      const userOrg = await orgRepo.getMyOrganization();
      if (!userOrg.success || !userOrg.data) {
        throw redirect('/home');
      } else if (
        userOrg.data.organization &&
        userOrg.data.organization.userType === ORGANIZATION_USER_LEARNER
      ) {
        throw redirect('/home');
      }
      if (userOrg.data.organization.id !== params.organizationId) {
        throw redirect(`/manage/${userOrg.data.organization.id}/workspace`);
      }
    } else if (
      userOrganization &&
      userOrganization.userType === ORGANIZATION_USER_LEARNER
    ) {
      throw redirect('/home');
    } else if (
      userOrganization &&
      userOrganization.userType === ORGANIZATION_USER_STAFF &&
      subscriptionStatus === ORGANIZATION_NO_SUBSCRIPTION
    ) {
      throw redirect(`/subscription/${userOrganization.id}/first_payment`);
    }

    return {user};
  };

const createSubscriptionLoader =
  (
    userPermissions: string[],
    subscriptionStatus: number,
    userOrganizationId?: string,
  ) =>
  async ({params}: any) => {
    const repo = new AuthRepository();
    const user = repo.getUser();
    if (!user) {
      throw redirect('/auth/login');
    }
    if (!user.emailConfirmed) {
      throw redirect('/auth/confirm_email');
    }
    if (userPermissions.length === 0 || !userOrganizationId) {
      const orgRepo = new OrganizationRepository();
      const newPermissions = await orgRepo.getMyOrganization();
      if (!newPermissions.success || !newPermissions.data) {
        return {user};
      } else {
        if (newPermissions.data.organization.id !== params.organizationId) {
          throw redirect(
            `/manage/${newPermissions.data.organization.id}/workspace`,
          );
        }
        if (
          !newPermissions.data.userRolePermissions.includes(
            APP_PERMISSIONS.ORG_MANAGE_SETTINGS,
          )
        ) {
          throw redirect(
            `/manage/${newPermissions.data.organization.id}/workspace`,
          );
        }
      }
    } else if (!userPermissions.includes(APP_PERMISSIONS.ORG_MANAGE_SETTINGS)) {
      throw redirect(`/manage/${params.organizationId}/workspace`);
    }
    if (subscriptionStatus === -1) {
      const orgRepo = new OrganizationRepository();
      const newPermissions = await orgRepo.getMyOrganization();
      if (!newPermissions.success || !newPermissions.data) {
        throw redirect('/home');
      } else {
        if (
          newPermissions.data.subscriptionState ===
          ORGANIZATION_SUBSCRIPTION_ACTIVE
        ) {
          throw redirect(
            `/manage/${newPermissions.data.organization.id}/workspace`,
          );
        }
      }
    } else if (subscriptionStatus === ORGANIZATION_SUBSCRIPTION_ACTIVE) {
      throw redirect(`/manage/${params.organizationId}/workspace`);
    }
    return {user};
  };

const permissionRouteLoader =
  (
    permission: string,
    permissions: string[],
    hasSubscription: boolean,
    organizationId?: string,
  ) =>
  async ({params}: any) => {
    const repo = new AuthRepository();
    const user = repo.getUser();
    if (!user) {
      throw redirect('/auth/login');
    }
    if (!user.emailConfirmed) {
      throw redirect('/auth/confirm_email');
    }
    if (organizationId && params.organizationId !== organizationId) {
      throw redirect(`/manage/${organizationId}/workspace`);
    }
    if (permissions.length === 0 || !organizationId) {
      const orgRepo = new OrganizationRepository();
      const newPermissions = await orgRepo.getMyOrganization();
      if (!newPermissions.success || !newPermissions.data) {
        throw redirect('/permission_error');
      } else {
        if (newPermissions.data.organization.id !== params.organizationId) {
          {
            throw redirect(
              `/manage/${newPermissions.data.organization.id}/workspace`,
            );
          }
        }
        if (!newPermissions.data.userRolePermissions.includes(permission)) {
          throw redirect('/permission_error');
        }
        if (
          newPermissions.data.subscriptionState ===
          ORGANIZATION_SUBSCRIPTION_ACTIVE
        ) {
          throw redirect(`/manage/${params.organizationId}/workspace`);
        }
      }
    } else {
      if (!permissions.includes(permission)) {
        throw redirect('/permission_error');
      }
      if (!hasSubscription) {
        throw redirect(`/manage/${params.organizationId}/workspace`);
      }
    }

    return {user};
  };

const featureRouteLoader =
  (feature: string, features: any, hasSubscription: boolean) =>
  async ({params}: any) => {
    const repo = new AuthRepository();
    const user = repo.getUser();
    if (!user) {
      throw redirect('/auth/login');
    }
    if (!user.emailConfirmed) {
      throw redirect('/auth/confirm_email');
    }
    if (!features) {
      const orgRepo = new OrganizationRepository();
      const newPermissions = await orgRepo.getMyOrganization();
      if (!newPermissions.success || !newPermissions.data) {
        throw redirect('/permission_error');
      } else if (!newPermissions.data.permissions[feature]) {
        throw redirect('/permission_error');
      }
    } else {
      if (!features[feature]) {
        throw redirect('/permission_error');
      }
      if (!hasSubscription) {
        throw redirect(`/manage/${params.organizationId}/workspace`);
      }
    }

    return {user};
  };

const workspaceRootRouteLoader =
  (permissions: string[], organizationDetails?: OrganizationDetails) =>
  async () => {
    const repo = new AuthRepository();
    const user = repo.getUser();
    if (!user) {
      throw redirect('/auth/login');
    }
    if (!user.emailConfirmed) {
      throw redirect('/auth/confirm_email');
    }

    if (permissions.length === 0) {
      const orgRepo = new OrganizationRepository();
      const newPermissions = await orgRepo.getMyOrganization();
      if (!newPermissions.success || !newPermissions.data) {
        throw redirect('/permission_error');
      } else {
        processWorkspacePermissionRedirects(
          newPermissions.data.userRolePermissions,
          newPermissions.data.organization,
        );
      }
    } else {
      processWorkspacePermissionRedirects(permissions, organizationDetails);
    }

    return {user};
  };

const processWorkspacePermissionRedirects = (
  permissions: string[],
  organizationDetails?: OrganizationDetails,
) => {
  if (
    !permissions.includes(APP_PERMISSIONS.ORG_ACCESS_DASHBOARD) &&
    organizationDetails
  ) {
    if (permissions.includes(APP_PERMISSIONS.ORG_LIST_COURSES)) {
      throw redirect(`/manage/${organizationDetails.id}/courses`);
    } else if (permissions.includes(APP_PERMISSIONS.ORG_LIST_FILES)) {
      throw redirect(`/manage/${organizationDetails.id}/library`);
    } else {
      throw redirect('/permission_error');
    }
  } else if (!permissions.includes(APP_PERMISSIONS.ORG_ACCESS_DASHBOARD)) {
    throw redirect('/permission_error');
  }
};

const confirmEmailLoader = () => {
  const repo = new AuthRepository();
  const user = repo.getUser();
  if (!user) {
    throw redirect('/auth/login');
  } else if (user && user.emailConfirmed) {
    throw redirect('/home');
  }
  return {user};
};

export {
  landingLoader,
  secureRouteLoader,
  confirmEmailLoader,
  permissionRouteLoader,
  rootLoader,
  workspaceRouteLoader,
  createSubscriptionLoader,
  exploreRouteLoader,
  workspaceRootRouteLoader,
  homeLoader,
  featureRouteLoader,
};
