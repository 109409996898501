import React from 'react';
import {dragIndicator, folderArrow, folderIc} from '../../../assets/images';
import {Folder} from '../../../data/models/Course/Folder';

interface Props {
  folder: Folder;
  onSelect: (folder: Folder) => void;
  isSelected: boolean;
  isDragging?: boolean;
  onOpen: (folder: Folder) => void;
}

const FolderCard = React.memo(
  ({folder, onSelect, isSelected, isDragging, onOpen}: Props) => {
    const onClick = (e: any) => {
      onSelect(folder);
      e.stopPropagation();
    };

    return (
      <div>
        <div
          className={`FolderCard ${isSelected ? 'FolderCardSelected' : ''}`}
          onClick={onClick}
          onDoubleClick={() => onOpen(folder)}>
          <p>{folder.name}</p>
          <div className="ImageContainer">
            {folder.image ? (
              <img className="Poster" src={folder.image} />
            ) : (
              <img src={folderIc} />
            )}
          </div>
          <div className="DragIconContainer">
            <img src={dragIndicator} />
          </div>
        </div>
        <div
          className="FolderCardArrowContainer"
          style={{
            opacity: isDragging ? 0 : 1,
          }}>
          <img src={folderArrow} />
        </div>
      </div>
    );
  },
);

export default FolderCard;
