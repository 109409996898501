import React from 'react';
import {createOrgText} from '../createOrgText';
import ErrorBox from '../../../uiToolkit/ErrorBox';
import OrgDataForm from '../../../forms/OrgDataForm';
import './styles.css';

interface Props {
  onCancel: () => void;
  onCreateOrganization: (
    name: string,
    primaryColor: string,
    secondaryColor: string,
    type: number,
    image?: File,
  ) => void;
  isLoading: boolean;
  errors: string[];
  clearErrors: () => void;
}

const CreateOrganizationUI = React.memo(
  ({onCreateOrganization, isLoading, errors, clearErrors}: Props) => {
    return (
      <div className="CreateOrganizationModal">
        <div className="CreateOrganizationContainer">
          <div className="Header">
            <h3>{createOrgText.create}</h3>
          </div>
          <ErrorBox
            errorCodes={errors}
            className="ErrorBox"
            clearErrors={clearErrors}
          />
          <OrgDataForm
            onSubmit={onCreateOrganization}
            submitText={createOrgText.submit}
            isLoading={isLoading}
            skipColors
            customNameTitle={createOrgText.nameTitle}
            customNameSubtitle={createOrgText.nameSubtitle}
            customTypeTitle={createOrgText.typeTitle}
            customImageTitle={createOrgText.imageTitle}
          />
        </div>
      </div>
    );
  },
);

export default CreateOrganizationUI;
