import {inject, observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import FileUploadModalBodyUI from '../view/FileUploadModalBodyUI';
import MyLibraryModalStore from '../../MyLibraryModal/store/MyLibraryModalStore';
import {useParams} from 'react-router-dom';
import {fileUploadText} from '../fileUploadText';
import {toast} from 'react-toastify';
import {toastConfig} from '../../../uiToolkit/Toast/toastConfig';

interface Props {
  file: File;
  myLibraryModalStore?: MyLibraryModalStore;
  onSuccess: () => void;
}

const FileUploadModalBodyContainer = inject('myLibraryModalStore')(
  observer(({myLibraryModalStore, file, onSuccess}: Props) => {
    const {organizationId} = useParams();
    const [maxAllowedRes, setMaxAllowedRes] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      setIsLoading(true);
      if (!file.type.includes('video') && organizationId) {
        myLibraryModalStore!
          .uploadLibraryFile(organizationId, file, 0, 0)
          .then(success => {
            if (success) {
              toast.success(fileUploadText.videoUploaded, toastConfig);
              onSuccess();
            }
          });
      } else if (organizationId) {
        myLibraryModalStore!
          .getMaxAllowedResolution(organizationId)
          .then(setMaxAllowedRes);
        setIsLoading(false);
      }
    }, []);

    const onFileUpload = (duration: number, targetRes: number) => {
      if (organizationId) {
        myLibraryModalStore!
          .uploadLibraryFile(organizationId, file, duration, targetRes)
          .then(success => {
            if (success) {
              toast.success(fileUploadText.videoUploaded, toastConfig);
              onSuccess();
            }
          });
      }
    };

    return (
      <FileUploadModalBodyUI
        file={file}
        onFileUpload={onFileUpload}
        isLoading={myLibraryModalStore!.isLoading}
        nonVideoLoading={isLoading}
        maxAllowedRes={maxAllowedRes}
      />
    );
  }),
);

export default FileUploadModalBodyContainer;
