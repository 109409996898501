import React from 'react';
import {
  LessonContentChoiceAnswerMapped,
  LessonContentChoiceMapped,
} from '../../../../data/models/LessonContent/LessonConentMapped';
import './styles.css';
import ChallengeInfoHeader from '../ChallengeInfoHeader/ChallengeInfoHeader';
import {tapyblPlayerText} from '../../tapyblPlayerText';

interface Props {
  choice: LessonContentChoiceMapped;
  onSelectChoice: (choice: LessonContentChoiceAnswerMapped) => void;
  passingScore?: number;
}

const ChallengeBranchOverlay = React.memo(
  ({choice, onSelectChoice, passingScore}: Props) => {
    return (
      <div className="ChallengeBranchOverlay">
        <ChallengeInfoHeader
          passingScore={passingScore}
          typeIcon="icon-typeChallenge-Branch"
          typeTitle={tapyblPlayerText.challengeBranch}
          isGradable
        />
        <div className="Content">
          <p>{choice.question}</p>
          <div className="AnswersContainer">
            {choice.answers.map((item, index) =>
              item.text ? (
                <div
                  key={index.toString()}
                  className="TextChoice"
                  onClick={() => onSelectChoice(item)}>
                  <p>{item.text}</p>
                </div>
              ) : item.imageSource ? (
                <img
                  className="ImageOption"
                  key={index.toString()}
                  src={item.imageSource}
                  onClick={() => onSelectChoice(item)}
                />
              ) : null,
            )}
          </div>
        </div>
      </div>
    );
  },
);

export default ChallengeBranchOverlay;
