import React from 'react';
import {
  USER_STATUS_ACTIVE,
  USER_STATUS_INV_DECLINED,
  USER_STATUS_INV_SENT,
} from '../../data/staticValues/userStatuses';
import {appText} from '../../assets/text/appText';
import './styles.css';

interface Props {
  status: string;
}

const UserActivityIndicator = React.memo(({status}: Props) => {
  if (status === USER_STATUS_ACTIVE) {
    return (
      <div className="UserActivityIndicator">
        <div className="Active">
          <span>{appText.active}</span>
        </div>
      </div>
    );
  }
  if (status === USER_STATUS_INV_SENT) {
    return (
      <div className="UserActivityIndicator">
        <div className="InvSent">
          <span>{appText.invitationSent}</span>
        </div>
      </div>
    );
  }
  if (status === USER_STATUS_INV_DECLINED) {
    return (
      <div className="UserActivityIndicator">
        <div className="Inactive">
          <span>{appText.invitationDeclined}</span>
        </div>
      </div>
    );
  }
  return (
    <div className="UserActivityIndicator">
      <div className="Inactive">
        <span>{appText.inactive}</span>
      </div>
    </div>
  );
});

export default UserActivityIndicator;
