import React from 'react';
import CsvDownloader from 'react-csv-downloader';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {videoSourcesText} from '../../videoSourcesText';
import {downloadTranscript} from '../../../../assets/images';

const columns = [
  {
    id: 'start',
    displayName: 'Start time',
  },
  {
    id: 'end',
    displayName: 'End time',
  },
  {
    id: 'text',
    displayName: 'Transcript',
  },
];

const transcriptTooltip = (
  <Tooltip>{videoSourcesText.downloadTranscript}</Tooltip>
);

interface Props {
  data: any[];
  filename: string;
}

const DownloadTranscriptButton = React.memo(({data, filename}: Props) => {
  return (
    <OverlayTrigger placement="bottom" overlay={transcriptTooltip}>
      <CsvDownloader
        filename={filename}
        extension=".csv"
        separator=";"
        wrapColumnChar="'"
        columns={columns}
        className="Preview"
        datas={data}>
        <img src={downloadTranscript} />
      </CsvDownloader>
    </OverlayTrigger>
  );
});

export default DownloadTranscriptButton;
