import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import CourseAccessGateUI from '../view/CourseAccessGateUI';
import CourseAccessGateStore from '../store/CourseAccessGateStore';
import {useNavigate, useParams} from 'react-router-dom';
import {CourseAccessResult} from '../../../data/models/Course/CourseAccessResult';

interface Props {
  children: any;
  courseAccessGateStore?: CourseAccessGateStore;
  publishedCourse?: boolean;
}

const CourseAccessGateContainer = inject('courseAccessGateStore')(
  observer(({children, courseAccessGateStore, publishedCourse}: Props) => {
    const [role, setRole] = useState<string>();
    const [isLearner, setIsLearner] = useState(false);
    const [hasSubscription, setHasSubscription] = useState(true);
    const {organizationId, courseId} = useParams();

    const navigate = useNavigate();

    useEffect(() => {
      if (courseId) {
        if (publishedCourse) {
          courseAccessGateStore!
            .checkPublishedCourseAccess(courseId)
            .then(res => processAccessResponse(publishedCourse, res));
        } else if (organizationId) {
          courseAccessGateStore!
            .checkCourseAccess(organizationId, courseId)
            .then(res => processAccessResponse(false, res));
        }
      }
    }, []);

    const processAccessResponse = (
      isPublished: boolean,
      courseAccess?: CourseAccessResult,
    ) => {
      if (!courseAccess || !courseAccess?.hasAccess) {
        navigate('/home', {replace: true});
      } else {
        setHasSubscription(!courseAccess.subscriptionExpired);
        if (courseAccess.subscriptionExpired) {
          if (isPublished) {
            setIsLearner(true);
          } else if (organizationId) {
            courseAccessGateStore!.getOrgUserRole(organizationId).then(res => {
              setRole(res.roleName);
              setIsLearner(res.isLearner);
              setHasSubscription(res.isSubscriptionActive);
            });
          }
        }
      }
    };

    if (!hasSubscription) {
      return (
        <CourseAccessGateUI
          role={role}
          isLearner={isLearner}
          hasSubscription={hasSubscription}
          organizationId={organizationId}
        />
      );
    }

    return children;
  }),
);

export default CourseAccessGateContainer;
