export const selectSubscriptionText = {
  selectTitle: 'Select the perfect plan for your needs',
  underTierInfo:
    "tapybl offers a range of plans tailored to your needs. Whether you're experimenting with our Creator Trial or managing advanced learning experiences with the Enterprise plan, you’ll find the perfect fit to create, manage, and analyze your content.",
  fullList: 'Full List of Features',
  tableFootnote: '* Optional features. Contact us for pricing.',
  perYear: ' / year',
  whatIsIncluded: 'What’s included',
  addUsers: 'Add users:',
  perUserPerMonth: ' per user / month',
  perMonth: ' / month',
  features: 'Features',
  pricingCalculator: 'Pricing calculator',
  numberOfAdditionalUsers: 'Number of additional users',
  numberOfUsers: 'Number of users',
  totalCost: 'Total cost per year',
  selectPlan: 'Select plan',
  faq: 'FAQ',
  requestQuote: 'Request quote',
};
