import React, {useEffect} from 'react';
import UserMenuPopUpUI from '../view/UserMenuPopUpUI';
import UserMenuPopUpStore from '../store/UserMenuPopUpStore';
import {useNavigate} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import ModalProviderStore from '../../ModalProvider/store/ModalProviderStore';
import {LOGOUT_CONFIRMATION_MODAL} from '../../ModalProvider/globalModals';
import UserInfoStore from '../../../stores/UserInfoStore';

interface Props {
  userMenuPopUpStore?: UserMenuPopUpStore;
  modalProviderStore?: ModalProviderStore;
  userInfoStore?: UserInfoStore;
  shown: boolean;
  onHide: () => void;
}

const UserMenuPopUpContainer = inject(
  'userMenuPopUpStore',
  'modalProviderStore',
  'userInfoStore',
)(
  observer(
    ({
      userMenuPopUpStore,
      shown,
      modalProviderStore,
      onHide,
      userInfoStore,
    }: Props) => {
      const navigate = useNavigate();

      useEffect(() => {
        document.body.addEventListener('click', onDocumentClick);
        return () => {
          removeEventListener('click', onDocumentClick);
        };
      }, []);

      useEffect(() => {
        if (shown) {
          userInfoStore?.fetchUserInfo();
        }
      }, [shown]);

      const onDocumentClick = () => {
        onHide();
      };

      const onLogout = async (confirmation?: boolean) => {
        if (confirmation === true) {
          await userMenuPopUpStore!.logout();
          navigate('/');
        }
      };

      const onStartLogout = () => {
        modalProviderStore?.openModal(LOGOUT_CONFIRMATION_MODAL, onLogout);
      };

      const onProfileClick = () => {
        navigate(`/profile/profile_settings`);
      };

      return shown ? (
        <UserMenuPopUpUI
          userName={userInfoStore!.userInfo?.name || ''}
          email={userInfoStore!.userInfo?.email || ''}
          onLogout={onStartLogout}
          onProfileClick={onProfileClick}
        />
      ) : null;
    },
  ),
);

export default UserMenuPopUpContainer;
