import React from 'react';
import {VIDEO_LENGTH_OPTIONS} from '../../../data/models/QuestionGeneration/AIGenerationDropdownOptions';
import {generateAIVideosText} from '../generateAIVideosText';
import {Col} from 'react-bootstrap';
import Checkbox from '../../../uiToolkit/Inputs/Checkbox';

interface Props {
  videoDuration?: string;
  setVideoDuration: (value: string) => void;
}

const VideoDuratonStep = React.memo(
  ({videoDuration, setVideoDuration}: Props) => {
    return (
      <Col lg={6} xs={12} className="formBox">
        <h5>
          {generateAIVideosText.videoDurationTitle}
          <h5 className="MandatoryIndicator">*</h5>
        </h5>
        <div className="DurationCheckboxGroup">
          {VIDEO_LENGTH_OPTIONS.map(option => (
            <Checkbox
              key={option.key}
              checked={option.key === videoDuration}
              onCheck={() => setVideoDuration(option.key)}
              text={option.value}
              radio
              slim
            />
          ))}
        </div>
      </Col>
    );
  },
);

export default VideoDuratonStep;
