import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const englishCoockirPromptText = {
  title: 'Enable third party cookies on tapybl for the best experience',
  info: 'To ensure tapybl operates securely and smoothly, we use cookies. Please enable third party cookies or switch to a different browser to continue. For detailed steps on enabling third party cookies, please refer to ',
  guide: 'this guide',
};

const welshCookiePromtText = {
  title: 'Enable third party cookies on tapybl for the best experience',
  info: "Er mwyn sicrhau bod tapybl yn gweithredu'n ddiogel ac yn llyfn, rydym yn defnyddio cwcis. Galluogwch cwcis trydydd parti neu newidiwch i borwr gwahanol i barhau. Am gamau manwl ar alluogi cwcis trydydd parti, cyfeiriwch at",
  guide: 'Y canllaw hwn',
};

export const cookiePromptText: any = getLocalizationBasedOnLang(
  englishCoockirPromptText,
  welshCookiePromtText,
);
