import * as React from 'react';
import {loginText} from '../loginText';
import {Link} from 'react-router-dom';
import AuthHeader from '../../../../uiToolkit/AuthHeader';
import {internalErrorCodes} from '../../../../data/errorCodes';
import ErrorBox from '../../../../uiToolkit/ErrorBox';
import PasswordInput from '../../../../uiToolkit/Inputs/PasswordInput';
import './styles.css';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';
import Button from '../../../../uiToolkit/Buttons/Button';
import {isSandbox} from '../../../../utils/envHelper';
import {CookiePrompt} from '../../../../modules/CookiePrompt';

interface Props {
  onLogin: (email: string, password: string) => void;
  errors: string[];
  isLoading: boolean;
  clearErorrs: () => void;
  onLoginWithGoogle: (idToken: string) => void;
}

const LoginUI = React.memo(
  ({onLogin, errors, isLoading, clearErorrs}: Props) => {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [internalErorrs, setInternalErorrs] = React.useState<string[]>([]);

    // const [buttonWidth, setButtonWidth] = React.useState(0);
    const signinButtonRef = React.useRef<any>(null);

    const onClearErrors = () => {
      setInternalErorrs([]);
      clearErorrs();
    };

    // React.useLayoutEffect(() => {
    //   if (signinButtonRef && signinButtonRef.current) {
    //     setButtonWidth(signinButtonRef.current.offsetWidth);
    //   }
    // }, [signinButtonRef]);

    const onSubmit = () => {
      const newErrors = [];
      if (email.trim() !== '' && password.trim() !== '') {
        onLogin(email.trim(), password.trim());
      } else {
        if (email.trim() === '') {
          newErrors.push(internalErrorCodes.INVALID_EMAIL);
        }
        if (password.trim() === '') {
          newErrors.push(internalErrorCodes.INVALID_PASSWORD);
        }
        setInternalErorrs(newErrors);
      }
    };

    const buttonDisabled = () => {
      return email.trim() === '' || password.trim() === '';
    };

    return (
      <>
        <div className="LoginErrorContainer">
          <ErrorBox
            errorCodes={[...errors, ...internalErorrs]}
            clearErrors={onClearErrors}
          />
        </div>
        <div className="LoginFormContainer">
          <AuthHeader title={loginText.title} subtitle="" />
          <div className="LoginInputContainer">
            <TextInput
              type="email"
              value={email}
              onChange={setEmail}
              placeholder={loginText.email}
              uiType="pill"
            />
          </div>
          <div className="LoginInputContainer">
            <PasswordInput
              value={password}
              onChange={setPassword}
              placeholder={loginText.password}
            />
          </div>
          <Link to="/auth/forgot_password" className="LoginForgotPassword">
            {loginText.forgotPassword}
          </Link>
          <div className="LoginSubmitButtonContainer" ref={signinButtonRef}>
            <Button
              title={loginText.submit}
              onClick={onSubmit}
              isLoading={isLoading}
              disabled={buttonDisabled()}
              uiType="auth_action"
              fullWidth
            />
          </div>
          {/* <div className="GoogleButtonContainer">
            {buttonWidth && (
              <GoogleLogin
                onSuccess={credentialResponse => {
                  onLoginWithGoogle(credentialResponse.credential || '');
                }}
                onError={() => {
                  console.log('Login Failed');
                }}
                theme="outline"
                shape="pill"
                width={buttonWidth}
              />
            )}
          </div> */}
          {/* <Button
            title={loginText.loginWithGoogle}
            onClick={onLoginWithGoogle}
            isLoading={isLoading}
            uiType="auth_action"
            fullWidth
          /> */}
          <p className="LoginDontHaveAccount">
            {loginText.dontHaveAccount}
            <Link
              to={'/auth/signup'}
              className={`LoginSignup ${isSandbox ? 'SignupDisabled' : ''}`}>
              {loginText.signup}
            </Link>
          </p>
        </div>
        <CookiePrompt />
      </>
    );
  },
);

export default LoginUI;
