import React, {useEffect, useState} from 'react';
import './styles.css';
import PageTitle from '../../../uiToolkit/PageTitle';
import {editVideoSourceText} from '../editVideoSourceText';
import Button from '../../../uiToolkit/Buttons/Button';
import {GeneratedVideoChunk} from '../../../data/models/LibraryFile/GeneratedVideoChunk';
import SimplePlayerControlled from '../../../uiToolkit/SimplePlayerControlled';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import TipBox from '../../../uiToolkit/TipBox';
import {MediaFile} from '../../../data/models/LibraryFile/MediaFile';

interface Props {
  onBack: () => void;
  onPublish: () => void;
  chunks: GeneratedVideoChunk[];
  toggleIncludeChunk: (chunk: GeneratedVideoChunk) => void;
  onChunksReorder: (chunks: GeneratedVideoChunk[]) => void;
  onPreview: (chunk: GeneratedVideoChunk) => void;
  isLoading: boolean;
  onPreviewFull: () => void;
  meidaFile?: MediaFile;
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const EditVideoSourceUI = React.memo(
  ({
    onBack,
    onPublish,
    chunks,
    toggleIncludeChunk,
    onChunksReorder,
    onPreview,
    isLoading,
    onPreviewFull,
    meidaFile,
  }: Props) => {
    const [memorizedVideos, setMemorizedVideos] = useState<any[]>([]);

    const getTimelineStartEndSeconds = (index: number) => {
      let timelineSumm = 0;

      chunks.forEach((chunk, iteratorIndex) => {
        if (iteratorIndex <= index && chunk.include) {
          timelineSumm += Math.round(chunk.duration * 100) / 100;
        }
      });
      return {
        start: Math.round((timelineSumm - chunks[index].duration) * 100) / 100,
        end: Math.round(timelineSumm * 100) / 100,
      };
    };

    useEffect(() => {
      if (memorizedVideos.length === 0) {
        const videos: any[] = [];
        chunks.forEach(chunk => {
          videos.push({
            id: chunk.id,
            video: (
              <SimplePlayerControlled
                width={chunk.width / 5}
                height={chunk.height / 5}
                source={chunk.uri}
                hideControls
                uiType="box"
              />
            ),
          });
        });
        setMemorizedVideos([...videos]);
      }
    }, [chunks]);

    const handleDrop = (result: any) => {
      if (!result.destination) {
        return;
      }

      const items = reorder(
        chunks,
        result.source.index,
        result.destination.index,
      );

      onChunksReorder(items);
    };

    const getChunkWidthMultiplier = (duration: number) => {
      let avgDuration = 0;
      chunks.forEach(chunk => {
        avgDuration += chunk.duration;
      });
      avgDuration /= chunks.length;
      const durationPercentage = (duration * 100) / avgDuration;
      if (durationPercentage < 20) {
        return 0.2;
      } else if (durationPercentage >= 20 && durationPercentage < 40) {
        return 0.4;
      } else if (durationPercentage >= 40 && durationPercentage < 60) {
        return 0.6;
      } else if (durationPercentage >= 60 && durationPercentage < 80) {
        return 0.8;
      } else {
        return 1;
      }
    };

    const getDots = (duration: number) => {
      const widthMultiplier = getChunkWidthMultiplier(duration);
      const resArr = [];
      let amount = 0;
      switch (widthMultiplier) {
        case 0.2:
          amount = 2;
          break;
        case 0.4:
          amount = 4;
          break;
        case 0.6:
          amount = 5;
          break;
        case 0.8:
          amount = 8;
          break;
        case 1:
          amount = 10;
          break;
      }
      for (let i = 0; i < amount; i++) {
        resArr.push(<span key={i}>·</span>);
      }
      return resArr;
    };

    return (
      <div className="EditVideoSourceContainer">
        <PageTitle
          title={editVideoSourceText.title}
          iconSymbol="icon-typeVideo"
        />
        <div className="ActionsContainer">
          <Button
            title={editVideoSourceText.back}
            onClick={onBack}
            uiType="text"
          />
          {chunks.length
            ? [
                <Button
                  title={editVideoSourceText.previewVideo}
                  onClick={onPreviewFull}
                  isLoading={isLoading}
                  disabled={!chunks.find(x => x.include)}
                  uiType="hollow"
                  key="preview"
                />,
                <Button
                  title={editVideoSourceText.publishVideo}
                  onClick={onPublish}
                  disabled={!chunks.find(x => x.include)}
                  isLoading={isLoading}
                  key="publish"
                />,
              ]
            : null}
        </div>
        {chunks.length ? (
          <>
            <TipBox tip={editVideoSourceText.tip} />
            <h3>
              {meidaFile?.fileName} {editVideoSourceText.timeline}
            </h3>
            <DragDropContext onDragEnd={handleDrop}>
              <Droppable droppableId="ListContainer" direction="horizontal">
                {provided => (
                  <div
                    className="VideoTimelineContainer"
                    {...provided.droppableProps}
                    ref={provided.innerRef}>
                    {chunks.map((chunk, index) => (
                      <Draggable
                        draggableId={chunk.id || ''}
                        index={index}
                        key={chunk.id}>
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}>
                            <div
                              style={{
                                width: `${
                                  (chunk.width / 5) *
                                  getChunkWidthMultiplier(chunk.duration)
                                }px`,
                              }}>
                              <div className="TimelineIndicatorsContainer">
                                {!chunk.include && <span>·</span>}
                                {getDots(chunk.duration)}
                                <span>
                                  {chunk.include
                                    ? getTimelineStartEndSeconds(index).end +
                                      's'
                                    : '·'}
                                </span>
                              </div>
                              <div style={{position: 'relative'}}>
                                <div
                                  className={`VideoChunk ${
                                    chunk.include ? '' : 'VideoChunkOmmited'
                                  }`}>
                                  {
                                    memorizedVideos.find(x => x.id === chunk.id)
                                      ?.video
                                  }
                                </div>
                                <div className="VideoCover">
                                  <button onClick={() => onPreview(chunk)}>
                                    <span className="icon-typePlay"></span>
                                  </button>
                                </div>
                              </div>
                              <div className="IncludeContainer">
                                <button
                                  onClick={() => toggleIncludeChunk(chunk)}>
                                  {chunk.include ? (
                                    <span className="icon-typeEye"></span>
                                  ) : (
                                    <span className="icon-typeDisabled-Eye"></span>
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </>
        ) : !isLoading ? (
          <div className="NoChunksContainer">
            <p>{editVideoSourceText.noChunks}</p>
          </div>
        ) : null}
      </div>
    );
  },
);

export default EditVideoSourceUI;
