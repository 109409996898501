import * as React from 'react';
import NavBar from '../../../uiToolkit/NavBar';
import {Col, Container, Row} from 'react-bootstrap';
import {homeText} from '../homeText';
import {UserInfo} from '../../../data/models/User/UserInfo';
import {LearningCourseModel} from '../../../data/models/Course/CourseList';
import UserEmptyState from '../components/UserEmptyState';
import OrganizationEmptyState from '../components/OrganizationEmptyState';
import CoursesCard from '../components/CoursesCard';
import './styles.css';
import {
  ORGANIZATION_USER_LEARNER,
  ORGANIZATION_USER_STAFF,
  OrganizationDetails,
} from '../../../data/models/Oganization/Organization';
import LearnerEmptyState from '../components/LearnerEmptyState';
import TablePagination from '../../../uiToolkit/TablePagination';
import {HOME_PAGE_SIZE} from '../store/HomeStore';

interface Props {
  userInfo?: UserInfo;
  userOrganizationDetails?: OrganizationDetails;
  isLoading: boolean;
  onExplore: () => void;
  onCreateOrg: () => void;
  onCreateCourse: () => void;
  learningCourses: LearningCourseModel[];
  onOpenCourse: (courseId: string, teaching?: boolean) => void;
  currentPage: number;
  pagesCount: number;
  onChangePage: (page: number) => void;
}

const HomeUI = React.memo(
  ({
    userInfo,
    isLoading,
    onCreateOrg,
    onExplore,
    onCreateCourse,
    learningCourses,
    onOpenCourse,
    userOrganizationDetails,
    currentPage,
    pagesCount,
    onChangePage,
  }: Props) => {
    return (
      <div>
        <NavBar />
        <Container fluid>
          <Row className="HomeStatisticsContainer">
            <Col xs={12}>
              <div className="StatisticsRow">
                <h2 className="HomeWelcomeText">
                  {homeText.welcome} {userInfo?.name}!
                </h2>
                {userOrganizationDetails &&
                userOrganizationDetails?.userType ===
                  ORGANIZATION_USER_LEARNER ? (
                  <div className="LearnerOrgDetails">
                    {userOrganizationDetails.avatar && (
                      <img src={userOrganizationDetails.avatar} />
                    )}
                    <h2>{userOrganizationDetails.name}</h2>
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="HomeCoursesContainer">
          {learningCourses?.length ? (
            <Row className="HomeCoursesContent">
              <h2 className="HomeCourseTitle">{homeText.learningTitle}</h2>
              {learningCourses.map(course => (
                <CoursesCard
                  key={course.id}
                  course={course}
                  onOpenCourse={() => onOpenCourse(course.id)}
                />
              ))}
            </Row>
          ) : null}

          {!learningCourses.length ? (
            isLoading ? (
              <div />
            ) : userOrganizationDetails?.userType ===
              ORGANIZATION_USER_STAFF ? (
              <OrganizationEmptyState onCreate={onCreateCourse} />
            ) : userOrganizationDetails?.userType ===
              ORGANIZATION_USER_LEARNER ? (
              <LearnerEmptyState organization={userOrganizationDetails} />
            ) : (
              <UserEmptyState onExplore={onExplore} onCreate={onCreateOrg} />
            )
          ) : null}
          {learningCourses?.length ? (
            <TablePagination
              currentPage={currentPage + 1}
              rowCount={pagesCount * HOME_PAGE_SIZE}
              rowsPerPage={HOME_PAGE_SIZE}
              onChangePage={page => onChangePage(page - 1)}
            />
          ) : null}
        </Container>
      </div>
    );
  },
);

export default HomeUI;
