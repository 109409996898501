export const getVideoDimentions = (
  file: File,
): Promise<{width: number; height: number}> => {
  return new Promise(res => {
    const url = URL.createObjectURL(file);
    const video = document.createElement('video');
    video.onloadedmetadata = () => {
      URL.revokeObjectURL(url);
      res({width: video.videoWidth, height: video.videoHeight});
    };
    video.src = url;
    video.load();
  });
};
