import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  create: 'Customize Your Workspace',
  submit: 'Continue to Workspace',
  nameTitle: 'What would you like your workspace to be called?',
  nameSubtitle:
    'This name will be visible to all users within your organization.',
  imageTitle: 'Upload a logo for your organization.',
  typeTitle:
    "Please select the category that most closely fits your organization's type.",
};

const welsh = {
  create: 'Creu Gweithle',
  submit: "Parhewh i'r Gweithle",
  nameTitle: "Beth hoffech i'r gweithle gael ei alw",
  nameSubtitle:
    "Bydd y enw hwn yn weladwy i'r holl ddefnyddwyr yn eich sefydliad",
  imageTitle: 'Llwythwch logo ar gyfer eich sefydliad',
  typeTitle: "Dewiswch y categori sy'n ffitio orau â chyfuniad eich sefydliad",
};

export const createOrgText = getLocalizationBasedOnLang(english, welsh);
