import React from 'react';
import './headerStyles.css';
import {subNavBarLogo} from '../../../../assets/images';
import {Container, Nav, Navbar} from 'react-bootstrap';

const SubscriptionHeader = React.memo(() => {
  return (
    <Navbar fixed="top" expand="lg" bg="dark" variant="dark">
      <Container fluid>
        <Navbar.Brand href="https://tapybl.com/">
          <img src={subNavBarLogo} alt="Logo" />
        </Navbar.Brand>
        <Nav.Link href="#" className="login-button">
          Log in
        </Nav.Link>
        <Navbar.Toggle aria-controls="navbarSupportedContent" />
        <Navbar.Collapse id="navbarSupportedContent">
          <Nav className="me-auto">
            <Nav.Item>
              <Nav.Link href="https://tapybl.com/#features">Features</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://tapybl.com/#solutions">
                Solutions
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://edu.tapybl.com/subscription_tier">
                Pricing
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://tapybl.com/#testimonials">
                Testimonials
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://tapybl.com/#faqs">FAQs</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://tapybl.com/request-demo/" id="contact-us">
                Contact Us
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Nav>
            <Nav.Item>
              <Nav.Link href="https://edu.tapybl.com/" id="login">
                Log in
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="https://edu.tapybl.com/subscription_tier"
                id="free-signup">
                Free sign up
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
});

export default SubscriptionHeader;
