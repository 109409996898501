import React, {useCallback, useEffect, useMemo} from 'react';
import ModalContainer from '../../../uiToolkit/ModalContainer';
import ReactFlow, {
  Background,
  Controls,
  addEdge,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import JourneyNode from '../components/JourneyNode';
import {LessonContentList} from '../../../data/models/LessonContent/LessonContentList';
import {buildNodeTree} from './nodeTreeBuilder';
import {getLayoutedElements} from '../../EditorSettings/layoutSettings';
import './styles.css';
import {LearnerProgressModel} from '../../../data/models/LessonContent/CreateLearnerLessonModel';

interface Props {
  onClose: () => void;
  lessonContent?: LessonContentList;
  progress?: LearnerProgressModel;
}

const LearningJourneyUI = React.memo(
  ({onClose, lessonContent, progress}: Props) => {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const nodeTypes = useMemo(
      () => ({
        journeyNode: JourneyNode,
      }),
      [],
    );

    useEffect(() => {
      if (lessonContent && progress) {
        setNodes([]);
        setEdges([]);
        const {nodes, edges} = buildNodeTree(lessonContent, progress);
        const {nodes: layoutedNodes, edges: layoutedEdges} =
          getLayoutedElements(nodes, edges);
        setNodes(layoutedNodes);
        setEdges(layoutedEdges);
      }
    }, [lessonContent]);

    const onConnect = useCallback(
      (params: any) => setEdges(eds => addEdge(params, eds)),
      [setEdges],
    );

    return (
      <ModalContainer onClose={onClose}>
        <div className="LearnerJourneyContainer">
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            minZoom={0.1}
            fitView>
            <Controls showInteractive={false} />
            <Background />
          </ReactFlow>
        </div>
      </ModalContainer>
    );
  },
);

export default LearningJourneyUI;
