import React from 'react';
import Button from '../../../../../uiToolkit/Buttons/Button';
import './styles.css';
import {appText} from '../../../../../assets/text/appText';

interface Props {
  onDelete: () => void;
  isPublished?: boolean;
}

const ChoiceHeaderOptionsUI = React.memo(({onDelete, isPublished}: Props) => {
  return (
    <div className="ChoiceHeaderOptionsContainer">
      <Button
        title={appText.deleteThisNodeAndChildren}
        onClick={onDelete}
        uiType="text_danger"
        disabled={isPublished}
      />
    </div>
  );
});

export default ChoiceHeaderOptionsUI;
