import {makeAutoObservable} from 'mobx';
import AuthRepository from '../../../../data/repositories/AuthRepository';
import {internalErrorCodes} from '../../../../data/errorCodes';
import OrganizationRepository from '../../../../data/repositories/OrganizationRepository';
import LoadingModule from '../../../../modules/LoadingModule/LoadingModule';

class ConfirmEmailStore {
  private authRepo = new AuthRepository();
  private organizationRepo = new OrganizationRepository();
  private loadingModule = new LoadingModule();

  public errors: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async confirmEmail(code: string) {
    this.loadingModule.startLoading();
    const result = await this.authRepo.confirmEmail(code);
    this.loadingModule.endLoading();
    if (result && result.success) {
      return result.success;
    } else if (result && result.errors) {
      this.setErrors(result.errors);
    } else {
      this.setErrors([internalErrorCodes.SOMETHING_WENT_WRONG]);
    }
    return false;
  }

  public async sendEmailVerification() {
    const result = await this.authRepo.sendEmailVerification();
    if (result && result.success) {
      return result.success;
    } else if (result && result.errors) {
      this.setErrors(result.errors);
    } else {
      this.setErrors([internalErrorCodes.SOMETHING_WENT_WRONG]);
    }
    return false;
  }

  public getUser() {
    return this.authRepo.getUser();
  }

  public clearUser() {
    return this.authRepo.logout();
  }

  public clearErrors() {
    this.errors = [];
  }

  private setErrors(errors: string[]) {
    this.errors = errors;
  }
}

export default ConfirmEmailStore;
