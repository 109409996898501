export interface Learner {
  id: string;
  name: string;
  avatarUrl: string;
  age: number;
  grade: number;
  isVerified: boolean;
}

export const LEARNER_INVITATION_SUCCESS = 0;
export const LEARNER_INVITATION_PARTIAL_SUCCESS = 1;
export const LEARNER_INVITATION_FAIL = 2;

export interface LearnerListItem {
  name: string;
  avatar?: string;
  email: string;
  status: number;
  learnerId: string;
  courses: LearnerListItemCourse[];
}

export interface LearnerListItemCourse {
  name: string;
  progress: number;
  status: number;
  finishedLessons: number;
  courseId: string;
}
