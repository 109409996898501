import React from 'react';
import './styles.css';

interface Props {
  width: number;
  children: React.ReactElement[] | React.ReactElement;
}

const EditorSvgCanvas = React.memo(({width, children}: Props) => {
  return (
    <div style={{width, height: width / 1.77}} className="EditorSvgCanvas">
      <svg width={width} height={width / 1.77}>
        {children}
      </svg>
    </div>
  );
});

export default EditorSvgCanvas;
