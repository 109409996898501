import {makeAutoObservable} from 'mobx';
import LessonContentRepository from '../../../../data/repositories/LessonContentRepository';
import {
  CHALLENGE_BRANCH_TEXT_OPTION,
  LessonContentChallengeBranchTextOption,
} from '../../../../data/models/LessonContent/LessonContentBase';

class AddChallengeBranchTextOptionStore {
  private lessonContentRepo = new LessonContentRepository();
  private isOpen = false;
  private parentId?: string = undefined;
  private onCompletedCallback?: () => void;

  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public openWizard(parentId: string, onCompletedCallback?: () => void) {
    this.isOpen = true;
    this.parentId = parentId;
    this.onCompletedCallback = onCompletedCallback;
  }

  public closeWizard() {
    this.isOpen = false;
    this.parentId = undefined;
    this.onCompletedCallback = undefined;
  }

  public showWizard() {
    return this.isOpen && this.parentId;
  }

  public async createOption(
    organizationId: string,
    lessonId: string,
    answer: string,
  ) {
    if (this.parentId) {
      this.isLoading = true;
      const result = await this.lessonContentRepo.addLessonContent(
        organizationId,
        lessonId,
        {
          data: {
            ivNodeType: CHALLENGE_BRANCH_TEXT_OPTION,
            answer,
            isCorrect: false,
          } as LessonContentChallengeBranchTextOption,
          parentsIds: [this.parentId],
        },
      );
      this.isLoading = false;
      if (result.success && this.onCompletedCallback) {
        this.onCompletedCallback();
        this.closeWizard();
      }
    }
  }
}

export default AddChallengeBranchTextOptionStore;
