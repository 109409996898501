import React from 'react';
import {userMenuPopupText} from '../userMenuPopupText';
import './styles.css';

interface Props {
  userName: string;
  email: string;
  onLogout: () => void;
  onProfileClick: () => void;
}

const UserMenuPopUpUI = React.memo(
  ({userName, email, onLogout, onProfileClick}: Props) => {
    const handleLogout = () => {
      onLogout();
    };

    const handleProfileClick = () => {
      onProfileClick();
    };

    const preventHide = (e: any) => {
      e.stopPropagation();
    };

    return (
      <div className="UserMenuPopUpContainer">
        <span className="popupArrowDetail"></span>
        <p className="ProfileButton" onClick={preventHide}>
          {userName}
        </p>
        <p className="ProfileSubtitle" onClick={preventHide}>
          {email}
        </p>
        <ul>
          <li>
            <a href="#" onClick={handleProfileClick}>
              <span className="icon-typeSettings"></span>
              {userMenuPopupText.accountSettings}
            </a>
          </li>
          <li>
            <a href="#" onClick={handleLogout}>
              <span className="icon-typeLog-out"></span>
              {userMenuPopupText.logOut}
            </a>
          </li>
        </ul>
      </div>
    );
  },
);

export default UserMenuPopUpUI;
