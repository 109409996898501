import React from 'react';
import {CourseAccessGate} from '../../../modules/CourseAccessGate';
import GeneralCourseEditContainer from './container/GeneralCourseEditContainer';

const GeneralCourseEdit = () => {
  return (
    <CourseAccessGate>
      <GeneralCourseEditContainer />
    </CourseAccessGate>
  );
};

export {GeneralCourseEdit};
