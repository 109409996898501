import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  title: 'Congratulations',
  subtitle: 'Your one-year subscription is now active',
  trialActive: 'Your trial is now active',
  updateSuccess: 'Your subscription was successfully updated',
  updateInfo: 'You can access transaction details through your invoices list.',
  whatsNext: 'What’s next?',
  cardText1: 'Start adding users to your workspace',
  cardText2: 'Create some courses and share them with your students',
  goToWorkspace: 'Go to workspace',
  paymentSubtitle: 'Your payment method has been successfully updated',
  paymentInfo:
    'Enter your workspace to create your next course and share it with your learners!',
};

const welsh = {
  title: 'Llongyfarchiadau',
  subtitle: 'Mae eich prawf un flwyddyn bellach yn weithredol',
  trialActive: 'Mae eich treial bellach yn weithredol',
  updateSuccess: "Mae eich tanysgrifiad wedi'i ddiweddaru'n llwyddiannus",
  updateInfo: 'Gallwch gyrchu manylion y trafodion trwy eich rhestr anfonebau',
  whatsNext: 'Beth sydd nesaf?',
  cardText1: "Dechreuwch ychwanegu defnyddwyr i'ch Gweithle",
  cardText2: "Creuwch rai cyrsiau a rhannwch nhw gyda'ch myfyrwyr",
  goToWorkspace: "Ewch I'r gweithle",
  paymentSubtitle: "Mae eich dull talu wedi'i ddiweddaru'n llwyddiannus",
  paymentInfo:
    "Mewngofnodwch i'ch Gweithle i greu eich cwrs nesaf a'i rannu gyda'ch dysgwyr!",
};

export const subscriptionSuccessText: any = getLocalizationBasedOnLang(
  english,
  welsh,
);
