import React, {useEffect} from 'react';
import ConfirmEmailUI from '../view/ConfirmEmailUI';
import ConfirmEmailStore from '../store/ConfirmEmailStore';
import {inject, observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';
import NavigationStore from '../../../../navigation/store/NavigationStore';

interface Props {
  confirmEmailStore?: ConfirmEmailStore;
  navigationStore?: NavigationStore;
}

const ConfirmEmailContainer = inject(
  'confirmEmailStore',
  'navigationStore',
)(
  observer(({confirmEmailStore, navigationStore}: Props) => {
    const navigate = useNavigate();

    useEffect(() => {
      return () => {
        confirmEmailStore!.clearErrors();
      };
    }, [confirmEmailStore]);

    const onConfirmEmail = async (code: string) => {
      const result = await confirmEmailStore?.confirmEmail(code);
      if (result) {
        if (navigationStore!.userOrganization) {
          navigationStore?.tryGetMyOrganization().then(() => {
            navigate('/home');
          });
        } else {
          navigationStore?.tryGetMyOrganization().then(() => {
            navigate('/auth/onboarding');
          });
        }
      }
    };

    const onRetry = () => {
      confirmEmailStore!.sendEmailVerification();
    };

    const onBack = async () => {
      await confirmEmailStore!.clearUser();
      navigate(-1);
    };

    const clearErorrs = () => {
      confirmEmailStore!.clearErrors();
    };

    return (
      <ConfirmEmailUI
        errors={confirmEmailStore!.errors}
        onConfirmEmail={onConfirmEmail}
        onRetry={onRetry}
        isLoading={confirmEmailStore!.isLoading}
        onBack={onBack}
        clearErorrs={clearErorrs}
      />
    );
  }),
);

export default ConfirmEmailContainer;
