import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  title: 'Select the lesson node to jump to',
  cancel: 'Cancel',
  accept: 'Accept',
  lessonNodes: 'Lesson Nodes:',
};

const welsh = {
  title: 'Dewiswch nod y wers i neidio iddo',
  cancel: 'Canslo',
  accept: 'Derbyn',
  lessonNodes: 'Nodau gwers',
};

export const addJumpToWizardText: any = getLocalizationBasedOnLang(
  english,
  welsh,
);
