import React from 'react';
import {CourseAccessGate} from '../../modules/CourseAccessGate';
import VideoPlaybackContainer from './container/VideoPlaybackContainer';

interface Props {
  preview?: boolean;
}

const VideoPlayback = ({preview}: Props) => {
  return (
    <CourseAccessGate publishedCourse={!preview}>
      <VideoPlaybackContainer preview={preview} />
    </CourseAccessGate>
  );
};

export {VideoPlayback};
