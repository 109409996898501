import React, {useState} from 'react';
import ModalContainer from '../../../../uiToolkit/ModalContainer';
import {addChoiceTextOptionWizardText} from '../addChoiceTextOptionQuetionWizardText';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';
import './styles.css';
import Button from '../../../../uiToolkit/Buttons/Button';

interface Props {
  onCancel: () => void;
  onCreate: (option: string) => void;
  isLoading?: boolean;
}

const AddChoiceTextOptionWizardUI = React.memo(
  ({onCancel, onCreate, isLoading}: Props) => {
    const [option, setOption] = useState('');

    const onSave = () => {
      if (option.trim() !== '') {
        onCreate(option);
      }
    };

    return (
      <ModalContainer onClose={onCancel}>
        <div className="AddChoiceTextOptionWizardContainer">
          <p className="Header">{addChoiceTextOptionWizardText.title}</p>
          <TextInput
            value={option}
            onChange={setOption}
            heading={addChoiceTextOptionWizardText.inputTitle}
            placeholder={addChoiceTextOptionWizardText.placeholder}
            maxCharacters={150}
            isMandatory={true}
          />
          <div className="Footer">
            <Button
              title={addChoiceTextOptionWizardText.cancel}
              onClick={onCancel}
              uiType="text"
            />
            <Button
              title={addChoiceTextOptionWizardText.accept}
              onClick={onSave}
              isLoading={isLoading}
              uiType="action"
            />
          </div>
        </div>
      </ModalContainer>
    );
  },
);

export default AddChoiceTextOptionWizardUI;
