import React from 'react';
import {
  SUBSCRIPTION_BUISNESS_BASIC,
  SUBSCRIPTION_CREATOR,
  SUBSCRIPTION_ENTERPRISE,
} from '../../config';

interface Props {
  selectedPlan: number;
  setSelectedPlan: (plan: number) => void;
}

const PlansSwitch = React.memo(({selectedPlan, setSelectedPlan}: Props) => {
  return (
    <div className="PlansSwitchContainer">
      <button
        className={selectedPlan === SUBSCRIPTION_CREATOR ? 'Selected' : ''}
        onClick={() => setSelectedPlan(SUBSCRIPTION_CREATOR)}>
        Creator
      </button>
      <button
        className={
          selectedPlan === SUBSCRIPTION_BUISNESS_BASIC ? 'Selected' : ''
        }
        onClick={() => setSelectedPlan(SUBSCRIPTION_BUISNESS_BASIC)}>
        Business
      </button>
      <button
        className={`LastElement ${
          selectedPlan === SUBSCRIPTION_ENTERPRISE ? 'Selected' : ''
        }`}
        onClick={() => setSelectedPlan(SUBSCRIPTION_ENTERPRISE)}>
        Enterprise
      </button>
    </div>
  );
});

export default PlansSwitch;
