import React, {useState, useEffect} from 'react';
import TapyblPlayerUI from '../view/TapyblPlayerUI';
import {
  CHOICE_HEADER_NODE_TYPE,
  IMAGE_NODE_TYPE,
  JUMP_TO_NODE,
  LessonContentBase,
  LessonContentJumpTo,
  LessonContentMChoiceAnswer,
  LessonContentVideo,
  OVERLAY_VIDEO_TYPE,
  OVERLAY_VIMEO_VIDEO_TYPE,
  VIDEO_NODE_TYPE,
  VIMEO_VIDEO_TYPE,
} from '../../../data/models/LessonContent/LessonContentBase';
import {
  LessonContentItem,
  LessonContentList,
} from '../../../data/models/LessonContent/LessonContentList';
import {
  getLessonRoot,
  getNextLessonNode,
  getNodeById,
} from '../lessonContentParser';
import {
  LearnerProgressModel,
  PROGRESS_ACKNOWLEDGMENT,
  PROGRESS_INFO_RECORD,
  PROGRESS_ITEM_CHALLENGE,
  PROGRESS_ITEM_CHALLENGE_AI,
  PROGRESS_ITEM_CHOICE,
  PROGRESS_ITEM_MCHOICE,
} from '../../../data/models/LessonContent/CreateLearnerLessonModel';
import {
  ChallengeBranchAIMapped,
  LessonContentChoiceAnswerMapped,
} from '../../../data/models/LessonContent/LessonConentMapped';
import {GradeResult} from '../models';
import {
  OVERLAY_NEXT_NODE_KEY,
  OVERLAY_PREV_NODE_KEY,
} from '../../../data/models/LessonContent/ContentFreeform';

interface Props {
  preview: boolean;
  isLoading: boolean;
  isGradable?: boolean;
  gradeResult?: GradeResult;
  nextLessonIsTheSame?: boolean;
  lessonContent: LessonContentList;
  onCourseHome: () => void;
  onContinue: () => void;
  passingScore: number;
  onFinishedLesson: (progress: LearnerProgressModel) => void;
  navigateToLesson: (lessonId: string) => void;
  fitHeight?: boolean;
  skipFinishButtons?: boolean;
  nextLessonId?: string;
  showScormFinish?: boolean;
}

const TapyblPlayerContainer = React.memo(
  ({
    preview,
    isLoading,
    lessonContent,
    onCourseHome,
    onContinue,
    passingScore,
    onFinishedLesson,
    isGradable,
    gradeResult,
    nextLessonIsTheSame,
    fitHeight,
    skipFinishButtons,
    nextLessonId,
    navigateToLesson,
    showScormFinish,
  }: Props) => {
    const [currentSourceNode, setCurrentSourceNode] =
      useState<LessonContentBase>();
    const [currentTempSourceNode, setCurrentTempSourceNode] =
      useState<LessonContentVideo>();
    const [currentNode, setCurrentNode] = useState<LessonContentItem>();
    const [started, setStarted] = useState(false);
    const [ended, setEnded] = useState(false);
    const [answers, setAnswers] = useState<
      {questionId: string; answers: string[]; type: number}[]
    >([]);

    //initialization of the node tree
    useEffect(() => {
      setStarted(false);
      setEnded(false);
      setAnswers([]);
      const lessonRoot = getLessonRoot(lessonContent);
      if (lessonRoot) {
        setCurrentSourceNode(lessonRoot.data as LessonContentVideo);
        setCurrentNode(lessonRoot);
      }
    }, [lessonContent]);

    //finishing lesson
    useEffect(() => {
      if (ended && !currentNode && !preview) {
        const editBody: LearnerProgressModel = {};
        answers.forEach(answer => {
          editBody[answer.questionId] = {
            type: answer.type,
            answers: answer.answers,
          };
        });
        onFinishedLesson(editBody);
      }
    }, [ended, currentNode]);

    //jumpTo handlers
    useEffect(() => {
      if (currentNode && currentNode.data.ivNodeType === JUMP_TO_NODE) {
        setAnswers([
          ...answers,
          {
            type: PROGRESS_INFO_RECORD,
            questionId: currentNode.data.internalId,
            answers: [],
          },
        ]);
        const nextNode = getNodeById(
          (currentNode.data as LessonContentJumpTo).jumpToNodeId,
          lessonContent,
        );
        setNextNode(nextNode);
      }
    }, [currentNode, currentSourceNode]);

    useEffect(() => {
      if (
        currentSourceNode === undefined &&
        currentTempSourceNode !== undefined
      ) {
        const tempSource = {...currentTempSourceNode};
        setCurrentTempSourceNode(undefined);
        setCurrentSourceNode({...tempSource});
      }
    }, [currentTempSourceNode, currentSourceNode]);

    const onFinishedVideo = () => {
      if (currentNode) {
        setAnswers([
          ...answers,
          {
            type: PROGRESS_INFO_RECORD,
            questionId: currentNode.data.internalId,
            answers: [],
          },
        ]);
        const nextNode = getNextLessonNode(lessonContent, currentNode);
        setNextNode(nextNode);
      }
    };

    const onSelectMChoice = (choices: LessonContentMChoiceAnswer[]) => {
      if (currentNode) {
        setAnswers([
          ...answers,
          {
            type: PROGRESS_ITEM_MCHOICE,
            questionId: currentNode?.data.internalId || '',
            answers: choices.map(item => item.value),
          },
        ]);
        const nextNode = getNextLessonNode(lessonContent, currentNode);
        setNextNode(nextNode);
      }
    };

    const onSelectChoice = (answer: LessonContentChoiceAnswerMapped) => {
      setAnswers([
        ...answers,
        {
          type: PROGRESS_ITEM_CHOICE,
          questionId: currentNode?.data.internalId || '',
          answers: [answer.id],
        },
      ]);
      if (answer.childId) {
        const nextNode = getNodeById(answer.childId, lessonContent);
        setNextNode(nextNode);
      } else {
        setEnded(true);
        setCurrentNode(undefined);
      }
    };

    const onSelectChallenge = (answer: LessonContentChoiceAnswerMapped) => {
      setAnswers([
        ...answers,
        {
          type: PROGRESS_ITEM_CHALLENGE,
          questionId: currentNode?.data.internalId || '',
          answers: [answer.id],
        },
      ]);
      if (answer.childId) {
        const nextNode = getNodeById(answer.childId, lessonContent);
        setNextNode(nextNode);
      } else {
        setEnded(true);
        setCurrentNode(undefined);
      }
    };

    const onSelectChallengeAI = (
      answer: ChallengeBranchAIMapped,
      selectedAnswer: string,
    ) => {
      setAnswers([
        ...answers,
        {
          type: PROGRESS_ITEM_CHALLENGE_AI,
          questionId: answer.internalId,
          answers: [selectedAnswer],
        },
      ]);
      let childId = '';
      if (answer.correctAnswerPathId === selectedAnswer) {
        childId = answer.correctChildId;
      } else {
        childId = answer.incorrectChildId;
      }
      if (childId) {
        const nextNode = getNodeById(childId, lessonContent);
        setNextNode(nextNode);
      } else {
        setEnded(true);
        setCurrentNode(undefined);
      }
    };

    const onSelectFreeformChoice = (choiceId: string) => {
      const progressType =
        currentNode?.data.ivNodeType === CHOICE_HEADER_NODE_TYPE
          ? PROGRESS_ITEM_CHOICE
          : PROGRESS_ITEM_CHALLENGE;
      setAnswers([
        ...answers,
        {
          type: progressType,
          questionId: currentNode?.data.internalId || '',
          answers: [choiceId],
        },
      ]);
      const answerNode = getNodeById(choiceId, lessonContent);
      if (answerNode && answerNode.childIds && answerNode.childIds.length) {
        const nextNode = getNodeById(answerNode.childIds[0], lessonContent);
        setNextNode(nextNode);
      } else {
        setEnded(true);
        setCurrentNode(undefined);
      }
    };

    const onAcknowledge = (acknowledgmentId: string) => {
      setAnswers([
        ...answers,
        {
          type: PROGRESS_ACKNOWLEDGMENT,
          questionId: acknowledgmentId,
          answers: [acknowledgmentId],
        },
      ]);
      if (currentNode && currentNode.childIds && currentNode.childIds.length) {
        const nextNode = getNodeById(currentNode.childIds[0], lessonContent);
        setNextNode(nextNode);
      } else {
        setEnded(true);
        setCurrentNode(undefined);
      }
    };

    const setNextNode = (node: LessonContentItem | null) => {
      if (node) {
        if (nodeIsASource(node)) {
          if (currentSourceNode?.internalId === node.data.internalId) {
            setCurrentTempSourceNode(node.data as LessonContentVideo);
            setCurrentSourceNode(undefined);
          } else {
            setCurrentSourceNode(node.data as LessonContentVideo);
          }
        }
        setCurrentNode(node);
      } else {
        setCurrentNode(undefined);
        setEnded(true);
      }
    };

    const nodeIsASource = (node: LessonContentItem) => {
      return (
        node.data.ivNodeType === VIDEO_NODE_TYPE ||
        node.data.ivNodeType === VIMEO_VIDEO_TYPE ||
        node.data.ivNodeType === OVERLAY_VIDEO_TYPE ||
        node.data.ivNodeType === OVERLAY_VIMEO_VIDEO_TYPE ||
        node.data.ivNodeType === IMAGE_NODE_TYPE
      );
    };

    const jumpToNode = (nodeId: string) => {
      if (
        nodeId === OVERLAY_NEXT_NODE_KEY &&
        currentNode &&
        currentNode.childIds?.length
      ) {
        const nextNode = getNodeById(currentNode.childIds[0], lessonContent);
        setNextNode(nextNode);
      } else if (
        nodeId === OVERLAY_PREV_NODE_KEY &&
        currentNode &&
        currentNode.parentsIds?.length
      ) {
        const nextNode = getNodeById(currentNode.parentsIds[0], lessonContent);
        setNextNode(nextNode);
      } else {
        const nextNode = getNodeById(nodeId, lessonContent);
        setNextNode(nextNode);
      }
    };

    const navigateTo = (lessonId: string) => {
      navigateToLesson(lessonId);
    };

    return (
      <TapyblPlayerUI
        started={started}
        ended={ended}
        currentNode={currentNode}
        currentSourseNode={currentSourceNode}
        preview={preview}
        isLoading={isLoading}
        onFinishedVideo={onFinishedVideo}
        onStart={() => setStarted(true)}
        onAcknowledge={onAcknowledge}
        onCourseHome={onCourseHome}
        onContinue={onContinue}
        passingScore={passingScore}
        onSelectMChoice={onSelectMChoice}
        onSelectChoice={onSelectChoice}
        onSelectChallenge={onSelectChallenge}
        onSelectChallengeAI={onSelectChallengeAI}
        isGradable={isGradable}
        nextLessonIsTheSame={nextLessonIsTheSame}
        gradeResult={gradeResult}
        fitHeight={fitHeight}
        skipFinishButtons={skipFinishButtons}
        nextLessonId={nextLessonId}
        jumpToNode={jumpToNode}
        navigateTo={navigateTo}
        onSelectFreeformChoice={onSelectFreeformChoice}
        showScormFinish={showScormFinish}
      />
    );
  },
);

export default TapyblPlayerContainer;
