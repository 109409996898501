import {makeAutoObservable} from 'mobx';
import SubscriptionRepository from '../../../data/repositories/SubscriptionRepository';

class PermissionGateStore {
  private subscriptionRepo = new SubscriptionRepository();

  constructor() {
    makeAutoObservable(this);
  }

  public async getUserPermissions(organizationId: string) {
    const result =
      await this.subscriptionRepo.getSubscriptionPermissions(organizationId);
    if (result.success && result.data) {
      return result.data;
    } else {
      return {
        permissions: {},
      };
    }
  }
}

export default PermissionGateStore;
