import React, {useState} from 'react';
import {ReactMultiEmail} from 'react-multi-email';
import './styles.css';

interface Props {
  heading?: string;
  value: string[];
  setValue: (value: string[]) => void;
  validator: (value: string) => boolean;
  tip?: string;
  placeholder?: string;
  customDelimiter?: string;
}

const MutiInput = React.memo(
  ({
    heading,
    value,
    setValue,
    validator,
    tip,
    placeholder,
    customDelimiter,
  }: Props) => {
    const [currentInput, setCurrentInput] = useState<string>('');

    const onBlur = () => {
      if (validator(currentInput) && currentInput.trim() !== '') {
        setValue([...value, currentInput]);
        setCurrentInput('');
      }
    };

    return (
      <div className="MultiInput">
        {heading && (
          <h3>
            {heading}{' '}
            <span className="TextBoxHeading MandatoryIndicator">*</span>
          </h3>
        )}
        <ReactMultiEmail
          emails={value}
          onChange={setValue}
          onChangeInput={setCurrentInput}
          inputValue={currentInput}
          validateEmail={validator}
          placeholder={placeholder}
          disableOnBlurValidation
          delimiter={customDelimiter}
          onBlur={onBlur}
          getLabel={(
            email: string,
            index: number,
            removeEmail: (index: number) => void,
          ) => {
            return (
              <div data-tag key={index}>
                {email}
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  ×
                </span>
              </div>
            );
          }}
        />
        {tip && (
          <div className="MultiInputTip">
            <p>{tip}</p>
          </div>
        )}
      </div>
    );
  },
);

export default MutiInput;
