import React, {useEffect, useState} from 'react';
import {LessonContentVideo} from '../../../../../data/models/LessonContent/LessonContentBase';
import {
  FILE_SOURCE_TAPYBL,
  MEDIA_FILES_VIDEO,
  MediaFile,
} from '../../../../../data/models/LibraryFile/MediaFile';
import {
  getMilisecondsFromTimeText,
  getTimeMilisecondsTextFromSeconds,
} from '../../../../../utils/getTimeTextFromSeconds';
import {lessonEditorText} from '../../../lessonEditorText';
import TextInput from '../../../../../uiToolkit/Inputs/TextInput';
import BoxVideoInput from '../../../../../uiToolkit/Inputs/BoxVideoInput';
import TimeInput from '../../../../../uiToolkit/Inputs/TimeInput/indes';
import Button from '../../../../../uiToolkit/Buttons/Button';
import './styles.css';
import EditorOptionsFooter from '../../../../../uiToolkit/Editor/EditorOptionsFooter';
import ReplaceNode from '../../ReplaceNode';
import {BaseContainerForwardProps} from '../../BaseOptionsContainer';

interface Props extends BaseContainerForwardProps {
  entityToEdit: LessonContentVideo;
}

const VideoSourceOptionsUI = React.memo(
  ({
    entityToEdit,
    onEdit,
    isLoading,
    onDelete,
    onOpenLibrary,
    isPublished,
    onSoftDelete,
  }: Props) => {
    const [duration, setDuration] = useState(0);
    const [timeStart, setTimeStart] = useState(
      getTimeMilisecondsTextFromSeconds(entityToEdit.startInSeconds),
    );
    const [timeEnd, setTimeEnd] = useState(
      getTimeMilisecondsTextFromSeconds(entityToEdit.endInSeconds),
    );
    const [title, setTitle] = useState(entityToEdit.title);
    const [newFile, setNewFile] = useState<MediaFile>();
    const [currentContent, setCurrentContent] = useState<LessonContentVideo>();

    useEffect(() => {
      setTimeStart(
        getTimeMilisecondsTextFromSeconds(entityToEdit.startInSeconds),
      );
      setTimeEnd(getTimeMilisecondsTextFromSeconds(entityToEdit.endInSeconds));
      setTitle(entityToEdit.title);
      setCurrentContent(undefined);
    }, [entityToEdit]);

    useEffect(() => {
      if (!currentContent && entityToEdit) {
        setCurrentContent(entityToEdit);
      }
    }, [entityToEdit, currentContent]);

    const onSave = () => {
      onEdit({
        ...entityToEdit,
        contentId: newFile ? newFile.storageFileName : entityToEdit.contentId,
        startInSeconds: getMilisecondsFromTimeText(timeStart),
        endInSeconds: getMilisecondsFromTimeText(timeEnd),
        durationInseconds:
          getMilisecondsFromTimeText(timeEnd) -
          getMilisecondsFromTimeText(timeStart),
        title,
      } as LessonContentVideo);
    };

    const startTimeIsValid = () => {
      if (duration > 0) {
        const seconds = getMilisecondsFromTimeText(timeStart);
        const endSeconds = getMilisecondsFromTimeText(timeEnd);
        return seconds <= duration && seconds < endSeconds;
      }
      return true;
    };

    const endTimeIsValid = () => {
      if (duration > 0) {
        const seconds = getMilisecondsFromTimeText(timeEnd);
        return seconds <= duration;
      }
      return true;
    };

    const changesWasMade = () => {
      return (
        entityToEdit.startInSeconds !== getMilisecondsFromTimeText(timeStart) ||
        entityToEdit.endInSeconds !== getMilisecondsFromTimeText(timeEnd) ||
        entityToEdit.title !== title.trim() ||
        newFile
      );
    };

    const isValid = () => {
      return startTimeIsValid() && endTimeIsValid() && title.trim() !== '';
    };

    const onPickNewFile = () => {
      onOpenLibrary(MEDIA_FILES_VIDEO, setNewFile, FILE_SOURCE_TAPYBL);
    };

    return (
      <div className="VideoSourceOptions">
        <TextInput
          heading={lessonEditorText.title}
          value={title}
          onChange={setTitle}
          placeholder={lessonEditorText.title}
          fieldError={title.trim() === ''}
          isMandatory
          uiType="box"
          className="Section"
        />
        {currentContent?.contentUrl ? (
          <BoxVideoInput
            heading={lessonEditorText.source}
            source={newFile ? newFile.fileUrl : currentContent?.contentUrl}
            startTime={
              startTimeIsValid()
                ? getMilisecondsFromTimeText(timeStart)
                : undefined
            }
            endTime={
              endTimeIsValid() ? getMilisecondsFromTimeText(timeEnd) : undefined
            }
            setDuration={setDuration}
          />
        ) : null}
        <div className="TimePickersContainer">
          <TimeInput
            isValid={startTimeIsValid()}
            value={timeStart}
            onChange={setTimeStart}
            label={lessonEditorText.startTime}
            includeMiliseconds
          />
          <TimeInput
            isValid={endTimeIsValid()}
            value={timeEnd}
            onChange={setTimeEnd}
            label={lessonEditorText.endTime}
            includeMiliseconds
          />
        </div>
        <Button
          title={lessonEditorText.editSource}
          onClick={onPickNewFile}
          uiType="box"
          className="OptionsButton"
        />
        <ReplaceNode currentNode={entityToEdit} />
        <EditorOptionsFooter
          saveEnabled={changesWasMade() && isValid()}
          onSave={onSave}
          onDelete={onDelete}
          isLoading={isLoading}
          deleteDisabled={isPublished}
          onSoftDelete={onSoftDelete}
        />
      </div>
    );
  },
);

export default VideoSourceOptionsUI;
