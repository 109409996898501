export const APP_PERMISSIONS: any = {
  ORG_LIST_LEARNERS: 'Permission.Workspace.ListLearners',
  ORG_CREATE_LEARNERS: 'Permission.Workspace.InviteLearners',
  ORG_CREATE_COURSES: 'Permission.Workspace.CreateCourses',
  ORG_LIST_COURSES: 'Permission.Workspace.ListCourses',
  ORG_EDIT_COURSES: 'Permission.Workspace.EditCourses',
  ORG_LIST_FILES: 'Permission.Workspace.ListFiles',
  ORG_UPLOAD_FILES: 'Permission.Workspace.UploadFiles',
  ORG_MANAGE_SETTINGS: 'Permission.Workspace.ManageSettings',
  ORG_ASSIGN_INSTRUCOR: 'Permission.Workspace.AssignInstructor',
  ORG_DELETE_COURSE: 'Permission.Workspace.DeleteCourse',
  ORG_ENROLL_LEARNER: 'Permission.Workspace.EnrollLearner',
  ORG_ACCESS_LIBRARY: 'Permission.Workspace.AccessLibrary',
  ORG_ACCESS_DASHBOARD: 'Permission.Workspace.AccessDashboard',
  ORG_ACCESS_COURSE: 'Permission.Workspace.CourseAccess',
};

export const getPermissionName = (permission: string) => {
  switch (permission) {
    case APP_PERMISSIONS.ORG_LIST_LEARNERS:
      return 'View Workspace Learners';
    case APP_PERMISSIONS.ORG_CREATE_LEARNERS:
      return 'Invite and Edit Learners in Workspace';
    case APP_PERMISSIONS.ORG_CREATE_COURSES:
      return 'Create and Edit Courses in Workspace';
    case APP_PERMISSIONS.ORG_LIST_COURSES:
      return 'View Courses in Workspace';
    case APP_PERMISSIONS.ORG_LIST_FILES:
      return 'View Workspace Library Files';
    case APP_PERMISSIONS.ORG_UPLOAD_FILES:
      return 'Upload files to Workspace Library';
    case APP_PERMISSIONS.ORG_MANAGE_SETTINGS:
      return 'Manage Workspace Settings';
    default:
      return '';
  }
};

export const SUBSCRIPTION_PERMISSIONS: any = {
  GRADABLE_LESSONS: 'gradable_lessons',
  COURSE_MANAGMENT: 'course_management',
  AI_ASSISTANT: 'ai_assistant',
};

export const SUBSCRIPTION_PERMISSION_TYPE = 1;
export const WORKPSACE_PERMISSION_TYPE = 0;

export const getSubscriptionFeatureName = (feature: string) => {
  switch (feature) {
    case SUBSCRIPTION_PERMISSIONS.GRADABLE_LESSONS:
      return 'Assessable Lessons';
    case SUBSCRIPTION_PERMISSIONS.COURSE_MANAGMENT:
      return 'Course Management';
    case SUBSCRIPTION_PERMISSIONS.AI_ASSISTANT:
      return 'AI Assistant';
    default:
      return '';
  }
};
