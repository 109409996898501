import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  title: 'Library',
  allFiles: 'All files',
  allImages: 'All images',
  allVideos: 'All videos',
  vimeoVideos: 'Vimeo Videos',
  fileNotReady: 'File is not ready to be used',
};

const welsh = {
  title: 'Llyfrgell',
  allFiles: 'Pob ffeil',
  allImages: 'Pob delwedd',
  allVideos: 'Pob fideo',
  vimeoVideos: 'Fideos Vimeo',
  videoUploaded: "Ffeil wedi'i Mewnosod yn Llwyddiannus",
  fileNotReady: "Nid yw'r ffeil yn barod i'w defnyddio",
};

export const myLibraryModalText: any = getLocalizationBasedOnLang(
  english,
  welsh,
);
