import React, {useEffect} from 'react';
import {courseIcon} from '../../../assets/images';
import Checkbox from '../../../uiToolkit/Inputs/Checkbox';
import {SubjectListItem} from '../../../data/models/Course/Subject';
import {exploreText} from '../exploreText';
import Button from '../../../uiToolkit/Buttons/Button';
import {appText} from '../../../assets/text/appText';

interface Props {
  subjects: SubjectListItem[];
  applyFiltersButton: (filters: string) => void;
  onCloseFilters: () => void;
  showFilter: boolean;
  removeFilter?: boolean;
}

const FilterExploreCourses = React.memo(
  ({
    subjects,
    applyFiltersButton,
    onCloseFilters,
    showFilter,
    removeFilter,
  }: Props) => {
    const [checkedCoursesId, setCheckedCoursesId] = React.useState<string>('');

    useEffect(() => {
      clearAllFilters();
    }, [removeFilter]);

    const handleApplyFilterClick = () => {
      applyFiltersButton(checkedCoursesId);
      onCloseFilters();
    };

    const clearAllFilters = () => {
      setCheckedCoursesId('');
      applyFiltersButton('');
      onCloseFilters();
    };

    return showFilter ? (
      <div className="FilterWizardContainer">
        <div className="BoxHeader">
          <h3>{exploreText.filter}</h3>
          <span
            className="icon-typeClose"
            onClick={() => onCloseFilters()}></span>
        </div>
        <div className="BoxSubHeader">
          <img src={courseIcon} alt="course" />
          <h3>{exploreText.categories}</h3>
        </div>
        <div className="BoxContent">
          {subjects?.map((course: any) => (
            <div key={course.id} className="BoxContentRow">
              <Checkbox
                checked={checkedCoursesId === course.id}
                className="Checkbox"
                onCheck={() => setCheckedCoursesId(course.id)}
                radio
                text={course.title}
              />
            </div>
          ))}
        </div>
        <div className="BoxFooter">
          <Button
            title={exploreText.clearFilter}
            onClick={clearAllFilters}
            className="Clear"
            uiType="hollow"
          />
          <Button
            title={appText.apply}
            onClick={handleApplyFilterClick}
            uiType="action"
          />
        </div>
      </div>
    ) : (
      <div />
    );
  },
);

export default FilterExploreCourses;
