import {makeAutoObservable} from 'mobx';
import OrganizationRepository from '../../../data/repositories/OrganizationRepository';
import {OrganizationSettings} from '../../../data/models/Oganization/Organization';
import LoadingModule from '../../../modules/LoadingModule/LoadingModule';

class SettingsStore {
  private organizationRepository = new OrganizationRepository();
  private loadingModule = new LoadingModule();

  public generalSettingsErrors: string[] = [];
  public roleSettingsErrors: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async getOrganizationDetails(organizationId: string) {
    const result =
      await this.organizationRepository.getOrganizationSettings(organizationId);
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async editOrganization(
    organization: OrganizationSettings,
    image?: File,
  ) {
    this.clearGeneralErrors();
    this.loadingModule.startLoading();
    const result = await this.organizationRepository.editOrganization(
      organization,
      image,
    );
    this.loadingModule.endLoading();
    if (!result.success && result.errors) {
      this.setGeneralSettingsErrors(result.errors);
      return false;
    }
    return true;
  }

  public async getOrganizationRoles(organizationId: string) {
    const result =
      await this.organizationRepository.getOrganizationRoles(organizationId);
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }

  public async createOrganizationRole(
    organizationId: string,
    roleName: string,
    permissionsList: string[],
  ) {
    this.loadingModule.startLoading();
    this.clearRoleSettingsErrors();
    const result = await this.organizationRepository.createRole({
      organizationId,
      roleName,
      permissionsList,
    });
    this.loadingModule.endLoading();
    if (!result.success && result.errors) {
      this.setRoleSettingsErrors(result.errors);
    }
    return result.success;
  }

  public async getRoleDetailsById(id: string, organizationId: string) {
    this.loadingModule.startLoading();
    const result = await this.organizationRepository.getRoleById(
      id,
      organizationId,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async editOrganizationRole(
    organizationId: string,
    id: string,
    roleName: string,
    permissions: string[],
  ) {
    this.loadingModule.startLoading();
    this.clearRoleSettingsErrors();
    const result = await this.organizationRepository.editRole({
      organizationId,
      roleName,
      permissions,
      id,
    });
    this.loadingModule.endLoading();
    if (!result.success && result.errors) {
      this.setRoleSettingsErrors(result.errors);
    }
    return result.success;
  }

  public async deleteOrganizationRole(organizationId: string, roleId: string) {
    const result = await this.organizationRepository.deleteRole(
      organizationId,
      roleId,
    );
    return result.success;
  }

  public clearGeneralErrors() {
    this.generalSettingsErrors = [];
  }

  public clearRoleSettingsErrors() {
    this.roleSettingsErrors = [];
  }

  private setGeneralSettingsErrors(errors: string[]) {
    this.generalSettingsErrors = errors;
  }

  private setRoleSettingsErrors(errors: string[]) {
    this.roleSettingsErrors = errors;
  }
}

export default SettingsStore;
