import CoursesRepository from '../../../data/repositories/CoursesRepository';
import {makeAutoObservable} from 'mobx';
import OrganizationRepository from '../../../data/repositories/OrganizationRepository';
import LoadingModule from '../../LoadingModule/LoadingModule';

class CourseAccessGateStore {
  private courseRepository = new CoursesRepository();
  private organizationRepository = new OrganizationRepository();

  private loadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async getOrgUserRole(organizationId: string) {
    const result =
      await this.organizationRepository.getOrgUserRole(organizationId);
    if (result.success) {
      return result.data;
    }
  }

  public async checkCourseAccess(organizationId: string, courseId: string) {
    this.loadingModule.startLoading();
    const result = await this.courseRepository.checkCoursePreviewAccess(
      organizationId,
      courseId,
    );
    this.loadingModule.endLoading();
    return result.data;
  }

  public async checkPublishedCourseAccess(courseId: string) {
    this.loadingModule.startLoading();
    const result =
      await this.courseRepository.checkCoursePresentationAccess(courseId);
    this.loadingModule.endLoading();
    return result.data;
  }
}

export default CourseAccessGateStore;
