import React, {useEffect, useState} from 'react';
import {rotateIc} from '../../../assets/images';
import {Lesson} from '../../../data/models/Course/Lesson';
import NumbersInput from '../../../uiToolkit/Inputs/NumbersInput';
import {lessonsText} from '../lessonsText';

interface Props {
  lesson: Lesson;
  onRetriesChange: (lesson: Lesson, retries: number) => void;
}

const RetriesCard = React.memo(({lesson, onRetriesChange}: Props) => {
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    document.body.addEventListener('click', onDocumentClick);
    return () => {
      removeEventListener('click', onDocumentClick);
    };
  }, []);

  const onDocumentClick = () => {
    setShowTooltip(false);
  };

  const onToggleTooltip = (e: any) => {
    e.stopPropagation();
    setShowTooltip(!showTooltip);
  };

  return (
    <div style={{position: 'relative'}}>
      <div className="RetryCard" onClick={onToggleTooltip}>
        <img src={rotateIc} />
        <span>{lesson.amountOfRetries}</span>
      </div>
      {showTooltip && (
        <div
          className="RetryTooltipContainer"
          onClick={e => e.stopPropagation()}>
          <span className="RetryTooltipArrowDetail"></span>
          <p>{lessonsText.numberOfRetries}</p>
          <NumbersInput
            value={lesson.amountOfRetries || 0}
            setValue={value =>
              onRetriesChange(lesson, value !== undefined ? value : 0)
            }
          />
        </div>
      )}
    </div>
  );
});

export default RetriesCard;
