import React, {useEffect, useState} from 'react';
import ModalContainer from '../../../uiToolkit/ModalContainer';
import {generateAIVideosText} from '../generateAIVideosText';
import Button from '../../../uiToolkit/Buttons/Button';
import './styles.css';
import {parseStringArrayToInt} from '../../../utils/parseStringArrayToInt';
import {DIALECTS} from '../../../data/models/QuestionGeneration/AIGenerationDropdownOptions';
import {getSecondsFromTimeText} from '../../../utils/getTimeTextFromSeconds';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import {
  FILE_PICK_STEP,
  IMAGE_GENERATION_STEP,
  MUTE_EXCLUDE_STEP,
  VIDEO_DURATION_STEP,
  VOICE_NARRATION_STEP,
  SUMMARY_STEP,
} from '../config';
import MuteExcudeStep from '../components/MuteExcludeStep';
import VideoDuratonStep from '../components/VideoDurationStep';
import ImageGenerationStep from '../components/ImageGenerationStep';
import SummaryStep from '../components/SummarySteps';
import VoiceNarrationStep from '../components/VoiceNarrationStep';
import GenerationSteps from '../components/GenerationSteps';
import UploadFileStep from '../components/UploadFIleStep';
import HelpTip from '../components/HelpTip';
import {Row} from 'react-bootstrap';

const MAX_IGNORE_AUDIO_SLIDES_DURATION_SECONDS = 3600;

interface Props {
  onSave: (
    file: File,
    aiImage: boolean,
    sliderValue: string | null,
    ignoreSlidesList: number[],
    ignoreSlidesAudioList: number[],
    ignoreSlidesAudioDuration: number,
    type: number,
    voiceGender: string,
    language: string,
    selectedDialect: string,
    phrasesToIgnore: string[],
    generateAiVideos: boolean,
  ) => void;
  onCancel: () => void;
  isLoading: boolean;
}

const GenerateAIVideosUI = React.memo(
  ({onSave, onCancel, isLoading}: Props) => {
    const [ignoreAudioSlidesList, setIgnoreAudioSlidesList] = useState('');
    const [ignoreSlidesList, setIgnoreSlidesList] = useState('');
    const [ignoreAudioSlidesDuration, setIgnoreAudioSlidesDuration] =
      useState('00:00:00');
    const [ignorePhrasesList, setIgnorePhrasesList] = useState<string[]>([]);
    const [videoDuration, setVideoDuration] = useState<string>();
    const [aiImage, setAiImage] = useState(false);
    const [generateAiVideos, setGenerateAiVideos] = useState(false);
    const [sliderValue, setSliderValue] = useState(70);
    const [file, setFile] = useState<File>();
    const [voiceGender, setVoiceGender] = useState('female');
    const [language, setLanguage] = useState('en');
    const initialDialects = DIALECTS[language as keyof typeof DIALECTS].map(
      (dialect: any) => ({key: dialect, value: dialect}),
    );
    const [dialectOptions, setDialectOptions] =
      useState<{key: string; value: string}[]>(initialDialects);
    const [selectedDialect, setSelectedDialect] = useState<string>(
      initialDialects[0]?.key,
    );

    const [ignoreListIsValid, setIgnoreListIsValid] = useState(true);
    const [ignoreAudioListIsValid, setIgnoreAudioListIsValid] = useState(true);

    const [step, setStep] = useState(FILE_PICK_STEP);

    useEffect(() => {
      const newDialects = DIALECTS[language as keyof typeof DIALECTS].map(
        (dialect: any) => ({key: dialect, value: dialect}),
      );
      setDialectOptions(newDialects);
      setSelectedDialect(newDialects[0]?.key);
    }, [language]);

    const ignoreAudioSlidesDurationIsValid = () => {
      if (ignoreAudioSlidesList === '') {
        return true;
      }
      return (
        ignoreAudioSlidesDuration !== '00:00:00' &&
        getSecondsFromTimeText(ignoreAudioSlidesDuration) <=
          MAX_IGNORE_AUDIO_SLIDES_DURATION_SECONDS
      );
    };

    const onSaveForm = () => {
      if (file && ignoreAudioSlidesDurationIsValid()) {
        onSave(
          file,
          aiImage,
          aiImage || generateAiVideos ? sliderValue.toString() : null,
          parseStringArrayToInt(ignoreSlidesList),
          parseStringArrayToInt(ignoreAudioSlidesList),
          getSecondsFromTimeText(ignoreAudioSlidesDuration),
          parseInt(videoDuration || '0', 10),
          voiceGender,
          language,
          selectedDialect,
          ignorePhrasesList,
          generateAiVideos,
        );
      }
    };

    const onAudioIgnoreSlidesChange = (value: string, isValid: boolean) => {
      setIgnoreAudioSlidesList(value);
      setIgnoreAudioListIsValid(isValid);
    };

    const onIgnoreSlidesChange = (value: string, isValid: boolean) => {
      setIgnoreSlidesList(value);
      setIgnoreListIsValid(isValid);
    };

    const updateDialectOptions = (language: string) => {
      const dialects = DIALECTS[language as keyof typeof DIALECTS];
      const options = dialects.map((dialect: any) => ({
        key: dialect,
        value: dialect,
      }));
      setDialectOptions(options);
    };

    const onLanguageSelect = (value: string) => {
      setLanguage(value);
      updateDialectOptions(value);
    };

    const onNextStep = () => {
      if (step !== SUMMARY_STEP) {
        setStep(step + 1);
      } else {
        onSaveForm();
      }
    };

    const onSkipStep = () => {
      if (step === MUTE_EXCLUDE_STEP) {
        setIgnoreAudioSlidesList('');
        setIgnoreSlidesList('');
        setIgnoreAudioListIsValid(true);
        setIgnoreListIsValid(true);
      }
      if (step === IMAGE_GENERATION_STEP) {
        setAiImage(false);
      }
      setStep(step + 1);
    };

    const canSkipCurrentStep = () => {
      if (
        step !== FILE_PICK_STEP &&
        step !== VIDEO_DURATION_STEP &&
        step !== VOICE_NARRATION_STEP &&
        step !== SUMMARY_STEP
      ) {
        return true;
      }
      return false;
    };

    const getNextButtonTitleForStep = () => {
      if (step !== SUMMARY_STEP) {
        return generateAIVideosText.nextStep;
      } else {
        return generateAIVideosText.generateVideo;
      }
    };

    const onPreviousStep = () => {
      if (step !== 0) {
        setStep(step - 1);
      }
    };

    const currentStepIsValid = () => {
      switch (step) {
        case FILE_PICK_STEP:
          return file !== undefined;
        case MUTE_EXCLUDE_STEP: {
          if (
            ignoreAudioSlidesList !== '' &&
            getSecondsFromTimeText(ignoreAudioSlidesDuration) === 0
          ) {
            return false;
          }
          return (
            ignoreAudioListIsValid &&
            ignoreListIsValid &&
            ignoreAudioSlidesDurationIsValid()
          );
        }
        case VIDEO_DURATION_STEP:
          return videoDuration !== undefined;
        case IMAGE_GENERATION_STEP:
          return true;
        case VOICE_NARRATION_STEP:
          return true;
        case SUMMARY_STEP:
          return true;
      }
    };

    const getContentForStep = () => {
      switch (step) {
        case FILE_PICK_STEP:
          return <UploadFileStep onFileSelected={setFile} file={file} />;
        case MUTE_EXCLUDE_STEP:
          return (
            <MuteExcudeStep
              ignoreAudioSlidesDuration={ignoreAudioSlidesDuration}
              ignoreAudioSlidesList={ignoreAudioSlidesList}
              ignoreSlidesList={ignoreSlidesList}
              setIgnoreAudioSlidesDuration={setIgnoreAudioSlidesDuration}
              setIgnoreAudioSlidesList={onAudioIgnoreSlidesChange}
              setIgnoreSlidesList={onIgnoreSlidesChange}
              ignorePhrasesList={ignorePhrasesList}
              setIgnorePhrasesList={setIgnorePhrasesList}
            />
          );
        case VIDEO_DURATION_STEP:
          return (
            <VideoDuratonStep
              videoDuration={videoDuration}
              setVideoDuration={setVideoDuration}
            />
          );
        case IMAGE_GENERATION_STEP:
          return (
            <ImageGenerationStep
              aiImage={aiImage}
              setSliderValue={setSliderValue}
              aiVideo={generateAiVideos}
              setAiVideo={setGenerateAiVideos}
              setAiImage={setAiImage}
              sliderValue={sliderValue}
            />
          );
        case VOICE_NARRATION_STEP:
          return (
            <VoiceNarrationStep
              language={language}
              setLanguage={onLanguageSelect}
              dialectOptions={dialectOptions}
              selectedDialect={selectedDialect}
              setSelectedDialect={setSelectedDialect}
              voiceGender={voiceGender}
              setVoiceGender={setVoiceGender}
            />
          );
        case SUMMARY_STEP:
          return (
            <SummaryStep
              filename={file?.name || ''}
              excludePhrases={
                ignorePhrasesList.length
                  ? ignorePhrasesList.reduce(
                      (acc, value) => (acc += ', ' + value),
                    )
                  : ''
              }
              muteDuration={ignoreAudioSlidesDuration}
              muteSlides={ignoreAudioSlidesList}
              excludeSlides={ignoreSlidesList}
              duration={videoDuration || '0'}
              generateImages={aiImage}
              imageRatio={sliderValue}
              language={language}
              dialect={selectedDialect}
              voiceGender={voiceGender}
            />
          );
        default:
          return <div />;
      }
    };

    return (
      <div className="AIGenerationContainer">
        <ModalContainer onClose={onCancel}>
          <div className="GenerateAIVideosContainer">
            <div className="Header">
              <h1>{generateAIVideosText.ModalHeader}</h1>
            </div>
            <Row className="Content">
              <GenerationSteps activeStep={step} />
              {getContentForStep()}
              <HelpTip step={step} />
            </Row>
            <div className="Footer">
              <Button
                title={generateAIVideosText.previousStep}
                onClick={onPreviousStep}
                uiType="hollow"
                className="SkipButton"
                disabled={step === 0}
              />
              {canSkipCurrentStep() && (
                <Button
                  title={generateAIVideosText.skipStep}
                  onClick={onSkipStep}
                  uiType="hollow"
                  className="SkipButton"
                />
              )}
              <Button
                title={getNextButtonTitleForStep()}
                onClick={onNextStep}
                uiType="action"
                disabled={!currentStepIsValid() || isLoading}
                isLoading={isLoading}
              />
            </div>
          </div>
        </ModalContainer>
      </div>
    );
  },
);

export default GenerateAIVideosUI;
