import React from 'react';
import {editorAddItemIc, lessonArrowDown} from '../../assets/images';
import './styles.css';
import Tooltip from '../Tooltip';
import EditorTooltipButton from '../EditorTooltopButton';

interface Props {
  onClick: () => void;
  isDragging: boolean;
  withArrow?: boolean;
  tooltip?: string;
  actions?: {icon: string; action: () => void}[];
}

const EditorAddBelowButton = React.memo(
  ({onClick, isDragging, withArrow, tooltip, actions}: Props) => {
    const getButton = () => {
      if (actions) {
        return <EditorTooltipButton actions={actions} />;
      } else {
        return (
          <button onClick={onClick} className="EditorAddBelowButton">
            <img src={editorAddItemIc} />
          </button>
        );
      }
    };

    return (
      <div
        className="EditorAddBelowAction"
        style={{
          opacity: isDragging ? 0 : 1,
        }}>
        {withArrow && <img src={lessonArrowDown} />}
        {tooltip ? (
          <Tooltip tooltipText={tooltip} greenTooltip>
            {getButton()}
          </Tooltip>
        ) : (
          getButton()
        )}
      </div>
    );
  },
);

export default EditorAddBelowButton;
