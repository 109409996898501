import {makeAutoObservable} from 'mobx';
import OrganizationStaffRepository from '../../../../data/repositories/OrganizationStaffRepository';
import {toast} from 'react-toastify';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import {courseInstructorText} from '../courseInstructorText';
import CoursesRepository from '../../../../data/repositories/CoursesRepository';

class CourseInstructorStore {
  private organizationStaffRepository = new OrganizationStaffRepository();
  private coursesRepository = new CoursesRepository();

  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public async getCurrentInstructor(organizationId: string, courseId: string) {
    const result = await this.coursesRepository.getCourseInstructor(
      organizationId,
      courseId,
    );
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async saveInstructors(
    selectedInstructor: string,
    organizationId: string,
    courseId: string,
  ) {
    this.isLoading = true;
    const result = await this.organizationStaffRepository.saveInstructors(
      selectedInstructor,
      organizationId,
      courseId,
    );
    this.isLoading = false;
    if (result.success) {
      toast.success(courseInstructorText.successMessage, toastConfig);
      return result.data;
    } else {
      toast.error(courseInstructorText.errorMessage, toastConfig);
      return result.errors;
    }
  }

  public async removeInstructor(
    organizationStaffId: string,
    organizationId: string,
    courseId: string,
  ) {
    this.isLoading = true;
    const result = await this.organizationStaffRepository.removeInstructor(
      organizationStaffId,
      organizationId,
      courseId,
    );
    this.isLoading = false;
    if (result.success) {
      toast.success(courseInstructorText.successRemoveMessage, toastConfig);
    } else {
      toast.error(courseInstructorText.errorRemoveMessage, toastConfig);
    }
  }

  public async getInstructorCandidates(
    organizationId: string,
    courseId: string,
  ) {
    const result =
      await this.organizationStaffRepository.getInstructorCandidates(
        organizationId,
        courseId,
      );
    return result;
  }
}

export default CourseInstructorStore;
