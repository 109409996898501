import React from 'react';
import {CourseAccessGate} from '../../../modules/CourseAccessGate';
import CourseGradebookContainer from './container/CourseGradebookContainer';

const CourseGradebook = () => {
  return (
    <CourseAccessGate>
      <CourseGradebookContainer />
    </CourseAccessGate>
  );
};

export {CourseGradebook};
