import React from 'react';
import blobLoader from './blob.svg';
import aiLogo from './aiLogo.svg';
import {appText} from '../../assets/text/appText';
import './styles.css';

const AILoader = React.memo(() => {
  return (
    <div className="AILoader">
      <div className="loader-container">
        <img src={aiLogo} />
        <img src={blobLoader} />
      </div>
      <span>{appText.generating}</span>
    </div>
  );
});

export default AILoader;
