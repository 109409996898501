import {makeAutoObservable} from 'mobx';
import FoldersRepository from '../../../data/repositories/FoldersRepository';
import CoursesRepository from '../../../data/repositories/CoursesRepository';
import LessonsRepository from '../../../data/repositories/LessonsRepository';
import {Lesson, LessonCreateModel} from '../../../data/models/Course/Lesson';
import {lessonsText} from '../lessonsText';
import {LESSON_SUPPLEMENTAL} from '../../../data/staticValues/supplementalTypes';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../data/errorCodes';
import {toastConfig} from '../../../uiToolkit/Toast/toastConfig';
import LoadingModule from '../../../modules/LoadingModule/LoadingModule';

class LessonsStore {
  private foldersRepo = new FoldersRepository();
  private courseRepo = new CoursesRepository();
  private lessonsRepository = new LessonsRepository();
  private loadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async createLesson(organizationId: string, lesson: LessonCreateModel) {
    const result = await this.lessonsRepository.createLesson(
      organizationId,
      lesson,
    );
    return result.success;
  }

  public async getLessonFolder(organizationId: string, folderId: string) {
    const result = await this.foldersRepo.getFolderById(
      organizationId,
      folderId,
    );

    return result.data;
  }

  public async exportAsScorm(
    organizationId: string,
    courseId: string,
    courseName: string,
  ) {
    this.loadingModule.startLoading();
    await this.courseRepo.exportAsScorm(organizationId, courseId, courseName);
    this.loadingModule.endLoading();
  }

  public async getLessons(organizationId: string, folderId: string) {
    const result = await this.lessonsRepository.getLessons(
      organizationId,
      folderId,
    );
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }

  public async editLesson(organizationId: string, lesson: Lesson) {
    this.loadingModule.startLoading();
    const result = await this.lessonsRepository.editLesson(
      organizationId,
      lesson,
    );
    this.loadingModule.endLoading();
    if (!result.success && result.errors && result.errors.length) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
    return result.success;
  }

  public async reorderLessons(
    organizationId: string,
    folderId: string,
    lessons: Lesson[],
  ) {
    const result = await this.lessonsRepository.orderLessons(
      organizationId,
      folderId,
      lessons,
    );
    return result.success;
  }

  public async getCourse(organizationId: string, courseId: string) {
    const result = await this.courseRepo.getCourse(organizationId, courseId);
    return result.data;
  }

  public async deleteLesson(organizationId: string, lessonId: string) {
    this.loadingModule.startLoading();
    const result = await this.lessonsRepository.deleteLesson(
      organizationId,
      lessonId,
    );
    this.loadingModule.endLoading();
    return result.success;
  }

  public async addSupplementalLesson(organizationId: string, lesson: Lesson) {
    this.loadingModule.startLoading();
    const createSupplementalResult = await this.lessonsRepository.createLesson(
      organizationId,
      {
        title: lessonsText.newLessonTitle,
        description: lessonsText.newLessonDescription,
        organizationId: organizationId,
        order: 0,
        isGradable: false,
        libraryMediaFiles: [],
        isSupplementalLesson: true,
        folderId: lesson.folderId,
      },
    );
    if (createSupplementalResult.success && createSupplementalResult.data) {
      lesson.supplementalLessonId = createSupplementalResult.data.id;
      lesson.supplementalType = LESSON_SUPPLEMENTAL;
      const result = await this.lessonsRepository.editLesson(
        organizationId,
        lesson,
      );
      if (!result.success) {
        await this.lessonsRepository.deleteLesson(
          organizationId,
          createSupplementalResult.data.id,
        );
      }
    }
    this.loadingModule.endLoading();
  }
}

export default LessonsStore;
