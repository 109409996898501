import {ToastOptions} from 'react-toastify';

export const toastConfig: ToastOptions = {
  position: 'bottom-left',
  autoClose: 4000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  theme: 'light',
};
