import React, {useEffect, useState} from 'react';
import PasswordInput from '../../../../uiToolkit/Inputs/PasswordInput';
import {securityUserProfileText} from '../securityUserProfileText';
import './styles.css';
import Button from '../../../../uiToolkit/Buttons/Button';

interface Props {
  onChangePassword: (
    password: string,
    newPassword: string,
    confirmPassword: string,
  ) => void;
  updated: boolean;
}

const SecurityUserProfileUI = React.memo(
  ({onChangePassword, updated}: Props) => {
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newPasswordSecure, setNewPasswordSecure] = useState(true);

    const [confirmBlurred, setConfirmBlurred] = useState(false);
    const [newBlurred, setNewBlurred] = useState(false);

    useEffect(() => {
      setPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setNewBlurred(false);
      setConfirmBlurred(false);
    }, [updated]);

    const onInitiateSave = () => {
      onChangePassword(
        password.trim(),
        newPassword.trim(),
        confirmPassword.trim(),
      );
    };

    const onNewPasswordChange = (value: string, isSecure: boolean) => {
      setNewPassword(value);
      setNewPasswordSecure(isSecure);
    };

    const changesWasMade = () => {
      return newPassword !== '' && confirmPassword !== '' && password !== '';
    };

    const changesAreValid = () => {
      return (
        newPassword.length > 0 &&
        password.length > 0 &&
        confirmPassword.length > 0 &&
        newPasswordSecure &&
        newPassword === confirmPassword
      );
    };

    return (
      <div className="SecurityUserProfileContainer">
        <h5>{securityUserProfileText.title}</h5>
        <span className="Subtitle">{securityUserProfileText.subtitle}</span>

        <PasswordInput
          value={password}
          onChange={setPassword}
          showValidation={false}
          placeholder={securityUserProfileText.currentPasswordPlaceholder}
          uiType="standart"
          title={securityUserProfileText.currentPasswordTitle}
          className="SecurityInput"
          isMandatory
        />

        <PasswordInput
          value={newPassword}
          onChange={onNewPasswordChange}
          showValidation={newPassword.length !== 0}
          placeholder={securityUserProfileText.newPasswordPlaceholder}
          uiType="standart"
          title={securityUserProfileText.newPasswordTitle}
          className="SecurityInput"
          fieldError={newBlurred && !newPasswordSecure}
          onBlur={() => setNewBlurred(true)}
          isMandatory
        />

        <PasswordInput
          value={confirmPassword}
          onChange={setConfirmPassword}
          showValidation={false}
          placeholder={securityUserProfileText.confirmPasswordPlaceholder}
          uiType="standart"
          title={securityUserProfileText.confirmPasswordTitle}
          className="SecurityInput"
          fieldError={
            confirmPassword.length > 0 &&
            newPassword !== confirmPassword &&
            confirmBlurred
          }
          onBlur={() => setConfirmBlurred(true)}
          isMandatory
        />

        <Button
          title={securityUserProfileText.saveChanges}
          onClick={onInitiateSave}
          uiType="action"
          disabled={!changesWasMade() || !changesAreValid()}
          isLoading={false}
          className="SecuritySaveButton"
        />
      </div>
    );
  },
);

export default SecurityUserProfileUI;
