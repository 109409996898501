import React, {useContext} from 'react';
import {EditorContext} from '../container/LessonEditorContainer';
import {LessonContentChoiceImageOption} from '../../../data/models/LessonContent/LessonContentBase';
import {NodeData} from '../models';
import NodeHandles from './NodeHandles';

interface Props {
  data: NodeData;
}

const ChoiceImageNode = ({data}: Props) => {
  const context = useContext(EditorContext);

  const onClick = (e: any) => {
    context.setSelectedNode(data.payload, data.type);
    e.stopPropagation();
  };

  return (
    <NodeHandles>
      <div
        className={`EditorCard ${
          context.selectedNode?.internalId === data.payload.internalId
            ? 'SelectedEditorCard'
            : ''
        }`}
        onClick={onClick}>
        <div className="PlaceholderContainer">
          <img
            src={(data.payload as LessonContentChoiceImageOption).contentUrl}
          />
        </div>
      </div>
    </NodeHandles>
  );
};

export default ChoiceImageNode;
