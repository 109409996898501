import React from 'react';
import {inject, observer} from 'mobx-react';
import AddJumpToWizardUI from '../view/AddJumpToWizardUI';
import AddJumpToWizardStore from '../store/AddJumpToWizardStore';
import {useParams} from 'react-router-dom';
import {
  LessonContentList,
  getListOfItemsFromContentList,
} from '../../../../data/models/LessonContent/LessonContentList';

interface Props {
  addJumpToWizardStore?: AddJumpToWizardStore;
  lessonContent?: LessonContentList;
}

const AddChoiceTextOptionWizardContainer = inject('addJumpToWizardStore')(
  observer(({addJumpToWizardStore, lessonContent}: Props) => {
    const {organizationId, lessonId} = useParams();

    const onCancel = () => {
      addJumpToWizardStore!.cancelWizard();
    };

    const onCreate = (jumpToId: string) => {
      if (organizationId && lessonId) {
        addJumpToWizardStore!.createOption(organizationId, lessonId, jumpToId);
      }
    };
    return addJumpToWizardStore!.isVisible() ? (
      <AddJumpToWizardUI
        onCancel={onCancel}
        onCreate={onCreate}
        options={getListOfItemsFromContentList(lessonContent)}
      />
    ) : null;
  }),
);

export default AddChoiceTextOptionWizardContainer;
