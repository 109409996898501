import React from 'react';
import {CourseAccessGate} from '../../modules/CourseAccessGate';
import CourseHomeContainer from './container/CourseHomeContainer';

const CourseHome = () => {
  return (
    <CourseAccessGate publishedCourse>
      <CourseHomeContainer />
    </CourseAccessGate>
  );
};

export {CourseHome};
