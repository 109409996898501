import React, {Children, ReactElement, cloneElement, useState} from 'react';
import './styles.css';

interface Props {
  tabs: {
    tabName: string;
  }[];
  entityToEdit?: any;
  entityType?: string;
  children: React.ReactElement[] | React.ReactElement;
  emptyStateText: string;
}

const EditorOptions = React.memo(
  ({children, tabs, entityToEdit, emptyStateText, entityType}: Props) => {
    const [activeTab, setActiveTab] = useState(tabs[0].tabName);

    const arrayChildren = Children.toArray(children);

    return entityToEdit ? (
      <div className="EditorOptionsTabs">
        <div className="Tabs">
          {tabs.map(tab => (
            <p
              key={tab.tabName}
              onClick={() => setActiveTab(tab.tabName)}
              className={activeTab === tab.tabName ? 'Active' : ''}>
              {tab.tabName}
            </p>
          ))}
        </div>
        <div className="TabsContentContainer">
          {Children.map(arrayChildren, child => {
            return (child as ReactElement).props.contentForTab === activeTab &&
              (child as ReactElement).props.optionsForType === entityType ? (
              cloneElement(child as ReactElement, {
                entityToEdit,
              })
            ) : (
              <div />
            );
          })}
        </div>
      </div>
    ) : (
      <div className="EditorOptionsEmptyStateContainer">
        <span>{emptyStateText}</span>
      </div>
    );
  },
);

export default EditorOptions;
