import React from 'react';
import LearningJourneyUI from '../view/LerningJourneyUI';
import {inject, observer} from 'mobx-react';
import LearningJourneyStore from '../store/LearningJourneyStore';

interface Props {
  learningJourneyStore?: LearningJourneyStore;
}

const LearningJourneyContainer = inject('learningJourneyStore')(
  observer(({learningJourneyStore}: Props) => {
    return learningJourneyStore?.isOpen ? (
      <LearningJourneyUI
        onClose={() => learningJourneyStore!.closeJourney()}
        lessonContent={learningJourneyStore!.lessonContent}
        progress={learningJourneyStore!.learnerProgress}
      />
    ) : null;
  }),
);

export default LearningJourneyContainer;
