// Use how width is used and for styles the width can by 70vw instead of 100%
import React, {useRef, useState} from 'react';
import SimplePlayerControlled from '../../SimplePlayerControlled';
import './styles.css';

interface Props {
  heading: string;
  source?: string;
  setDuration?: (duration: number) => void;
  startTime?: number;
  endTime?: number;
}

const BoxVideoInput = React.memo(
  ({heading, source, setDuration, startTime, endTime}: Props) => {
    const [width, setWidth] = useState(0);

    const containerRef = useRef<any>(null);

    React.useEffect(() => {
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [containerRef, containerRef.current]);

    const handleResize = () => {
      if (containerRef && containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
      }
    };

    return (
      <div className="BoxVideoInput">
        <div className="VideoContainer" ref={containerRef}>
          <p className="EditorOptionsSectionTitle">{heading}</p>
          <SimplePlayerControlled
            source={source}
            width={width}
            onDuration={setDuration}
            noLogo
            startTime={startTime}
            endTime={endTime}
            uiType="box"
          />
        </div>
      </div>
    );
  },
);

export default BoxVideoInput;
