import React from 'react';
import {dashboardRootText} from '../dashboardRootText';

interface Props {
  storageUsed: number;
  storageAvailable?: number;
  isUsersLimit?: boolean;
}

const QuotaLimitUI = React.memo(
  ({storageUsed, storageAvailable, isUsersLimit}: Props) => {
    const getWidthPercent = () => {
      let result = storageUsed;
      if (storageAvailable) {
        result = (storageUsed * 100) / storageAvailable;
      }
      if (result > 100) {
        return 0;
      }
      return result;
    };

    const getStorageAvailable = () => {
      if (isUsersLimit) {
        return storageAvailable ? storageAvailable : 0;
      } else {
        return storageAvailable ? storageAvailable / 1000 : 0;
      }
    };

    return (
      <div className="QuotaLimitContainer">
        <div className="Header">
          <p>
            {isUsersLimit
              ? dashboardRootText.usersQuota
              : dashboardRootText.quota}
          </p>
          <p>{}</p>
        </div>
        <div className="Underline">
          <div className="Inline" style={{width: `${getWidthPercent()}%`}} />
        </div>
        <div className="Footer">
          <p className="FooterText">
            {!isUsersLimit ? storageUsed / 1000 : storageUsed}{' '}
            {isUsersLimit ? dashboardRootText.users : dashboardRootText.mb} /{' '}
            {getStorageAvailable()}{' '}
            {isUsersLimit ? dashboardRootText.users : dashboardRootText.mb}
          </p>
        </div>
      </div>
    );
  },
);

export default QuotaLimitUI;
