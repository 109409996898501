import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const englishCourseAccessText = {
  accessNotAllowedAdmin1:
    'Sorry, this feature is not available in your current plan.',
  accessNotAllowedAdmin2:
    'To access this feature, please upgrade your subscription.',
  accessNotAllowedLearner1:
    'Sorry, it looks like this course cannot be accessed right now.',
  accessNotAllowedLearner2: 'Please contact your Administrator.',
  accessNotAllowedStaff1:
    'Sorry, it looks like this feature cannot be accessed right now.',
  accessNotAllowedStaff2: 'Please contact your Administrator.',
  home: 'Home',
  upgrade: 'Upgrade',
};

const welshCourseAccessText = {
  accessNotAllowedAdmin1:
    "Mae'n ddrwg gennym, nid yw'r nodwedd hon ar gael yn eich cynllun presennol",
  accessNotAllowedAdmin2:
    'I gael mynediad at y nodwedd hon, uwchraddiwch eich tanysgrifiad',
  accessNotAllowedLearner1:
    "Mae'n ddrwg gennym, mae'n edrych yn debyg nad oes modd cyrchu'r cwrs hwn ar hyn o bryd",
  accessNotAllowedLearner2: "Cysylltwch â'ch Gweinyddwr",
  accessNotAllowedStaff1:
    "Mae'n ddrwg gennym, mae'n edrych yn debyg nad oes modd cyrchu'r cwrs hwn ar hyn o bryd",
  accessNotAllowedStaff2: "Cysylltwch â'ch Gweinyddwr",
  home: 'Cartref',
  upgrade: 'Uwchraddio',
};

export const courseAccessGateText: any = getLocalizationBasedOnLang(
  englishCourseAccessText,
  welshCourseAccessText,
);
