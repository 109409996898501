import React from 'react';
import {addFreeformChoiceText} from '../addFreeformChoiceText';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';
import {LessonContentFreeformShapeChoice} from '../../../../data/models/LessonContent/LessonContentBase';
import Button from '../../../../uiToolkit/Buttons/Button';
import {
  MAX_SHAPE_POINTS,
  MIN_SHAPE_POINTS,
} from '../../FreeformElements/EditorSvgPath/utils';
import Checkbox from '../../../../uiToolkit/Inputs/Checkbox';

interface Props {
  shapeNode: LessonContentFreeformShapeChoice;
  onChangeShapeField: (field: string, value: any) => void;
  onAddShapePoint: () => void;
  onRemoveShapePoint: () => void;
}

const FreeformShapeOptions = React.memo(
  ({
    shapeNode,
    onChangeShapeField,
    onAddShapePoint,
    onRemoveShapePoint,
  }: Props) => {
    return (
      <div className="OptionsContainer">
        <p className="SectionHeader">{addFreeformChoiceText.options}</p>
        <div className="OptionsContent">
          <div className="d-flex flex-row justify-content-between OptionsInput">
            <Button
              uiType="box"
              title={addFreeformChoiceText.addPoint}
              onClick={onAddShapePoint}
              disabled={
                shapeNode.overlayCoordinates.length === MAX_SHAPE_POINTS
              }
            />
            <Button
              uiType="box"
              title={addFreeformChoiceText.removePoint}
              onClick={onRemoveShapePoint}
              className="RemovePointButton"
              disabled={
                shapeNode.overlayCoordinates.length === MIN_SHAPE_POINTS
              }
            />
          </div>
          <TextInput
            value={shapeNode.title}
            heading={addFreeformChoiceText.imageChoiceTitle}
            onChange={(value: string) => onChangeShapeField('title', value)}
            placeholder={addFreeformChoiceText.title}
            uiType="box"
          />
          <Checkbox
            text={addFreeformChoiceText.showOverlay}
            checked={shapeNode.overlayVisible}
            onCheck={() =>
              onChangeShapeField('overlayVisible', !shapeNode.overlayVisible)
            }
            slim
            color="#555555"
            className="VisibleCheck"
          />
        </div>
      </div>
    );
  },
);

export default FreeformShapeOptions;
