import React from 'react';
import {inject, observer} from 'mobx-react';
import {LessonContentFreeformShapeChoice} from '../../../../../data/models/LessonContent/LessonContentBase';
import {lessonEditorText} from '../../../lessonEditorText';
import FreeformShapeOptionsUI from '../view/FreeformShapeOptionsUI';
import AddFreeformChoiceStore from '../../../../../modules/Editor/AddFreeformChoiceOption/store/AddFreeformChoiceStore';
import BaseOptionsContainer, {
  BaseContainerForwardProps,
  BaseContainerProps,
} from '../../BaseOptionsContainer';

interface Props extends BaseContainerForwardProps {
  entityToEdit?: LessonContentFreeformShapeChoice;
  addFreeformChoiceStore?: AddFreeformChoiceStore;
}

const FreeformShapeOptionsContainer = inject('addFreeformChoiceStore')(
  observer(
    ({
      entityToEdit,
      onDelete,
      onEdit,
      isPublished,
      onChangesMade,
      addFreeformChoiceStore,
      isLoading,
    }: Props) => {
      const onEditShape = (title: string, isCorrect: boolean | null) => {
        onEdit({
          ...entityToEdit,
          title,
          isCorrect,
        } as LessonContentFreeformShapeChoice);
      };

      const openEditOverlay = () => {
        if (entityToEdit) {
          addFreeformChoiceStore!.showWizardForEdit(
            entityToEdit,
            onChangesMade,
          );
        }
      };

      return entityToEdit ? (
        <>
          <FreeformShapeOptionsUI
            choiceShape={entityToEdit}
            openEditOverlay={openEditOverlay}
            isLoading={isLoading}
            onSave={onEditShape}
            onDelete={onDelete}
            isPublished={isPublished}
          />
        </>
      ) : null;
    },
  ),
);

export default React.memo((props: BaseContainerProps) => (
  <BaseOptionsContainer
    {...props}
    deleteMessage={lessonEditorText.areYouSureDeleteChoiceShapeOption}>
    {forwardProps => (
      <FreeformShapeOptionsContainer
        {...forwardProps}
        entityToEdit={
          forwardProps.entityToEdit as LessonContentFreeformShapeChoice
        }
      />
    )}
  </BaseOptionsContainer>
));
