import React from 'react';
import './styles.css';

interface Props {
  title: string;
  onSelect: (value: string) => void;
  value: string;
  subtitle: string;
  isSelected: boolean;
  className?: string;
}

const RadioGroupOption = React.memo(
  ({title, onSelect, value, isSelected, className, subtitle}: Props) => {
    return (
      <button
        onClick={() => onSelect(value)}
        className={`RadioGroupOption ${className}`}>
        <div className="RadioButton">
          <div className="OptionCircle">
            {isSelected ? <div className="OptionSelcted" /> : null}
          </div>
          <p>{title}</p>
        </div>
        <p className="Subtitle">{subtitle}</p>
      </button>
    );
  },
);

export default RadioGroupOption;
