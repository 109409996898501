import {makeAutoObservable, runInAction} from 'mobx';
import NotificationsRepository from '../../../data/repositories/NotificationsRepository';
import {Notification} from '../../../data/models/Notifications/Invitation';

class NotificationCentreStore {
  private notificationsRepository = new NotificationsRepository();

  constructor() {
    makeAutoObservable(this);
  }

  private notifications: Notification[] = [];

  public async getMyNotifications() {
    const result = await this.notificationsRepository.getMyNotifications();
    runInAction(() => {
      if (result.success && result.data) {
        this.notifications = result.data;
      } else {
        this.notifications = [];
      }
    });
  }

  public async denyInvitation(invitaionId: string, notificationId: string) {
    const result =
      await this.notificationsRepository.denyInvitation(invitaionId);
    if (result.success) {
      await this.notificationsRepository.markAsSeen(notificationId);
    }
    return result.success;
  }

  public async markAsSeen(notificationId: string) {
    await this.notificationsRepository.markAsSeen(notificationId);
  }

  public async markAsRead() {
    await this.notificationsRepository.markAsRead();
  }

  public getNotifications() {
    return this.notifications;
  }

  public async getSeenNotifications(page: number, pageSize: number) {
    const result = await this.notificationsRepository.getSeenNotifications(
      page,
      pageSize,
    );
    if (result.success && result.data) {
      return result.data;
    }
    return {
      data: [],
      pagesCount: 0,
    };
  }
}

export default NotificationCentreStore;
