import * as React from 'react';
import HomeUI from '../view/HomeUI';
import HomeStore from '../store/HomeStore';
import {inject, observer} from 'mobx-react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import ModalProviderStore from '../../../modules/ModalProvider/store/ModalProviderStore';
import {
  REGISTER_TO_MANAGE,
  REGISTER_TO_TEACH,
} from '../../../data/staticValues/onboardingOptions';
import {CREATE_ORGANIZATION_MODAL} from '../../../modules/ModalProvider/globalModals';
import {ORGANIZATION_USER_LEARNER} from '../../../data/models/Oganization/Organization';
import NavigationStore from '../../../navigation/store/NavigationStore';
import UserInfoStore from '../../../stores/UserInfoStore';

interface Props {
  homeStore?: HomeStore;
  modalProviderStore?: ModalProviderStore;
  navigationStore?: NavigationStore;
  userInfoStore?: UserInfoStore;
}

const HomeContainer = inject(
  'homeStore',
  'modalProviderStore',
  'navigationStore',
  'userInfoStore',
)(
  observer(
    ({
      homeStore,
      modalProviderStore,
      navigationStore,
      userInfoStore,
    }: Props) => {
      const [searchParams] = useSearchParams();
      const navigate = useNavigate();

      React.useEffect(() => {
        homeStore!.setCurrentPage(0);
        userInfoStore!.fetchUserInfo();
        homeStore!.fetchCourses();
      }, [homeStore]);

      React.useEffect(() => {
        if (
          searchParams.get('rp') &&
          (searchParams.get('rp') === REGISTER_TO_MANAGE ||
            searchParams.get('rp') === REGISTER_TO_TEACH) &&
          !navigationStore!.userOrganization
        ) {
          openCreateOrg();
        }
      }, []);

      const openCreateOrg = () => {
        modalProviderStore!.openModal(
          CREATE_ORGANIZATION_MODAL,
          onCloseOrgModal,
        );
      };

      const onCloseOrgModal = (orgId?: string) => {
        if (orgId && typeof orgId === 'string') {
          navigate(`/manage/${orgId}/courses`);
        } else if (searchParams.get('rp')) {
          navigate('/home', {replace: true});
        }
      };

      const onExplore = () => {
        navigate('/explore');
      };

      const onCreateCourse = () => {
        if (
          navigationStore!.userOrganization &&
          navigationStore!.userOrganization.userType !==
            ORGANIZATION_USER_LEARNER
        ) {
          navigate(`/manage/${navigationStore!.userOrganization.id}/courses`);
        }
      };

      const onOpenCourse = (courseId: string, teaching?: boolean) => {
        if (
          teaching &&
          navigationStore!.userOrganization &&
          navigationStore!.userOrganization.userType !==
            ORGANIZATION_USER_LEARNER
        ) {
          navigate(
            `/preview/${
              navigationStore!.userOrganization.id
            }/course/${courseId}`,
          );
        } else {
          navigate(`/course/${courseId}`);
        }
      };

      const onChangePage = (page: number) => {
        homeStore!.setCurrentPage(page);
        homeStore!.fetchCourses();
      };

      return (
        <HomeUI
          userInfo={userInfoStore!.userInfo}
          userOrganizationDetails={navigationStore!.userOrganization}
          learningCourses={homeStore!.myLearningCoursesList}
          isLoading={homeStore!.isLoading}
          onCreateOrg={openCreateOrg}
          onExplore={onExplore}
          onCreateCourse={onCreateCourse}
          onOpenCourse={onOpenCourse}
          currentPage={homeStore!.currentPage}
          pagesCount={homeStore!.pagesCount}
          onChangePage={onChangePage}
        />
      );
    },
  ),
);

export default HomeContainer;
