import React from 'react';
import {videoSourcesText} from '../../videoSourcesText';
import {MediaFile} from '../../../../data/models/LibraryFile/MediaFile';
import RecentFileItem from '../components/RecentFileItem';
import TapyblFilesTable from '../components/TapyblFilesTable';
import './styles.css';
import SearchInput from '../../../../uiToolkit/Inputs/SearchInput';

interface Props {
  onPreview: (mediaFile: MediaFile) => void;
  recentFiles: MediaFile[];
  libraryFiles: MediaFile[];
  onPageChange: (page: number) => void;
  pages: number;
  onDelete: (id: string) => void;
  onUpload: (file: File) => void;
  isLoading: boolean;
  onSort: (selectedColumn: string, sortDirection: string) => void;
  onDownloadTranscript: (id: string) => void;
  search: string;
  onSearch: (value: string) => void;
}

const TapyblLibraryUI = React.memo(
  ({
    onPreview,
    recentFiles,
    libraryFiles,
    onPageChange,
    pages,
    onDelete,
    onSort,
    onUpload,
    isLoading,
    onDownloadTranscript,
    search,
    onSearch,
  }: Props) => {
    return (
      <div>
        <h5 className="RecentFilesTitle">{videoSourcesText.recent}</h5>
        <div className="RecentFilesContainer">
          {recentFiles.map(item => (
            <RecentFileItem
              item={item}
              onItemClick={onPreview}
              key={item.id.toString()}
            />
          ))}
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between mt-4">
          <h5 className="AllFilsTitle">{videoSourcesText.allFiles}</h5>
          <SearchInput value={search} onChange={onSearch} className="Search" />
        </div>
        <TapyblFilesTable
          sources={libraryFiles}
          onPageChange={onPageChange}
          pages={pages}
          onDelete={onDelete}
          onDownloadTranscript={onDownloadTranscript}
          onFileUpload={onUpload}
          isLoading={isLoading}
          onSort={onSort}
          onPreview={onPreview}
        />
      </div>
    );
  },
);

export default TapyblLibraryUI;
