import LearnersApi from '../api/StudentsApi';
import OrganizationApi from '../api/OrganizationApi';

class LearnerRepository {
  private learnersApi = new LearnersApi();
  private organizationApi = new OrganizationApi();

  public getMyLearners = (
    page: number,
    organizationId: string,
    pageSize: number,
    courseIds?: string[],
    search?: string,
  ) => {
    return this.learnersApi.getMyLearners(
      page,
      organizationId,
      pageSize,
      courseIds,
      search,
    );
  };

  public getEnrollmentCandidates = (
    organizationId: string,
    courseId: string,
    page: number,
    pageSize: number,
    search?: string,
  ) => {
    return this.learnersApi.getEnrollmentCandidates(
      organizationId,
      courseId,
      page,
      pageSize,
      search,
    );
  };

  public getLearnersInvitations = (
    organizationId: string,
    page: number,
    pageSize: number,
    orderBy: string,
    search?: string,
    orderAsc?: boolean,
  ) => {
    return this.learnersApi.getLearnerInvitations(
      organizationId,
      page,
      pageSize,
      orderBy,
      search,
      orderAsc,
    );
  };

  public addLearnersToCourse = (
    organizationLearnerIds: string[],
    organizationId: string,
    courseId: string,
    dueDate?: Date,
  ) => {
    return this.learnersApi.addLearnersToCourse(
      organizationLearnerIds,
      organizationId,
      courseId,
      dueDate,
    );
  };

  public addLearners = (organizationId: string, learnersEmails: string[]) => {
    return this.organizationApi.addLearnersToOrganization(
      organizationId,
      learnersEmails,
    );
  };

  public deleteLearner = (organizationId: string, learnerId: string) => {
    return this.learnersApi.deleteLearnerFromOrganization(
      organizationId,
      learnerId,
    );
  };

  public activateLearner = (organizationId: string, learnerId: string) => {
    return this.learnersApi.reactivateLearnerInOrganization(
      organizationId,
      learnerId,
    );
  };

  public removeLearnerFromTheCourse = (
    organizationId: string,
    learnerId: string,
    courseId: string,
  ) => {
    return this.learnersApi.removeLearnerFromTheCourse(
      organizationId,
      learnerId,
      courseId,
    );
  };

  public reEnrollLearnerInTheCourse = (
    organizationId: string,
    courseId: string,
    learnerId: string,
  ) => {
    return this.learnersApi.reEnrollLearnerInTheCourse(
      organizationId,
      courseId,
      learnerId,
    );
  };

  public async deleteLearnerInvitation(
    organizationId: string,
    invitationId: string,
  ) {
    return this.learnersApi.deleteLearnerInvitation(
      organizationId,
      invitationId,
    );
  }
}

export default LearnerRepository;
