import React from 'react';
import {LessonContentChallengeBranchAI} from '../../../../../data/models/LessonContent/LessonContentBase';
import ChallengeBranchAIOptionsUI from '../view/ChallengeBranchAIOptionsUI';
import {lessonEditorText} from '../../../lessonEditorText';
import BaseOptionsContainer, {
  BaseContainerProps,
} from '../../BaseOptionsContainer';

export default React.memo((props: BaseContainerProps) => (
  <BaseOptionsContainer
    {...props}
    deleteMessage={lessonEditorText.areYouSureDeleteChallengeHeaderOption}>
    {forwardProps => (
      <ChallengeBranchAIOptionsUI
        {...forwardProps}
        entityToEdit={
          forwardProps.entityToEdit as LessonContentChallengeBranchAI
        }
      />
    )}
  </BaseOptionsContainer>
));
