export const REGISTER_TO_MANAGE = 'to_manage';
export const REGISTER_TO_TEACH = 'to_create';
export const REGISTER_TO_LEARN = 'to_learn';

export const onboardingOptions = [
  {
    key: REGISTER_TO_LEARN,
    subtitle:
      "Explore and enroll in any of tapybl's thousands of captivating interactive learning courses!",
    value: 'Learn',
  },
  {
    key: REGISTER_TO_TEACH,
    subtitle:
      'Sign up to create courseware for your organization or to share on the tapybl workspace.',
    value: 'Create',
  },
  {
    key: REGISTER_TO_MANAGE,
    subtitle:
      'Set up and manage an organizational workspace for seamless course creation, learner management, integrations, and beyond!',
    value: 'Manage a workspace',
  },
];
