import {makeAutoObservable} from 'mobx';
import UserRepository from '../../../../data/repositories/UserRepository';
import LoadingModule from '../../../../modules/LoadingModule/LoadingModule';
import {getErrorFromCode} from '../../../../data/errorCodes';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import {securityUserProfileText} from '../securityUserProfileText';
import {toast} from 'react-toastify';

class SecurityUserProfileStore {
  private userRepository = new UserRepository();
  private loadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async editUserPassword(
    password: string,
    newPassword: string,
    confirmNewPassword: string,
  ) {
    this.loadingModule.startLoading();
    const result = await this.userRepository.changeUserPassword(
      password,
      newPassword,
      confirmNewPassword,
    );
    if (result.errors && result.errors.length) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    } else if (!result.success) {
      toast.error(securityUserProfileText.passwordUpdateFailed, toastConfig);
    }
    this.loadingModule.endLoading();
    return result.success;
  }
}

export default SecurityUserProfileStore;
