import React from 'react';
import NodeHandles from './NodeHandles';
import {mChoiceAnswerIncorrect} from '../../../assets/images';

const ChallengeBranchAIInorrectNode = React.memo(() => {
  return (
    <NodeHandles>
      <div>
        <img src={mChoiceAnswerIncorrect} />
      </div>
    </NodeHandles>
  );
});

export default ChallengeBranchAIInorrectNode;
