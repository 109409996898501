import React from 'react';
import './styles.css';
import {OverlayTrigger, Tooltip as BSTooltip} from 'react-bootstrap';

interface Props {
  children: React.ReactElement;
  tooltipText: string;
  greenTooltip?: boolean;
  className?: string;
}

const Tooltip = React.memo(
  ({children, tooltipText, greenTooltip, className}: Props) => {
    const tooltip = () => (
      <BSTooltip
        id={greenTooltip ? 'g-t' : 'd-t'}
        className={'DefaultTooltip'}
        style={{position: 'fixed'}}>
        {tooltipText}
      </BSTooltip>
    );

    return (
      <div className={`DefaultTooltip ${className ? className : ''}`}>
        <OverlayTrigger placement="bottom" overlay={tooltip()}>
          <div>{children}</div>
        </OverlayTrigger>
      </div>
    );
  },
);

export default Tooltip;
