import React from 'react';
import {Col} from 'react-bootstrap';
import {
  FILE_PICK_STEP,
  MUTE_EXCLUDE_STEP,
  VIDEO_DURATION_STEP,
  IMAGE_GENERATION_STEP,
  VOICE_NARRATION_STEP,
  SUMMARY_STEP,
} from '../config';
import {generateAIVideosText} from '../generateAIVideosText';

interface HelpTipProps {
  step: number;
}

const HelpTip: React.FC<HelpTipProps> = ({step}) => {
  const getHelpContentForStep = () => {
    switch (step) {
      case FILE_PICK_STEP:
        return (
          <div>
            <ul>
              <li>{generateAIVideosText.helpTipFile1}</li>
              <li>{generateAIVideosText.helpTipFile2}</li>
              <li>{generateAIVideosText.helpTipFile3}</li>
              <li>{generateAIVideosText.helpTipFile4}</li>
            </ul>
          </div>
        );
      case MUTE_EXCLUDE_STEP:
        return (
          <div>
            <p>{generateAIVideosText.helpTipExclude1}</p>
            <p>{generateAIVideosText.helpTipExclude2}</p>
            <p>{generateAIVideosText.helpTipExclude3}</p>
            <p>{generateAIVideosText.helpTipExclude4}</p>
          </div>
        );
      case VIDEO_DURATION_STEP:
        return (
          <div>
            <p>{generateAIVideosText.helpTipDuration}</p>
            <p>{generateAIVideosText.helpTipDuration2}</p>
          </div>
        );
      case IMAGE_GENERATION_STEP:
        return (
          <div>
            <p>{generateAIVideosText.helpTipImageGen1}</p>
            <p>{generateAIVideosText.helpTipImageGen2}</p>
          </div>
        );
      case VOICE_NARRATION_STEP:
        return (
          <div>
            <p>{generateAIVideosText.helpTipNarration}</p>
            <p>{generateAIVideosText.helpTipNarration2}</p>
          </div>
        );
      case SUMMARY_STEP:
        return (
          <div>
            <p>{generateAIVideosText.helpTipSummary}</p>
          </div>
        );
      default:
        return <p></p>;
    }
  };

  return (
    <Col lg={6} xs={12}>
      <div className="HelpTip">
        <h3>
          <span className="icon-typeHelp"></span>{' '}
          {generateAIVideosText.helpTitle}
        </h3>
        {getHelpContentForStep()}
      </div>
    </Col>
  );
};

export default HelpTip;
