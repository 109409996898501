import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  title: 'Welcome to the tapybl Add-ons Shop',
  subtitle:
    'Supercharge your tapybl experience with powerful, flexible tools designed to elevate your productivity. From increasing storage capacity to adding more AI credits and learners, our add-ons are here to grow with your needs. Get started now and unlock more in minutes! ',
  orderSummary: 'Review Your Order',
  goBack: 'Go back to shop',
  yourCurrentPlanIs: 'Your current plan is: ',
  product: 'Product',
  quantity: 'Quantity',
  price: 'Price',
  description: 'Description',
  needMore: 'Need more features? ',
  exploreMore: 'Explore Other Plans',
  cancel: 'Cancel',
  nextStep: 'Next Step',
  maxAmountAvailable: 'Maximum additional seats available: ',
  continue: 'Complete Order',
};

const welsh = {
  title: 'Croeso i Siop Ychwanegion tapybl.',
  subtitle:
    'Uwchraddiwch eich profiad tapybl gyda chyfarpar pwerus a hyblyg sydd wedi’u cynllunio i godi’ch cynhyrchiant. O gynyddu’r capasiti storio i ychwanegu mwy o gredydau AI a dysgwyr, mae ein hychwanegion yma i dyfu gyda’ch anghenion. Dechreuwch nawr a datgloi mwy o fewn ychydig funudau!',
  orderSummary: 'Adolygwch eich archeb',
  goBack: "Ewch yn ôl i'r siop",
  yourCurrentPlanIs: 'Eich cynllun presennol yw ',
  product: 'Cynnyrch',
  quantity: 'Nifer',
  price: 'Pris',
  description: 'Disgrifiad',
  needMore: 'Angen mwy o nodweddion?',
  exploreMore: 'Archwiliwch gynlluniau eraill',
  cancel: 'Canslo',
  nextStep: 'Camau Nesaf',
  continue: 'Complete Order',
};

export const marketplaceText = getLocalizationBasedOnLang(english, welsh);
