import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  welcome: 'Welcome',
  noCourses: 'Take the first step!',
  noLearnerCourses: 'Looks like you have not been enrolled in any courses yet!',
  learnerDisbled:
    'Sorry, it looks like this workspace cannot be accessed right now. Please contact your Administrator.',
  create: 'Become a tapybl creator with your own workspace and course',
  createAction: 'Create Workspace',
  progress: 'Progress',
  teachingTitle: 'My Teaching Courses',
  learningTitle: 'My Learning Courses',
};

const welsh = {
  welcome: 'Croeso',
  noCourses: 'Cymerwch y cam cyntaf!',
  noLearnerCourses:
    "Mae'n edrych fel nad ydych wedi'ch cofrestru mewn unrhyw gyrsiau eto!",
  learnerDisbled:
    "Mae'n ddrwg gennym, mae'n edrych fel na ellir cael mynediad i'r gweithle hwn ar hyn o bryd. Cysylltwch â'ch Gweinyddwr, os gwelwch yn dda",
  create: "Dewch yn creadwr tapybl gyda'ch gweithle a'ch cwrs eich hun",
  createAction: 'Creu Gweithle',
  progress: 'Cynnydd',
  teachingTitle: 'Fy Nghyrsiau Addysgu',
  learningTitle: 'Fy Nghyrsiau Dysgu',
};

export const homeText: any = getLocalizationBasedOnLang(english, welsh);
