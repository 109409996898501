import React from 'react';
import {lessonsText} from '../lessonsText';
import {alertTriangleIc} from '../../../assets/images';

interface Props {
  onSelect: () => void;
  isSelected: boolean;
}

const AlertCard = React.memo(({onSelect, isSelected}: Props) => {
  const onClick = (e: any) => {
    onSelect();
    e.stopPropagation();
  };

  return (
    <div
      className={`AlertCard ${isSelected ? 'SelectedLessonCard' : ''}`}
      onClick={onClick}>
      <p>{lessonsText.supplementalAlert}</p>
      <img src={alertTriangleIc} />
    </div>
  );
});

export default AlertCard;
