import React, {useState, useEffect} from 'react';
import NotificationsMenuPopUpUI from '../view/NotificationsMenuPopUpUI';
import NotificationCentreStore from '../../NotificationCentre/store/NotificationCentreStore';
import {inject, observer} from 'mobx-react';
import NotificationsOpenerButton from '../components/NotificationsOpenerButton';
import {useNavigate} from 'react-router-dom';

interface Props {
  notificationCentreStore?: NotificationCentreStore;
  shown: boolean;
  onToggle: (e: any) => void;
  onHide: () => void;
}

const NotificationsMenuPopUpContainer = inject('notificationCentreStore')(
  observer(({notificationCentreStore, shown, onToggle, onHide}: Props) => {
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
      document.body.addEventListener('click', onDocumentClick);
      return () => {
        removeEventListener('click', onDocumentClick);
      };
    }, []);

    const onDocumentClick = () => {
      onHide();
    };

    useEffect(() => {
      fetchNotifications();
      if (isExpanded) {
        markAllAsRead();
      }
    }, [isExpanded]);

    const markAllAsRead = async () => {
      await notificationCentreStore!.markAsRead();
    };

    const fetchNotifications = () => {
      notificationCentreStore?.getMyNotifications();
    };

    const onViewWorkspace = async (
      workspaceId: string,
      notificationId: string,
    ) => {
      await notificationCentreStore!.markAsSeen(notificationId);
      navigate(`/manage/${workspaceId}/workspace`);
    };

    const onViewCourse = async (courseId: string, notificationId: string) => {
      await notificationCentreStore!.markAsSeen(notificationId);
      navigate(`/course/${courseId}`);
    };

    const onViewCoursePreview = async (
      courseId: string,
      workspaceId: string,
      notificationId: string,
    ) => {
      await notificationCentreStore!.markAsSeen(notificationId);
      navigate(`/preview/${workspaceId}/course/${courseId}`);
    };

    const onViewAll = () => {
      navigate('/profile/notifications');
    };

    return (
      <div>
        <NotificationsOpenerButton
          onClick={e => {
            onToggle(e);
            setIsExpanded(!isExpanded);
          }}
          notificationsCount={
            notificationCentreStore!.getNotifications().length
          }
        />
        {shown ? (
          <NotificationsMenuPopUpUI
            notifications={notificationCentreStore!.getNotifications()}
            onViewAll={onViewAll}
            onViewCourse={onViewCourse}
            onViewWorkspace={onViewWorkspace}
            onViewCoursePreview={onViewCoursePreview}
          />
        ) : null}
      </div>
    );
  }),
);

export default NotificationsMenuPopUpContainer;
