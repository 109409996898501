import React from 'react';
import './styles.css';
import {cookiePromptText} from '../cookiePromptText';

interface Props {
  onClose: () => void;
}

const HELP_GUIDE =
  'https://anitechsolutions.atlassian.net/wiki/spaces/THC/pages/242024617/Tapybl+Third-Party+Cookies+Help+Guide';

const CookiePromptUI = React.memo(({onClose}: Props) => {
  return (
    <div className="CookiePromptContainer">
      <div className="d-flex flex-row justify-content-end">
        <button className="CloseButton" onClick={onClose}>
          <span className="icon-typeClose"></span>
        </button>
      </div>
      <div className="TextContainer">
        <h3>{cookiePromptText.title}</h3>
        <p>
          {cookiePromptText.info}
          <a href={HELP_GUIDE}>{cookiePromptText.guide}</a>
        </p>
      </div>
    </div>
  );
});

export default CookiePromptUI;
