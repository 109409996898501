import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  DateHeading: 'Course Completion Date',
  DatePlaceholder: '(Optional)',
  cantFindLearners: 'No results found',
  invite: 'Invite',
  enroll: 'Enroll Selected',
  courseNotPublished: 'To enroll learners you need to publish this course',
};

const welsh = {
  DateHeading: 'Dyddiad Gorffen y Cwrs',
  DatePlaceholder: '(Dewisol)',
  cantFindLearners: "Dim canlyniad wedi'i ganfod",
  invite: 'Gwahodd',
  enroll: "Cofrestru'r rhai a ddewiswyd",
  courseNotPublished:
    "I gofrestru dysgwyr, mae angen i chi gyhoeddi'r cwrs hwn",
};

export const courseLearnerText: any = getLocalizationBasedOnLang(
  english,
  welsh,
);
