import React from 'react';
import {Handle, Position} from 'reactflow';
import {handleStyle} from '../../../modules/EditorSettings/settings';

interface Props {
  children: React.ReactElement;
}

const NodeHandles = React.memo(({children}: Props) => {
  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        style={handleStyle}
        isConnectable={false}
      />
      {children}
      <Handle
        type="source"
        position={Position.Bottom}
        style={handleStyle}
        isConnectable={false}
      />
    </>
  );
});

export default NodeHandles;
