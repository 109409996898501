import {makeAutoObservable} from 'mobx';
import LibraryRepository from '../../../data/repositories/LibraryRepository';
import LoadingModule from '../../../modules/LoadingModule/LoadingModule';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../data/errorCodes';
import {toastConfig} from '../../../uiToolkit/Toast/toastConfig';
import {GeneratedVideoChunk} from '../../../data/models/LibraryFile/GeneratedVideoChunk';
import {editVideoSourceText} from '../editVideoSourceText';

class EditVideoSourceStore {
  private libraryRepository = new LibraryRepository();
  private loadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async getVideoChunks(organizationId: string, mediaFileId: string) {
    this.loadingModule.startLoading();
    const result =
      await this.libraryRepository.getVideoChunksForGeneratedContent(
        organizationId,
        mediaFileId,
      );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    } else if (result.errors && result.errors.length) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
    return [];
  }

  public getMediaFile = async (organizationId: string, mediaFileId: string) => {
    const result = await this.libraryRepository.getByContentId(
      organizationId,
      mediaFileId,
    );
    if (result.success && result.data) {
      return result.data;
    }
  };

  public async updateVideoChunksForGeneratedContent(
    organizationId: string,
    mediaFileId: string,
    chunks: GeneratedVideoChunk[],
  ) {
    const result =
      await this.libraryRepository.updateVideoChunksForGeneratedContent(
        organizationId,
        mediaFileId,
        chunks,
      );
    if (result.errors && result.errors.length) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
    return result.success;
  }

  public async compileVideoChunks(organizationId: string, mediaFileId: string) {
    this.loadingModule.startLoading();
    const result = await this.libraryRepository.compileVideoFile(
      organizationId,
      mediaFileId,
    );
    this.loadingModule.endLoading();
    if (!result.success && result.errors && result.errors.length) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    } else if (result.success) {
      toast.success(editVideoSourceText.publishSuccess, toastConfig);
    }
    return result.success;
  }
}

export default EditVideoSourceStore;
