import React from 'react';
import {LessonContentVimeoVideo} from '../../../../../data/models/LessonContent/LessonContentBase';
import VideoSourceOptionsUI from '../view/VideoSourceOptionsUI';
import {lessonEditorText} from '../../../lessonEditorText';
import BaseOptionsContainer, {
  BaseContainerProps,
} from '../../BaseOptionsContainer';

export default React.memo((props: BaseContainerProps) => (
  <BaseOptionsContainer
    {...props}
    deleteMessage={lessonEditorText.areYouSureDeleteVideo}
    softDeleteMessage={lessonEditorText.areYouSureDeleteVideoSoft}>
    {forwardProps => (
      <VideoSourceOptionsUI
        {...forwardProps}
        entityToEdit={forwardProps.entityToEdit as LessonContentVimeoVideo}
      />
    )}
  </BaseOptionsContainer>
));
