import React from 'react';
import DataTable from 'react-data-table-component';
import {downloadTranscript, logoTextWhite} from '../../../../assets/images';
import {
  MEDIA_FILES_ALL,
  MediaFile,
} from '../../../../data/models/LibraryFile/MediaFile';
import TablePagination from '../../../../uiToolkit/TablePagination';
import {videoSourcesText} from '../../videoSourcesText';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import LibraryEmptyState from '../../../../uiToolkit/EmptyStates/LibraryEmptyState';
import {isImageFile} from '../../../../data/models/LibraryFile/VideoSourceShort';
import {formatDateTimeTextFormat} from '../../../../utils/notificationTimeTextConvertor';
import {getLocalizationBasedOnLang} from '../../../../data/models/User/UserInfo';

const tooltip = <Tooltip>{videoSourcesText.fileIsUsed}</Tooltip>;
const transcriptTooltip = (
  <Tooltip>{videoSourcesText.downloadTranscript}</Tooltip>
);

const columns = (
  onDelete: (itemId: string) => void,
  onPreview: (file: MediaFile) => void,
  onDownloadTranscript: (id: string) => void,
) => [
  {
    name: getLocalizationBasedOnLang('Filename', 'Enw Ffeil'),
    selector: (row: MediaFile) => row.fileName,
    sortable: true,
    sortField: 'file_name',
    cell: (row: MediaFile) => (
      <div className="TableNameContainer" data-tag="allowRowEvents">
        <div className="TableNameContainerThumb">
          {isImageFile(row.fileType) ? (
            row.fileUrl && <img src={row.fileUrl} alt={row.fileName} />
          ) : row.placeholderUrl ? (
            <img
              src={row.placeholderUrl}
              alt="placeholder"
              data-tag="allowRowEvents"
            />
          ) : (
            <img
              src={logoTextWhite}
              alt="placeholder"
              className="NoPlaceholderImage"
              data-tag="allowRowEvents"
            />
          )}
        </div>

        <h5 className="TableText" data-tag="allowRowEvents">
          {row.fileName}
        </h5>
      </div>
    ),
    grow: 4,
  },
  {
    name: getLocalizationBasedOnLang('Uploaded Date', 'Dyddiad a Lwythwyd'),
    sortable: true,
    sortField: 'created_date',
    selector: (row: MediaFile) => row.dateCreated.toString(),
    cell: (row: MediaFile) => (
      <h5 className="TableText" data-tag="allowRowEvents">
        {formatDateTimeTextFormat(row.dateCreated.toString())}
      </h5>
    ),
    id: 'created_date',
  },
  {
    name: getLocalizationBasedOnLang('Size', 'Maint'),
    sortable: true,
    sortField: 'size',
    selector: (row: MediaFile) => row.dateCreated.toString(),
    cell: (row: MediaFile) => (
      <h5 className="TableText" data-tag="allowRowEvents">
        {row.fileSize} MB
      </h5>
    ),
    id: 'size',
  },
  {
    name: getLocalizationBasedOnLang('Actions', 'Camau'),
    cell: (row: MediaFile) => (
      <>
        {row.hasTranscript ? (
          <OverlayTrigger placement="bottom" overlay={transcriptTooltip}>
            <div
              className="Transcript"
              onClick={() => onDownloadTranscript(row.id)}>
              <img src={downloadTranscript} />
            </div>
          </OverlayTrigger>
        ) : null}
        <button className="TableDeleteButton">
          {row.isUsed ? (
            <OverlayTrigger placement="bottom" overlay={tooltip}>
              <div className="DeleteDisabled">
                <span className="icon-typeTrash" />
              </div>
            </OverlayTrigger>
          ) : (
            <div className="Delete" onClick={() => onDelete(row.id)}>
              <span className="icon-typeTrash" />
            </div>
          )}
        </button>
        <div className="Preview" onClick={() => onPreview(row)}>
          <span className="icon-typeEye" />
        </div>
      </>
    ),
    id: 'actions',
  },
];

interface Props {
  sources: MediaFile[];
  pages: number;
  onPageChange: (page: number) => void;
  onDelete: (itemId: string) => void;
  isLoading: boolean;
  onFileUpload: (file: File) => void;
  onSort: (selectedColumn: any, sortDirection: any) => void;
  onPreview: (file: MediaFile) => void;
  onDownloadTranscript: (id: string) => void;
}

const TapyblFilesTable = React.memo(
  ({
    sources,
    pages,
    onPageChange,
    onDelete,
    isLoading,
    onFileUpload,
    onSort,
    onPreview,
    onDownloadTranscript,
  }: Props) => {
    return (
      <DataTable
        className="Table"
        columns={columns(onDelete, onPreview, onDownloadTranscript)}
        data={sources}
        noDataComponent={
          <LibraryEmptyState
            onVideoUpload={onFileUpload}
            isLoading={isLoading}
            type={MEDIA_FILES_ALL}
          />
        }
        pointerOnHover
        highlightOnHover
        onChangePage={onPageChange}
        pagination
        paginationServer
        onSort={onSort}
        paginationPerPage={10}
        paginationComponent={TablePagination}
        paginationTotalRows={pages * 10}
        striped
        sortServer
        theme="custom-stripes"
      />
    );
  },
);

export default TapyblFilesTable;
