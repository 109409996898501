import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  next: 'Next',
  optionsEmpty: (structure: string) =>
    `Get started by selecting the ${structure} to edit!`,
  folderTitle: 'Name',
  deleteTitle: (structure: string) => 'Delete ' + structure,
  deleteAction: 'Delete',
  deleteConfirmation: (structure: string) =>
    `Are you sure you want to delete this ${structure}?`,
  discardChangesConfirmation:
    'You have unsaved changes do you want to proceed?',
  description: 'Description',
  descriptionPlaceholder: (structure: string) => structure + ' description',
  newFolder: (structure: string) => 'Add New ' + structure,
  newFolderName: (structure: string) => 'New ' + structure,
  changesSaved: 'Changes Saved',
};

const welsh = {
  next: 'Nesaf',
  optionsEmpty: (structure: string) =>
    `Dechreuwch trwy ddewis y ${structure} i'w olygu!`,
  folderTitle: 'Enw',
  deleteTitle: (structure: string) => 'Dileu ' + structure,
  deleteAction: 'Dileu',
  deleteConfirmation: (structure: string) =>
    `Ydych chi'n siŵr eich bod am ddileu'r ${structure} hwn?`,
  discardChangesConfirmation:
    'Mae gennych newidiadau heb eu cadw. Ydych chi am barhau?',
  description: 'Disgrifiad',
  descriptionPlaceholder: (structure: string) => `Disgrifiad o ${structure}`,
  newFolder: (structure: string) => `Ychwanegwch ${structure} Newydd`,
  newFolderName: (structure: string) => `${structure} Newydd`,
  changesSaved: "Newidiadau Wedi'u Cadw",
};

export const foldersText: any = getLocalizationBasedOnLang(english, welsh);
