import React, {useState} from 'react';
import {closeFaq, openFaq} from '../../../../assets/images';

interface Props {
  question: string;
  answer: string[];
}

const FaqCard = React.memo(({question, answer}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`FAQCard ${isOpen ? 'FAQCardOpen' : ''}`}>
      <div className="Question">
        <p>{question}</p>
        <button onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? (
            <img src={closeFaq} />
          ) : (
            <div className="ButtonOpen">
              <img src={openFaq} />
            </div>
          )}
        </button>
      </div>
      {isOpen && (
        <div className="Answer">
          {answer.map(a => (
            <p key={a}>{a}</p>
          ))}
        </div>
      )}
    </div>
  );
});

export default FaqCard;
