import React from 'react';
import {Col} from 'react-bootstrap';
import {bookIc} from '../../../assets/images';
import {homeText} from '../homeText';
import Button from '../../../uiToolkit/Buttons/Button';

interface Props {
  onExplore: () => void;
  onCreate: () => void;
}

const UserEmptyState = React.memo(({onCreate}: Props) => {
  return (
    <Col lg={{span: 8, offset: 2}} xs={12}>
      <div className="HomeEmptyStateContainer">
        <img src={bookIc} alt="book" />
        <p className="Title">{homeText.noCourses}</p>
        <div className="ButtonsContainer">
          <div className="Button">
            <p>{homeText.create}</p>
            <Button
              title={homeText.createAction}
              onClick={onCreate}
              uiType="action"
            />
          </div>
        </div>
      </div>
    </Col>
  );
});

export default UserEmptyState;
