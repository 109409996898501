import React from 'react';

const CreateStudent = React.memo(() => {
  return (
    <div>
      <h1>CreateStudent</h1>
    </div>
  );
});

export {CreateStudent};
