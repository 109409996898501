import React, {useEffect, useState} from 'react';
import RolesListUI from '../view/RolesListUI';
import {Role} from '../../../../data/models/Oganization/Role';
import {useNavigate, useParams} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import SettingsStore from '../../store/SettingsStore';

interface Props {
  settingsStore?: SettingsStore;
}

const RoleListContainer = inject('settingsStore')(
  observer(({settingsStore}: Props) => {
    const [roles, setRoles] = useState<Role[]>([]);
    const {organizationId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      if (organizationId) {
        settingsStore!.getOrganizationRoles(organizationId).then(setRoles);
      }
    }, []);

    const onCreate = () => {
      navigate(`/manage/${organizationId}/settings/create_role`);
    };

    const onEdit = (id: string) => {
      navigate(`/manage/${organizationId}/settings/edit_role/${id}`);
    };

    const onDelete = async (id: string) => {
      if (organizationId) {
        const success = await settingsStore?.deleteOrganizationRole(
          organizationId,
          id,
        );
        if (success) {
          settingsStore!.getOrganizationRoles(organizationId).then(setRoles);
        }
      }
    };

    return (
      <RolesListUI
        roles={roles}
        onCreate={onCreate}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    );
  }),
);

export default RoleListContainer;
