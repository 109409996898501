import React, {useState} from 'react';
import VideoPlayerControls from '../../../uiToolkit/VideoPlayerControls';
import {pauseControlsIcon, playControlsIcon} from '../../../assets/images';
import {LessonContentVideo} from '../../../data/models/LessonContent/LessonContentBase';
import {StartEndPlayerRef} from '../models';
import StartEndPlayer from './StartEndPlayer';

const RATIO_16_9 = 1.7777;

const MILISECONDS_OVERFLOW_MARGIN = 0.03;
const MILISECONDS_IMAGE_OVERFLOW_MARGIN = 0.5;

interface Props {
  onFinished: () => void;
  currentNode?: LessonContentVideo;
  started: boolean;
  showControls?: boolean;
  fitHeight?: boolean;
  onWidthCalculated: (width: number) => void;
  reference?: (ref: any) => void;
  setProgres: (prog: number) => void;
  hideSmallControls?: boolean;
}

const VideoPlayer2 = React.memo(
  ({
    onFinished,
    currentNode,
    started,
    showControls,
    fitHeight,
    onWidthCalculated,
    reference,
    setProgres,
    hideSmallControls,
  }: Props) => {
    const [firstPlayerSource, setFirstPlaterSource] =
      useState<LessonContentVideo>();
    const [secondPlayerSource, setSecondPlayerSource] =
      useState<LessonContentVideo>();
    const [activeFirstPlayer, setActiveFirstPlayer] = useState(true);
    const [playerLoaded, setPlayerLoaded] = useState(false);
    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);

    const [currentPlayerRef, setCurrentPlayerRef] =
      useState<StartEndPlayerRef>();

    const containerRef = React.useRef<any>(null);

    React.useEffect(() => {
      if (reference) {
        reference({pause: refPause, seekTo: refSeek});
      }
    }, [currentPlayerRef]);

    React.useEffect(() => {
      if (currentPlayerRef) {
        setProgres(currentPlayerRef.currentTime);
      }
    }, [currentPlayerRef]);

    React.useEffect(() => {
      if (currentNode && width) {
        if (activeFirstPlayer) {
          setSecondPlayerSource(currentNode);
        } else {
          setFirstPlaterSource(currentNode);
        }
      }
    }, [currentNode, width]);

    const onCurrentPlayerFinished = () => {
      onFinished();
    };

    React.useEffect(() => {
      window.addEventListener('resize', setPlayerDimentions);
      return () => {
        window.removeEventListener('resize', setPlayerDimentions);
      };
    }, []);

    React.useLayoutEffect(() => {
      setPlayerDimentions();
    }, []);

    const setPlayerDimentions = () => {
      if (containerRef && containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
        onWidthCalculated(containerRef.current.offsetWidth);
        setHeight(containerRef.current.offsetHeight);
      }
    };

    const onFirstPlayerLoaded = () => {
      setActiveFirstPlayer(true);
      setSecondPlayerSource(undefined);
      setPlayerLoaded(true);
    };

    const onSecondPlayerLoaded = () => {
      setActiveFirstPlayer(false);
      setFirstPlaterSource(undefined);
      setPlayerLoaded(true);
    };

    const refPause = () => {
      currentPlayerRef?.setPlaying(false);
    };

    const refSeek = (seconds: number) => {
      currentPlayerRef?.seekTo(seconds);
    };

    const getEndTime = (source?: LessonContentVideo) => {
      if (source) {
        if (source.generatedFromImage) {
          return source.endInSeconds - MILISECONDS_IMAGE_OVERFLOW_MARGIN;
        } else {
          return source.endInSeconds - MILISECONDS_OVERFLOW_MARGIN;
        }
      }
      return 1;
    };

    return (
      <div
        className="VideoPlaybackPlayerContainer"
        ref={containerRef}
        style={{
          height: fitHeight ? '100vh' : '',
        }}>
        <div className="VideoCover" />
        {(!started || !playerLoaded) && (
          <div
            style={{
              width: width,
              height: fitHeight ? '100vh' : width / RATIO_16_9,
            }}
          />
        )}
        <StartEndPlayer
          source={firstPlayerSource?.contentUrl || ''}
          startTime={firstPlayerSource?.startInSeconds || 0}
          endTime={getEndTime(firstPlayerSource)}
          width={width}
          visible={started && activeFirstPlayer}
          height={fitHeight ? height : width / RATIO_16_9}
          onFinished={onCurrentPlayerFinished}
          reference={activeFirstPlayer ? setCurrentPlayerRef : undefined}
          onLoaded={onFirstPlayerLoaded}
        />
        <StartEndPlayer
          source={secondPlayerSource?.contentUrl || ''}
          startTime={secondPlayerSource?.startInSeconds || 0}
          endTime={getEndTime(secondPlayerSource)}
          width={width}
          visible={started && !activeFirstPlayer}
          height={fitHeight ? height : width / RATIO_16_9}
          onFinished={onCurrentPlayerFinished}
          reference={!activeFirstPlayer ? setCurrentPlayerRef : undefined}
          onLoaded={onSecondPlayerLoaded}
        />
        {showControls ? (
          <VideoPlayerControls
            playing={currentPlayerRef?.isPlaying || false}
            duration={currentPlayerRef?.duration || 0}
            togglePlaying={currentPlayerRef?.togglePlaying}
            currentTime={currentPlayerRef?.currentTime || 0}
            seekTo={currentPlayerRef?.seekTo}
            setPlaying={currentPlayerRef?.setPlaying}
          />
        ) : !hideSmallControls ? (
          <button
            className="SmallPlayButton"
            onClick={currentPlayerRef?.togglePlaying}>
            <img
              src={
                currentPlayerRef?.isPlaying
                  ? pauseControlsIcon
                  : playControlsIcon
              }
              alt="play"
              className="Icon"
            />
          </button>
        ) : null}
      </div>
    );
  },
);

export default VideoPlayer2;
