import React, {useState} from 'react';
import ModalContainer from '../../../../uiToolkit/ModalContainer';
import {addChoiceWizardText} from '../addChoiceQuetionWizardText';
import './styles.css';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';
import Button from '../../../../uiToolkit/Buttons/Button';

interface Props {
  onCancel: () => void;
  onCreate: (question: string) => void;
  isLoading?: boolean;
}

const AddChoiceQuestionWizardUI = React.memo(
  ({onCancel, onCreate, isLoading}: Props) => {
    const [question, setQuestion] = useState('');

    const onSave = () => {
      if (question.trim() !== '') {
        onCreate(question);
      }
    };

    return (
      <ModalContainer onClose={onCancel}>
        <div className="AddChoiceQuestionWizardContainer">
          <p className="Header">{addChoiceWizardText.title}</p>
          <TextInput
            value={question}
            onChange={setQuestion}
            heading={addChoiceWizardText.inputTitle}
            placeholder={addChoiceWizardText.placeholder}
            maxCharacters={150}
            isMandatory={true}
          />
          <div className="Footer">
            <Button
              title={addChoiceWizardText.cancel}
              onClick={onCancel}
              uiType="text"
            />
            <Button
              title={addChoiceWizardText.accept}
              onClick={onSave}
              isLoading={isLoading}
              uiType="action"
            />
          </div>
        </div>
      </ModalContainer>
    );
  },
);

export default AddChoiceQuestionWizardUI;
