import AuthApi from '../api/AuthAPI';
import {GoogleOAuthModel} from '../models/Auth/GoogleOAuthModel';
import {LoginModel} from '../models/Auth/LoginModel';
import {RegisterModel} from '../models/Auth/RegisterModel';
import {UserDataModel} from '../models/Auth/UserDataModel';
import LocalStorage from '../storage/LocalStorage';
import {storesProvider} from '../../App';

class AuthRepository {
  private authApi = new AuthApi();
  private localStorage = new LocalStorage();

  public async loginUser(model: LoginModel) {
    const result = await this.authApi.loginUser(model);
    if (result.success && result.data) {
      this.saveUserData({
        identityId: result.data.userIdentityId,
        email: model.email,
        emailConfirmed: result.data.emailConfirmed,
      });
    }
    return result;
  }

  public async loginAdminUser(model: LoginModel) {
    const result = await this.authApi.loginAdminUser(model);
    if (result.success && result.data) {
      this.saveUserData({
        identityId: result.data.userIdentityId,
        email: model.email,
        emailConfirmed: result.data.emailConfirmed,
      });
    }
    return result;
  }

  public getGoogleAuthLink() {
    return this.authApi.getGoogleAuthLink();
  }

  public refreshToken() {
    return this.authApi.refreshToken();
  }

  public async loginUserWithGoogle(model: GoogleOAuthModel) {
    const result = await this.authApi.loginUserWithGoogle(model);
    if (result.success && result.data) {
      this.saveUserData({
        identityId: result.data.userIdentityId,
        email: '',
        emailConfirmed: result.data.emailConfirmed,
      });
    }
    return result;
  }

  public async registerUser(model: RegisterModel) {
    const result = await this.authApi.registerUser(model);
    if (result.success && result.data) {
      this.saveUserData({
        identityId: result.data.userIdentityId,
        email: model.email,
        emailConfirmed: false,
      });
    }
    return result;
  }

  public async confirmEmail(code: string) {
    const user = this.getUser();
    if (user) {
      const result = await this.authApi.confirmEmail({code, email: user.email});
      this.saveUserData({
        ...user,
        emailConfirmed: true,
      });
      return result;
    }
    return null;
  }

  public async logout() {
    await this.authApi.invalidateToken();
    this.localStorage.resetThemeColors();
    this.localStorage.wipeLocalStorage();
    storesProvider.reInitStores();
    window.location.reload();
  }

  public async verifyUserPermission(
    permission: string,
    organizationId: string,
  ) {
    const result = await this.authApi.verifyUserPermission(
      permission,
      organizationId,
    );
    if (result.success && result.data?.permitted) {
      return true;
    }
    return false;
  }

  public getUserOrgPermissions(organizationId: string) {
    return this.authApi.getUserOrgPermissions(organizationId);
  }

  public sendResetPassword(email: string) {
    return this.authApi.sendForgotPasswordEmail(email);
  }

  public changePasswordWithCode(email: string, password: string, code: string) {
    return this.authApi.changePasswordWithCode({email, password, code});
  }

  public async sendEmailVerification() {
    return await this.authApi.sendEmailVerification();
  }

  public getUser(): UserDataModel | null {
    return this.localStorage.getItemFromStorage(
      LocalStorage.Keys.USER_KEY,
    ) as UserDataModel;
  }

  public async getTestCookie() {
    await this.authApi.getTestCookie();
  }

  public testCookiesEnabled() {
    return this.authApi.testCookiesEnabled();
  }

  private saveUserData(data: UserDataModel) {
    this.localStorage.saveToStorage(LocalStorage.Keys.USER_KEY, data);
  }
}

export default AuthRepository;
