import React, {useContext} from 'react';
import NodeHandles from './NodeHandles';
import {NodeData} from '../models';
import {LessonContentAcknowledgment} from '../../../data/models/LessonContent/LessonContentBase';
import {EditorContext} from '../container/LessonEditorContainer';
import Tooltip from '../../../uiToolkit/Tooltip';

interface Props {
  data: NodeData;
}

const AcknowledgmentNode = React.memo(({data}: Props) => {
  const context = useContext(EditorContext);

  const onClick = (e: any) => {
    context.setSelectedNode(data.payload, data.type);
    e.stopPropagation();
  };

  return (
    <NodeHandles>
      <div className="EditorNodeContainer">
        <div
          onClick={onClick}
          className={`EditorCard ${
            context.selectedNode?.internalId === data.payload.internalId
              ? 'SelectedEditorCard'
              : ''
          } ForceWidthCard`}>
          <Tooltip
            tooltipText={(data.payload as LessonContentAcknowledgment).title}
            greenTooltip>
            <p className="ChoiceQuestionNode">
              {(data.payload as LessonContentAcknowledgment).title}
            </p>
          </Tooltip>
        </div>
      </div>
    </NodeHandles>
  );
});

export default AcknowledgmentNode;
