import React, {useEffect, useState} from 'react';
import GeneralCourseEditStore from '../store/GeneralCourseEditStore';
import GeneralCourseEditUI from '../view/GeneralCourseEditUI';
import {inject, observer} from 'mobx-react';
import {useOutletContext, useParams} from 'react-router-dom';
import {CourseEdit} from '../../../../data/models/Course/CourseEdit';
import {toast} from 'react-toastify';
import {courseEditText} from '../../courseEditText';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import {Subject} from '../../../../data/models/Course/LearningStandards';
interface Props {
  generalCourseEditStore?: GeneralCourseEditStore;
}

const GeneralCourseEditContainer = inject('generalCourseEditStore')(
  observer(({generalCourseEditStore}: Props) => {
    // eslint-disable-next-line
    const [courseName, setCourseName] = useOutletContext() as [
      string,
      React.Dispatch<React.SetStateAction<string>>,
    ];
    const [subjects, setSubjects] = useState<Subject[]>([]);
    const {organizationId, courseId} = useParams();
    const [course, setCourse] = useState<any>();

    useEffect(() => {
      fetchCourse();
      if (organizationId) {
        generalCourseEditStore
          ?.getLearningStandarts(organizationId)
          .then(setSubjects);
      }
    }, [generalCourseEditStore]);

    const fetchCourse = () => {
      if (organizationId && courseId) {
        generalCourseEditStore!
          .getCourse(organizationId, courseId)
          .then(course => {
            setCourse(course);
            setCourseName(course?.title || '');
          });
      }
    };

    const onSave = async (courseEdit: CourseEdit) => {
      if (organizationId && courseId && course) {
        const success = await generalCourseEditStore!.saveCourse(
          organizationId,
          courseId,
          course,
          courseEdit,
        );
        if (success) {
          toast.success(courseEditText.changesSaved, toastConfig);
        }

        fetchCourse();
      }
    };

    return course ? (
      <GeneralCourseEditUI
        course={course}
        isLoading={generalCourseEditStore!.isLoading}
        onSave={onSave}
        subjectOptions={subjects}
      />
    ) : null;
  }),
);

export default GeneralCourseEditContainer;
