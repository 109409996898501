import React, {useEffect, useState} from 'react';
import TextInput from '../../../../../uiToolkit/Inputs/TextInput';
import {LessonContentAcknowledgment} from '../../../../../data/models/LessonContent/LessonContentBase';
import {lessonEditorText} from '../../../lessonEditorText';
import EditorOptionsFooter from '../../../../../uiToolkit/Editor/EditorOptionsFooter';
import {BaseContainerForwardProps} from '../../BaseOptionsContainer';
import ReplaceNode from '../../ReplaceNode';

interface Props extends BaseContainerForwardProps {
  entityToEdit: LessonContentAcknowledgment;
}

const AcknowledgmentOptionsUI = React.memo(
  ({
    entityToEdit,
    onEdit,
    onDelete,
    isPublished,
    isLoading,
    onSoftDelete,
  }: Props) => {
    const [title, setTitle] = useState('');
    const [buttonText, setButtonText] = useState('');

    useEffect(() => {
      setTitle(entityToEdit.title);
      setButtonText(entityToEdit.buttonTitle);
    }, [entityToEdit]);

    const changesWasMade = () =>
      entityToEdit.title !== title || entityToEdit.buttonTitle !== buttonText;

    const isValid = () => title.trim() !== '';

    const onSave = () => {
      if (isValid()) {
        onEdit({
          ...entityToEdit,
          title,
          buttonTitle: buttonText,
        } as LessonContentAcknowledgment);
      }
    };

    return (
      <div>
        <TextInput
          heading={lessonEditorText.acknowledgmentText}
          value={title}
          onChange={setTitle}
          placeholder={lessonEditorText.acknowledgmentTextPlaceholder}
          fieldError={title.trim() === ''}
          maxCharacters={500}
          isMandatory={true}
          uiType="box"
        />
        <TextInput
          heading={lessonEditorText.acknowledgmentButton}
          value={buttonText}
          onChange={setButtonText}
          placeholder={lessonEditorText.acknowledgmentButtonPlaceholder}
          fieldError={buttonText.trim() === ''}
          maxCharacters={40}
          isMandatory={true}
          uiType="box"
        />
        <ReplaceNode currentNode={entityToEdit} />
        <EditorOptionsFooter
          onSave={onSave}
          saveEnabled={changesWasMade() && isValid()}
          onDelete={onDelete}
          isLoading={isLoading}
          deleteDisabled={isPublished}
          onSoftDelete={onSoftDelete}
        />
      </div>
    );
  },
);

export default AcknowledgmentOptionsUI;
