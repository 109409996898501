import React from 'react';
import './styles.css';
import PageTitle from '../../../uiToolkit/PageTitle';
import {usersTitleIcon} from '../../../assets/images';
import {appText} from '../../../assets/text/appText';
import {Link, Outlet, useLocation, useParams} from 'react-router-dom';
import {Col, Row} from 'react-bootstrap';
import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const tabs = (organizationId: string) => [
  {
    name: getLocalizationBasedOnLang('General', 'Cyffredinol'),
    route: `/manage/${organizationId}/learners/general`,
  },
];

const LearnersRoot = React.memo(() => {
  const {organizationId} = useParams();
  const location = useLocation();

  return (
    <div className="ListRootContainer">
      <PageTitle icon={usersTitleIcon} title={appText.learners} />
      <div className="TabsContainer">
        {tabs(organizationId || '').map(tab => (
          <Link
            key={tab.name}
            className={location.pathname === tab.route ? 'ActiveButton' : ''}
            to={tab.route}>
            {tab.name}
          </Link>
        ))}
        <div className="FillBorder" />
      </div>
      <Row>
        <Col lg={12} md={12}>
          <Outlet />
        </Col>
      </Row>
    </div>
  );
});

export default LearnersRoot;
