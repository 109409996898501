import FoldersApi from '../api/FoldersApi';
import {Folder, FolderCreateModel} from '../models/Course/Folder';

class FoldersRepository {
  private readonly foldersApi = new FoldersApi();

  public getCourseFolders(organizationId: string, courseId: string) {
    return this.foldersApi.getCourseFolders(organizationId, courseId);
  }

  public async editFolder(organizationId: string, folder: Folder) {
    const result = await this.foldersApi.editFolder(organizationId, folder);
    if (result.success && folder.newImage) {
      await this.foldersApi.uploadFolderPoster(
        organizationId,
        folder.newImage,
        folder.courseId,
        folder.id || '',
      );
    }
    return result;
  }

  public createFolder(organizationId: string, folder: FolderCreateModel) {
    return this.foldersApi.createFolder(organizationId, folder);
  }

  public getFolderById(organizationId: string, folderId: string) {
    return this.foldersApi.getFolderById(organizationId, folderId);
  }

  public reorderFolders(
    organizationId: string,
    courseId: string,
    folders: Folder[],
  ) {
    return this.foldersApi.reorderFolders(organizationId, courseId, folders);
  }

  public deleteFolder(
    organizationId: string,
    courseId: string,
    folderId: string,
  ) {
    return this.foldersApi.deleteFolder(organizationId, courseId, folderId);
  }
}

export default FoldersRepository;
