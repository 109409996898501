import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const englishAcknowledgmentText = {
  title: 'Add Acknowledgment',
  titleInput: 'Acknowledgment text',
  placeholder:
    'Input your text that describes the terms and conditions of this acknowledgment node',
  buttonInput: 'Accept button title',
  buttonPlaceholder:
    'Set the title of the button i.e. Accept, OK, Acknowledge, Confirm, etc.',
  cancel: 'Cancel',
  save: 'Save',
};

const welshAcknowledgmentText = {
  title: 'Ychwanegwch Cydnabyddiaeth',
  titleInput: 'Testun Cydnabyddiaeth',
  placeholder:
    "Mewnbynnwch eich testun sy'n disgrifio telerau ac amodau'r nod cydnabod hwn",
  buttonInput: 'Derbyn teitl botwm',
  buttonPlaceholder:
    'Setiwch deitl y botwm h.y. Derbyn, Iawn, Cydnabod, Cadarnhau, ac ati.',
  cancel: 'Canslo',
  save: 'Cadw',
};

export const addAcknowledgmentWizardText: any = getLocalizationBasedOnLang(
  englishAcknowledgmentText,
  welshAcknowledgmentText,
);
