import {
  CHALLENGE_BRANCH_HEADER,
  CHOICE_HEADER_NODE_TYPE,
} from '../../data/models/LessonContent/LessonContentBase';
import {LessonContentList} from '../../data/models/LessonContent/LessonContentList';

export const sourceHasBranchChild = (
  lessonContent?: LessonContentList,
  sourceId?: string,
) => {
  if (
    lessonContent &&
    lessonContent.contentList &&
    lessonContent.contentList.items &&
    sourceId
  ) {
    const videoListItem = lessonContent.contentList.items[sourceId];
    if (
      videoListItem &&
      videoListItem.childIds &&
      videoListItem.childIds.length
    ) {
      const child = lessonContent.contentList.items[videoListItem.childIds[0]];
      if (
        child &&
        (child.data.ivNodeType === CHOICE_HEADER_NODE_TYPE ||
          child.data.ivNodeType === CHALLENGE_BRANCH_HEADER)
      ) {
        return true;
      }
    }
  }
  return false;
};
