import {ApiResponse} from '../models/ApiResponse';
import {InvoiceListItem} from '../models/Subscription/Invoice';
import {OrganizationHasSubscriptionResponse} from '../models/Subscription/OrganizationSubscription';
import {OrganizationSubscription} from '../models/Subscription/Subscription';
import {SubscriptionPermissions} from '../models/Subscription/SubscriptionPermission';
import {apiClient, withAuth} from './baseApiClient';

class SubscriptionApi {
  public async getSubscriptionPermissions(
    organizationId: string,
  ): Promise<ApiResponse<SubscriptionPermissions>> {
    const headers = await withAuth({});
    return apiClient.get(`/subscription/permissions/${organizationId}/all`, {
      headers,
    });
  }

  public async getSubscribeSession(
    organizationId: string,
    tier: number,
  ): Promise<ApiResponse<string>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/subscription/${organizationId}/create`,
      {type: tier},
      {
        headers,
      },
    );
  }

  public async getRenewSession(
    organizationId: string,
  ): Promise<ApiResponse<string>> {
    const headers = await withAuth({});
    return apiClient.post(`/subscription/${organizationId}/renew`, {
      headers,
    });
  }

  public editPreSelectedSubscription(organizationId: string, newTier: number) {
    return apiClient.post(
      `subscription/${organizationId}/update_selected_subscription_type`,
      {subscriptionType: newTier},
    );
  }

  public async getOrganizationHasSubscription(
    organizationId: string,
  ): Promise<ApiResponse<OrganizationHasSubscriptionResponse>> {
    return apiClient.get(`/subscription/${organizationId}/has_subscription`);
  }

  public async getPaymentMethodSession(
    organizationId: string,
  ): Promise<ApiResponse<string>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/subscription/${organizationId}/change_payment_method`,
      {},
      {
        headers,
      },
    );
  }

  public async purchaseAddons(
    addons: any,
    organizationId: string,
  ): Promise<ApiResponse<any>> {
    return apiClient.post(`/subscription/${organizationId}/update/one_time`, {
      addons,
    });
  }

  public async upgradeSubscription(
    organizationId: string,
    tier: number,
    addons?: any,
  ): Promise<ApiResponse<any>> {
    return apiClient.post(`/subscription/${organizationId}/update`, {
      addons,
      type: tier,
    });
  }

  public async subscribeForTrial(
    organizationId: string,
    tier: number,
  ): Promise<ApiResponse<string>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/subscription/${organizationId}/create_trial?type=${tier}`,
      {},
      {
        headers,
      },
    );
  }

  public async getOrganizationSubscription(
    organizationId: string,
  ): Promise<ApiResponse<OrganizationSubscription>> {
    const headers = await withAuth({});
    return apiClient.get(`/subscription/${organizationId}`, {
      headers,
    });
  }

  public async cancelSubscription(
    organizationId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/subscription/${organizationId}/cancel`,
      {},
      {
        headers,
      },
    );
  }

  public async getInvoicesList(
    organizationId: string,
  ): Promise<ApiResponse<InvoiceListItem[]>> {
    const headers = await withAuth({});
    return apiClient.get(`Subscription/${organizationId}/invoices`, {headers});
  }
}

export default SubscriptionApi;
