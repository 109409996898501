import {makeAutoObservable, runInAction} from 'mobx';
import NotificationsRepository from '../../../data/repositories/NotificationsRepository';
import CoursesListRepository from '../../../data/repositories/CoursesRepository';
import LoadingModule from '../../../modules/LoadingModule/LoadingModule';
import {LearningCourseModel} from '../../../data/models/Course/CourseList';

export const HOME_PAGE_SIZE = 8;

class HomeStore {
  private notificationsRepository = new NotificationsRepository();
  private coursesListRepository = new CoursesListRepository();
  private loadingModule = new LoadingModule(true);

  private learningCourses: LearningCourseModel[] = [];
  private _currentPage = 0;
  private _pagesCount = 0;

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public get myLearningCoursesList() {
    return this.learningCourses;
  }

  public get currentPage() {
    return this._currentPage;
  }

  public get pagesCount() {
    return this._pagesCount;
  }

  public setCurrentPage(page: number) {
    this._currentPage = page;
  }

  public async getMyInvitations() {
    const result = await this.notificationsRepository.getMyNotifications();
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }

  public async fetchCourses() {
    this.loadingModule.startLoading();
    const result = await this.coursesListRepository.getCourseList(
      this._currentPage,
      HOME_PAGE_SIZE,
    );

    runInAction(() => {
      if (result.success && result.data) {
        this.learningCourses = result.data.data;
        this._pagesCount = result.data.pagesCount;
      }
    });

    this.loadingModule.endLoading();
  }
}

export default HomeStore;
