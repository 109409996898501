import React, {useContext} from 'react';
import {inject, observer} from 'mobx-react';
import {
  BRANCH_IMAGE_FREEFORM,
  LessonContentFreeformImageChoice,
} from '../../../../../data/models/LessonContent/LessonContentBase';
import FreeformImageOptionsUI from '../view/FreeformImageOptionsUI';
import AddFreeformChoiceStore from '../../../../../modules/Editor/AddFreeformChoiceOption/store/AddFreeformChoiceStore';
import BaseOptionsContainer, {
  BaseContainerForwardProps,
  BaseContainerProps,
} from '../../BaseOptionsContainer';
import {lessonEditorText} from '../../../lessonEditorText';
import {
  MEDIA_FILES_IMAGE,
  MediaFile,
} from '../../../../../data/models/LibraryFile/MediaFile';
import {EditorContext} from '../../../container/LessonEditorContainer';
import {LessonContentList} from '../../../../../data/models/LessonContent/LessonContentList';

interface Props extends BaseContainerForwardProps {
  addFreeformChoiceStore?: AddFreeformChoiceStore;
  entityToEdit: LessonContentFreeformImageChoice;
}

const FreeformImageOptionsContainer = inject('addFreeformChoiceStore')(
  observer(
    ({
      entityToEdit,
      isPublished,
      addFreeformChoiceStore,
      onEdit,
      onDelete,
      isLoading,
      onChangesMade,
      onOpenLibrary,
    }: Props) => {
      const context = useContext(EditorContext);

      const onEditImage = (title: string, isCorrect: boolean | null) => {
        onEdit({
          ...entityToEdit,
          title,
          isCorrect,
        } as LessonContentFreeformImageChoice);
      };

      const openEditOverlay = () => {
        if (entityToEdit) {
          addFreeformChoiceStore!.showWizardForEdit(
            entityToEdit,
            onChangesMade,
          );
        }
      };

      const getCurrentNodeParent = () => {
        const lessonContent: LessonContentList = context.lessonContent;
        if (
          !lessonContent ||
          !lessonContent.contentList ||
          !lessonContent.contentList.items
        )
          return;
        const parents =
          lessonContent.contentList.items[entityToEdit.internalId]?.parentsIds;
        if (!parents || !parents.length) {
          return undefined;
        }
        return lessonContent.contentList.items[parents[0]]?.data;
      };

      const onReplaceSourceImage = () => {
        const parent = getCurrentNodeParent();
        onOpenLibrary(MEDIA_FILES_IMAGE, (file?: MediaFile) => {
          if (file && parent) {
            addFreeformChoiceStore!.showWizard(
              parent.internalId,
              BRANCH_IMAGE_FREEFORM,
              onChangesMade,
              context.lessonContent,
              {id: file.storageFileName, url: file.fileUrl || ''},
              entityToEdit,
            );
          }
        });
      };

      return entityToEdit ? (
        <>
          <FreeformImageOptionsUI
            entityToEdit={entityToEdit}
            openEditOverlay={openEditOverlay}
            isLoading={isLoading}
            onSave={onEditImage}
            onDelete={onDelete}
            isPublished={isPublished}
            onReplaceImageSource={onReplaceSourceImage}
          />
        </>
      ) : null;
    },
  ),
);

export default React.memo((props: BaseContainerProps) => (
  <BaseOptionsContainer
    {...props}
    deleteMessage={lessonEditorText.areYouSureDeleteChoiceImageOption}>
    {forwardProps => (
      <FreeformImageOptionsContainer
        {...forwardProps}
        entityToEdit={
          forwardProps.entityToEdit as LessonContentFreeformImageChoice
        }
      />
    )}
  </BaseOptionsContainer>
));
