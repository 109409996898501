import React from 'react';
import ChallengeQuestionOptionsUI from '../view/ChallengeQuestionOptionsUI';
import {LessonContentChallengeBranchQuestion} from '../../../../../data/models/LessonContent/LessonContentBase';
import {lessonEditorText} from '../../../lessonEditorText';
import BaseOptionsContainer, {
  BaseContainerProps,
} from '../../BaseOptionsContainer';

export default React.memo((props: BaseContainerProps) => (
  <BaseOptionsContainer
    {...props}
    deleteMessage={lessonEditorText.areYouSureDeleteChoiceQuestion}>
    {forwardProps => (
      <ChallengeQuestionOptionsUI
        {...forwardProps}
        entityToEdit={
          forwardProps.entityToEdit as LessonContentChallengeBranchQuestion
        }
      />
    )}
  </BaseOptionsContainer>
));
