import React from 'react';

import {CourseAccessGate} from '../../modules/CourseAccessGate';
import LessonsContainer from './container/LessonsContainer';

const Lessons = () => {
  return (
    <CourseAccessGate>
      <LessonsContainer />
    </CourseAccessGate>
  );
};

export {Lessons};
