import React, {useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {selectSubscriptionText} from '../selectSubscriptionText';
import './styles.css';
import {FAQ, subscriptionPlans} from '../subscriptionPlans';
import FeaturesTable from '../components/FeaturesTable';
import PlansSwitch from '../components/PlansSwitch';
import {SUBSCRIPTION_CREATOR} from '../../config';
import SubscriptionDetails from '../components/SubscriptionDetails';
import PlansCalculator from '../components/PlansCalculator';
import FaqCard from '../components/FaqCard';
import SubscriptionHeader from '../components/SubcsriptionHeader';

interface Props {
  onSelect: (tier?: number) => void;
}

const SelectSubscriptionUI = React.memo(({onSelect}: Props) => {
  const [planToShow, setPlanToShow] = useState(SUBSCRIPTION_CREATOR);

  const getCurrentPlan = () => {
    const result = subscriptionPlans.find(x => x.id === planToShow);
    if (result) {
      return result;
    } else {
      return subscriptionPlans.find(x => x.id === undefined);
    }
  };

  return (
    <>
      <SubscriptionHeader />

      <div className="SelectSubscriptionContainer">
        <h2 className="SelectTitle">{selectSubscriptionText.selectTitle}</h2>
        <div className="SwitchWrapper">
          <PlansSwitch
            selectedPlan={planToShow}
            setSelectedPlan={setPlanToShow}
          />
        </div>
        <SubscriptionDetails plan={getCurrentPlan()} onSelect={onSelect} />
        <PlansCalculator />
        <Container>
          <Row>
            <Col xs={12}>
              <FeaturesTable onSelect={onSelect} />
              <p className="Footnote">{selectSubscriptionText.tableFootnote}</p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col lg={{span: 9, offset: 1}} xs={12}>
              <h2 className="FAQTitle">{selectSubscriptionText.faq}</h2>
              <div>
                {FAQ.map(faq => (
                  <FaqCard {...faq} key={faq.question} />
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
});

export default SelectSubscriptionUI;
