export interface Point {
  x: number;
  y: number;
}

export interface SvgImage {
  x: number;
  y: number;
  width: number;
}

export const overlayTapActionOptions = () => {
  const availableActions = [
    {
      key: '0',
      value: 'URL',
    },
    {
      key: '1',
      value: 'Seek To',
    },
    {
      key: '2',
      value: 'Jump To Node',
    },
  ];
  // if (!gradable) {
  //   availableActions.push({
  //     key: '3',
  //     value: 'Navigate to Lesson',
  //   });
  // }
  return availableActions;
};

export const OVERLAY_PREV_NODE_KEY = 'prev_node';
export const OVERLAY_NEXT_NODE_KEY = 'next_node';

export const OVERLAY_PREV_NODE_OPTION = {
  key: OVERLAY_PREV_NODE_KEY,
  value: 'Prevoius Node',
};

export const OVERLAY_NEXT_NODE_OPTION = {
  key: OVERLAY_NEXT_NODE_KEY,
  value: 'Next Node',
};

export interface VideoOverlay {
  overlayStartTime: number;
  overlayEndTime: number;
  overlayCoordinates: {x: number; y: number; id?: number}[];
  actionType: number;
  payload?: string;
  encodedAtWidth: number;
  overlayVisible: boolean;
  overlayTitle: string;
}

export const OVERLAY_ACTION_URL = 0;
export const OVERLAY_ACTION_SEEK = 1;
export const OVERLAY_ACTION_JUMP = 2;
export const OVERLAY_ACTION_NAVIGATION = 3;
