import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  title: 'Learners',
  allLearners: 'All Learners',
  customGroups: 'Custom groups',
  active: 'Active',
  inactive: 'Inactive',
  invited: 'Invitation Sent',
  invite: 'Add Learners',
  learnerNotEnrolled:
    'Selected Learner has not been enrolled in any course yet.',
  remove: 'Remove',
  removeFromCourse: 'Remove learner enrollment',
  removeFromCourseConfirmation:
    'Are you sure you want to remove this learner from this course?',
  removeFromOrgConfirmation:
    'Are you sure you want to remove this learner from this workspace?',
  noLearners:
    'Looks like there are no learners in this workspace yet. Use the "Add Learners" button to get started!',
  noLearnersInCourse:
    'Looks like there are no learners in this course yet. Enroll learners in the course on the courses page',
  deleteLearner: 'Delete this learner',
  deleteInvitation: 'Delete invitation',
  activate: 'Activate this learner',
  activateEnrollment: 'Activate this enrollment',
  activateLearnerToActivateEnrollment:
    'Activate the learner record to activate his enrollments',
  copyEmail: 'Copy Email',
};

const welsh = {
  title: 'Dysgwyr',
  allLearners: 'Pob dysgwr',
  customGroups: 'Grwpiau personol',
  active: 'Actif',
  inactive: 'Anactif',
  invited: 'Gwahoddiad wedi’i anfon',
  invite: 'Ychwanegwch ddysgwyr',
  learnerNotEnrolled:
    'Nid yw’r Dysgwr Dethol wedi’i gofrestru mewn unrhyw gwrs eto',
  remove: 'Tynnwch ',
  removeFromCourse: 'Tynnwch gofrestriad dysgwr',
  removeFromCourseConfirmation:
    "Dych chi'n siŵr eich bod am dynnu’r dysgwr hwn o’r cwrs hwn?",
  removeFromOrgConfirmation:
    "Dych chi'n siŵr eich bod am dynnu’r dysgwr hwn o’r gweithle hwn?",
  noLearners:
    "Mae'n edrych fel nad oes dysgwyr yn y gweithle hwn eto. Defnyddiwch y botwm 'Ychwanegwch Ddysgwyr' i ddechrau!",
  noLearnersInCourse:
    "Mae'n edrych fel nad oes dysgwyr yn y cwrs hwn eto. Cofrestrwch ddysgwyr yn y cwrs ar y dudalen gyrsiau",
  deleteLearner: 'Dilewch y dysgwr hwn',
  deleteInvitation: 'Dilewch y gwahoddiad',
  activate: 'Actifadwch y dysgwr hwn',
  activateEnrollment: 'Actifadwch y cofrestriad hwn',
  activateLearnerToActivateEnrollment:
    'Actifadwch y cofrestr dysgwr i actifadu ei gofrestriadau',
  copyEmail: 'Copwch e-bost',
};

export const listLearnersText = getLocalizationBasedOnLang(english, welsh);
