import React, {useEffect} from 'react';
import ForgotPasswordStore from '../store/ForgotPasswordStore';
import {inject, observer} from 'mobx-react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import ResetPasswordUI from '../view/ResetPasswordUI';

interface Props {
  forgotPasswordStore?: ForgotPasswordStore;
}
const ResetPasswordContainer = inject('forgotPasswordStore')(
  observer(({forgotPasswordStore}: Props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
      return () => {
        forgotPasswordStore!.clearErrors();
      };
    }, []);

    const onResetPassword = async (password: string) => {
      const email = searchParams.get('email');
      const code = searchParams.get('code');
      if (email && code) {
        const result = await forgotPasswordStore?.changePasswordWithCode(
          email,
          password,
          code,
        );
        if (result) {
          navigate('/auth/login');
        }
      }
    };

    const clearErorrs = () => {
      forgotPasswordStore!.clearErrors();
    };

    return (
      <ResetPasswordUI
        errors={forgotPasswordStore!.errors}
        onResetPassword={onResetPassword}
        isLoading={forgotPasswordStore!.isLoading}
        clearErrors={clearErorrs}
      />
    );
  }),
);

export default ResetPasswordContainer;
