import React, {useState} from 'react';
import AuthHeader from '../../../../uiToolkit/AuthHeader';
import {signupText} from '../signupText';
import Checkbox from '../../../../uiToolkit/Inputs/Checkbox';
import {Link} from 'react-router-dom';
import PasswordInput from '../../../../uiToolkit/Inputs/PasswordInput';
import ErrorBox from '../../../../uiToolkit/ErrorBox';
import {internalErrorCodes} from '../../../../data/errorCodes';
import './styles.css';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';
import Button from '../../../../uiToolkit/Buttons/Button';
import {CookiePrompt} from '../../../../modules/CookiePrompt';

interface Props {
  onSignUp: (email: string, password: string, name: string) => void;
  errors: string[];
  isLoading: boolean;
  clearErorrs: () => void;
}

const SignupUI = React.memo(
  ({onSignUp, errors, isLoading, clearErorrs}: Props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [internalErrors, setInternalErrors] = useState<string[]>([]);
    const [passwordValid, setPasswordValid] = useState(false);

    const onClearErrors = () => {
      setInternalErrors([]);
      clearErorrs();
    };

    const onSubmit = () => {
      const newErrors = [];
      if (
        emailFormatIsValid(email.trim()) &&
        passwordValid &&
        name.trim() !== ''
      ) {
        onSignUp(email.trim(), password, name);
      } else {
        if (!emailFormatIsValid(email.trim())) {
          newErrors.push(internalErrorCodes.INVALID_EMAIL);
        }
        if (!passwordValid) {
          newErrors.push(internalErrorCodes.INVALID_PASSWORD);
        }
        if (name.trim() === '') {
          newErrors.push(internalErrorCodes.PROVIDE_USERNAME);
        }
      }
      setInternalErrors(newErrors);
    };

    const emailFormatIsValid = (emailValue: string) => {
      return emailValue.match(
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
    };

    const onChangePassword = (value: string, isValid: boolean) => {
      setPassword(value);
      setPasswordValid(isValid);
    };

    const buttonEnabled = () => {
      return (
        email.trim() !== '' &&
        password.trim() !== '' &&
        name.trim() !== '' &&
        name.trim().length >= 2 &&
        termsAccepted
      );
    };

    return (
      <div>
        <div className="SignupErrorContainer">
          <ErrorBox
            errorCodes={[...errors, ...internalErrors]}
            clearErrors={onClearErrors}
          />
        </div>
        <div className="SignupFormContainer">
          <AuthHeader title={signupText.title} subtitle={signupText.subtitle} />
          <div className="SignupInputContainer">
            <TextInput
              type="email"
              value={email}
              onChange={setEmail}
              placeholder={signupText.email}
              uiType="pill"
            />
          </div>
          <div className="SignupInputContainer">
            <TextInput
              type="text"
              value={name}
              onChange={setName}
              placeholder={signupText.name}
              maxCharacters={50}
              minCharacters={2}
              uiType="pill"
            />
          </div>
          <div className="SignupInputContainer">
            <PasswordInput
              value={password}
              onChange={onChangePassword}
              placeholder={signupText.password}
              showValidation
            />
          </div>
          <div className="SignupCheckboxContainer">
            <Checkbox
              checked={termsAccepted}
              onCheck={() => setTermsAccepted(!termsAccepted)}
              text={
                <span>
                  {signupText.acceptTerms}{' '}
                  <Link
                    to={'https://tapybl.com/full-terms-and-conditions/'}
                    className="SignupTermsLink"
                    target="_blank">
                    {signupText.termsAndPrivacy}
                  </Link>
                </span>
              }
            />
          </div>
          <div className="SignupSubmitButtonContainer">
            <Button
              title={signupText.submit}
              onClick={onSubmit}
              disabled={!buttonEnabled()}
              isLoading={isLoading}
              uiType="auth_action"
              fullWidth
            />
          </div>
          <p className="SignupHaveAccount">
            {signupText.haveAnAccount}
            <Link to={'/auth/login'} className="SignupSignin">
              {signupText.signin}
            </Link>
          </p>
        </div>
        <CookiePrompt />
      </div>
    );
  },
);

export default SignupUI;
