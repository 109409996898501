import {makeAutoObservable} from 'mobx';
import LoadingModule from '../../../../modules/LoadingModule/LoadingModule';
import SubscriptionRepository from '../../../../data/repositories/SubscriptionRepository';

class SelectSubcsriptionStore {
  private subscriptionRepo = new SubscriptionRepository();

  private loadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async editPreSelectedSubscription(
    organizationId: string,
    newTier: number,
  ) {
    this.loadingModule.startLoading();
    await this.subscriptionRepo.editPreSelectedSubscription(
      organizationId,
      newTier,
    );
    this.loadingModule.endLoading();
  }
}

export default SelectSubcsriptionStore;
