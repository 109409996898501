import React from 'react';
import {inject, observer} from 'mobx-react';
import {LessonContentFreeformTextChoice} from '../../../../../data/models/LessonContent/LessonContentBase';
import {lessonEditorText} from '../../../lessonEditorText';
import FreeformTextOptionsUI from '../view/FreeformTextOptionsUI';
import AddFreeformChoiceStore from '../../../../../modules/Editor/AddFreeformChoiceOption/store/AddFreeformChoiceStore';
import BaseOptionsContainer, {
  BaseContainerForwardProps,
  BaseContainerProps,
} from '../../BaseOptionsContainer';

interface Props extends BaseContainerForwardProps {
  entityToEdit?: LessonContentFreeformTextChoice;
  addFreeformChoiceStore?: AddFreeformChoiceStore;
}

const FreeformTextOptionsContainer = inject('addFreeformChoiceStore')(
  observer(
    ({
      entityToEdit,
      onDelete,
      onEdit,
      isLoading,
      isPublished,
      onChangesMade,
      addFreeformChoiceStore,
    }: Props) => {
      const onEditTextChoice = (isCorrect?: boolean | null) => {
        onEdit({
          ...entityToEdit,
          isCorrect,
        } as LessonContentFreeformTextChoice);
      };

      const openEditOverlay = () => {
        if (entityToEdit) {
          addFreeformChoiceStore!.showWizardForEdit(
            entityToEdit,
            onChangesMade,
          );
        }
      };

      return entityToEdit ? (
        <>
          <FreeformTextOptionsUI
            openEditOverlay={openEditOverlay}
            isLoading={isLoading}
            onDelete={onDelete}
            isPublished={isPublished}
            onSave={onEditTextChoice}
            choiceText={entityToEdit}
          />
        </>
      ) : null;
    },
  ),
);

export default React.memo((props: BaseContainerProps) => (
  <BaseOptionsContainer
    {...props}
    deleteMessage={lessonEditorText.areYouSureDeleteChoiceTextOption}>
    {forwardProps => (
      <FreeformTextOptionsContainer
        {...forwardProps}
        entityToEdit={
          forwardProps.entityToEdit as LessonContentFreeformTextChoice
        }
      />
    )}
  </BaseOptionsContainer>
));
