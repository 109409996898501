import * as React from 'react';
import LoginUI from '../view/LoginUI';
import {inject, observer} from 'mobx-react';
import LandingStore from '../store/LoginStore';
import {useNavigate} from 'react-router-dom';
import NavigationStore from '../../../../navigation/store/NavigationStore';

interface Props {
  loginStore?: LandingStore;
  navigationStore?: NavigationStore;
}

const LoginContainer = inject(
  'loginStore',
  'navigationStore',
)(
  observer(({loginStore, navigationStore}: Props) => {
    const navigate = useNavigate();

    React.useEffect(() => {
      const user = loginStore!.getUser();
      if (user) {
        if (user && user?.emailConfirmed) {
          navigate('/home', {replace: true});
        } else if (user && !user.emailConfirmed) {
          loginStore!.sendEmailVerification().then(() => {
            navigate('/auth/confirm_email');
          });
        }
      }
      return () => {
        loginStore!.clearErrors();
      };
    }, [loginStore]);

    const onLogin = async (email: string, password: string) => {
      const success = await loginStore?.loginUser(email, password);
      if (success && loginStore!.isUserEmailConfirmed()) {
        navigationStore?.tryGetMyOrganization().then(() => {
          navigate('/home', {replace: true});
        });
      } else if (success && !loginStore!.isUserEmailConfirmed()) {
        loginStore!.sendEmailVerification().then(() => {
          navigate('/auth/confirm_email');
        });
      }
    };

    const clearErrors = () => {
      loginStore!.clearErrors();
    };

    const onLoginWithGoogle = async (idToken: string) => {
      const success = await loginStore?.loginWithGoogle(idToken);
      if (success) {
        navigate('/home');
      }
    };

    return (
      <LoginUI
        onLogin={onLogin}
        errors={loginStore!.errors}
        isLoading={loginStore!.isLoading}
        clearErorrs={clearErrors}
        onLoginWithGoogle={onLoginWithGoogle}
      />
    );
  }),
);

export default LoginContainer;
