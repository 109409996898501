import React from 'react';
import {Outlet} from 'react-router-dom';
import {Col, Row} from 'react-bootstrap';
import './styles.css';
import NavBar from '../../../../uiToolkit/NavBar';
import {navConfig} from '../navConfig';
import NavigationTabs from '../../../../uiToolkit/NavigationTabs';
import UserProfilePageBannerImage from '../../../../assets/images/UserProfilePageBanner.png';
import {getFirstCapitals} from '../../../../utils/getFirstCapitals';
import {
  getLocalizationBasedOnLang,
  UserInfo,
} from '../../../../data/models/User/UserInfo';

interface Props {
  profileData?: UserInfo;
}

const UserProfileRootUI = React.memo(({profileData}: Props) => {
  return (
    <div>
      <NavBar />
      <Row className="UserProfileRowBackground justify-content-center">
        <Col lg={{span: 8}} xs={12}>
          <div className="UserProfileContainer">
            <div
              className="UserProfileBanner"
              style={{
                backgroundImage: `url(${UserProfilePageBannerImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}>
              <ul className="UserProfileBannerList">
                <li>
                  {profileData?.avatarUrl ? (
                    <img
                      src={profileData.avatarUrl}
                      alt="User Profile Picture"
                      className="Avatar"
                    />
                  ) : (
                    <span className="UserAvatarTextImage">
                      {getFirstCapitals(profileData?.name || '')}
                    </span>
                  )}
                </li>
                <li>
                  <p className="UserProfileContainerText">
                    {profileData?.name}&apos;s{' '}
                    {getLocalizationBasedOnLang('profile', 'proffil')}
                  </p>
                </li>
              </ul>
            </div>

            <NavigationTabs tabs={navConfig} />
            <Outlet />
          </div>
        </Col>
      </Row>
    </div>
  );
});

export default UserProfileRootUI;
