import React, {useEffect, useState} from 'react';
import {editorAddItemIc} from '../../assets/images';
import './styles.css';
import Tooltip from '../Tooltip';
import EditorTooltipContent from './EditorTooltipContent';

export interface TooltipAction {
  icon: string;
  action: () => void;
  tooltip?: string;
}

interface Props {
  actions: TooltipAction[];
  tooltipInfo?: string;
  style?: any;
}

const EditorTooltipButton = React.memo(
  ({actions, tooltipInfo, style}: Props) => {
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
      document.body.addEventListener('click', onDocumentClick);
      return () => {
        removeEventListener('click', onDocumentClick);
      };
    }, []);

    const onDocumentClick = () => {
      setShowTooltip(false);
    };

    const onToggleTooltip = (e: any) => {
      e.stopPropagation();
      setShowTooltip(!showTooltip);
    };

    const onAction = (action: () => void) => {
      setShowTooltip(false);
      action();
    };

    return (
      <div className="EditorTooltopButton" style={style}>
        {tooltipInfo && !showTooltip ? (
          <Tooltip tooltipText={tooltipInfo} greenTooltip>
            <button className="AddButton" onClick={onToggleTooltip}>
              <img src={editorAddItemIc} />
            </button>
          </Tooltip>
        ) : (
          <button className="AddButton" onClick={onToggleTooltip}>
            <img src={editorAddItemIc} />
          </button>
        )}

        {showTooltip && (
          <EditorTooltipContent actions={actions} onAction={onAction} />
        )}
      </div>
    );
  },
);

export default EditorTooltipButton;
