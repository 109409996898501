import {makeAutoObservable, runInAction} from 'mobx';
import {LANGUAGE_STORAGE_KEY, UserInfo} from '../data/models/User/UserInfo';

import UserRepository from '../data/repositories/UserRepository';

class UserInfoStore {
  private userRepo = new UserRepository();
  private fetching = false;

  private userInfoCache?: UserInfo;

  constructor() {
    makeAutoObservable(this);
  }

  public get userInfo() {
    return this.userInfoCache;
  }

  public async fetchUserInfo(force?: boolean) {
    if ((!this.userInfoCache || force) && !this.fetching) {
      runInAction(() => {
        this.fetching = true;
      });
      const result = await this.userRepo.getUserInfo();
      runInAction(() => {
        if (result.success && result.data) {
          this.userInfoCache = result.data;
          if (
            localStorage.getItem(LANGUAGE_STORAGE_KEY) !==
            result.data.preferredLanguage.toString()
          ) {
            localStorage.setItem(
              LANGUAGE_STORAGE_KEY,
              result.data.preferredLanguage.toString(),
            );
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }

          this.fetching = false;
        }
      });
    }
  }
}

export default UserInfoStore;
