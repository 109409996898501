import React, {useState} from 'react';
import NavBar from '../../../../uiToolkit/NavBar';
import {Col, Container, Row} from 'react-bootstrap';
import {marketplaceText} from '../marketplaceText';
import Button from '../../../../uiToolkit/Buttons/Button';
import {
  getPriceByTierAndAddon,
  marketplaceCheckOutItems,
} from '../marketplaceItems';
import {Link} from 'react-router-dom';
import NumbersInput from '../../../../uiToolkit/Inputs/NumbersInput';
import {
  getSubscriptionNameFromTier,
  OrganizationSubscription,
} from '../../../../data/models/Subscription/Subscription';
import {truncatePriceTo2Decimals} from '../../../../utils/price';

interface Props {
  onGoBack: () => void;
  productId: string;
  organizationId: string;
  subscription: OrganizationSubscription;
  onPurchase: (quantity: number) => void;
  isLoading: boolean;
  maxLeanresAvailable: number;
}

const MarketplaceCheckOutUI = React.memo(
  ({
    onGoBack,
    productId,
    organizationId,
    subscription,
    onPurchase,
    isLoading,
    maxLeanresAvailable,
  }: Props) => {
    const [selectedAmount, setSeletedAmount] = useState<number | undefined>(1);

    const currentConf = () => {
      return marketplaceCheckOutItems.find(x => x.addonId === productId);
    };

    const onNextStep = () => {
      onPurchase(selectedAmount || 1);
    };

    return (
      <div className="MarketplaceCheckOut">
        <NavBar />
        <Container>
          <Row>
            <Col xs={12}>
              <h2 className="Title">{marketplaceText.orderSummary}</h2>
              <Button
                uiType="text"
                onClick={onGoBack}
                title={marketplaceText.goBack}
                icon="icon-typeGo-Back"
                className="BackButton"
              />
              <p className="CurrentPlan">
                {marketplaceText.yourCurrentPlanIs}
                <b>{getSubscriptionNameFromTier(subscription.type)}</b>
              </p>
              {productId === 'addons_learner_or_instructor' && (
                <p className="MoreFeatures">
                  {marketplaceText.maxAmountAvailable}
                  <span
                    className={
                      maxLeanresAvailable === 0 ? 'NotAvailableMax' : ''
                    }>
                    {maxLeanresAvailable}
                  </span>
                </p>
              )}

              <p className="MoreFeatures">
                {marketplaceText.needMore}
                <Link
                  to={`/manage/${organizationId}/settings/subscription`}
                  target="_blank">
                  {marketplaceText.exploreMore}
                </Link>
              </p>
              <div className="CheckOutCard">
                <div>
                  <p className="SummarySectionTitle">
                    {marketplaceText.product}
                  </p>
                  <div className="d-flex flex-row align-items-center">
                    <img src={currentConf()?.image} className="ProductImage" />
                    <div>
                      <p className="ProductTitle">{currentConf()?.title}</p>
                      <p className="DescrptionText">
                        {marketplaceText.description}
                      </p>
                      <p className="ProductDescrption">
                        {currentConf()?.description}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <p className="SummarySectionTitle">
                    {marketplaceText.quantity}
                  </p>
                  <NumbersInput
                    value={selectedAmount}
                    setValue={setSeletedAmount}
                    className="QuantitySelector"
                    maxValue={
                      productId === 'addons_learner_or_instructor'
                        ? maxLeanresAvailable
                        : 999
                    }
                  />
                </div>
                <div className="d-flex flex-column align-items-center">
                  <p className="SummarySectionTitle">{marketplaceText.price}</p>
                  <p className="Price">
                    €
                    {truncatePriceTo2Decimals(
                      (getPriceByTierAndAddon(subscription.type, productId) ||
                        0) * (selectedAmount || 0),
                    )}
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-end mt-3">
                <Button
                  uiType="text"
                  title={marketplaceText.cancel}
                  onClick={onGoBack}
                />
                <Button
                  title={
                    productId === 'addons_learner_or_instructor' ||
                    productId === 'addons_course_author'
                      ? marketplaceText.continue
                      : marketplaceText.nextStep
                  }
                  onClick={onNextStep}
                  disabled={
                    !selectedAmount ||
                    (productId === 'addons_learner_or_instructor' &&
                      maxLeanresAvailable === 0)
                  }
                  isLoading={isLoading}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  },
);

export default MarketplaceCheckOutUI;
