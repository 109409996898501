import React from 'react';
import InputMask from 'react-input-mask';
import './styles.css';

const mask = [/[0-9]/, /[0-9]/, ':', /[0-5]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];

const milisecondsMask = [
  /[0-9]/,
  /[0-9]/,
  ':',
  /[0-5]/,
  /[0-9]/,
  ':',
  /[0-5]/,
  /[0-9]/,
  '.',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];

type TIME_INPUT_UI_TYPES = 'standart' | 'text';

interface Props {
  isValid: boolean;
  label?: string;
  value: string;
  onChange: (value: string) => void;
  labelTop?: boolean;
  className?: string;
  fullWidth?: boolean;
  fieldError?: boolean;
  uiType?: TIME_INPUT_UI_TYPES;
  subtitle?: string;
  isMandatory?: boolean;
  includeMiliseconds?: boolean;
  warningText?: string | null;
}

const TimeInput = React.memo(
  ({
    isValid,
    label,
    value,
    onChange,
    labelTop,
    className,
    fullWidth,
    fieldError,
    uiType,
    subtitle,
    isMandatory,
    includeMiliseconds,
    warningText,
  }: Props) => {
    const getRootClass = () => {
      if (!uiType) return 'TimeInputContainer';
      switch (uiType) {
        case 'text':
          return 'TimeInputTextContainer';
      }
    };

    return (
      <>
        <div
          className={`${getRootClass()} ${!isValid ? 'Invalid' : ''} ${
            className ? className : ''
          } ${fieldError ? 'TimeInputError' : ''}`}>
          {(labelTop || uiType === 'text') && label && (
            <p className="LabelTop">
              {label}{' '}
              {isMandatory ? <span className="MandatoryIndicator">*</span> : ''}
            </p>
          )}
          {subtitle && <p className="TimeInputSubtitle">{subtitle}</p>}
          <InputMask
            mask={includeMiliseconds ? milisecondsMask : mask}
            maskPlaceholder={includeMiliseconds ? '00:00:00.000' : '00:00:00'}
            onChange={e => onChange(e.target.value)}
            value={value}
            className={fullWidth ? 'TimeInputFullWidth' : ''}
          />
          {!labelTop && uiType !== 'text' && <p>{label}</p>}
        </div>
        <div className="TimeInputWarning">
          {warningText && <p>{warningText}</p>}
        </div>
      </>
    );
  },
);

export default TimeInput;
