import {ApiResponse} from '../models/ApiResponse';
import {LtiPlatform, LtiTool} from '../models/Lti/LtiIntegration';
import {apiClient, withAuth} from './baseApiClient';

class LtiApi {
  public async generateKeys(organizationId: string) {
    const headers = await withAuth({});
    return apiClient.post(`/lti/${organizationId}/create_keys`, {}, {headers});
  }

  public async getToolData(
    organizationId: string,
  ): Promise<ApiResponse<LtiTool>> {
    const headers = await withAuth({});
    return apiClient.get(`/lti/${organizationId}/tool_data`, {headers});
  }

  public async addPlatform(
    organizationId: string,
    platform: LtiPlatform,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.post(`/lti/${organizationId}/add_platform`, platform, {
      headers,
    });
  }

  public async getPlatforms(
    organizationId: string,
  ): Promise<ApiResponse<LtiPlatform[]>> {
    const headers = await withAuth({});
    return apiClient.get(`/lti/${organizationId}/get_platforms`, {headers});
  }

  public async deletePlatform(
    organizationId: string,
    platformId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.delete(`/lti/${organizationId}/${platformId}`, {headers});
  }

  public async editPlatform(
    organizationId: string,
    platformId: string,
    platform: LtiPlatform,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/lti/${organizationId}/update_platform/${platformId}`,
      platform,
      {headers},
    );
  }
}

export default LtiApi;
