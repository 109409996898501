import {makeAutoObservable} from 'mobx';
import OrganizationStaffRepository from '../../../data/repositories/OrganizationStaffRepository';
import OrganizationRepository from '../../../data/repositories/OrganizationRepository';
import {toastConfig} from '../../../uiToolkit/Toast/toastConfig';
import {settingsText} from '../settingsText';
import {toast} from 'react-toastify';
import LoadingModule from '../../../modules/LoadingModule/LoadingModule';

class OrganizationStaffStore {
  private organizationStaffRepository = new OrganizationStaffRepository();
  private organizationRepository = new OrganizationRepository();
  private loadingModule = new LoadingModule();

  public errors: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async getOrgStaffRecords(organizationId: string) {
    this.loadingModule.startLoading();
    const result =
      await this.organizationStaffRepository.getStaffMembers(organizationId);
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }

  public async deleteOrgMember(organizationId: string, memberId: string) {
    this.loadingModule.startLoading();
    const result = await this.organizationStaffRepository.deleteStaffMember(
      organizationId,
      memberId,
    );
    this.loadingModule.endLoading();
    if (result.success) {
      toast.success(settingsText.memberDeleted, toastConfig);
    } else {
      toast.error(settingsText.somethingWentWrong, toastConfig);
    }
    return result.success;
  }

  public async activateOrgMember(organizationId: string, memberId: string) {
    this.loadingModule.startLoading();
    const result = await this.organizationStaffRepository.activateStaffMember(
      organizationId,
      memberId,
    );
    this.loadingModule.endLoading();
    if (result.success) {
      toast.success(settingsText.memberActivated, toastConfig);
    } else {
      toast.error(settingsText.somethingWentWrong, toastConfig);
    }
    return result.success;
  }

  public async editStaffMemeber(
    organizationId: string,
    memberId: string,
    roleId: string,
  ) {
    this.loadingModule.startLoading();
    const result = await this.organizationStaffRepository.editStaffMember(
      organizationId,
      {memberId, roleId},
    );
    return result.success;
  }

  public async getStaffMember(organizationId: string, memberId: string) {
    this.loadingModule.startLoading();
    const result = await this.organizationStaffRepository.getStaffRecord(
      organizationId,
      memberId,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async inviteOrgStaffMember(
    organizationId: string,
    email: string,
    roleId: string,
  ) {
    this.clearErrors();
    this.loadingModule.startLoading();
    const result = await this.organizationStaffRepository.inviteStaffMember(
      organizationId,
      {email, roleId},
    );
    this.loadingModule.endLoading();
    if (!result.success && result.errors) {
      this.setErrors(result.errors);
    }
    return result.success;
  }

  public async deleteInvitation(organizationId: string, invitationId: string) {
    this.loadingModule.startLoading();
    const result = await this.organizationStaffRepository.deleteStaffInvitation(
      organizationId,
      invitationId,
    );
    this.loadingModule.endLoading();
    return result.success;
  }

  public async getOrganizationRoles(organizationId: string) {
    const result =
      await this.organizationRepository.getOrganizationRoles(organizationId);
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }

  public clearErrors() {
    this.errors = [];
  }

  private setErrors(errors: string[]) {
    this.errors = errors;
  }
}

export default OrganizationStaffStore;
