import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import AuthRepository from '../../../data/repositories/AuthRepository';
import Lottie from 'lottie-react';
import {loadingLottie} from '../../../assets/lottie';
import {appText} from '../../../assets/text/appText';
import './styles.css';
import {getErrorFromCode} from '../../../data/errorCodes';

const GoogleOAuth = React.memo(() => {
  const [repo] = useState(new AuthRepository());
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const url = window.location.hash;
    const searchParams = new URLSearchParams(url);
    repo
      .loginUserWithGoogle({
        accessToken: searchParams.get('access_token') || '',
      })
      .then(res => {
        setIsLoading(false);
        if (res.success) {
          navigate('/home');
        } else if (res.errors && res.errors.length) {
          setError(res.errors[0]);
        }
      });
  }, []);

  return (
    <div className="GoogleOauthContainer">
      {isLoading && (
        <>
          <Lottie
            animationData={loadingLottie}
            loop={true}
            style={{
              width: '44px',
              height: '44px',
            }}
          />
          <p>{appText.pleaseWait}</p>
        </>
      )}
      {error && (
        <>
          <p>{error ? getErrorFromCode(error) : ''}</p>
          <Link to="/auth/login">Go Back to Login</Link>
        </>
      )}
    </div>
  );
});

export default GoogleOAuth;
