import React from 'react';
import {Col} from 'react-bootstrap';
import {bookIc} from '../../../assets/images';
import {homeText} from '../homeText';

interface Props {
  organization: any;
}

const LearnerEmptyState = React.memo(({organization}: Props) => {
  return (
    <Col lg={{span: 8, offset: 2}} xs={12}>
      <div className="HomeEmptyStateContainer">
        <img src={bookIc} alt="book" />
        <p className="Title">
          {organization.isDisabled
            ? homeText.learnerDisbled
            : homeText.noLearnerCourses}
        </p>
      </div>
    </Col>
  );
});

export default LearnerEmptyState;
