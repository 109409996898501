import chroma from 'chroma-js';

export const generateColorPalette = (baseColor: string): string[] => {
  const scaleSize = 9;
  const baseColorObj = chroma(baseColor);
  const targetContrast = 4.5; // Nivel de contraste deseado
  const maxIterations = 10; // Máximo número de iteraciones para evitar bucle infinito

  const colorScale = Array.from({length: scaleSize}).map((_, index) => {
    let generatedColor = baseColorObj.darken(index / scaleSize).hex(); // Generar color basado en el índice
    let contrast = chroma.contrast(generatedColor, baseColor);
    let iterations = 0;

    // Ajustar el color si el contraste no es suficiente
    while (contrast < targetContrast && iterations < maxIterations) {
      generatedColor = chroma(generatedColor).darken(0.1).hex();
      contrast = chroma.contrast(generatedColor, baseColor);
      iterations++;
    }

    return generatedColor;
  });

  return [baseColor, ...colorScale];
};
