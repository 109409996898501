import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  title: 'Add an option for your choice branch',
  inputTitle: 'Choice option',
  placeholder: 'Option',
  cancel: 'Cancel',
  accept: 'Accept',
};

const welsh = {
  title: 'Ychwanegwch opsiwn ar gyfer eich dewis gangen',
  inputTitle: 'Opsiwn dewis',
  placeholder: 'Opsiwn',
  cancel: 'Canslo',
  accept: 'Derbyn',
};

export const addChoiceTextOptionWizardText: any = getLocalizationBasedOnLang(
  english,
  welsh,
);
