import React from 'react';
import CoursesListContainer from './List/container/CoursesListContainer';
import CreateCourseContainer from './Create/container/CreateCourseContainer';
import CoursePreviewContainer from './Preview/container/CoursePreviewContainer';
import {CourseAccessGate} from '../../modules/CourseAccessGate';

const CoursePreview = () => {
  return (
    <CourseAccessGate>
      <CoursePreviewContainer />
    </CourseAccessGate>
  );
};

export {
  CoursesListContainer as CoursesList,
  CreateCourseContainer as CreateCourse,
  CoursePreview,
};
