import React, {useState} from 'react';
import {courseGradebookText} from '../courseGradebookText';
import {
  GradebookGrades,
  GradebookLearner,
  GradebookLesson,
} from '../../../../data/models/Course/Gradebook';
import TablePagination from '../../../../uiToolkit/TablePagination';
import Tooltip from '../../../../uiToolkit/Tooltip';

interface Props {
  lessons: GradebookLesson[];
  learners: GradebookLearner[];
  grades: GradebookGrades;
  pagesCount: number;
  itemsPerPage: number;
  currentPage: number;
  onChangePage: (page: number) => void;
  onOpenLearningJourney: (
    learnerId: string,
    lessonId: string,
    courseId: string,
  ) => void;
}

const GradebookTable = React.memo(
  ({
    learners,
    lessons,
    grades,
    pagesCount,
    itemsPerPage,
    currentPage,
    onChangePage,
    onOpenLearningJourney,
  }: Props) => {
    const [selectedColumn, setSelectedColumn] = useState('');
    const [selectedRow, setSelectedRow] = useState('');

    const clearSelectedRowCol = () => {
      setSelectedColumn('');
      setSelectedRow('');
    };

    const onSelectColumn = (value: string) => (e: any) => {
      e.stopPropagation();
      setSelectedColumn(value);
    };

    const onSelectRow = (value: string) => (e: any) => {
      e.stopPropagation();
      setSelectedRow(value);
    };

    const getGradeByLearnerLesson = (lessonId: string, learnerId: string) => {
      if (grades[lessonId]) {
        const record = grades[lessonId].find(x => x.learnerId === learnerId);
        if (record && !record.isGradable) {
          if (record.passedAcknowledgement) {
            return courseGradebookText.accepted;
          } else if (record.isFinished) {
            return courseGradebookText.finished;
          } else {
            return 'N/A';
          }
        } else if (record) {
          if (record.isFinished && record.grade !== undefined) {
            return Math.round(record.grade);
          } else {
            return 'N/A';
          }
        }
      }
      return 'N/A';
    };

    return (
      <div className="GradebookTableContainer">
        <table className="GradebookTable" onClick={clearSelectedRowCol}>
          <thead>
            <tr className="GradebookHeader">
              <th className="LearnerHeader">{courseGradebookText.learner}</th>
              {lessons.map(lesson => (
                <th
                  key={lesson.lessonId}
                  className="LessonHeader"
                  onClick={onSelectColumn(lesson.lessonId)}>
                  <Tooltip tooltipText={lesson.folderName}>
                    <p>{lesson.folderName.toUpperCase()}</p>
                  </Tooltip>
                  <Tooltip tooltipText={lesson.title}>
                    <p>{lesson.title}</p>
                  </Tooltip>
                </th>
              ))}
              {/* <th className="FinalGradeHeader">{gradebookText.courseGrade}</th> */}
            </tr>
          </thead>
          <tbody>
            {learners.map((learner, index) => (
              <tr
                key={learner.learnerId}
                className={`${index % 2 !== 0 ? 'OddRow' : 'EvenRow'} ${
                  selectedRow === learner.learnerId ? 'SelectedRowCol' : ''
                }`}>
                <td
                  className={`LearnerName ${
                    index % 2 !== 0 ? 'OddRow' : 'EvenRow'
                  }`}
                  onClick={onSelectRow(learner.learnerId)}>
                  <Tooltip tooltipText={learner.name}>
                    <span>{learner.name}</span>
                  </Tooltip>
                </td>
                {lessons.map(lesson => (
                  <td
                    key={lesson.lessonId}
                    className={
                      selectedColumn === lesson.lessonId ? 'SelectedRowCol' : ''
                    }>
                    <button
                      onClick={() =>
                        onOpenLearningJourney(
                          learner.learnerId,
                          lesson.lessonId,
                          lesson.courseId,
                        )
                      }
                      className="GradeButton">
                      {getGradeByLearnerLesson(
                        lesson.lessonId,
                        learner.learnerId,
                      )}
                    </button>
                  </td>
                ))}
                {/* <td
                  className={`FinalGrade ${
                    index % 2 !== 0 ? 'OddRow' : 'EvenRow'
                  }`}>
                  FG
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="PagingContainer">
          <TablePagination
            rowsPerPage={itemsPerPage}
            rowCount={pagesCount * itemsPerPage}
            currentPage={currentPage}
            onChangePage={onChangePage}
          />
        </div>
      </div>
    );
  },
);

export default GradebookTable;
