import {makeAutoObservable} from 'mobx';
import LoadingModule from '../../../LoadingModule/LoadingModule';
import LessonContentRepository from '../../../../data/repositories/LessonContentRepository';
import {
  ACKNOWLEDGMENT_NODE,
  LessonContentAcknowledgment,
  LessonContentBase,
} from '../../../../data/models/LessonContent/LessonContentBase';
import {ApiResponse} from '../../../../data/models/ApiResponse';

class AddAcknowledgmentWizardStore {
  private lessonContentRepo = new LessonContentRepository();
  private showWizard = false;
  private parentId?: string = undefined;
  private childId?: string = undefined;
  private onFinishCallback?: () => void = undefined;
  private nodeToReplace?: LessonContentBase;

  private loadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get showShowWizard() {
    return this.showWizard;
  }

  public openWizard(
    parentId: string,
    onFinishCallback: () => void,
    nodeToReplace?: LessonContentBase,
    childId?: string,
  ) {
    this.showWizard = true;
    this.parentId = parentId;
    this.onFinishCallback = onFinishCallback;
    this.childId = childId;
    this.nodeToReplace = nodeToReplace;
  }

  public closeWizard() {
    this.showWizard = false;
  }

  public async createNode(
    organizationId: string,
    lessonId: string,
    title: string,
    buttonTitle: string,
  ) {
    if (this.parentId) {
      this.loadingModule.startLoading();
      const model = {
        data: {
          ivNodeType: ACKNOWLEDGMENT_NODE,
          title,
          buttonTitle,
        } as LessonContentAcknowledgment,
        parentsIds: [this.parentId],
      };
      if (this.nodeToReplace) {
        const result = await this.lessonContentRepo.editLessonContent(
          organizationId,
          lessonId,
          {...model.data, internalId: this.nodeToReplace.internalId},
        );
        this.processResult(result);
      } else {
        const result = await this.lessonContentRepo.addLessonContent(
          organizationId,
          lessonId,
          model,
          this.childId,
        );
        this.processResult(result);
      }
      this.loadingModule.endLoading();
    }
  }

  private processResult = (result: ApiResponse<any>) => {
    if (result.success && this.onFinishCallback) {
      this.onFinishCallback();
      this.closeWizard();
    }
  };
}

export default AddAcknowledgmentWizardStore;
