import React from 'react';
import './styles.css';
import {notificationsText} from '../notificationsText';
import {
  NOTIFICATION_COURSE_ENROLLMENT,
  NOTIFICATION_COURSE_INSTRUCTION,
  NOTIFICATION_LEARNER_INVITATION,
  NOTIFICATION_LESSON_GENERATED,
  NOTIFICATION_STAFF_INVITATION,
  NOTIFICATION_VIDEO_GENERATED,
} from '../../../../data/models/Notifications/Invitation';

interface Props {
  title: string;
  dateSubtitle: string;
  icon?: string;
  onDeny: () => void;
  type: number;
  onSeenNotification: () => void;
  onViewCourse: () => void;
  onViewWorkspace: () => void;
  onViewCoursePreview: () => void;
  seen?: boolean;
  description?: string;
}

const InvitationNotificationUserProfile = React.memo(
  ({
    title,
    dateSubtitle,
    icon,
    onDeny,
    type,
    onSeenNotification,
    onViewCourse,
    onViewWorkspace,
    seen,
    onViewCoursePreview,
    description,
  }: Props) => {
    return (
      <div
        className={`InvitationNotificationsMenuPopUpContainer ${
          !seen ? 'InvitationNotificationsMenuPopUpContainerNotSeen' : ''
        }`}>
        <div className="flexDisplay">
          <div>
            <button
              className="ProfilePicture"
              style={{backgroundImage: `url(${icon})`}}
            />
          </div>

          <div className="TextContainer">
            {title && <p>{title}</p>}
            {description && <p>{description}</p>}
            <p className="SubtitleText">{dateSubtitle}</p>
          </div>
        </div>
        {!seen && (
          <div>
            {type === NOTIFICATION_STAFF_INVITATION && (
              <div className="ButtonContainer">
                <button className="AcceptButton" onClick={onViewWorkspace}>
                  {notificationsText.viewWorkspace}
                </button>
                <button onClick={onDeny}>{notificationsText.deny}</button>
              </div>
            )}
            {type === NOTIFICATION_LEARNER_INVITATION && (
              <div className="ButtonContainer">
                <button onClick={onSeenNotification}>
                  {notificationsText.markAsRead}
                </button>
              </div>
            )}
            {type === NOTIFICATION_COURSE_ENROLLMENT && (
              <div className="ButtonContainer">
                <button className="AcceptButton" onClick={onViewCourse}>
                  {notificationsText.viewCourse}
                </button>
                <button onClick={onSeenNotification}>
                  {notificationsText.markAsRead}
                </button>
              </div>
            )}
            {type === NOTIFICATION_COURSE_INSTRUCTION && (
              <div className="ButtonContainer">
                <button className="AcceptButton" onClick={onViewCoursePreview}>
                  {notificationsText.viewCourse}
                </button>
                <button onClick={onSeenNotification}>
                  {notificationsText.markAsRead}
                </button>
              </div>
            )}
            {(type === NOTIFICATION_LESSON_GENERATED ||
              type === NOTIFICATION_VIDEO_GENERATED) && (
              <div className="ButtonContainer">
                <button onClick={onSeenNotification}>
                  {notificationsText.markAsRead}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  },
);

export default InvitationNotificationUserProfile;
