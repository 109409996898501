import SubscriptionApi from '../api/SubscriptionApi';

class SubscriptionRepository {
  private subscriptionApi = new SubscriptionApi();

  public getSubscriptionPermissions(organizationId: string) {
    return this.subscriptionApi.getSubscriptionPermissions(organizationId);
  }

  public getSubscribeSession(organizationId: string, tier: number) {
    return this.subscriptionApi.getSubscribeSession(organizationId, tier);
  }

  public async purchaseAddons(addons: any, organizationId: string) {
    return this.subscriptionApi.purchaseAddons(addons, organizationId);
  }

  public async getRenewSession(organizationId: string) {
    return this.subscriptionApi.getRenewSession(organizationId);
  }

  public editPreSelectedSubscription(organizationId: string, newTier: number) {
    return this.subscriptionApi.editPreSelectedSubscription(
      organizationId,
      newTier,
    );
  }

  public upgradeSubscription(
    organizationId: string,
    tier: number,
    addons?: any,
  ) {
    return this.subscriptionApi.upgradeSubscription(
      organizationId,
      tier,
      addons,
    );
  }

  public getOrganizationHasSubscription(organizationId: string) {
    return this.subscriptionApi.getOrganizationHasSubscription(organizationId);
  }

  public async getOrganizationSubscription(organizationId: string) {
    return this.subscriptionApi.getOrganizationSubscription(organizationId);
  }

  public async getPaymentMethodSession(organizationId: string) {
    return this.subscriptionApi.getPaymentMethodSession(organizationId);
  }

  public async subscribeForTrial(organizationId: string, tier: number) {
    return this.subscriptionApi.subscribeForTrial(organizationId, tier);
  }

  public cancelSubscription(organizationId: string) {
    return this.subscriptionApi.cancelSubscription(organizationId);
  }

  public getInvoicesList(organizationId: string) {
    return this.subscriptionApi.getInvoicesList(organizationId);
  }
}

export default SubscriptionRepository;
