import React, {useEffect, useState} from 'react';
import ModalContainer from '../../../../uiToolkit/ModalContainer';
import {settingsText} from '../../settingsText';
import {Col, Row} from 'react-bootstrap';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';
import Dropdown from '../../../../uiToolkit/Inputs/Dropdown';
import {
  LMSPlatforms,
  LtiPlatform,
} from '../../../../data/models/Lti/LtiIntegration';
import Button from '../../../../uiToolkit/Buttons/Button';

interface Props {
  onClose: () => void;
  onCreatePlatform?: (platform: LtiPlatform) => void;
  platformToEdit?: LtiPlatform;
  onEditPlatform?: (platform: LtiPlatform) => void;
}

const AddPlatformModal = React.memo(
  ({onClose, onCreatePlatform, platformToEdit, onEditPlatform}: Props) => {
    const [name, setName] = useState('');
    const [platform, setPlatform] = useState('0');
    const [accessTokenUrl, setAccessTokenUrl] = useState('');
    const [authorizeUrl, setAuthorizeUrl] = useState('');
    const [issuer, setIssuer] = useState('');
    const [jwkSetUrl, setJwkSetUrl] = useState('');
    const [clientId, setClientId] = useState('');

    useEffect(() => {
      if (platformToEdit && onEditPlatform) {
        setName(platformToEdit.name);
        setPlatform(platformToEdit.type.toString());
        setAccessTokenUrl(platformToEdit.accessTokenUrl);
        setAuthorizeUrl(platformToEdit.authorizeUrl);
        setIssuer(platformToEdit.issuer);
        setJwkSetUrl(platformToEdit.jwkSetUrl);
        setClientId(platformToEdit.clientId);
      }
    }, [platformToEdit, onEditPlatform]);

    const onSave = () => {
      if (isValid() && onCreatePlatform) {
        onCreatePlatform({
          name,
          accessTokenUrl,
          authorizeUrl,
          issuer,
          clientId,
          jwkSetUrl,
          type: parseInt(platform, 10),
        });
      } else if (isValid() && onEditPlatform && platformToEdit) {
        onEditPlatform({
          ...platformToEdit,
          name,
          accessTokenUrl,
          authorizeUrl,
          issuer,
          clientId,
          jwkSetUrl,
          type: parseInt(platform, 10),
        });
      }
    };

    const isValid = () => {
      return (
        name.trim() !== '' &&
        accessTokenUrl.trim() !== '' &&
        authorizeUrl.trim() !== '' &&
        issuer.trim() !== '' &&
        jwkSetUrl.trim() !== '' &&
        clientId.trim() !== ''
      );
    };

    return (
      <ModalContainer onClose={onClose}>
        <div className="AddPlatformModal">
          <h3 className="Title">{settingsText.newLtiIntegration}</h3>
          <Row>
            <Col xs={6}>
              <TextInput
                heading={settingsText.ltiName}
                placeholder={settingsText.ltiNamePlaceholder}
                value={name}
                onChange={setName}
                className="LTIFormInput"
                isMandatory
              />
              <TextInput
                heading={settingsText.accessTokenUrl}
                placeholder={settingsText.accessTokenPlaceholder}
                value={accessTokenUrl}
                onChange={setAccessTokenUrl}
                className="LTIFormInput"
                isMandatory
              />
              <TextInput
                heading={settingsText.issuer}
                placeholder={settingsText.issuerPlaceholder}
                value={issuer}
                onChange={setIssuer}
                className="LTIFormInput"
                isMandatory
              />
              <TextInput
                heading={settingsText.clientId}
                placeholder={settingsText.clientIdPlaceholder}
                value={clientId}
                onChange={setClientId}
                className="LTIFormInput"
                isMandatory
              />
            </Col>
            <Col xs={6}>
              <Dropdown
                heading={settingsText.platformType}
                placeholder={settingsText.platformTypePlaceholder}
                options={LMSPlatforms}
                setSelectedOption={setPlatform}
                selectedOption={platform}
                className="LTIFormInput"
                isMandatory
              />
              <TextInput
                heading={settingsText.authorizeUrl}
                placeholder={settingsText.authorizeUrlPlaceholder}
                value={authorizeUrl}
                onChange={setAuthorizeUrl}
                className="LTIFormInput"
                isMandatory
              />
              <TextInput
                heading={settingsText.jwkSetUrl}
                placeholder={settingsText.jwkSetUrlPlaceholder}
                value={jwkSetUrl}
                onChange={setJwkSetUrl}
                className="LTIFormInput"
                isMandatory
              />
            </Col>
          </Row>
          <div className="Footer">
            <Button
              title={settingsText.cancel}
              onClick={onClose}
              uiType="text"
            />
            <Button
              title={settingsText.save}
              onClick={onSave}
              disabled={!isValid()}
            />
          </div>
        </div>
      </ModalContainer>
    );
  },
);

export default AddPlatformModal;
