import {ApiResponse} from '../models/ApiResponse';
import {LearnerInvitationResult} from '../models/Learners/LearnerInvitation';
import {CreateOrganizationModel} from '../models/Oganization/CreateOrganizationModel';
import {
  CreateRoleModel,
  EditRoleModel,
} from '../models/Oganization/CreateRoleModel';
import {
  Organization,
  OrganizationSettings,
  UserOrganizationDetails,
} from '../models/Oganization/Organization';
import {Role} from '../models/Oganization/Role';
import {DashboardData} from '../models/Workspace/Dashboard';
import {apiClient, withAuth} from './baseApiClient';

class OrganizationApi {
  public async getUserOrganizationPermissions(): Promise<
    ApiResponse<UserOrganizationDetails>
  > {
    const headers = await withAuth({});
    return apiClient.get('/organization/my_organization_details', {headers});
  }

  public async getUserOrganization(): Promise<ApiResponse<Organization>> {
    return apiClient.get('/organization/my_organization');
  }

  public async createOrganization(
    model: CreateOrganizationModel,
  ): Promise<ApiResponse<Organization>> {
    const headers = await withAuth({});
    return apiClient.post('/organization', model, {headers});
  }

  public async uploadOrganizationAvatar(image: File, organizationId: string) {
    const headers = await withAuth({});
    const form = new FormData();
    form.append('image', image);
    return apiClient.post(`/organization/${organizationId}/avatar`, form, {
      headers,
    });
  }

  public async getOrganizationDetails(
    organizationId: string,
  ): Promise<ApiResponse<Organization>> {
    const headers = await withAuth({});
    return apiClient.get(`/organization/${organizationId}/details`, {headers});
  }

  public async editOrganization(
    organization: OrganizationSettings,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.put(
      `organization/${organization.id}/edit_details`,
      organization,
      {headers},
    );
  }

  public async getOrganizationRoles(
    organizationId: string,
  ): Promise<ApiResponse<Role[]>> {
    const headers = await withAuth({});
    return apiClient.get(`/organizationPermissions/${organizationId}/roles`, {
      headers,
    });
  }

  public async createRole(model: CreateRoleModel): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/organizationPermissions/${model.organizationId}/roles/create`,
      model,
      {headers},
    );
  }

  public async getRoleById(
    id: string,
    organizationId: string,
  ): Promise<ApiResponse<Role>> {
    const headers = await withAuth({});
    return apiClient.get(
      `/organizationPermissions/${organizationId}/roles/${id}`,
      {headers},
    );
  }

  public async editRole(model: EditRoleModel): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.put(
      `/organizationPermissions/${model.organizationId}/roles/edit`,
      model,
      {headers},
    );
  }

  public async deleteRole(
    organizationId: string,
    roleId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.delete(
      `/organizationPermissions/${organizationId}/roles/${roleId}`,
      {headers},
    );
  }

  public async addLearnersToOrganization(
    organizationId: string,
    emails: string[],
  ): Promise<ApiResponse<{result: LearnerInvitationResult}>> {
    const headers = await withAuth({});
    return apiClient.post(`/OrganizationInvitation/${organizationId}`, emails, {
      headers,
    });
  }

  public async getOrganizationUsersCount(
    organizationId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.get(`/organization/${organizationId}/users/count`, {
      headers,
    });
  }

  public async addVimeoSecret(
    organizationId: string,
    secret: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/organizationSettings/${organizationId}/vimeo/accessToken`,
      {accessToken: secret},
      {headers},
    );
  }

  public getHaveVimeoIntegration(
    organizationId: string,
  ): Promise<ApiResponse<any>> {
    return apiClient.get(
      `/organizationSettings/${organizationId}/vimeo/vimeoConfigured`,
    );
  }

  public async getOrganizationSettings(
    organizationId: string,
  ): Promise<ApiResponse<OrganizationSettings>> {
    return apiClient.get(`/organization/${organizationId}/settings`);
  }

  public async getOrganizationDashboardDetails(
    organizationId: string,
  ): Promise<ApiResponse<DashboardData>> {
    const headers = await withAuth({});
    return apiClient.get(`/Organization/${organizationId}/dashboard`, {
      headers,
    });
  }

  public async getOrgUserRole(
    organizationId: string,
  ): Promise<ApiResponse<any>> {
    return apiClient.get(`organizationPermissions/${organizationId}/user_role`);
  }

  public async removeVimeoIntegration(
    organizationId: string,
  ): Promise<ApiResponse<any>> {
    return apiClient.delete(`organizationSettings/${organizationId}/vimeo`);
  }

  public async checkCourseEditAccess(
    courseId: string,
  ): Promise<ApiResponse<any>> {
    return apiClient.get(`course/${courseId}/check_course_edit_access`);
  }
}

export default OrganizationApi;
