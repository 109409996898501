import React, {useEffect, useState} from 'react';
import GeneralSettingsUI from '../view/GeneralSettingsUI';
import {inject, observer} from 'mobx-react';
import {OrganizationSettings} from '../../../../data/models/Oganization/Organization';
import SettingsStore from '../../store/SettingsStore';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {settingsText} from '../../settingsText';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import NavigationStore from '../../../../navigation/store/NavigationStore';
import {
  organizationHasFeature,
  WHITE_LABEL_CUSTOMIZATION,
} from '../../../../data/models/Subscription/SubscriptionPermission';

interface Props {
  settingsStore?: SettingsStore;
  navigationStore?: NavigationStore;
}

const GeneralSettingsContainer = inject(
  'settingsStore',
  'navigationStore',
)(
  observer(({settingsStore, navigationStore}: Props) => {
    const [organization, setOrganization] = useState<OrganizationSettings>();
    const {organizationId} = useParams();

    useEffect(() => {
      if (organizationId) {
        settingsStore!
          .getOrganizationDetails(organizationId)
          .then(setOrganization);
        navigationStore!.tryGetMyOrganization();
      }
      return () => {
        settingsStore!.clearGeneralErrors();
      };
    }, []);

    const onEdit = async (organization: OrganizationSettings, image?: File) => {
      const success = await settingsStore!.editOrganization(
        organization,
        image,
      );
      if (success) {
        toast.success(settingsText.detailsUpdated, toastConfig);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    };

    const onCancel = () => {
      setOrganization(undefined);
      if (organizationId) {
        settingsStore!
          .getOrganizationDetails(organizationId)
          .then(setOrganization);
      }
    };

    const clearErrors = () => {
      settingsStore!.clearGeneralErrors();
    };

    return organization ? (
      <GeneralSettingsUI
        onEdit={onEdit}
        clearErrors={clearErrors}
        organization={organization}
        isLoading={settingsStore!.isLoading}
        errors={settingsStore!.generalSettingsErrors}
        onCancel={onCancel}
        showColors={organizationHasFeature(
          navigationStore!.subscriptionPermissions || {},
          WHITE_LABEL_CUSTOMIZATION,
        )}
      />
    ) : null;
    // (
    //   <Skeleton count={5} height={80} style={{marginTop: '20px'}} />
    // );
  }),
);

export default GeneralSettingsContainer;
