import {makeAutoObservable, runInAction} from 'mobx';
import CoursesListRepository from '../../../data/repositories/CoursesRepository';
import {PublicCourseModel} from '../../../data/models/Course/CourseList';

export const EXPLORE_PAGE_SIZE = 12;

class ExploreStore {
  private coursesListRepository = new CoursesListRepository();

  private publicCoursesList: PublicCourseModel[] = [];
  private _currentPage = 0;
  private _pagesCount = 0;

  constructor() {
    makeAutoObservable(this);
  }

  public get publicCourses() {
    return this.publicCoursesList;
  }

  public get currentPage() {
    return this._currentPage;
  }

  public get pagesCount() {
    return this._pagesCount;
  }

  public setCurrentPage(page: number) {
    this._currentPage = page;
  }

  public async getPublicCourses(subjectId?: string) {
    const result = await this.coursesListRepository.getPublicCourseList(
      this._currentPage,
      EXPLORE_PAGE_SIZE,
      subjectId,
    );
    runInAction(() => {
      if (result.success && result.data) {
        this.publicCoursesList = result.data.data;
        this._pagesCount = result.data.pagesCount;
      }
    });
    return [];
  }

  public async getSubjects() {
    const result = await this.coursesListRepository.getSubjects();
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }
}

export default ExploreStore;
