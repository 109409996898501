import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  pickVideoTitle: 'Library',
  allVideos: 'All Videos',
  vimeoVideos: 'Vimeo Videos',
  cropTitle: 'Add a start and end for video',
  startTime: 'Start',
  endTime: 'End',
  useFullVideo: 'Use the full video instead',
  cancel: 'Cancel',
  save: 'Save',
  videoUploaded: 'File Uploaded Successfully',
  fileNotReady: 'File is not ready to be used',
};

const welsh = {
  pickVideoTitle: 'Llyfrgell',
  allVideos: 'Pob Fideo',
  vimeoVideos: 'Fideos Vimeo',
  cropTitle: 'Ychwanegwch dechrau a diwedd ar gyfer fideo',
  startTime: 'Dechrau',
  endTime: 'Diwedd',
  useFullVideo: 'Defnyddiwch y fideo llawn yn lle hynny',
  cancel: 'Canslo',
  save: 'Cadw',
  videoUploaded: "Ffeil wedi'i huwchlwytho'n llwyddiannus",
  fileNotReady: "Nid yw'r ffeil yn barod i'w defnyddio",
};

export const addVideoSourceWizardText: any = getLocalizationBasedOnLang(
  english,
  welsh,
);
