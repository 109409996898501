import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  title: 'Edit video source',
  back: 'Back',
  publishVideo: 'Save Video',
  previewVideo: 'Preview',
  timeline: 'Video Timeline',
  publishSuccess:
    'Publishing successful. We will notify you when your video is ready',
  noChunks:
    'Oops! Videos generated before v1.1.2 cannot be edited. Please create a new video to use the editing features.',
  tip: "To hide a specific clip from your final version, click the eye icon. This won't delete the clip, and you can re-enable it anytime to export a different version.",
  areYouSure:
    'Are you sure you want to apply your changes to the video? This will overwrite your previous video file',
};

const welsh = {
  title: 'Golygu ffynhonnell y fideo',
  back: 'Yn ôl',
  publishVideo: 'Cadw y Fideo',
  previewVideo: 'Gweddol',
  timeline: 'Llinell Amser y Fideo',
  publishSuccess:
    "Cyhoeddi'n llwyddiannus. Byddwn yn eich hysbysu pan fydd eich fideo'n barod.",
  noChunks:
    "Wps! Ni ellir golygu fideos a gynhyrchwyd cyn v1.1.2. Cewch greu fideo newydd i ddefnyddio'r nodweddion golygu",
  tip: "I guddio clip penodol o'ch fersiwn derfynol, cliciwch yr eicon llygad. Ni fydd hyn yn dileu'r clip, a gallwch ei ailddechrau unrhyw bryd i allforio fersiwn wahanol.",
  areYouSure:
    "Ydych chi'n siŵr eich bod eisiau cymhwyso'ch newidiadau i'r fideo? Bydd hyn yn disodli'ch ffeil fideo flaenorol.",
};

export const editVideoSourceText: any = getLocalizationBasedOnLang(
  english,
  welsh,
);
