import React, {useEffect, useState} from 'react';
import {lessonEditorText} from '../../../lessonEditorText';
import {
  LessonContentImageSource,
  MAX_IMAGE_DURATION_SECONDS,
} from '../../../../../data/models/LessonContent/LessonContentBase';
import {MediaFile} from '../../../../../data/models/LibraryFile/MediaFile';
import BoxImagePreview from '../../../../../uiToolkit/BoxImagePreview';
import EditorOptionsFooter from '../../../../../uiToolkit/Editor/EditorOptionsFooter';
import {
  getSecondsFromTimeText,
  getTimeTextFromSeconds,
} from '../../../../../utils/getTimeTextFromSeconds';
import TextInput from '../../../../../uiToolkit/Inputs/TextInput';
import TimeInput from '../../../../../uiToolkit/Inputs/TimeInput/indes';
import ReplaceNode from '../../ReplaceNode';

interface Props {
  entityToEdit: LessonContentImageSource;
  newImage?: MediaFile;
  openImagePicker: () => void;
  onSave: (duration: number, title: string) => void;
  isLoading: boolean;
  onDelete: () => void;
  isPublished?: boolean;
  onSoftDelete?: () => void;
}

const ImageSourceOptionsUI = React.memo(
  ({
    entityToEdit,
    openImagePicker,
    newImage,
    onSave,
    isLoading,
    onDelete,
    isPublished,
    onSoftDelete,
  }: Props) => {
    const [title, setTitle] = useState('');
    const [duration, setDuration] = useState('00:00:01');

    useEffect(() => {
      setTitle(entityToEdit?.title || '');
      setDuration(
        entityToEdit
          ? getTimeTextFromSeconds(entityToEdit.durationInSeconds)
          : '00:00:01',
      );
    }, [entityToEdit]);

    const durationIsValid = () => {
      return (
        duration !== '00:00:00' &&
        getSecondsFromTimeText(duration) <= MAX_IMAGE_DURATION_SECONDS
      );
    };

    const saveChanges = () => {
      onSave(getSecondsFromTimeText(duration), title);
    };

    return (
      <div>
        <BoxImagePreview
          heading={lessonEditorText.source}
          source={newImage ? newImage.fileUrl : entityToEdit.contentUrl}
          actionTitle={lessonEditorText.editSource}
          action={openImagePicker}
        />
        <TextInput
          heading={lessonEditorText.title}
          value={title}
          onChange={setTitle}
          placeholder={lessonEditorText.title}
          uiType="box"
          className="mt-4"
          fieldError={title.trim() === ''}
          maxCharacters={100}
        />
        <TimeInput
          label="Duration"
          labelTop
          value={duration}
          onChange={setDuration}
          isValid
          className="mt-1 align-items-start"
          fullWidth
          fieldError={!durationIsValid()}
        />
        <span className="ImageMaxDuration">
          {lessonEditorText.maxImageDuration}
        </span>
        <ReplaceNode currentNode={entityToEdit} />
        <EditorOptionsFooter
          onSave={saveChanges}
          saveEnabled={title.trim() !== '' || !durationIsValid()}
          onDelete={onDelete}
          isLoading={isLoading}
          deleteDisabled={isPublished}
          onSoftDelete={onSoftDelete}
        />
      </div>
    );
  },
);

export default ImageSourceOptionsUI;
