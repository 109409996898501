import React, {useEffect, useState} from 'react';
import {
  LessonContentOverlayVideo,
  LessonContentVimeoOverlayVideo,
} from '../../../../../data/models/LessonContent/LessonContentBase';
import {lessonEditorText} from '../../../lessonEditorText';
import TextInput from '../../../../../uiToolkit/Inputs/TextInput';
import BoxVideoInput from '../../../../../uiToolkit/Inputs/BoxVideoInput';
import Button from '../../../../../uiToolkit/Buttons/Button';
import './styles.css';
import EditorOptionsFooter from '../../../../../uiToolkit/Editor/EditorOptionsFooter';
import ReplaceNode from '../../ReplaceNode';

interface Props {
  videoContent: LessonContentOverlayVideo | LessonContentVimeoOverlayVideo;
  onDelete: () => void;
  onSoftDelete?: () => void;
  onEdit: () => void;
  onPickNewFile: () => void;
  onSave: (title: string) => void;
  isLoading: boolean;
  isPublished?: boolean;
}

const VideoOverlayOptionsUI = React.memo(
  ({
    videoContent,
    isLoading,
    onDelete,
    onPickNewFile,
    isPublished,
    onSave,
    onEdit,
    onSoftDelete,
  }: Props) => {
    const [title, setTitle] = useState(videoContent.title);

    useEffect(() => {
      setTitle(videoContent.title);
    }, [videoContent]);

    const onSaveTitle = () => {
      if (title.trim() !== '') {
        onSave(title);
      }
    };

    const changesWasMade = () => {
      return videoContent.title !== title.trim();
    };

    return (
      <div className="VideoSourceOptions">
        <TextInput
          heading={lessonEditorText.title}
          value={title}
          onChange={setTitle}
          placeholder={lessonEditorText.title}
          fieldError={title.trim() === ''}
          isMandatory
          uiType="box"
          className="Section"
        />
        {videoContent?.contentUrl ? (
          <BoxVideoInput
            heading={lessonEditorText.source}
            source={videoContent.contentUrl}
            startTime={videoContent.startInSeconds}
            endTime={videoContent.endInSeconds}
          />
        ) : null}
        <Button
          title={lessonEditorText.editSourceWithOverlay}
          onClick={onPickNewFile}
          uiType="box"
          className="OptionsButton"
        />
        <Button
          title={lessonEditorText.editOverlay}
          onClick={onEdit}
          uiType="box"
          className="OptionsButton"
        />
        <ReplaceNode currentNode={videoContent} />
        <EditorOptionsFooter
          saveEnabled={changesWasMade()}
          onSave={onSaveTitle}
          onDelete={onDelete}
          isLoading={isLoading}
          deleteDisabled={isPublished}
          onSoftDelete={onSoftDelete}
        />
      </div>
    );
  },
);

export default VideoOverlayOptionsUI;
