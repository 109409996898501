import React, {useState} from 'react';
import ErrorBox from '../../../../uiToolkit/ErrorBox';
import {internalErrorCodes} from '../../../../data/errorCodes';
import AuthHeader from '../../../../uiToolkit/AuthHeader';
import {forgotPasswordText} from '../forgotPasswordText';
import './styles.css';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';
import Button from '../../../../uiToolkit/Buttons/Button';

interface Props {
  errors: string[];
  onSendForgotPassword: (email: string) => void;
  isLoading: boolean;
  sent: boolean;
  onBack: () => void;
  clearErorrs: () => void;
}

const SendForgotPasswordUI = React.memo(
  ({
    errors,
    onSendForgotPassword,
    isLoading,
    sent,
    onBack,
    clearErorrs,
  }: Props) => {
    const [email, setEmail] = useState('');
    const [internalErrors, setInternalErrors] = useState<string[]>([]);

    const onClearErrors = () => {
      setInternalErrors([]);
      clearErorrs();
    };

    const onSubmit = () => {
      if (emailFormatIsValid(email)) {
        onSendForgotPassword(email);
      } else {
        setInternalErrors([internalErrorCodes.INVALID_EMAIL]);
      }
    };

    const emailFormatIsValid = (emailValue: string) => {
      return emailValue.match(
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
    };

    return (
      <>
        <div className="ForgotPasswordErrorContainer">
          <ErrorBox
            errorCodes={[...errors, ...internalErrors]}
            clearErrors={onClearErrors}
          />
        </div>
        <div className="ForgotPasswordFormContainer">
          <button className="ForgotPasswordBackButton" onClick={onBack}>
            <span className="icon-typeArrow-down"></span>
            {forgotPasswordText.goBack}
          </button>
          <AuthHeader
            title={forgotPasswordText.title}
            subtitle={forgotPasswordText.subtitle}
          />
          {sent ? (
            <div>
              <h3 className="ForgotPasswordEmailSent">
                {forgotPasswordText.emailSent}
              </h3>
            </div>
          ) : (
            <>
              <div className="ForgotPasswordInputContainer">
                <TextInput
                  type="email"
                  value={email}
                  onChange={setEmail}
                  placeholder={forgotPasswordText.email}
                  uiType="pill"
                />
              </div>
              <div className="ForgotPasswordSubmitButtonContainer">
                <Button
                  title={forgotPasswordText.submit}
                  onClick={onSubmit}
                  disabled={email.trim() === ''}
                  isLoading={isLoading}
                  uiType="auth_action"
                  fullWidth
                />
              </div>
            </>
          )}
        </div>
      </>
    );
  },
);

export default SendForgotPasswordUI;
