import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  previewMode: 'You are in preview mode',
  course: 'Course Name: ',
};

const welsh = {
  previewMode: 'Rydych chi mewn modd rhagolwg',
  course: "Enw 'r Cwrs",
};

export const videoPlaybackText = getLocalizationBasedOnLang(english, welsh);
