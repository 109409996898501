import {makeAutoObservable} from 'mobx';
import CoursesRepository from '../../../../data/repositories/CoursesRepository';
import AuthRepository from '../../../../data/repositories/AuthRepository';

class CourseEditRootStore {
  private courseRepo = new CoursesRepository();
  private authRepo = new AuthRepository();

  constructor() {
    makeAutoObservable(this);
  }

  public async getPublishedCourse(organizaitonId: string, courseId: string) {
    const result = await this.courseRepo.getPublishedCourseVersion(
      organizaitonId,
      courseId,
    );
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async getPermissions(organizationId: string): Promise<string[]> {
    const result = await this.authRepo.getUserOrgPermissions(organizationId);
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }
}

export default CourseEditRootStore;
