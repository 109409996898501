import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  title: 'Image Source',
  titleInputHeading: 'Title',
  titleInputPlaceholder: 'Image Title',
  durationInputHeading: 'Duration',
  save: 'Save',
  cancel: 'Cancel',
  maxDuration: '*Maximum length: 3 minutes.',
};

const welsh = {
  title: 'Ffynhonnell Delwedd',
  titleInputHeading: 'Teitl',
  titleInputPlaceholder: 'Teitl Delwedd',
  durationInputHeading: 'Hyd',
  save: 'Cadw',
  cancel: 'Canslo',
  maxDuration: '* Hyd mwyaf: tri munud.',
};

export const addImageNodeWizardText: any = getLocalizationBasedOnLang(
  english,
  welsh,
);
