import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const enlish = {
  nameTitle: 'Profile Name',
  namePlaceholder: 'Please Enter Your Full Name',
  imageTitle: 'Profile Picture',
  imageSubtitle: 'Please Upload Your Profile Picture',
  emailTitle: 'Email',
  emailPlaceholder: 'Please Enter Your Email',
  nameUpdateFailed: 'Failed to Update Name',
  profilePictureUpdateFailed: 'Failed to Update Profile Picture',
  profileDataUpdateSuccessful: 'Successfully Updated Profile Data',
  profileSettingsTitle: 'Profile info',
  profileSettingsSubtitle:
    'Update your name and profile photo here to keep your account information current.',
  saveChanges: 'Save Changes',
  language: 'language',
};

const welsh = {
  nameTitle: 'Enw Proffil',
  namePlaceholder: 'Rhowch Eich Enw Llawn',
  imageTitle: 'Llun Proffil',
  imageSubtitle: 'Rhowch Eich Llun Proffil',
  emailTitle: 'e-bost',
  emailPlaceholder: 'Rhowch Eich E-bost',
  nameUpdateFailed: "Methwyd â Diweddaru'r Enw",
  profilePictureUpdateFailed: "Methwyd â Diweddaru'r Llun Proffil",
  profileDataUpdateSuccessful: "Data Proffil Wedi'i Ddiweddaru'n Llwyddiannus",
  profileSettingsTitle: 'Gwybodaeth Proffil',
  profileSettingsSubtitle:
    "Diweddarwch eich enw a'ch llun proffil yma i gadw'ch gwybodaeth gyfrif yn gyfredol.",
  saveChanges: 'Cadw Newidiadau',
  language: 'Iaith',
};

export const userProfileText: any = getLocalizationBasedOnLang(enlish, welsh);
