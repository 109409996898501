import React from 'react';
import {inject, observer} from 'mobx-react';
import AddImageNodeUI from '../view/AddImageNodeUI';
import AddImageNodeWizardStore from '../store/AddImageNodeWizardStore';
import {useParams} from 'react-router-dom';

interface Props {
  addImageNodeWizardStore?: AddImageNodeWizardStore;
}

const AddImageNodeContainer = inject('addImageNodeWizardStore')(
  observer(({addImageNodeWizardStore}: Props) => {
    const {organizationId, lessonId} = useParams();

    const onClose = () => {
      addImageNodeWizardStore!.closeWizard();
    };

    const onSave = (duration: number, title: string) => {
      if (organizationId && lessonId) {
        addImageNodeWizardStore?.createImageNode(
          organizationId,
          lessonId,
          duration,
          title,
        );
      }
    };

    return addImageNodeWizardStore!.isOpen ? (
      <AddImageNodeUI
        onClose={onClose}
        image={addImageNodeWizardStore!.imageSource}
        onSave={onSave}
      />
    ) : null;
  }),
);

export default AddImageNodeContainer;
