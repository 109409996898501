import OrganizationStaffApi from '../api/OrganizationStaffApi';
import {EditStaffMemberModel} from '../models/Oganization/EditStaffMemberModel';
import {InviteOrgStaffModel} from '../models/Oganization/InviteOrgStaffModel';

class OrganizationStaffRepository {
  private organizationStaffApi = new OrganizationStaffApi();

  public getStaffMembers(organizationId: string) {
    return this.organizationStaffApi.getStaffRecords(organizationId);
  }

  public deleteStaffMember(organizationId: string, memberId: string) {
    return this.organizationStaffApi.deleteStaffMember(
      organizationId,
      memberId,
    );
  }

  public activateStaffMember(organizationId: string, memberId: string) {
    return this.organizationStaffApi.activateStaffMember(
      organizationId,
      memberId,
    );
  }

  public getStaffRecord(organizationId: string, memberId: string) {
    return this.organizationStaffApi.getStaffRecord(organizationId, memberId);
  }

  public editStaffMember(organizationId: string, model: EditStaffMemberModel) {
    return this.organizationStaffApi.editStaffMember(organizationId, model);
  }

  public inviteStaffMember(organizationId: string, model: InviteOrgStaffModel) {
    return this.organizationStaffApi.inviteStaffUser(organizationId, model);
  }

  public deleteStaffInvitation(organizationId: string, invitationId: string) {
    return this.organizationStaffApi.deleteInvitation(
      organizationId,
      invitationId,
    );
  }

  public getInstructors(organizationId: string) {
    return this.organizationStaffApi.getInstructors(organizationId);
  }

  public async saveInstructors(
    selectedInstructor: string,
    organizationId: string,
    courseId: string,
  ) {
    return this.organizationStaffApi.saveInstructors(
      selectedInstructor,
      organizationId,
      courseId,
    );
  }

  public async removeInstructor(
    organizationStaffId: string,
    organizationId: string,
    courseId: string,
  ) {
    return this.organizationStaffApi.removeInstructor(
      organizationStaffId,
      organizationId,
      courseId,
    );
  }

  public async getInstructorCandidates(
    organizationId: string,
    courseId: string,
  ) {
    return this.organizationStaffApi.getInstructorCandidates(
      organizationId,
      courseId,
    );
  }
}

export default OrganizationStaffRepository;
