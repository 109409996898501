import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  title: 'Sign Up',
  subtitle: 'It’s quick and easy.',
  email: 'Email',
  password: 'Password',
  name: 'Full Name',
  acceptTerms: 'I agree to the',
  termsAndPrivacy: 'terms & conditions.',
  submit: 'Sign Up',
  haveAnAccount: 'Already have an account?',
  signin: 'Sign In',
};

const welsh = {
  title: 'Cofrestrwch',
  subtitle: "Mae'n gyflym ac yn hawdd",
  email: 'e-bost',
  password: 'Cyfrinair',
  name: 'Enw Llawn',
  acceptTerms: "Rwy'n cytuno i",
  termsAndPrivacy: 'Telerau ac Amodau',
  submit: 'Cofrestrwch',
  haveAnAccount: 'Eisoes gennych gyfrif?',
  signin: 'Mewngofnodwch',
};

export const signupText: any = getLocalizationBasedOnLang(english, welsh);
