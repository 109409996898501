import React, {useEffect, useState} from 'react';
import CourseEditRootUI from '../view/CourseEditRootUI';
import {useParams} from 'react-router-dom';
import {Course} from '../../../../data/models/Course/Course';
import {inject, observer} from 'mobx-react';
import CourseEditRootStore from '../store/CourseEditRootStore';

interface Props {
  courseEditRootStore?: CourseEditRootStore;
}

const CourseEditRootContainer = inject('courseEditRootStore')(
  observer(({courseEditRootStore}: Props) => {
    const [userPermissions, setUserPermissions] = useState<string[]>([]);
    const [publisedCourse, setPublishedCourse] = useState<Course>();
    const {organizationId, courseId} = useParams();

    useEffect(() => {
      if (organizationId && courseId) {
        courseEditRootStore
          ?.getPermissions(organizationId)
          .then(setUserPermissions);
        courseEditRootStore
          ?.getPublishedCourse(organizationId, courseId)
          .then(setPublishedCourse);
      }
    }, []);

    const getCourseLink = () => {
      if (organizationId) {
        return `/manage/${organizationId}/courses`;
      } else {
        return `/home`;
      }
    };

    return (
      <div>
        <CourseEditRootUI
          courseLink={getCourseLink()}
          permissions={userPermissions}
          publishedCourse={publisedCourse}
        />
      </div>
    );
  }),
);

export default CourseEditRootContainer;
