import React from 'react';
import './styles.css';
import InvitationNotifcationPopUp from '../components/InvitationNotifcationPopUp';
import {Notification} from '../../../data/models/Notifications/Invitation';
import {notificationsText} from '../notificationsText';
import {notificationTimeTextConvertor} from '../../../utils/notificationTimeTextConvertor';

interface Props {
  notifications: Notification[];
  onViewAll: () => void;
  onViewCourse: (courseId: string, notificationId: string) => void;
  onViewWorkspace: (workspaceId: string, notificationId: string) => void;
  onViewCoursePreview: (
    courseId: string,
    workspaceId: string,
    notificationId: string,
  ) => void;
}

const NotificationsMenuPopUpUI = React.memo(
  ({
    notifications,
    onViewAll,
    onViewCourse,
    onViewWorkspace,
    onViewCoursePreview,
  }: Props) => {
    const renderNotifications = () => {
      if (notifications.length === 0) {
        return (
          <div className="EmptyState">
            <h3>{notificationsText.noNotifications}</h3>
          </div>
        );
      }
      return notifications.map((notification: Notification, index: number) => {
        const formattedDateSubtitle = notificationTimeTextConvertor(
          notification.dateCreated,
        );

        return (
          <li key={index}>
            <InvitationNotifcationPopUp
              description={notification.description}
              title={notification.title}
              dateSubtitle={formattedDateSubtitle}
              icon={notification.organizationAvatarUrl}
              type={notification.type}
              onViewCoursePreview={() =>
                onViewCoursePreview(
                  notification.entityId,
                  notification.organizationId!,
                  notification.id,
                )
              }
              onViewCourse={() =>
                onViewCourse(notification.entityId, notification.id)
              }
              onViewWorkspace={() =>
                onViewWorkspace(notification.organizationId!, notification.id)
              }
            />
          </li>
        );
      });
    };

    const onHandleViewAllClick = () => {
      onViewAll();
    };

    return (
      <div className="NotificationsMenuPopUpContainer">
        <span className="popupArrowDetail"></span>
        <span className="HeadingText">{notificationsText.notifications}</span>
        <ul>{renderNotifications()}</ul>
        <button
          onClick={onHandleViewAllClick}
          className="ViewAllNotificationsButton">
          {notificationsText.viewAll}
        </button>
      </div>
    );
  },
);

export default NotificationsMenuPopUpUI;
