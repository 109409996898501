import {inject, observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import AddChallengeBranchAIWizardUI from '../view/AddChallengeBranchAIWizardUI';
import AddChallengeBranchAIStore from '../store/AddChallengeBranchAIStore';
import {useParams} from 'react-router-dom';
import {GeneratedChallengeBranch} from '../../../../data/models/QuestionGeneration/GeneratedQuestions';

interface Props {
  addChallengeBranchAIStore?: AddChallengeBranchAIStore;
}

const AddChallengeBranchAIWizardContainer = inject('addChallengeBranchAIStore')(
  observer(({addChallengeBranchAIStore}: Props) => {
    const [result, setResult] = useState<GeneratedChallengeBranch>();

    const {organizationId, lessonId} = useParams();

    useEffect(() => {
      onGenerate();
    }, [addChallengeBranchAIStore?.isShown()]);

    const onGenerate = () => {
      if (organizationId && lessonId) {
        addChallengeBranchAIStore
          ?.generateChallengeBranchAI(organizationId, lessonId)
          .then(setResult);
      }
    };

    const onCreateChallengeBranch = (
      answer: string,
      question: string,
      frame: string,
    ) => {
      if (organizationId && lessonId) {
        addChallengeBranchAIStore!.createChallengeAINode(
          organizationId,
          lessonId,
          question,
          answer,
          frame,
        );
      }
    };

    return addChallengeBranchAIStore?.isShown() ? (
      <AddChallengeBranchAIWizardUI
        onClose={() => addChallengeBranchAIStore!.closeWizard()}
        questions={result}
        isLoading={addChallengeBranchAIStore!.isLoading}
        onRegenerate={onGenerate}
        onCreateChallengeBranch={onCreateChallengeBranch}
        isGenerating={addChallengeBranchAIStore!.isGenerating}
      />
    ) : null;
  }),
);

export default AddChallengeBranchAIWizardContainer;
