import {Point} from '../../../../data/models/LessonContent/ContentFreeform';

const STARTING_WIDTH = 120;
const STARTING_HEIGHT = 140;

export const MAX_SHAPE_POINTS = 7;
export const MIN_SHAPE_POINTS = 3;

export const generateShapePoints = (width: number) => {
  const generatedPoints: Point[] = [];
  const centerPointX = width / 2;
  const centerPointY = width / 1.77 / 2;
  generatedPoints.push({
    x: centerPointX + STARTING_WIDTH / 2,
    y: centerPointY + STARTING_HEIGHT / 2,
  });
  generatedPoints.push({
    x: centerPointX - STARTING_WIDTH / 2,
    y: centerPointY + STARTING_HEIGHT / 2,
  });
  generatedPoints.push({
    x: centerPointX - STARTING_WIDTH / 2,
    y: centerPointY - STARTING_HEIGHT / 2,
  });
  generatedPoints.push({
    x: centerPointX + STARTING_WIDTH / 2,
    y: centerPointY - STARTING_HEIGHT / 2,
  });
  return generatedPoints;
};

export const addPointToOverlay = (points: Point[]) => {
  if (points.length >= MAX_SHAPE_POINTS) {
    return points;
  }
  const midX = (points[0].x + points[1].x) / 2;
  const midY = (points[0].y + points[1].y) / 2;
  return [
    ...points.slice(0, 1),
    {
      x: midX,
      y: midY,
    },
    ...points.slice(1),
  ];
};

export const removePointFromOverlay = (points: Point[]) => {
  if (points.length <= MIN_SHAPE_POINTS) {
    return points;
  }
  const newPoints = [...points];
  newPoints.pop();
  return newPoints;
};
