import React, {useEffect, useState} from 'react';
import NavBar from '../../../uiToolkit/NavBar';
import {Col, Container, Row} from 'react-bootstrap';
import {subscriptionSuccessText} from './subcsriptionSuccessText';
import Button from '../../../uiToolkit/Buttons/Button';
import './styles.css';
import {useNavigate, useParams} from 'react-router-dom';
import NavigationStore from '../../../navigation/store/NavigationStore';
import {inject, observer} from 'mobx-react';

interface Props {
  forPayment?: boolean;
  forTrial?: boolean;
  forUpdate?: boolean;
  navigationStore?: NavigationStore;
}

const SubscriptionSuccess = inject('navigationStore')(
  observer(({forPayment, forTrial, forUpdate, navigationStore}: Props) => {
    const {organizationId} = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      refreshWorkspaceData();
    }, []);

    const refreshWorkspaceData = async () => {
      if (organizationId) {
        await navigationStore!.refreshToken();
        await navigationStore!.tryGetMyOrganization();
        await navigationStore!.getOrganizationDetails(organizationId);
        await navigationStore!.getOrganizationDashboardAnalytics(
          organizationId,
        );
        setIsLoading(false);
      }
    };

    const onGoToWorkspace = () => {
      navigate(`/manage/${organizationId}/workspace`);
    };

    return (
      <div>
        <NavBar />
        <Container>
          <Row>
            <Col xs={12}>
              <div className="SubscriptionSuccessContainer">
                <span className="icon-typeCheck SuccessCheck"></span>
                <h1>{subscriptionSuccessText.title}</h1>
                <h2>
                  {forPayment
                    ? subscriptionSuccessText.paymentSubtitle
                    : forTrial
                    ? subscriptionSuccessText.trialActive
                    : forUpdate
                    ? subscriptionSuccessText.updateSuccess
                    : subscriptionSuccessText.subtitle}
                </h2>
                <div className="InfoBox">
                  <h4>{subscriptionSuccessText.whatsNext}</h4>
                  <p>
                    {forPayment || forUpdate
                      ? subscriptionSuccessText.paymentInfo
                      : subscriptionSuccessText.cardText1}
                  </p>
                  {!forPayment && !forUpdate && (
                    <p>{subscriptionSuccessText.cardText2}</p>
                  )}
                  {forUpdate && <p>{subscriptionSuccessText.updateInfo}</p>}
                  <Button
                    title={subscriptionSuccessText.goToWorkspace}
                    onClick={onGoToWorkspace}
                    className="WorkspaceButton"
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }),
);

export default SubscriptionSuccess;
