import React, {useEffect, useState} from 'react';
import ListStudentsUI from '../view/ListLearnersUI';
import {inject, observer} from 'mobx-react';
import LearnersStore from '../../store/LearnersStore';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import ConfirmationModal from '../../../../uiToolkit/ConfirmationModal';
import {listLearnersText} from '../listLearnersText';
import {toast} from 'react-toastify';
import {appText} from '../../../../assets/text/appText';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import NavigationStore from '../../../../navigation/store/NavigationStore';
import {LearnerListItem} from '../../../../data/models/Learners/Learner';

const PAGE_SIZE = 10;

interface Props {
  learnersStore?: LearnersStore;
  navigationStore?: NavigationStore;
}

const ListLearnersContainer = inject(
  'learnersStore',
  'navigationStore',
)(
  observer(({learnersStore, navigationStore}: Props) => {
    const [learners, setLearners] = useState<LearnerListItem[]>([]);
    const [pages, setPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [courses, setCourses] = useState<any[]>([]);
    const [filters, setFilters] = useState<string[] | undefined>([]);
    const [search, setSearch] = useState<string | undefined>('');
    const [deleteFromCoursePayload, setDeleteFromCoursPayload] = useState<{
      learnerId: string;
      courseId: string;
    }>();
    const [learnerToDeleteId, setLearnerToDeleteId] = useState<string>();

    const location = useLocation();
    const navigate = useNavigate();
    const {organizationId} = useParams();

    useEffect(() => {
      fetchCourses();
    }, [learnersStore]);

    useEffect(() => {
      if (!location.state) {
        fetchLearners();
      }
    }, [learnersStore, currentPage, search, filters]);

    useEffect(() => {
      if (
        location &&
        location.state &&
        location.state.courseName &&
        courses.length
      ) {
        const filterCourse = courses.find(
          x => x.title === location.state.courseName,
        );
        if (filterCourse) {
          setFilters([filterCourse.id]);
        }
        location.state = null;
      }
    }, [location, location.state, courses]);

    const fetchLearners = () => {
      if (organizationId) {
        learnersStore
          ?.getMyLearners(
            currentPage - 1,
            organizationId,
            PAGE_SIZE,
            filters,
            search,
          )
          .then(res => {
            setLearners(res.data || []);
            setPages(res.pagesCount);
          });
      }
    };
    const onAddNewLearners = () => {
      navigate(`/manage/${organizationId}/learners/create`);
    };

    const onInitiateDeleteLearner = (learnerId: string) => {
      setLearnerToDeleteId(learnerId);
    };

    const onDeleteLearner = () => {
      if (organizationId && learnerToDeleteId && !learnersStore?.isLoading) {
        setLearnerToDeleteId(undefined);
        learnersStore!
          .deleteLearner(organizationId, learnerToDeleteId)
          .then(() => {
            navigationStore!.getOrganizationDashboardAnalytics(organizationId);
            fetchLearners();
          });
      }
    };

    const onActivateLearner = (learnerId: string) => {
      if (organizationId && !learnersStore?.isLoading) {
        learnersStore!.activateLearner(organizationId, learnerId).then(() => {
          navigationStore!.getOrganizationDashboardAnalytics(organizationId);
          fetchLearners();
        });
      }
    };

    const onPageChange = (page: number) => {
      setCurrentPage(page);
    };

    const fetchCourses = () => {
      if (organizationId) {
        learnersStore
          ?.getOrganizationCourses(organizationId)
          .then(res => setCourses(res));
      }
    };

    const handleAcceptButtonPressed = (filteredCourses: string[]) => {
      if (filteredCourses.length === 0) {
        setFilters(undefined);
      } else {
        setFilters(filteredCourses);
      }
    };

    const handleSearchKeyChange = (search: string) => {
      if (search !== '') {
        setSearch(search);
      } else {
        setSearch(undefined);
      }
    };

    const onDeleteFromCourse = (learnerId: string, courseId: string) => {
      setDeleteFromCoursPayload({learnerId, courseId});
    };

    const deleteFromCourse = () => {
      if (
        organizationId &&
        deleteFromCoursePayload &&
        !learnersStore?.isLoading
      ) {
        learnersStore!
          .removeLearnerFromTheCourse(
            organizationId,
            deleteFromCoursePayload.learnerId,
            deleteFromCoursePayload.courseId,
          )
          .then(fetchLearners);
        setDeleteFromCoursPayload(undefined);
      }
    };

    const onReEnroll = (courseId: string, learnerId: string) => {
      if (organizationId && !learnersStore?.isLoading) {
        learnersStore!
          .reEnrollLearner(organizationId, courseId, learnerId)
          .then(fetchLearners);
      }
    };

    const onCopyEmail = async (email: string) => {
      await navigator.clipboard.writeText(email);
      toast.success(appText.copiedToClipboard, toastConfig);
    };

    return (
      <>
        <ListStudentsUI
          learners={learners}
          onAddNewLearners={onAddNewLearners}
          onDelete={onInitiateDeleteLearner}
          pages={pages}
          onPageChange={onPageChange}
          courses={courses}
          applyFiltersButton={handleAcceptButtonPressed}
          onSearchKeyChange={handleSearchKeyChange}
          onDeleteFromCourse={onDeleteFromCourse}
          onActivateLearner={onActivateLearner}
          onReEnroll={onReEnroll}
          onCopyEmail={onCopyEmail}
          filters={filters || []}
        />
        <ConfirmationModal
          shown={deleteFromCoursePayload !== undefined}
          onAccept={deleteFromCourse}
          onCancel={() => setDeleteFromCoursPayload(undefined)}
          acceptText={listLearnersText.remove}
          title={listLearnersText.removeFromCourseConfirmation}
        />
        <ConfirmationModal
          shown={learnerToDeleteId !== undefined}
          onAccept={onDeleteLearner}
          onCancel={() => setLearnerToDeleteId(undefined)}
          acceptText={listLearnersText.remove}
          title={listLearnersText.removeFromOrgConfirmation}
        />
      </>
    );
  }),
);

export default ListLearnersContainer;
