import {makeAutoObservable, runInAction} from 'mobx';
import LibraryRepository from '../../../../data/repositories/LibraryRepository';
import {
  FILE_ORIGIN_GENERATED,
  MEDIA_FILES_ALL,
  MediaFile,
} from '../../../../data/models/LibraryFile/MediaFile';
import LoadingModule from '../../../../modules/LoadingModule/LoadingModule';

const PAGE_SIZE = 10;

class AIFilesStore {
  private libraryRepo = new LibraryRepository();

  private _recentFiles: MediaFile[] = [];
  private _amountOfPages = 0;
  private _currentPage = 0;
  private _files: MediaFile[] = [];
  private _sortOptions = {
    column: 'created_date',
    order: 'desc',
  };

  private loadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get amountOfPages() {
    return this._amountOfPages;
  }

  public get currentPage() {
    return this._currentPage;
  }

  public get tapyblFiles() {
    return this._files;
  }

  public get recentFiles() {
    return this._recentFiles;
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public setCurrentPage(page: number) {
    this._currentPage = page;
  }

  public setSortOptions(column: string, order: string) {
    this._sortOptions = {column, order};
  }

  public async fetchFiles(organizationId: string) {
    this.loadingModule.startLoading();
    const result = await this.libraryRepo.getMediaFiles(
      this._currentPage,
      organizationId,
      PAGE_SIZE,
      this._sortOptions.column,
      MEDIA_FILES_ALL,
      '',
      this._sortOptions.order === 'asc',
      FILE_ORIGIN_GENERATED,
    );
    this.loadingModule.endLoading();

    runInAction(() => {
      if (result.success && result.data) {
        this._files = result.data.data;
        this._amountOfPages = result.data.pagesCount;
      }
    });
  }
}

export default AIFilesStore;
