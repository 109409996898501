import React from 'react';
import ExploreUi from '../view/ExploreUi';
import ExploreStore from '../store/ExplorePageStore';
import {useNavigate} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import {SubjectListItem} from '../../../data/models/Course/Subject';

interface Props {
  exploreStore?: ExploreStore;
}

const ExploreContainer = inject(
  'exploreStore',
  'modalProviderStore',
)(
  observer(({exploreStore}: Props) => {
    const [subjectsList, setSubjectsList] = React.useState<SubjectListItem[]>(
      [],
    );
    const [selectedFilter, setSelectedFilter] = React.useState('');
    const navigate = useNavigate();

    React.useEffect(() => {
      exploreStore!.setCurrentPage(0);
      exploreStore!.getPublicCourses(selectedFilter);
      exploreStore!.getSubjects().then(setSubjectsList);
    }, [selectedFilter]);

    const onOpenCourse = (courseId: string) => {
      navigate(`/course/${courseId}`);
    };

    const onChangePage = (page: number) => {
      exploreStore!.setCurrentPage(page);
      exploreStore!.getPublicCourses(selectedFilter);
    };

    return (
      <ExploreUi
        isLoading={false}
        publicCourses={exploreStore!.publicCourses}
        onOpenCourse={onOpenCourse}
        subjectsList={subjectsList}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        onChangePage={onChangePage}
        currentPage={exploreStore!.currentPage}
        pagesCount={exploreStore!.pagesCount}
      />
    );
  }),
);

export default ExploreContainer;
