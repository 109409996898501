import React from 'react';
import {
  alertTriangleIc,
  arrowSupplementalIc,
  lessonArrowBack,
  lessonArrowDown,
  lessonIc,
} from '../../../assets/images';
import EditorTooltipButton from '../../../uiToolkit/EditorTooltopButton';
import {Lesson} from '../../../data/models/Course/Lesson';
import LessonCard from './LessonCard';
import RetriesCard from './RetriesCard';
import {
  ALERT_SUPPLEMENTAL,
  LESSON_SUPPLEMENTAL,
} from '../../../data/staticValues/supplementalTypes';
import AlertCard from './AlertCard';
import {ALERT_OPTIONS, LESSON_OPTIONS} from '../view/LessonsUI';
import {lessonsText} from '../lessonsText';

interface Props {
  gradableCourse: boolean;
  lesson: Lesson;
  isDragging?: boolean;
  onSelect: (lesson: Lesson) => void;
  selectedLesson?: Lesson;
  onRetriesChange: (lesson: Lesson, retries: number) => void;
  onAddSupplementalLesson: (lesson: Lesson) => void;
  onAddSupplementalAlert: (lesson: Lesson) => void;
  onSelectAlert: (lesson: Lesson) => void;
  selectedType: string;
  onOpen: (lesson: Lesson) => void;
}

const LessonsBlock = React.memo(
  ({
    lesson,
    gradableCourse,
    isDragging,
    onSelect,
    selectedLesson,
    onRetriesChange,
    onAddSupplementalLesson,
    onAddSupplementalAlert,
    onSelectAlert,
    selectedType,
    onOpen,
  }: Props) => {
    const addSupplementalActions = [
      {
        icon: alertTriangleIc,
        action: () => {
          onAddSupplementalAlert(lesson);
        },
        tooltip: lessonsText.alertInstructor,
      },
      {
        icon: lessonIc,
        action: () => {
          onAddSupplementalLesson(lesson);
        },
        tooltip: lessonsText.watchSupplemental,
      },
    ];

    const renderSupplementalContent = () => {
      if (
        lesson.supplementalType === LESSON_SUPPLEMENTAL &&
        lesson.supplementalLesson
      ) {
        return (
          <LessonCard
            lesson={lesson.supplementalLesson}
            onSelect={onSelect}
            onOpen={onOpen}
            isSelcted={
              selectedLesson?.id === lesson.id && selectedType === ALERT_OPTIONS
            }
          />
        );
      }
      if (lesson.supplementalType === ALERT_SUPPLEMENTAL) {
        return (
          <AlertCard
            onSelect={() => onSelectAlert(lesson)}
            isSelected={
              selectedLesson?.id === lesson.id && selectedType === ALERT_OPTIONS
            }
          />
        );
      }
      return (
        <EditorTooltipButton
          actions={addSupplementalActions}
          tooltipInfo={lessonsText.addSupplemental}
          style={{width: 'auto'}}
        />
      );
    };

    const renderSupplementalPath = () => (
      <>
        <img src={arrowSupplementalIc} className="Arrow" />
        <RetriesCard lesson={lesson} onRetriesChange={onRetriesChange} />
        <img src={arrowSupplementalIc} className="Arrow" />
        {renderSupplementalContent()}
        {lesson.supplementalType === LESSON_SUPPLEMENTAL &&
          lesson.supplementalLesson && (
            <img src={lessonArrowBack} className="BackArrowLarge" />
          )}
      </>
    );

    return (
      <div className="LessonBlock">
        {!isDragging ? (
          <div className="ArrowContainer">
            <img src={lessonArrowDown} />
          </div>
        ) : (
          <div className="ArrowPlaceholder" />
        )}
        <div className="SupplementalBlock">
          {gradableCourse && lesson.isGradable && !isDragging && (
            <div className="BalancingBlock">{renderSupplementalPath()}</div>
          )}
          <LessonCard
            lesson={lesson}
            onSelect={onSelect}
            onOpen={onOpen}
            isSelcted={
              selectedLesson?.id === lesson.id &&
              selectedType === LESSON_OPTIONS
            }
          />
          {gradableCourse &&
            lesson.isGradable &&
            !isDragging &&
            renderSupplementalPath()}
        </div>
      </div>
    );
  },
);

export default LessonsBlock;
