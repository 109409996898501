const FILE_PICK_STEP = 0;
const MUTE_EXCLUDE_STEP = 1;
const VIDEO_DURATION_STEP = 2;
const IMAGE_GENERATION_STEP = 3;
const VOICE_NARRATION_STEP = 4;
const SUMMARY_STEP = 5;

const videoGenerationSteps = [
  {
    id: FILE_PICK_STEP,
    title: 'Upload',
  },
  {
    id: MUTE_EXCLUDE_STEP,
    title: 'Exceptions',
  },
  {
    id: VIDEO_DURATION_STEP,
    title: 'Video Duration',
  },
  {
    id: IMAGE_GENERATION_STEP,
    title: 'AI Media',
  },
  {
    id: VOICE_NARRATION_STEP,
    title: 'Narration',
  },
  {
    id: SUMMARY_STEP,
    title: 'Summary',
  },
];

export {
  FILE_PICK_STEP,
  MUTE_EXCLUDE_STEP,
  VIDEO_DURATION_STEP,
  VOICE_NARRATION_STEP,
  IMAGE_GENERATION_STEP,
  SUMMARY_STEP,
  videoGenerationSteps,
};
