import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  next: 'Next',
  optionsEmpty: 'Select a lesson to see options here',
  newLessonTitle: 'New Lesson',
  newLessonDescription: '',
  lessonTitle: 'Title',
  lessonTitlePlaceholder: 'Title for your lesson',
  description: 'Description',
  descriptionPlaceholder: 'Lesson Description',
  deleteLesson: 'Delete Lesson',
  delete: 'Delete',
  newLesson: 'Add New Lesson',
  deleteConfirmation: 'Are you sure you want to delete this lesson?',
  discardChangesConfirmation:
    'You have unsaved changes do you want to proceed?',
  gradableTitle: 'Assessable lesson?',
  changesSaved: 'Changes Saved',
  operationFailed: 'Operation Failed',
  numberOfRetries: 'Number of retries',
  supplementalAlert: 'Notify instructors in the event of a lesson failure.',
  deleteAlert: 'Delete alert on fail',
  deleteAlertConfirmation:
    'Are you sure you want to remove the notification for lesson failures?',
  passingScore: 'Passing score',
  infoGradable: 'This lesson is assessable',
  infoPercent: 'Score needed to pass',
  retriesInfo: 'This lesson allows retries',
  supplementalArrowInfo: 'Supplemental lesson',
  alertInfo: 'Notify the instructor in case of lesson failure',
  lessonInfo: 'Show supplemental lesson',
  addSupplemental: 'Add supplemental path',
  alertInstructor: 'Alert instructor',
  watchSupplemental: 'Show supplemental lesson',
  cantChangeScoreOfPublishedLesson:
    "*Can't edit a grade settings of the published lesson",
  generateLesson: 'Generate lesson with AI assistant',
};

const welsh = {
  next: 'Nesaf',
  optionsEmpty: 'Dewiswch wers i weld opsiynau yma',
  newLessonTitle: 'Gwers Newydd',
  newLessonDescription: "Disgrifiad o'r Wers Newydd(?)",
  lessonTitle: 'Teitl',
  lessonTitlePlaceholder: 'Teitl ar gyfer eich gwers',
  description: 'Disgrifiad',
  descriptionPlaceholder: 'Disgrifiad y Gwers',
  deleteLesson: 'Dileu Gwers',
  delete: 'Dileu',
  newLesson: 'Ychwanegwch Wers Newydd',
  deleteConfirmation: "Ydych chi'n siŵr eich bod am ddileu'r wers hon?",
  discardChangesConfirmation:
    'Mae gennych newidiadau heb eu cadw. Ydych chi am barhau?',
  gradableTitle: 'Gwers Asesadwy',
  changesSaved: "Newidiadau wedi'u Cadw",
  operationFailed: 'Methodd y Gweithrediad',
  numberOfRetries: 'Nifer o ail-geisio',
  supplementalAlert: 'Hysbyswch hyfforddwyr os bydd y gwers yn methu',
  deleteAlert: 'Rhybudd dileu ar fethian',
  deleteAlertConfirmation:
    "Ydych chi'n siŵr eich bod am dynnu'r hysbysiad am fethiannau gwersi?",
  passingScore: "Sgôr i'r Basio",
  infoGradable: "Mae'r wers hon yn asesadwy",
  infoPercent: 'Sgôr sydd ei hangen i basio',
  retriesInfo: "Mae'r wers hon yn caniatáu ail-geisiadau",
  supplementalArrowInfo: 'Gwers Atodol',
  alertInfo: 'Hysbyswch y hyfforddwr os bydd y wers yn methu',
  lessonInfo: 'Dangos gwers atodol',
  addSupplemental: 'Ychwanegwch llwybr atodol',
  alertInstructor: "Rhybuddio'r hyfforddwr",
  watchSupplemental: 'Dangos llwybr atodol',
  cantChangeScoreOfPublishedLesson:
    'Methu golygu gosodiadau gradd y wers a gyhoeddwyd',
  generateLesson: 'Cynhyrchwch wers gyda Cynorthwy-ydd  AI',
};

export const lessonsText: any = getLocalizationBasedOnLang(english, welsh);
