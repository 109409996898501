import React from 'react';
import ModalProviderUI from '../view/ModalProviderUI';
import {inject, observer} from 'mobx-react';
import ModalProviderStore from '../store/ModalProviderStore';

interface Props {
  modalProviderStore?: ModalProviderStore;
}

const ModalProviderContainer = inject('modalProviderStore')(
  observer(({modalProviderStore}: Props) => {
    return (
      <ModalProviderUI
        activeModal={modalProviderStore?.getActiveStore()}
        onClose={(payload?: any) => modalProviderStore!.onClose(payload)}
      />
    );
  }),
);

export default ModalProviderContainer;
