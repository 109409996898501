import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  title: 'Courses',
  create: 'Create new course',
  actions: 'Actions',
  deleteConfirm: 'Are you sure you want to delete this course?',
  copyConfirm: 'Are you sure you want to copy this course?',
  copySuccess: 'Course copied successfully',
  edit: 'Edit Course Info',
  open: 'Course Editor',
  cantDelete: "You can't delete published course with active enrollments",
  delete: 'Delete Course',
  published: 'Published',
  yes: 'Yes',
  unpublished: 'Unpublished',
  no: 'No',
  public: 'Public',
  private: 'Private',
  copyCourse: 'Create Copy',
  courseDelted: 'Course deleted successfully',
};

const welsh = {
  title: 'Cyrsiau',
  create: 'Creuwch gwrs newydd',
  actions: 'Creuwch gwrs newydd',
  deleteConfirm: "Ydych chi'n siŵr eich bod am ddileu'r cwrs hwn?",
  copyConfirm: "Ydych chi'n siŵr eich bod am gopïo'r cwrs hwn?",
  copySuccess: 'Cwrs wedi’i gopïo’n llwyddiannus',
  edit: 'Golygwch wybodaeth y cwrs',
  open: 'Golygydd Cwrs',
  cantDelete: 'Ni allwch ddileu cwrs cyhoeddedig gyda chofrestriadau actif.',
  delete: 'Dilewch Gwrs',
  published: 'Cyhoeddedig',
  yes: 'Ie',
  unpublished: 'Heb ei gyhoeddi',
  no: 'Na',
  public: 'Cyhoeddus',
  private: 'Preifat',
  copyCourse: 'Creuwch gopi',
  courseDelted: 'Cwrs wedi’i ddileu’n llwyddiannus',
};

export const coursesListText: any = getLocalizationBasedOnLang(english, welsh);
