import React, {useEffect, useState} from 'react';
import TapyblLibraryUI from '../view/TapyblLibraryUI';
import {inject, observer} from 'mobx-react';
import TapyblLibraryStore from '../store/TapyblLibraryStore';
import {useParams} from 'react-router-dom';
import FilePreviewContainer from '../../Root/container/FilePreviewContainer';
import {
  MediaFile,
  VideoTranscriptSegment,
} from '../../../../data/models/LibraryFile/MediaFile';
import ConfirmationModal from '../../../../uiToolkit/ConfirmationModal';
import {videoSourcesText} from '../../videoSourcesText';
import NavigationStore from '../../../../navigation/store/NavigationStore';
import VideoSourcesStore from '../../Root/store/VideoSourcesStore';
import DownloadTranscriptionModal from '../../Root/components/DownloadTranscriptionModal';
import UploadFileLibraryModal from '../../UploadFileLibraryModal';

interface Props {
  tapyblLibraryStore?: TapyblLibraryStore;
  navigationStore?: NavigationStore;
  videoSourcesStore?: VideoSourcesStore;
}

const TapyblLibraryContainer = inject(
  'tapyblLibraryStore',
  'navigationStore',
  'videoSourcesStore',
)(
  observer(
    ({tapyblLibraryStore, navigationStore, videoSourcesStore}: Props) => {
      const [search, setSearch] = useState('');
      const [fileToPreview, setFileToPreview] = useState<MediaFile>();
      const [fileToDeleteId, setFileToDeleteId] = useState<string>();
      const [fileTranscriptions, setFileTranscriptions] =
        useState<VideoTranscriptSegment[]>();
      const [uploadedFile, setUploadedFile] = useState<File>();

      const {organizationId} = useParams();

      useEffect(() => {
        if (organizationId) {
          tapyblLibraryStore!.fetchRecentFiles(organizationId);
        }
      }, []);

      useEffect(() => {
        if (organizationId) {
          tapyblLibraryStore!.setPageAndOptions(0, 'file_name', 'desc');
          tapyblLibraryStore!.fetchFiles(organizationId, search);
        }
      }, []);

      const onDelete = () => {
        if (organizationId && fileToDeleteId) {
          videoSourcesStore!
            .deleteMediaFile(organizationId, fileToDeleteId)
            .then(refetchFiles);
          setFileToDeleteId(undefined);
        }
      };

      const refetchFiles = () => {
        if (organizationId) {
          tapyblLibraryStore!.fetchFiles(organizationId, search);
          tapyblLibraryStore!.fetchRecentFiles(organizationId);
          navigationStore!.getOrganizationDetails(organizationId);
        }
      };

      const onPageChange = (page: number) => {
        tapyblLibraryStore!.setCurrentPage(page - 1);
        if (organizationId) {
          tapyblLibraryStore!.fetchFiles(organizationId, search);
        }
      };

      const onSort = (selectedColumn: any, sortDirection: any) => {
        tapyblLibraryStore!.setSortOptions(
          selectedColumn.sortField,
          sortDirection,
        );
        if (organizationId) {
          tapyblLibraryStore!.fetchFiles(organizationId, search);
        }
      };

      const onUpload = (video: File) => {
        setUploadedFile(video);
      };

      const onDownloadTranscript = async (id: string) => {
        if (organizationId) {
          const result = await videoSourcesStore!.downloadTranscript(
            organizationId,
            id,
          );
          if (result.length) {
            setFileTranscriptions(result);
          }
        }
      };

      const onSearch = (value: string) => {
        setSearch(value);
        if (organizationId) {
          tapyblLibraryStore!.fetchFiles(organizationId, value);
        }
      };

      const onCloseUpload = () => {
        if (organizationId) {
          setUploadedFile(undefined);
          tapyblLibraryStore!.fetchFiles(organizationId, search);
        }
      };

      return (
        <>
          <TapyblLibraryUI
            libraryFiles={tapyblLibraryStore!.tapyblFiles}
            pages={tapyblLibraryStore!.amountOfPages}
            onPreview={setFileToPreview}
            recentFiles={tapyblLibraryStore!.recentFiles}
            onPageChange={onPageChange}
            isLoading={tapyblLibraryStore!.isLoading}
            onDelete={setFileToDeleteId}
            onSort={onSort}
            onUpload={onUpload}
            onDownloadTranscript={onDownloadTranscript}
            search={search}
            onSearch={onSearch}
          />
          <ConfirmationModal
            shown={fileToDeleteId !== undefined}
            onAccept={onDelete}
            onCancel={() => setFileToDeleteId(undefined)}
            acceptText={videoSourcesText.delete}
            title={videoSourcesText.confirmDelete}
          />
          <FilePreviewContainer
            fileToPreview={fileToPreview}
            onClose={() => setFileToPreview(undefined)}
          />
          {fileTranscriptions && (
            <DownloadTranscriptionModal
              transcriptions={fileTranscriptions}
              onClose={() => setFileTranscriptions(undefined)}
            />
          )}
          {uploadedFile && (
            <UploadFileLibraryModal
              file={uploadedFile}
              onClose={onCloseUpload}
            />
          )}
        </>
      );
    },
  ),
);

export default TapyblLibraryContainer;
