import React from 'react';
import {courseAccessGateText} from '../courseAccessGateText';
import {Link} from 'react-router-dom';
import './styles.css';
import {WORKSPACE_ADMIN} from '../../../data/models/Oganization/Role';
import NavBar from '../../../uiToolkit/NavBar';

interface Props {
  hasSubscription: boolean;
  role?: string;
  isLearner: boolean;
  organizationId?: string;
}

const CourseAccessGateUI = React.memo(
  ({role, isLearner, organizationId}: Props) => {
    const getTextForRole = () => {
      if (isLearner) {
        return (
          <>
            <h3>{courseAccessGateText.accessNotAllowedLearner1}</h3>
            <h3>{courseAccessGateText.accessNotAllowedLearner2}</h3>
          </>
        );
      } else if (role === WORKSPACE_ADMIN) {
        return (
          <>
            <h3>{courseAccessGateText.accessNotAllowedAdmin1}</h3>
            <h3>{courseAccessGateText.accessNotAllowedAdmin2}</h3>
          </>
        );
      } else {
        return (
          <>
            <h3>{courseAccessGateText.accessNotAllowedStaff1}</h3>
            <h3>{courseAccessGateText.accessNotAllowedStaff2}</h3>
          </>
        );
      }
    };

    const isInstructorPreview = () => {
      return window.location.pathname.includes('/preview');
    };

    return (
      <>
        {(!organizationId || isInstructorPreview()) && <NavBar />}
        <div className="CourseAccessGateContainer">
          {getTextForRole()}
          <div className="d-flex flex-row justify-content-around">
            {role === WORKSPACE_ADMIN && (
              <Link to={`/subscription/${organizationId}/select`}>
                {courseAccessGateText.upgrade}
              </Link>
            )}
            <Link to="/home">{courseAccessGateText.home}</Link>
          </div>
        </div>
      </>
    );
  },
);

export default CourseAccessGateUI;
