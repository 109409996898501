import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  title: "Looks like you've stumbled upon a page that no longer exists.",
  info: "Sorry for the inconvenience. The page you're looking for may have been removed, or you might have entered an incorrect URL.",
  home: 'Home',
  help: 'Help',
  explore: 'Explore',
};

const welsh = {
  title: "Mae'n edrych fel eich bod wedi'i syrthio ar dudalen nad oes mwyach",
  info: "Mae'n ddrwg gennyf am yr anhwylustod. Efallai bod y dudalen rydych chi'n chwilio amdani wedi'i chymryd i lawr, neu efallai eich bod wedi'i teipio URL anghywir.",
  home: 'Cartref',
  help: 'Cymorth',
  explore: 'Archwilio',
};

export const notFoundText: any = getLocalizationBasedOnLang(english, welsh);
