import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  learnerInviteSuccessMsg: 'Invitation(s) Sent Successfully',
  learnerInvitePartialSuccessMsg: 'Some of the invitation(s) Sent Successfully',
  learnerInviteFailMsg: 'Invitation(s) not sent',
  inviteLearnersPageTitle: 'Invite Learners',
  inviteLearnersPageSubtitle:
    'Invite learners to your workspace by adding their email addresses below.',
  HeadingInviteLearnerInputField: 'Invite Learners',
  SubmitButton: 'Submit',
  CancelButton: 'Cancel',
  learnerDeleteSuccessMsg: 'Learner Deleted Successfully',
  learnerActivated: 'Learner Activated Successfully',
  learnerRemoveFromCourseSuccess: 'Learner removed from the course',
  learnerReEnrollSuccess: 'Learner enrollment activated',
  EmailInstructions:
    'Use the Enter or Return Key to add additional email addresses',
  learnerReEnrollFail: 'Failed to enroll learner in the course',
};

const welsh = {
  learnerInviteSuccessMsg: 'Anfonwyd gwahoddiad(au) yn llwyddiannus',
  learnerInvitePartialSuccessMsg:
    "Rhai o'r gwahoddiad(au) a Anfonwyd yn Llwyddiannus",
  learnerInviteFailMsg: 'Gwahoddiad(au) heb eu hanfon',
  inviteLearnersPageTitle: 'Gwahodd Dysgwyr',
  inviteLearnersPageSubtitle:
    "Gwahoddwch ddysgwyr i'ch gweithle trwy ychwanegu eu cyfeiriadau e-bost isod.",
  HeadingInviteLearnerInputField: 'Gwahodd Dysgwyr',
  SubmitButton: 'Cyflwyno',
  CancelButton: 'Canslo',
  learnerDeleteSuccessMsg: "Dysgwr wedi'i Dileu'n Llwyddiannus",
  learnerActivated: "Dysgwr wedi'i Ysgogi'n Llwyddiannus",
  learnerRemoveFromCourseSuccess: "Dysgwr wedi'i dynnu o'r cwrs",
  learnerReEnrollSuccess: "Ymrestriad dysgwr wedi'i ysgogi",
  EmailInstructions:
    'Defnyddiwch yr Allwedd Dychwelyd neu Nodi i ychwanegu cyfeiriadau e-bost ychwanegol',
  learnerReEnrollFail: 'Wedi methu â chofrestru dysgwr ar y cwrs',
};

export const learnersText: any = getLocalizationBasedOnLang(english, welsh);
