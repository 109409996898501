import {makeAutoObservable} from 'mobx';
import {
  MediaFile,
  VimeoMediaFile,
} from '../../../../data/models/LibraryFile/MediaFile';
import {AddLessonContentModel} from '../../../../data/models/LessonContent/AddLessonContentModel';
import {
  LessonContentBase,
  LessonContentVideo,
  LessonContentVimeoVideo,
  VIDEO_NODE_TYPE,
  VIMEO_VIDEO_TYPE,
} from '../../../../data/models/LessonContent/LessonContentBase';
import LessonContentRepository from '../../../../data/repositories/LessonContentRepository';
import LoadingModule from '../../../LoadingModule/LoadingModule';
import {ApiResponse} from '../../../../data/models/ApiResponse';

class AddVideoSourceWizardStore {
  private libraryContentRepo = new LessonContentRepository();

  private parentId?: string;
  private selectedFile?: MediaFile;
  private selectedVimeoFile?: VimeoMediaFile;
  private successCallback?: () => void;
  private nodeToReplace?: LessonContentBase;
  private childId?: string;
  private loadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public startWizard(
    successCallback: () => void,
    file?: MediaFile,
    vimeoFile?: VimeoMediaFile,
    parentId?: string,
    nodeToReplace?: LessonContentBase,
    childId?: string,
  ) {
    this.successCallback = successCallback;
    this.parentId = parentId;
    this.selectedFile = file;
    this.selectedVimeoFile = vimeoFile;
    this.nodeToReplace = nodeToReplace;
    this.childId = childId;
  }

  public cancelWizard() {
    this.parentId = undefined;
    this.selectedFile = undefined;
    this.selectedVimeoFile = undefined;
    this.successCallback = undefined;
    this.nodeToReplace = undefined;
    this.childId = undefined;
  }

  public get showWizard() {
    return (
      this.selectedFile !== undefined || this.selectedVimeoFile !== undefined
    );
  }

  public getSelectedFile() {
    if (this.selectedFile) {
      return this.selectedFile;
    }
  }

  public getSelectedVimeoFile() {
    if (this.selectedVimeoFile) {
      return this.selectedVimeoFile;
    }
  }

  public async addLessonVideoNode(
    organizationId: string,
    lessonId: string,
    video: MediaFile,
    start: number,
    end: number,
  ) {
    this.loadingModule.startLoading();
    const model: AddLessonContentModel = {
      data: {
        ivNodeType: VIDEO_NODE_TYPE,
        startInSeconds: start,
        endInSeconds: end,
        durationInseconds: end - start,
        contentId: video.storageFileName,
        title: video.fileName,
      } as LessonContentVideo,
      parentsIds: this.parentId ? [this.parentId] : undefined,
    };
    if (this.nodeToReplace) {
      const result = await this.libraryContentRepo.editLessonContent(
        organizationId,
        lessonId,
        {...model.data, internalId: this.nodeToReplace.internalId},
      );
      this.processResult(result);
    } else {
      const result = await this.libraryContentRepo.addLessonContent(
        organizationId,
        lessonId,
        model,
        this.childId,
      );
      this.processResult(result);
    }
    this.loadingModule.endLoading();
  }

  public async addLessonVimeoNode(
    organizationId: string,
    lessonId: string,
    video: VimeoMediaFile,
    start: number,
    end: number,
  ) {
    this.loadingModule.startLoading();
    const model: AddLessonContentModel = {
      data: {
        ivNodeType: VIMEO_VIDEO_TYPE,
        startInSeconds: start,
        endInSeconds: end,
        durationInSeconds: end - start,
        contentUrl: video.url,
        placeholderUrl: video.placeholderUrl,
        title: video.title,
      } as LessonContentVimeoVideo,
      parentsIds: this.parentId ? [this.parentId] : undefined,
    };
    if (this.nodeToReplace) {
      const result = await this.libraryContentRepo.editLessonContent(
        organizationId,
        lessonId,
        {...model.data, internalId: this.nodeToReplace.internalId},
      );
      this.processResult(result);
    } else {
      const result = await this.libraryContentRepo.addLessonContent(
        organizationId,
        lessonId,
        model,
      );
      this.processResult(result);
    }
    this.loadingModule.endLoading();
  }

  private processResult = (result: ApiResponse<any>) => {
    if (result.success && this.successCallback) {
      this.successCallback();
      this.cancelWizard();
    }
  };
}

export default AddVideoSourceWizardStore;
