import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  title: 'Verify your email address',
  subtitle: 'Activate your account by entering the code sent to your email',
  code: 'Code',
  submit: 'Activate account',
  didntGetACode: 'Didn’t get the code? Retry now!',
  goBack: 'Go back',
};

const welsh = {
  title: 'Dilyswch eich cyfeiriad e-bost',
  subtitle: "Gweithredwch eich cyfrif trwy nodi'r cod a anfonwyd i'ch e-bost",
  code: 'Cod',
  submit: 'Gweithredu cyfrif',
  didntGetACode: "Nid ydych wedi'i cael y cod? Ceisiwch eto nawr!",
  goBack: 'Ewch yn ôl',
};

export const confirmEmailText: any = getLocalizationBasedOnLang(english, welsh);
