import React, {useState} from 'react';
import {settingsText} from '../../settingsText';
import ErrorBox from '../../../../uiToolkit/ErrorBox';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';
import './styles.css';
import {Role} from '../../../../data/models/Oganization/Role';
import Dropdown from '../../../../uiToolkit/Inputs/Dropdown';
import Button from '../../../../uiToolkit/Buttons/Button';

interface Props {
  errors: string[];
  isLoading: boolean;
  onSubmit: (email: string, roleId: string) => void;
  onCancel: () => void;
  roles: Role[];
  clearErrors: () => void;
}

const StaffInviteUI = React.memo(
  ({errors, onSubmit, isLoading, onCancel, roles, clearErrors}: Props) => {
    const [email, setEmail] = useState('');
    const [roleId, setRoleId] = useState('');

    const onSave = () => {
      onSubmit(email, roleId);
    };

    const buttonEnabled = () => email.trim() !== '' && roleId.trim() !== '';

    return (
      <div className="StaffInviteContainer">
        <h2>{settingsText.invite}</h2>
        <h5>{settingsText.inviteSubtitle}</h5>
        <ErrorBox
          errorCodes={errors}
          className="ErrorBox"
          clearErrors={clearErrors}
        />
        <TextInput
          value={email}
          onChange={setEmail}
          heading={settingsText.email}
          placeholder={settingsText.userEmail}
          className="NameInput"
          isMandatory={true}
        />
        <Dropdown
          options={roles.map(item => ({
            key: item.id,
            value: item.roleName,
          }))}
          selectedOption={roleId}
          setSelectedOption={setRoleId}
          heading={settingsText.role}
          placeholder={settingsText.selectRole}
          isMandatory={true}
        />
        <div className="Footer">
          <Button
            onClick={onCancel}
            title={settingsText.cancel}
            className="Cancel"
            uiType="text"
          />
          <Button
            onClick={onSave}
            title={settingsText.inviteAction}
            disabled={!buttonEnabled()}
            isLoading={isLoading}
            uiType="action"
          />
        </div>
      </div>
    );
  },
);

export default StaffInviteUI;
