import React, {useState} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import NavBar from '../../../../uiToolkit/NavBar';
import {Col, Container, Row} from 'react-bootstrap';
import './styles.css';
import {helpConfig, navConfig, contactConfig} from '../navConfig';
import NavListItem from '../components/NavListItem';
import {OrganizationDetails} from '../../../../data/models/Oganization/Organization';
import QuotaLimitUI from '../components/QuotaLimitUI';
import Tooltip from '../../../../uiToolkit/Tooltip';
import MobileRestriction from '../../../../modules/MobileRestriction';
import {SubscriptionPermissions} from '../../../../data/models/Subscription/SubscriptionPermission';
import {APP_PERMISSIONS} from '../../../../data/permissions';
import {CreateOrganization} from '../../../../modules/CreateOrganization';

interface Props {
  permissions: string[];
  organization?: OrganizationDetails;
  organizationId?: string;
  storageUsed: number;
  hasSubscription?: boolean;
  subscriptionPermissions?: SubscriptionPermissions;
  usersCount: number;
  isDefaultOrgData: boolean;
  usersMax: number;
}

const DashboardRootUI = React.memo(
  ({
    permissions,
    organizationId,
    organization,
    storageUsed,
    hasSubscription,
    subscriptionPermissions,
    usersCount,
    isDefaultOrgData,
    usersMax,
  }: Props) => {
    const location = useLocation();
    const [navHeight, setNavHeight] = useState(0);

    const [menuIsOpen, setMenuIsOpen] = useState(true);

    return (
      <div className="DashboardRoot">
        <NavBar onHeightCalculated={setNavHeight} />
        <CreateOrganization
          isOpen={isDefaultOrgData}
          onClose={() => {
            //TODO
          }}
        />
        <Container fluid>
          <Row>
            <Col
              lg={menuIsOpen ? 2 : 1}
              className="DashboardRootSideNav d-none d-lg-block"
              style={{height: `calc(100vh - ${navHeight}px)`}}>
              <div className="NavItemsList">
                {organization && (
                  <Tooltip tooltipText={organization.name}>
                    <div
                      className={`Header ${
                        !menuIsOpen ? 'HeaderCollapsed' : ''
                      }`}>
                      {organization.avatar ? (
                        <img src={organization.avatar} />
                      ) : (
                        <div className="OrgAvatarPlaceholder">
                          <p>{organization.name[0].toUpperCase()}</p>
                        </div>
                      )}
                      {menuIsOpen && <span>{organization.name}</span>}
                    </div>
                  </Tooltip>
                )}
                <button
                  onClick={() => setMenuIsOpen(!menuIsOpen)}
                  className={`MenuCollapse ${
                    menuIsOpen ? 'MenuOpen' : 'MenuClosed'
                  }`}>
                  <span className="icon-typeChvron-Down"></span>
                </button>
                {navConfig(organizationId).map(item =>
                  !item.reqireSubscription ||
                  (item.reqireSubscription && hasSubscription) ? (
                    <NavListItem
                      {...item}
                      activeRoute={location.pathname}
                      key={item.title}
                      userPermissions={permissions}
                      collapsed={!menuIsOpen}
                    />
                  ) : null,
                )}
              </div>
              {menuIsOpen &&
                hasSubscription &&
                permissions.includes(APP_PERMISSIONS.ORG_MANAGE_SETTINGS) && (
                  <QuotaLimitUI
                    storageUsed={storageUsed}
                    storageAvailable={subscriptionPermissions?.storageLimit}
                  />
                )}
              {menuIsOpen &&
                hasSubscription &&
                permissions.includes(APP_PERMISSIONS.ORG_MANAGE_SETTINGS) && (
                  <QuotaLimitUI
                    storageUsed={usersCount}
                    storageAvailable={usersMax}
                    isUsersLimit
                  />
                )}
              <div className="Divider" />
              <NavListItem
                {...helpConfig}
                activeRoute={location.pathname}
                key={helpConfig.title}
                userPermissions={permissions}
                collapsed={!menuIsOpen}
              />
              <NavListItem
                {...contactConfig}
                activeRoute={location.pathname}
                key={contactConfig.title}
                userPermissions={permissions}
                collapsed={!menuIsOpen}
              />
            </Col>
            <Col
              lg={menuIsOpen ? 10 : 11}
              xs={12}
              className="DashboardRootContent d-none d-lg-block px-4"
              style={{height: `calc(100vh - ${navHeight}px)`}}>
              <Outlet />
            </Col>
            <Col
              xs={12}
              className="DashboardRootContent d-lg-none d-block px-4"
              style={{height: `calc(100vh - ${navHeight}px)`}}>
              <MobileRestriction />
            </Col>
          </Row>
        </Container>
      </div>
    );
  },
);

export default DashboardRootUI;
