import React from 'react';
import './styles.css';
import {getFirstCapitals} from '../../utils/getFirstCapitals';

interface Props {
  avatarUrl?: string;
  username?: string;
}

const UserAvatar = React.memo(({avatarUrl, username}: Props) => {
  return (
    <div className="UserAvatarContainer">
      {avatarUrl ? (
        <img src={avatarUrl} alt="avatar" />
      ) : username ? (
        <span className="UserAvatarText">{getFirstCapitals(username)}</span>
      ) : (
        <div />
      )}
    </div>
  );
});

export default UserAvatar;
