import {ApiResponse} from '../models/ApiResponse';
import {UserInfo} from '../models/User/UserInfo';
import {apiClient, withAuth} from './baseApiClient';

class UserApi {
  public async getUserInfo(): Promise<ApiResponse<UserInfo>> {
    const headers = await withAuth({});
    return apiClient.get('/user/me', {headers});
  }

  public async editUserInfo(
    name: string,
    preferredLanguage: number,
  ): Promise<ApiResponse<UserInfo>> {
    const headers = await withAuth({});
    return apiClient.post('/user/me', {name, preferredLanguage}, {headers});
  }

  public async uploadUserAvatar(image: File): Promise<ApiResponse<UserInfo>> {
    const headers = await withAuth({});
    const form = new FormData();
    form.append('image', image);
    return apiClient.post(`/user/avatar`, form, {
      headers,
    });
  }

  public async changeUserPassword(
    currentPassword: string,
    newPassword: string,
    confirmNewPassword: string,
  ): Promise<ApiResponse<UserInfo>> {
    const headers = await withAuth({});
    return apiClient.post(
      '/user/password',
      {currentPassword, newPassword, confirmNewPassword},
      {headers},
    );
  }
}

export default UserApi;
