import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  quota: 'Storage Quota',
  usersQuota: 'Number of users',
  mb: 'GB',
  users: 'Users',
};

const welsh = {
  quota: 'Cwota Storio',
  usersQuota: 'Maint y defnyddwyr',
  mb: 'GB',
  users: 'Defnyddwyr.',
};

export const dashboardRootText = getLocalizationBasedOnLang(english, welsh);
