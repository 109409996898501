import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  title: 'Forgot your password?',
  subtitle: 'Enter your registered email address',
  email: 'Email',
  submit: 'Reset password',
  password: 'New Password',
  emailSent:
    'A password reset link has been sent if your account is registered. Please check your inbox',
  goBack: 'Go Back',
  login: 'Login',
  resetSubtitle: 'Please enter a new password',
};

const welsh = {
  title: 'Wedi anghofio eich cyfrinair?',
  subtitle: 'Nodwch eich cyfeiriad e-bost cofrestredig',
  email: 'E-bost',
  submit: 'Ailosod cyfrinair"',
  password: 'Cyfrinair Newydd',
  emailSent:
    "Mae dolen i ailosod cyfrinair wedi cael ei anfon os yw eich cyfrif wedi'i gofrestru. Gwiriwch eich blwch derbyn.",
  goBack: 'Ewch yn ôl',
  login: 'Mewngofnodi',
  resetSubtitle: 'Nodwch gyfrinair newydd, os gwelwch yn dda',
};

export const forgotPasswordText: any = getLocalizationBasedOnLang(
  english,
  welsh,
);
