import {Edge, Node} from 'reactflow';
import {
  LessonContentItem,
  LessonContentList,
} from '../../../data/models/LessonContent/LessonContentList';
import {
  CHALLENGE_BRANCH_QUESTION,
  CHOICE_TEXT_QUESTION,
  LessonContentBase,
} from '../../../data/models/LessonContent/LessonContentBase';
import {
  defaultEdgeOptions,
  defaultNodeOptions,
} from '../../EditorSettings/settings';
import {mapNode} from './nodeMapper';
import {LearnerProgressModel} from '../../../data/models/LessonContent/CreateLearnerLessonModel';

const ENTRY_NODE_ID = 'entry';

const EntryNode: LessonContentItem = {
  data: {
    internalId: ENTRY_NODE_ID,
    ivNodeType: -1,
  },
  parentsIds: [],
  childIds: [],
};

export const buildNodeTree = (
  lessonContent: LessonContentList,
  progress: LearnerProgressModel,
) => {
  const nodes: Node[] = [];
  const edges: Edge[] = [];
  const {contentList} = lessonContent;

  //   nodes.push(getEntryNode(lessonTitle));

  if (!contentList.rootId) {
    return {nodes, edges};
  }

  if (contentList.rootId) {
    const firstNode = contentList.items[contentList.rootId];
    const {nodes: nodesFilled, edges: edgesFilled} = getNodeTreeRecursive(
      firstNode,
      lessonContent,
      progress,
    );
    return {
      nodes: [...nodes, ...nodesFilled],
      edges: [...edges, ...edgesFilled],
    };
  }

  return {
    nodes: [...nodes],
    edges: [...edges],
  };
};

const getNodeTreeRecursive = (
  currentNode: LessonContentItem,
  lessonContent: LessonContentList,
  progress: LearnerProgressModel,
) => {
  const nodes: Node[] = [];
  const edges: Edge[] = [];

  let currentNodeParent = EntryNode;
  if (currentNode?.parentsIds?.length) {
    currentNodeParent =
      lessonContent.contentList.items[currentNode.parentsIds[0]];
  }

  const visited = getNodeIsVisited(progress, currentNode);

  nodes.push(
    getSourceNode(currentNode.data, currentNodeParent.data, visited, progress),
  );
  edges.push(
    getEdge(
      currentNode.data.internalId,
      currentNodeParent.data.internalId,
      visited,
      currentNodeParent.data.ivNodeType,
    ),
  );

  if (currentNode.childIds) {
    currentNode.childIds.forEach(childId => {
      const {nodes: childNodes, edges: childEdges} = getNodeTreeRecursive(
        lessonContent.contentList.items[childId],
        lessonContent,
        progress,
      );
      nodes.push(...childNodes);
      edges.push(...childEdges);
    });
  }

  return {nodes, edges};
};

const getNodeIsVisited = (
  progress: LearnerProgressModel,
  node: LessonContentItem,
) => {
  const visited =
    Object.keys(progress).includes(node.data.internalId) ||
    Object.keys(progress).some(key =>
      progress[key].answers.includes(node.data.internalId),
    );

  if (
    !visited &&
    (node.data.ivNodeType === CHOICE_TEXT_QUESTION ||
      node.data.ivNodeType === CHALLENGE_BRANCH_QUESTION) &&
    node.parentsIds?.length
  ) {
    return Object.keys(progress).includes(node.parentsIds[0]);
  }

  return visited;
};

const getSourceNode = (
  node: LessonContentBase,
  parent: LessonContentBase,
  visited: boolean,
  progress: LearnerProgressModel,
) => ({
  id: node.internalId,
  data: {
    payload: mapNode(node, progress),
    type: node.ivNodeType,
    parent,
    visited,
  },
  type: 'journeyNode',
  ...defaultNodeOptions,
});

const getEdge = (
  target: string,
  source: string,
  visited: boolean,
  parentType?: number,
) => ({
  id: `e-${target}-${source}`,
  source: source,
  target: target,
  ...defaultEdgeOptions(parentType, visited),
});
