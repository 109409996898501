import React from 'react';
import {Link} from 'react-router-dom';
import {appText} from '../../assets/text/appText';
import './styles.css';

interface Props {
  hint: string;
  className?: string;
}

const UpgradeHint = ({hint, className}: Props) => {
  return (
    <div className={className}>
      <div className="UpgradeHintContainer">
        <p>
          {hint}{' '}
          <Link
            to="https://help.tapybl.com/support/tickets/new?ticket_form=upgrade_plan"
            target="_blank">
            {appText.upgrade}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default UpgradeHint;
