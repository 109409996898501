import {makeAutoObservable} from 'mobx';

class LoadingModule {
  public isLoading = false;

  constructor(defaultState = false) {
    makeAutoObservable(this);
    this.isLoading = defaultState;
  }

  startLoading() {
    this.isLoading = true;
  }

  endLoading() {
    this.isLoading = false;
  }
}

export default LoadingModule;
