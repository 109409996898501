import React, {useEffect, useState} from 'react';
import GradebookUI from '../view/CourseGradebookUI';
import {inject, observer} from 'mobx-react';
import CourseGradebookStore from '../store/CourseGradebookStore';
import {useOutletContext, useParams} from 'react-router-dom';
import {
  GradebookGrades,
  GradebookLearner,
  GradebookLesson,
} from '../../../../data/models/Course/Gradebook';
import LearningJourneyStore from '../../../../modules/LearningJourney/store/LearningJourneyStore';
import {LearningJourney} from '../../../../modules/LearningJourney';
import {Course} from '../../../../data/models/Course/Course';

interface Props {
  courseGradebookStore?: CourseGradebookStore;
  learningJourneyStore?: LearningJourneyStore;
}

const PAGE_SIZE = 9;

const CourseGradebookContainer = inject(
  'courseGradebookStore',
  'learningJourneyStore',
)(
  observer(({courseGradebookStore, learningJourneyStore}: Props) => {
    const [search, setSearch] = useState('');
    const {courseId, organizationId} = useParams();
    const [learners, setLearners] = useState<GradebookLearner[]>([]);
    const [lessons, setLessons] = useState<GradebookLesson[]>([]);
    const [grades, setGrades] = useState<GradebookGrades>({});
    const [course, setCourse] = useState<Course>();
    const [pagesCount, setPagesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const context = useOutletContext() as [
      any,
      (value: string) => void,
      Course,
    ];

    useEffect(() => {
      if (organizationId && courseId) {
        courseGradebookStore!
          .getCourse(organizationId, courseId)
          .then(course => {
            setCourse(course);
            context[1](course?.title || '');
          });
        courseGradebookStore!
          .getGradebook(
            organizationId,
            courseId,
            currentPage - 1,
            PAGE_SIZE,
            search,
          )
          .then(res => {
            setLearners(res.learners);
            setLessons(res.lessons);
            setGrades(res.grades);
            setPagesCount(res.pagesCount);
          });
      }
    }, [currentPage, search]);

    const onOpenLearningJourney = (
      learnerId: string,
      lessonId: string,
      courseId: string,
    ) => {
      learningJourneyStore!.openJourney(learnerId, lessonId, courseId);
    };

    return (
      <>
        <GradebookUI
          search={search}
          setSearch={setSearch}
          learners={learners}
          lessons={lessons}
          grades={grades}
          onChangePage={setCurrentPage}
          pagesCount={pagesCount}
          itemsPerPage={PAGE_SIZE}
          currentPage={currentPage}
          onOpenLearningJourney={onOpenLearningJourney}
          courseTitle={course?.title || ''}
          publishedCourse={context[2]}
        />
        <LearningJourney />
      </>
    );
  }),
);

export default CourseGradebookContainer;
