import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  deny: 'Ignore',
  notifications: 'Notifications',
  viewAll: 'View All',
  noNotifications: 'No New Notifications',
  markAsRead: 'Delete',
  viewCourse: 'View course',
  viewWorkspace: 'View Workspace',
};

const welsh = {
  deny: 'Anwybyddu',
  notifications: 'Hysbysiadau',
  viewAll: 'Gweld Pob',
  noNotifications: 'Dim Hysbysiadau Newydd',
  markAsRead: 'Dileu',
  viewCourse: 'Gweld y Cwrs',
  viewWorkspace: 'Gweld y Gweithle',
};

export const notificationsText: any = getLocalizationBasedOnLang(
  english,
  welsh,
);
