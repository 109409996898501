import React from 'react';
import {appText} from '../../../assets/text/appText';
import './styles.css';

interface Props {
  className?: string;
  onClick?: () => void;
}

const FilterButton = React.memo(({className, onClick}: Props) => {
  return (
    <div className={className}>
      <button className="FilterButtonContainer" onClick={onClick}>
        {/* <img src={filterIc} alt="filter" /> */}
        <span className="icon-typeFilter"></span>
        <span>{appText.filter}</span>
      </button>
    </div>
  );
});

export default FilterButton;
