import {makeAutoObservable} from 'mobx';
import GradebookRepository from '../../../data/repositories/GradebookRepository';
import {LearnerJourney} from '../../../data/models/LessonContent/LearnerJourney';

class LearningJourneyStore {
  private gradeebookRepo = new GradebookRepository();
  private learnerJourney?: LearnerJourney = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  public openJourney(learnerId: string, lessonId: string, courseId: string) {
    this.gradeebookRepo
      .getLearningJourney(learnerId, lessonId, courseId)
      .then(res => {
        if (res.success && res.data) {
          this.learnerJourney = res.data;
        }
      });
  }

  public get lessonContent() {
    if (this.learnerJourney) {
      return this.learnerJourney.content;
    }
  }

  public get learnerProgress() {
    if (this.learnerJourney) {
      return this.learnerJourney.progress;
    }
  }

  public get isOpen() {
    return this.learnerJourney !== undefined;
  }

  public closeJourney() {
    this.learnerJourney = undefined;
  }
}

export default LearningJourneyStore;
