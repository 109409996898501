import React from 'react';
import {
  CHECK_VALUE,
  CROSS_VALUE,
  FeaturesTableConf,
} from '../subscriptionPlans';
import '../view/styles.css';
import {selectSubscriptionText} from '../selectSubscriptionText';
import {
  subscriptionCrossIc,
  subscriptionGreenCheck,
} from '../../../../assets/images';
import {SUBSCRIPTION_ENTERPRISE} from '../../config';

interface Props {
  onSelect?: (planId?: number) => void;
}

const FeaturesTable = React.memo(({onSelect}: Props) => {
  const onSelectPlan = (planId: number) => {
    if (!onSelect) return;
    if (planId === SUBSCRIPTION_ENTERPRISE) {
      onSelect();
    } else {
      onSelect(planId);
    }
  };
  return (
    <table className="FeaturesTable">
      <thead>
        <tr className="PricesHeader">
          <td className="MobileTableTitle d-table-cell d-md-none" colSpan={3}>
            {FeaturesTableConf.subheader[0]}
          </td>
        </tr>
        <tr className="PricesSubheader StickyHeader">
          {FeaturesTableConf.subheader.map((item, index) => (
            <td
              key={index}
              rowSpan={index === 0 ? 2 : 1}
              className={index === 0 ? 'TableTitle' : 'Plan'}>
              {item}
            </td>
          ))}
        </tr>
        {onSelect && (
          <tr className="SelectPlan StickyButtons">
            {FeaturesTableConf.buttons.map((item, index) =>
              item ? (
                <td key={index}>
                  <button onClick={() => onSelectPlan(item)}>
                    {item === SUBSCRIPTION_ENTERPRISE
                      ? selectSubscriptionText.requestQuote
                      : selectSubscriptionText.selectPlan}
                  </button>
                </td>
              ) : null,
            )}
          </tr>
        )}
      </thead>
      <tbody>
        {FeaturesTableConf.sections.map((section, index) => (
          <>
            <tr className="SectionHeader" key={index}>
              <td className="FeatureName" colSpan={4}>
                {section.title}
              </td>
            </tr>
            {section.data.map((data, index) => (
              <>
                <tr className="d-table-row d-md-none FeatureNameMobile">
                  <td colSpan={3}>{data[0]}</td>
                </tr>
                <tr key={index} className="FeatureListing">
                  {data.map((item, index) => (
                    <td
                      key={index}
                      className={index === 0 ? 'FeatureName' : ''}>
                      {item === CHECK_VALUE ? (
                        <img src={subscriptionGreenCheck} />
                      ) : item === CROSS_VALUE ? (
                        <img className="CrossValue" src={subscriptionCrossIc} />
                      ) : (
                        <span className={index === 0 ? '' : 'FeatureTextValue'}>
                          {item}
                        </span>
                      )}
                    </td>
                  ))}
                </tr>
              </>
            ))}
          </>
        ))}
      </tbody>
    </table>
  );
});

export default FeaturesTable;
