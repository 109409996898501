import React from 'react';
import Button from '../../../../uiToolkit/Buttons/Button';
import {settingsText} from '../../settingsText';

interface Props {
  onChangePaymentMethod?: () => void;
  onCancelSubscription?: () => void;
}

const ActionsSection = React.memo(
  ({onCancelSubscription, onChangePaymentMethod}: Props) => {
    return (
      <div className="ActionsSectionContainer">
        {(onCancelSubscription || onChangePaymentMethod) && (
          <h3>{settingsText.otherActions}</h3>
        )}
        {onChangePaymentMethod && (
          <>
            <p className="PaymentMethodText">
              {settingsText.changePaymentMethodInfo}
            </p>
            <Button
              title={settingsText.changePaymentMethod}
              uiType="secondary"
              onClick={onChangePaymentMethod}
            />
          </>
        )}
        {onCancelSubscription && (
          <>
            <p className="UnsubscribeText">{settingsText.unscubscribeInfo}</p>
            <Button
              title={settingsText.unsubscribe}
              uiType="action_danger"
              onClick={onCancelSubscription}
            />
          </>
        )}
      </div>
    );
  },
);

export default ActionsSection;
