import React from 'react';
import GradebookTable from '../components/GradebookTable';
import SearchInput from '../../../../uiToolkit/Inputs/SearchInput';
import {
  GradebookGrades,
  GradebookLearner,
  GradebookLesson,
} from '../../../../data/models/Course/Gradebook';
import './styles.css';
import CourseNotPublished from '../../components/CourseNotPublished';
import {courseGradebookText} from '../courseGradebookText';

interface Props {
  search: string;
  setSearch: (search: string) => void;
  lessons: GradebookLesson[];
  learners: GradebookLearner[];
  grades: GradebookGrades;
  pagesCount: number;
  itemsPerPage: number;
  currentPage: number;
  onChangePage: (page: number) => void;
  onOpenLearningJourney: (
    lessonId: string,
    learnerId: string,
    courseId: string,
  ) => void;
  courseTitle: string;
  publishedCourse?: any;
}

const CourseGradebookUI = React.memo(
  ({
    search,
    setSearch,
    learners,
    lessons,
    grades,
    pagesCount,
    itemsPerPage,
    currentPage,
    onChangePage,
    onOpenLearningJourney,
    publishedCourse,
  }: Props) => {
    return (
      <div className="GradebookContainer">
        {publishedCourse ? (
          <>
            <div className="Header">
              <SearchInput value={search} onChange={setSearch} />
            </div>
            {learners.length && lessons.length ? (
              <GradebookTable
                learners={learners}
                lessons={lessons
                  .sort((x, y) => (x.order < y.order ? 1 : -1))
                  .sort((x, y) => (x.folderOrder > y.folderOrder ? 1 : -1))}
                grades={grades}
                pagesCount={pagesCount}
                onChangePage={onChangePage}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onOpenLearningJourney={onOpenLearningJourney}
              />
            ) : (
              <p className="NoLearners">No records</p>
            )}
          </>
        ) : (
          <CourseNotPublished subtitle={courseGradebookText.notPublished} />
        )}
      </div>
    );
  },
);

export default CourseGradebookUI;
