import React from 'react';
import './styles.css';

interface Props {
  title: string;
  icon: any;
  onClick: () => void;
  element?: React.ReactNode;
}

const DashboardCardSlim = ({title, icon, onClick, element}: Props) => {
  return (
    <div className="DashboardSlim" onClick={onClick}>
      {element ? (
        element
      ) : (
        <div className="PageTitleContainer">
          {icon && <span className={`${icon}`}></span>}
          <h4>{title}</h4>
        </div>
      )}
    </div>
  );
};

export default DashboardCardSlim;
