import React from 'react';
import Button from '../Buttons/Button';
import {appText} from '../../assets/text/appText';
import './styles.css';

interface Props {
  onClick: (tier?: number) => void;
  onRenew?: (tier?: number) => void;
  onShowFullList: () => void;
  title: string;
  features: string[];
  buttonText: string;
  price: string;
  amountOfUsers: string;
  usersPerMonth: boolean;
  firstFeatureBold: boolean;
  buttonSecondary?: boolean;
  showDetails: boolean;
  startsAt: string | null;
  green: boolean;
  trialTier?: number;
  id?: number;
  trialText?: string;
  hideTrial?: boolean;
  currentPlan?: boolean;
  expired?: boolean;
}

const SubscriptionPlan = React.memo(
  ({
    title,
    features,
    buttonText,
    onClick,
    onShowFullList,
    price,
    amountOfUsers,
    usersPerMonth,
    firstFeatureBold,
    buttonSecondary,
    showDetails,
    startsAt,
    green,
    trialTier,
    id,
    trialText,
    currentPlan,
    expired,
    onRenew,
  }: Props) => {
    return (
      <div
        className="SubscriptionPlan"
        style={
          green
            ? {
                backgroundColor: '#DCF79A',
                marginTop: '6px',
                marginBottom: '6px',
              }
            : {}
        }>
        <h6 className="Title">{title}</h6>
        <h2 className="Price">{price}</h2>
        {usersPerMonth ? (
          <p className="UsersPerMonth">{appText.usersPerMonth}</p>
        ) : (
          <div className="UsersPerMonthPlaceholder" />
        )}
        <div className="AmountOfUsers">
          <span>{amountOfUsers}</span>
        </div>
        <ul className="Features">
          {features.map((item, index) => (
            <li
              key={index}
              style={
                index === 0 && firstFeatureBold
                  ? {fontWeight: 'bold'}
                  : undefined
              }>
              <span className="icon-typeCheck"></span>
              {item}
            </li>
          ))}
        </ul>
        {currentPlan ? (
          <>
            <p className="CurrentPlan">{appText.currentPlan}</p>
            {expired && (
              <Button
                title={appText.renew}
                onClick={() =>
                  onRenew
                    ? onRenew(id)
                    : () => {
                        //noting
                      }
                }
                className={
                  buttonSecondary
                    ? 'SelectSubButtonSecondry'
                    : 'SelectSubButton'
                }
              />
            )}
          </>
        ) : (
          <Button
            title={buttonText}
            onClick={() => onClick(id)}
            className={
              buttonSecondary ? 'SelectSubButtonSecondry' : 'SelectSubButton'
            }
          />
        )}

        {trialTier !== undefined && (
          <Button
            title={trialText || ''}
            onClick={() => onClick(trialTier)}
            className={'SelectSubTrialButton'}
          />
        )}
        {startsAt && !currentPlan && <p className="StartsAt">{startsAt}</p>}
        {showDetails && (
          <>
            <p className="MoreDetails">{appText.showDetails}</p>
            <p onClick={onShowFullList} className="PseudoLink">
              {appText.clickHere}
            </p>
          </>
        )}
        {!startsAt && <div className="StartsAtPlaceholder" />}
      </div>
    );
  },
);

export default SubscriptionPlan;
