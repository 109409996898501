import React from 'react';
import PageTitle from '../../../../uiToolkit/PageTitle';
import './styles.css';
import {Row, Col, Alert} from 'react-bootstrap';
import {isEmail} from 'react-multi-email';
import {learnersText} from '../../learnersText';
import Button from '../../../../uiToolkit/Buttons/Button';
import {appText} from '../../../../assets/text/appText';
import {getErrorFromCode} from '../../../../data/errorCodes';
import 'react-multi-email/dist/style.css';
import MultiInput from '../../../../uiToolkit/Inputs/MultiInput';

interface Props {
  onSubmit: (learners: string[]) => void;
  onCancel: () => void;
  learners: string[];
  setLearners: (learners: string[]) => void;
  isLoading: boolean;
  failedInvitations: any;
}

const CreateLearnersUI = React.memo(
  ({
    onSubmit,
    onCancel,
    learners,
    setLearners,
    isLoading,
    failedInvitations,
  }: Props) => {
    const buttonEnabled = () => learners.length > 0;

    const onSave = () => {
      onSubmit(learners);
    };

    const getFailedReasons = () => {
      if (failedInvitations) {
        return Object.values(failedInvitations).filter(
          (value, index, array) => {
            return array.indexOf(value) === index;
          },
        );
      }
      return [];
    };

    return (
      <Row>
        <Col lg={8} xs={12}>
          <div className="AddLearnersContainer">
            <div className="HeaderContainer">
              <PageTitle
                title={learnersText.inviteLearnersPageTitle}
                subtitle={learnersText.inviteLearnersPageSubtitle}
              />
            </div>
            <div className="Input">
              <MultiInput
                validator={isEmail}
                value={learners}
                setValue={setLearners}
                heading={learnersText.HeadingInviteLearnerInputField}
                tip={learnersText.EmailInstructions}
              />
            </div>
            <div className="Footer">
              <Button
                onClick={onCancel}
                title={learnersText.CancelButton}
                className={appText.cancel}
                uiType="text"
              />
              <Button
                onClick={onSave}
                title={learnersText.SubmitButton}
                disabled={!buttonEnabled()}
                isLoading={isLoading}
                uiType="action"
              />
            </div>
            <div className="FailedEmailsContainer">
              {getFailedReasons().map((reason: any) => (
                <Alert key={reason.toString()} variant="warning">
                  <p className="FailedEmailReason">
                    {getErrorFromCode(reason)}
                  </p>

                  {Object.keys(failedInvitations)
                    .filter(x => failedInvitations[x] === reason)
                    .map(item => (
                      <p className="FailedEmailError" key={item}>
                        {item}
                      </p>
                    ))}
                </Alert>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    );
  },
);

export default CreateLearnersUI;
