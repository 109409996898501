// USE THIS FOR PLAYER and useLayout effect to compute the width. This has been done in Le

import React from 'react';
import ReactPlayer from 'react-player';
import {OnProgressProps} from 'react-player/base';
import VideoPlayerControls from '../VideoPlayerControls';
import {truncateDurationTo3Decimals} from '../../utils/getTimeTextFromSeconds';
import Lottie from 'lottie-react';
import {loadingLottie} from '../../assets/lottie';
import './styles.css';

type PlayerUiTypes = 'standart' | 'box';

interface Props {
  width: number;
  source?: string;
  startTime?: number;
  endTime?: number;
  onDuration?: (duration: number) => void;
  onVideoProgress?: (time: number) => void;
  noLogo?: boolean;
  uiType?: PlayerUiTypes;
  hideControls?: boolean;
  dontLoop?: boolean;
  height?: number;
  onFinished?: () => void;
}

const SimplePlayerControlled = React.memo(
  ({
    width,
    source,
    startTime,
    endTime,
    onDuration,
    noLogo,
    uiType,
    onVideoProgress,
    hideControls,
    dontLoop,
    height,
    onFinished,
  }: Props) => {
    const [playing, setPlaying] = React.useState(false);
    const [currentTime, setCurrentTime] = React.useState(0);
    const [duration, setDuration] = React.useState(0);
    const playerRef = React.useRef<ReactPlayer>(null);

    const [ready, setReady] = React.useState(false);

    const getBorderRadius = () => {
      switch (uiType) {
        case 'box':
          return '3px';
        default:
          return '20px';
      }
    };

    const onReady = (player: ReactPlayer) => {
      if (playerRef && playerRef.current) {
        const innerPlayer = player.getInternalPlayer();
        innerPlayer.style.borderRadius = getBorderRadius();
      }
      setReady(true);
    };

    const togglePlaying = () => {
      setPlaying(!playing);
    };

    const onProgress = (state: OnProgressProps) => {
      const playedSeconds = truncateDurationTo3Decimals(state.playedSeconds);
      if (startTime !== undefined && endTime !== undefined) {
        if (playedSeconds < startTime || playedSeconds > endTime) {
          playerRef.current?.seekTo(startTime, 'seconds');
          setCurrentTime(startTime);
          if (onVideoProgress) {
            onVideoProgress(startTime);
          }
        } else {
          setCurrentTime(playedSeconds);
          if (onVideoProgress) {
            onVideoProgress(playedSeconds);
          }
        }
      } else {
        setCurrentTime(playedSeconds);
        if (onVideoProgress) {
          onVideoProgress(playedSeconds);
        }
      }
    };

    const onDurationCallback = (seconds: number) => {
      const durationTrunc = truncateDurationTo3Decimals(seconds);
      setDuration(durationTrunc);
      if (onDuration) {
        onDuration(durationTrunc);
      }
    };

    const seekTo = (time: number) => {
      playerRef.current?.seekTo(time, 'seconds');
      setCurrentTime(time);
    };

    return (
      <div className="SimplePlayerControlled">
        <ReactPlayer
          url={source}
          width={width}
          ref={playerRef}
          height={height ? height : width / 1.77}
          playing={playing}
          onReady={onReady}
          onProgress={onProgress}
          onDuration={onDurationCallback}
          progressInterval={1}
          loop={dontLoop ? false : true}
          onError={e => {
            console.log(e);
          }}
          onEnded={onFinished ? onFinished : undefined}
        />
        {!ready && (
          <div
            style={{width: width, height: height ? height : width / 1.77}}
            className="VideoLoading">
            <Lottie
              animationData={loadingLottie}
              style={{
                width: '44px',
                height: '44px',
              }}
            />
          </div>
        )}
        {!hideControls && (
          <VideoPlayerControls
            playing={playing}
            duration={duration}
            togglePlaying={togglePlaying}
            currentTime={currentTime}
            seekTo={seekTo}
            setPlaying={setPlaying}
            noLogo={noLogo}
            slim={uiType === 'box'}
            zIndexOverride={6}
          />
        )}
      </div>
    );
  },
);

export default SimplePlayerControlled;
