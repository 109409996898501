import React from 'react';
import {notFoundIcon} from '../../assets/images';
import './styles.css';
import {notFoundText} from './notFoundText';
import {Link} from 'react-router-dom';

const NotFound = React.memo(() => {
  return (
    <div className="NotFoundContainer">
      <img src={notFoundIcon} />
      <h3>{notFoundText.title}</h3>
      <span>{notFoundText.info}</span>
      <div className="Links">
        <Link to="/home">{notFoundText.home}</Link>
        <Link to="https://help.tapybl.com/support/home" target="_blank">
          {notFoundText.help}
        </Link>
      </div>
    </div>
  );
});

export default NotFound;
