import React from 'react';
import {TooltipAction} from '.';
import Tooltip from '../Tooltip';

interface Props {
  actions: TooltipAction[];
  onAction: (action: () => void) => void;
}

const EditorTooltipContent = React.memo(({actions, onAction}: Props) => {
  return (
    <div className="EditorTooltipContainer">
      <span className="EditorTooltipArrowDetail"></span>
      <div
        className="EditorTooltipContent"
        onClick={e => e.stopPropagation()}
        style={{
          width: `${actions.length * 20 + 24 + (actions.length - 1) * 12}px`,
        }}>
        {actions.map((item, index) =>
          item.tooltip ? (
            <Tooltip
              tooltipText={item.tooltip}
              key={index.toString()}
              greenTooltip>
              <button onClick={() => onAction(item.action)}>
                <img src={item.icon} />
              </button>
            </Tooltip>
          ) : (
            <button
              onClick={() => onAction(item.action)}
              key={index.toString()}>
              <img src={item.icon} />
            </button>
          ),
        )}
      </div>
    </div>
  );
});

export default EditorTooltipContent;
