import {makeAutoObservable} from 'mobx';
import {
  LessonContentBase,
  LessonContentMChoice,
  LessonContentMChoiceAnswer,
  MULTIPLE_CHOICE,
  OVERLAY_VIDEO_TYPE,
  VIDEO_NODE_TYPE,
} from '../../../../data/models/LessonContent/LessonContentBase';
import LessonContentRepository from '../../../../data/repositories/LessonContentRepository';
import QuestionGenerationRepository from '../../../../data/repositories/QuestionGenerationRepository';
import {toast} from 'react-toastify';
import {
  getErrorFromCode,
  internalErrorCodes,
} from '../../../../data/errorCodes';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import {movePunctuationToStart} from '../../../../utils/movePunctuationToStart';
import {ApiResponse} from '../../../../data/models/ApiResponse';
import {LessonContentList} from '../../../../data/models/LessonContent/LessonContentList';

class AddMChoiceWizardStore {
  private lessonContentRepo = new LessonContentRepository();
  private questionGenerationRepo = new QuestionGenerationRepository();

  public showWizard = false;
  private parent?: LessonContentBase = undefined;
  private onCreateCallback?: () => void = undefined;
  private lessonId?: string = undefined;
  private nodeToReplace?: LessonContentBase;
  private childId?: string;

  public isLoading = false;

  public isLoadingAi = false;

  constructor() {
    makeAutoObservable(this);
  }

  public startWizard(
    parent: LessonContentBase,
    lessonId: string,
    onCreateCallback?: () => void,
    nodeToReplace?: LessonContentBase,
    childId?: string,
  ) {
    this.parent = parent;
    this.onCreateCallback = onCreateCallback;
    this.showWizard = true;
    this.lessonId = lessonId;
    this.nodeToReplace = nodeToReplace;
    this.childId = childId;
  }

  public async generateQuestions(
    organizationId: string,
    topic: string,
    lessonContent: LessonContentList,
  ) {
    const videoParent = this.findClosestVideoParent(lessonContent, this.parent);
    if (videoParent && this.lessonId) {
      this.isLoadingAi = true;
      const result = await this.questionGenerationRepo.generateQuestions(
        organizationId,
        videoParent.internalId,
        topic,
        this.lessonId,
      );
      this.isLoadingAi = false;
      if (
        result.success &&
        result.data &&
        result.data.question_answers &&
        result.data.question_answers.length
      ) {
        const finalData = movePunctuationToStart(result.data);
        return finalData;
      } else if (result.errors && result.errors.length) {
        toast.error(getErrorFromCode(result.errors[0]), toastConfig);
      } else {
        toast.error(
          getErrorFromCode(internalErrorCodes.CANT_GENERATE_QUESTIONS),
          toastConfig,
        );
      }
    }
  }

  public async createMultipleChoice(
    organizationId: string,
    lessonId: string,
    question: string,
    answers: LessonContentMChoiceAnswer[],
    gradeWeight: number,
  ) {
    if (this.parent) {
      this.isLoading = true;
      const model = {
        data: {
          ivNodeType: MULTIPLE_CHOICE,
          question,
          answers,
          gradeWeight,
        } as LessonContentMChoice,
        parentsIds: [this.parent.internalId],
      };
      if (this.nodeToReplace) {
        const result = await this.lessonContentRepo.editLessonContent(
          organizationId,
          lessonId,
          {...model.data, internalId: this.nodeToReplace.internalId},
        );
        this.processResult(result);
      } else {
        const result = await this.lessonContentRepo.addLessonContent(
          organizationId,
          lessonId,
          model,
          this.childId,
        );
        this.processResult(result);
      }

      this.isLoading = false;
    }
  }

  public isVisible() {
    return this.showWizard;
  }

  public cancelWizard() {
    this.showWizard = false;
    this.parent = undefined;
    this.onCreateCallback = undefined;
    this.lessonId = undefined;
    this.childId = undefined;
  }

  public clearLoading() {
    this.isLoadingAi = false;
  }

  private processResult = (result: ApiResponse<any>) => {
    if (result.success && this.onCreateCallback) {
      this.onCreateCallback();
      this.cancelWizard();
    }
  };

  public canGenerateQuestions = (lessonContent: LessonContentList) => {
    return (
      this.findClosestVideoParent(lessonContent, this.parent) !== undefined
    );
  };

  private findClosestVideoParent = (
    lessonContent: LessonContentList,
    parentNode?: LessonContentBase,
  ): LessonContentBase | undefined => {
    if (parentNode) {
      if (
        parentNode.ivNodeType === VIDEO_NODE_TYPE ||
        parentNode.ivNodeType === OVERLAY_VIDEO_TYPE
      ) {
        return parentNode;
      }
      const parentListItem =
        lessonContent.contentList.items[parentNode.internalId];
      if (
        parentListItem &&
        parentListItem.parentsIds &&
        parentListItem.parentsIds.length
      ) {
        return this.findClosestVideoParent(
          lessonContent,
          lessonContent.contentList.items[parentListItem.parentsIds[0]]?.data,
        );
      }
    } else {
      return undefined;
    }
  };
}

export default AddMChoiceWizardStore;
