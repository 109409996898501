import LtiApi from '../api/LtiApi';
import {LtiPlatform} from '../models/Lti/LtiIntegration';

class LtiRepository {
  private ltiApi = new LtiApi();

  public generateKeys(organizationId: string) {
    return this.ltiApi.generateKeys(organizationId);
  }

  public getToolData(organizationId: string) {
    return this.ltiApi.getToolData(organizationId);
  }

  public addPlatform(organizationId: string, platform: LtiPlatform) {
    return this.ltiApi.addPlatform(organizationId, platform);
  }

  public getPlatforms(organizationId: string) {
    return this.ltiApi.getPlatforms(organizationId);
  }

  public editPlatform(
    organizationId: string,
    platformId: string,
    platform: LtiPlatform,
  ) {
    return this.ltiApi.editPlatform(organizationId, platformId, platform);
  }

  public async deletePlatform(organizationId: string, platformId: string) {
    return this.ltiApi.deletePlatform(organizationId, platformId);
  }
}

export default LtiRepository;
