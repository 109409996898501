import {Subject} from './LearningStandards';

export interface CourseViewModel {
  title: string;
  description?: string;
  subject: string;
  organizationId?: string;
  image?: File;
  structure: string;
  structureAmount: string;
  isPublic: boolean;
  type: string;
  passingScore?: number;
}

export interface Course {
  id?: string;
  organizationId?: string;
  title: string;
  description: string;
  subjectId: string;
  image?: string;
  structure: number;
  structureAmount: number;
  subject?: Subject;
  passingScore?: number;
  isPublic: boolean;
  type: string;
  publishStatus?: number;
  dateLastPublished?: string;
}

export interface CourseListItem {
  id: string;
  dateCreated: Date;
  dateUpdated: Date;
  organizationId: string;
  title: string;
  description: string;
  subjectTitle: string;
  averageGrade: number;
  structure: number;
  type: number;
  isPublic: boolean;
  structureAmount: number;
  image: string;
  lessonsNumber: number;
  enrollNumber: number;
  completedNumber: number;
  published: boolean;
}

export interface CourseInstructor {
  instructorId: string;
  instructor: {
    userName: string;
    email: string;
  };
}

export const COURSE_NOT_PUBLISHED_STATUS = 0;
export const COURSE_PUBLISHING_STATUS = 1;
export const COURSE_PUBLISHED_STATUS = 2;
