import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  filter: 'Filter',
  categories: 'Categories',
  title: 'Explore & enroll in your next tapybl course!',
  noCourses: 'At the moment there are no public courses',
  clearFilter: 'Clear Filter',
};

const welsh = {
  filter: 'Hidlo',
  categories: 'Categorïau',
  title: 'Archwiliwch a chofrestrwch ar gyfer eich cwrs tapybl nesaf!',
  noCourses: 'Ar hyn o bryd, nid oes unrhyw gyrsiau cyhoeddus',
  clearFilter: "Clirio'r hidlo",
};

export const exploreText: any = getLocalizationBasedOnLang(english, welsh);
