import React from 'react';
import ChoiceImageOptionOptionsUI from '../view/ChallengeImageOptionOptionsUI';
import {LessonContentChallengeBranchImageOption} from '../../../../../data/models/LessonContent/LessonContentBase';
import {lessonEditorText} from '../../../lessonEditorText';
import BaseOptionsContainer, {
  BaseContainerProps,
} from '../../BaseOptionsContainer';

export default React.memo((props: BaseContainerProps) => (
  <BaseOptionsContainer
    {...props}
    deleteMessage={lessonEditorText.areYouSureDeleteChallengeImageOption}>
    {forwardProps => (
      <ChoiceImageOptionOptionsUI
        {...forwardProps}
        entityToEdit={
          forwardProps.entityToEdit as LessonContentChallengeBranchImageOption
        }
      />
    )}
  </BaseOptionsContainer>
));
