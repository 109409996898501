import React from 'react';
import {
  LessonContentChoiceAnswerMapped,
  LessonContentChoiceMapped,
} from '../../../../data/models/LessonContent/LessonConentMapped';
import './styles.css';

interface Props {
  choice: LessonContentChoiceMapped;
  onSelectChoice: (choice: LessonContentChoiceAnswerMapped) => void;
}

const ChoiceOverlay = React.memo(({choice, onSelectChoice}: Props) => {
  return (
    <div className="ChoiceOverlay">
      <p>{choice.question}</p>
      <div className="AnswersContainer">
        {choice.answers.map((item, index) =>
          item.text ? (
            <div
              key={index.toString()}
              className="TextChoice"
              onClick={() => onSelectChoice(item)}>
              <span>{item.text}</span>
            </div>
          ) : item.imageSource ? (
            <img
              key={index.toString()}
              src={item.imageSource}
              onClick={() => onSelectChoice(item)}
            />
          ) : null,
        )}
      </div>
    </div>
  );
});

export default ChoiceOverlay;
