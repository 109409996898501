import React from 'react';
import {OrgLastAccessedModel} from '../../../data/models/Oganization/OrgLastAccessedModel';
import {formatDateTimeTextFormat} from '../../../utils/notificationTimeTextConvertor';
import DataTable from 'react-data-table-component';
import '../../../uiToolkit/DataTable/styles.css';
import TablePagination from '../../../uiToolkit/TablePagination';

interface Props {
  lastAccess: OrgLastAccessedModel[];
  pages: number;
  onPageChange: (page: number) => void;
}

const columns = [
  {
    name: 'Workspace Name',
    selector: (row: OrgLastAccessedModel) => row.name,
    sortable: true,
    sortField: 'file_name',
    cell: (row: OrgLastAccessedModel) => (
      <h5 className="DataTableText" data-tag="allowRowEvents">
        {row.name}
      </h5>
    ),
    grow: 4,
  },
  {
    name: 'Last accessed at',
    sortable: true,
    sortField: 'created_date',
    selector: (row: OrgLastAccessedModel) => row.accessedAt?.toString() || '',
    cell: (row: OrgLastAccessedModel) => (
      <h5 className="DataTableText" data-tag="allowRowEvents">
        {row.accessedAt
          ? formatDateTimeTextFormat(row.accessedAt.toString())
          : 'N/A'}
      </h5>
    ),
    id: 'created_date',
  },
];

const WorkspaceVisitsTable = React.memo(
  ({lastAccess, pages, onPageChange}: Props) => {
    return (
      <DataTable
        className="Table"
        columns={columns}
        data={lastAccess}
        onChangePage={onPageChange}
        pagination
        paginationServer
        paginationPerPage={10}
        paginationComponent={TablePagination}
        paginationTotalRows={pages * 10}
        striped
        theme="custom-stripes"
      />
    );
  },
);

export default WorkspaceVisitsTable;
