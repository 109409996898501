import React from 'react';
import {bookIc} from '../../assets/images';
import {appText} from '../../assets/text/appText';
import Button from '../Buttons/Button';
import './styles.css';

interface Props {
  onCreate: () => void;
  canCreateCourses: boolean;
}

const CoursesEmptyState = ({onCreate, canCreateCourses}: Props) => {
  return (
    <div className="EmptyStateContainer">
      <img src={bookIc} />
      <p className="Title">
        {canCreateCourses
          ? appText.noCoursesTitle
          : appText.instructorNoCoursesTitle}
      </p>
      <p className="Subtitle">
        {canCreateCourses
          ? appText.noCoursesSubtitlte
          : appText.instructorNoCoursesSubtitle}
      </p>
      {canCreateCourses && (
        <Button
          onClick={onCreate}
          title={appText.createCourseAction}
          uiType="secondary"
        />
      )}
    </div>
  );
};

export default CoursesEmptyState;
