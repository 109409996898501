import {ApiResponse} from '../models/ApiResponse';
import {
  GeneratedChallengeBranch,
  GeneratedQuestions,
} from '../models/QuestionGeneration/GeneratedQuestions';
import {apiClient, withAuth} from './baseApiClient';

class QuestionGenerationAPI {
  public async generateQuestions(
    organizationId: string,
    videoNodeId: string,
    topic: string,
    lessonId: string,
  ): Promise<ApiResponse<GeneratedQuestions>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/videoQuestions/${organizationId}/generate`,
      {videoNodeId, topics: [topic], lessonId},
      {headers},
    );
  }

  public async generateChallangeBranchAI(
    organizationId: string,
    lessonId: string,
    videoNodeId: string,
  ): Promise<ApiResponse<GeneratedChallengeBranch>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/videoQuestions/${organizationId}/lesson/${lessonId}/videoNode/${videoNodeId}/generate_masks_and_questions`,
      {},
      {headers},
    );
  }
}

export default QuestionGenerationAPI;
