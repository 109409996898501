import React, {useEffect} from 'react';
import {getErrorFromCode} from '../../data/errorCodes';
import './styles.css';

interface Props {
  errorCodes: string[];
  className?: string;
  clearErrors: () => void;
}

const FADE_OUT_TIMEOUT = 5000;

const ErrorBox = React.memo(({errorCodes, className, clearErrors}: Props) => {
  useEffect(() => {
    if (errorCodes.length > 0) {
      setTimeout(() => {
        clearErrors();
      }, FADE_OUT_TIMEOUT);
    }
  }, [errorCodes.length]);

  const getErrorsText = () => {
    return errorCodes.map((errorCode, index) => {
      if (index + 1 !== errorCodes.length) {
        return getErrorFromCode(errorCode) + ', ';
      } else {
        return getErrorFromCode(errorCode);
      }
    });
  };

  return (
    <div className={className}>
      <div
        className={`ErrorBoxContainer ${
          errorCodes.length === 0 && 'ErrorBoxHidden'
        }`}>
        <p className="ErrorBoxText">{getErrorsText()}</p>
      </div>
    </div>
  );
});

export default ErrorBox;
