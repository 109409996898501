import React from 'react';
import Button from '../Buttons/Button';
import './styles.css';

interface Props {
  heading: string;
  source?: string;
  actionTitle: string;
  action: () => void;
}

const BoxImagePreview = React.memo(
  ({heading, source, actionTitle, action}: Props) => {
    return (
      <div className="BoxImagePreview">
        <p className="Title">{heading}</p>
        <div className="PreviewContainer">
          <img src={source} />
        </div>
        <Button
          title={actionTitle}
          onClick={action}
          uiType="box"
          className="PreviewActionButton"
        />
      </div>
    );
  },
);

export default BoxImagePreview;
