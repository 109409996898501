import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  ModalHeader: 'AI Video Generator',
  text: 'Generate AI Videos',
  nextStep: 'Next Step',
  skipStep: 'Skip step',
  slideIgnoreAudioHeadingText: 'Slides or Pages to Silence',
  slideIgnoreAudioPlaceholderText:
    'Enter slide or page numbers separated by commas, like 1, 2, 4-6, 8',
  slideIgnoreHeadingText: 'Slides or Pages to Exclude',
  slideIgnorePlaceholderText:
    'Enter slide or page numbers separated by commas, like 1, 2, 4-6, 8',
  textIgnoreHeadingText: 'Exclude Text from Audio',
  textIgnorePlaceholder: 'Enter all phrases to exclude, separated by commas',
  videoDurationTitle: 'Choose Your Video Duration ',
  generateImagesTitle: 'Include AI-Generated Images',
  generateVideosTitle: 'Include AI-Generated Videos',
  sliderHeading: 'Content to Image Ratio',
  uploadPPTText: 'Upload your file',
  successMessage:
    'AI Video is being generated. You will be notified once it is ready.',
  uploadPPTSubtitle: 'Accepted formats: pdf, docx, pptx',
  voiceGenderHeading: 'Select the Voice of the Narrator',
  languageDropdownHeading: 'Select the Language of the Narrator',
  dialectDropdownHeading: 'Select the Dialect of the Narrator',
  narrationCustomization: 'Customize Voice Narration',
  slideIgnoreAudioDurationTitle: 'Select the Duration for Slides to Mute',
  slideIgnoreAudioDurationSubtitle:
    'Select how long each muted slide displays to ensure smooth video flow.',
  generateVideo: 'Generate video',
  slideIgnoreAudioDurationWarning:
    'The duration must not exceed a limit of 10 minutes (00:10:00).',
  summaryReady: 'We’re ready to generate your video.',
  summaryInfo:
    'This action cannot be undone. Please note that video generation will take time, depending on the length of your uploaded file and generation choices. You’ll be able to see the final video in your library once it has finished.',
  summary: 'Summary',
  summaryFile: 'File: ',
  summaryExceptions: 'Exceptions: ',
  summaryVideoDuration: 'Video Duration: ',
  summaryMedia: 'AI Media: ',
  summaryNarration: 'Narration: ',
  summaryGenImagesWith: 'Generate images with ',
  summaryRatio: ' ratio',
  summaryNoImages: 'No Images',
  summaryExclude: 'Exclude pages ',
  summaryMute: 'Mute pages ',
  summaryExcludePhrases: 'Exclude phrases',
  summaryFor: ' for ',
  exit: 'Cancel',
  sliderLeft: 'AI Images',
  sliderRight: 'Your content',
  previousStep: 'Previous Step',
  helpTipFile1: 'Upload one file.',
  helpTipFile2: '75 slides limit for pptx',
  helpTipFile3: '75 page limit for pdf or docx',
  helpTipFile4: 'Larger files will result in longer videos',
  helpTipExclude1:
    'If you have page or slide content, or entire sections you’d like to keep from being shown in your final video, let us know here:',
  helpTipExclude2:
    'A) Slides or Pages to Silence: If you have pages of diagrams or image-only slides you’d like shown visually without any narration, list them here.',
  helpTipExclude3:
    'B) Slides or Pages to Exclude: the slide or page numbers to exclude from your generated video content. They will not appear visually, and their content will not be narrated. Think of this as pages you’d like to delete from your document/presentation.',
  helpTipExclude4:
    ' C) Exclude Text from Audio: any text, such as document authors, headers or footers, to exclude from narration. The reader will skip over them in the generated video.',
  helpTipDuration:
    'Select "Short" for minimal narration, "Average" for standard detail, or "Long" for in-depth commentary on each slide. ',
  helpTipDuration2:
    'If your video contains math equations or formulas, it’s best to select "average" or "long" video length when uploading for the best learner experience.',
  helpTipImageGen1:
    'Note: This section is experimental and can give unexpected results. Proceed with caution. You will be able to remove unwanted images using the timeline editor, but doing so will also remove your slide or document content from that portion of the video.',
  helpTipImageGen2:
    'Move the slider to choose the length of time the images are shown relative to the slide or document content. A choice of 100 would only show your content, while 0 would only show AI images to the viewer.',
  helpTipNarration:
    'Select the narrator’s voice, language, and dialect from the available options for your video.',
  helpTipNarration2:
    'Note: Translations impact the audio of your generated video, and not the visual elements.',
  helpTipSummary:
    'This action costs AI credits. Your credits will be updated after video generation.',
  helpTitle: 'tapybl Tips',
};

const welsh = {
  ModalHeader: 'Cynhyrchydd Fideo AI',
  text: 'Cynhyrchwch fideos AI',
  nextStep: 'Cam Nesaf',
  skipStep: 'Can Blaenorol',
  slideIgnoreAudioHeadingText: 'Sleidiau neu Dudalennau i Dawelu',
  slideIgnoreAudioPlaceholderText:
    'Nodwch rifau sleidiau neu dudalennau wedi’u separo gan gommasau, fel 1, 2, 4-6, 8.',
  slideIgnoreHeadingText: 'Sleidiau neu Dudalennau i Eithrio',
  slideIgnorePlaceholderText:
    'Nodwch rifau sleidiau neu dudalennau wedi’u separo gan gommasau, fel 1, 2, 4-6, 8.',
  textIgnoreHeadingText: "Eithrio Testun o'r Sain",
  textIgnorePlaceholder:
    'Nodwch yr holl frawddegau i eithrio, wedi’u separo gan gommasau',
  videoDurationTitle: 'Dewiswch hyd eich fideo',
  generateImagesTitle: 'Ychwanegwch Delweddau a Grëwyd gan AI',
  sliderHeading: 'Cyfran cynnwys i ddelwedd',
  uploadPPTText: 'Ychwanegwch eich ffeil',
  successMessage:
    'Mae fideo AI yn cael ei gynhyrchu. Byddwch yn cael gwybod unwaith y bydd yn barod',
  uploadPPTSubtitle: 'Fformatau a dderbynnir: pdf, docx, pptx',
  voiceGenderHeading: 'Dewiswch Llais y Llenor',
  languageDropdownHeading: 'Dewiswch Iath y LLenor',
  dialectDropdownHeading: 'Dewiswch Ddiwyliant y Llenor',
  narrationCustomization: 'Addasu Adrodd Llais',
  slideIgnoreAudioDurationTitle: 'Dewiswch y Hyd ar gyfer Sleidiau i Daweithio',
  slideIgnoreAudioDurationSubtitle:
    'Dewiswch pa hyd y dylai pob sleid dawel fod ar gael i sicrhau llif fideo llyfn',
  generateVideo: 'Cynhyrchwch fideo',
  slideIgnoreAudioDurationWarning:
    "Ni ddylai'r hyd fod yn fwy na 10 munud (00:10:00)",
  summaryReady: 'Rydym yn barod i gynhyrchu eich fideo',
  summaryInfo:
    'Ni ellir dychwelyd yr weithred hon. Sylwch y gall gynhyrchu fideo gymryd amser, yn dibynnu ar hyd eich ffeil a ychwanegwyd a’r dewisiadau cynhyrchu. Byddwch yn gallu gweld y fideo terfynol yn eich llyfrgell unwaith y bydd wedi gorffen',
  summary: 'Crynodeb',
  summaryFile: 'Ffeil:',
  summaryExceptions: 'Eithriadau:',
  summaryVideoDuration: 'Hyd y Fideo:',
  summaryMedia: 'Cyfryngau AI:',
  summaryNarration: 'Adrodd:',
  summaryGenImagesWith: 'Cynhyrchwch ddelweddau gyda',
  summaryRatio: 'Cyfran',
  summaryNoImages: 'Dim Delweddau',
  summaryExclude: 'Eithrwch tudalennau',
  summaryMute: 'Tawelwch tudalennau',
  summaryExcludePhrases: 'Eithrwch frawddeg',
  summaryFor: 'ar gyfer',
  exit: 'Canslo',
  sliderLeft: 'Delwaddau AI',
  sliderRight: 'Eich cynnwys',
  previousStep: 'Cam Blaenorol',
  helpTipFile1: 'Ychwanegwch 1 ffeil',
  helpTipFile2: 'Terfyn 100 sleid ar gyfer pptx',
  helpTipFile3: 'Terfyn 100 tudalen ar gyfer pdf neu docx',
  helpTipFile4: 'Bydd ffeiliau mwy yn arwain at fideos hwy.',
  helpTipExclude1:
    'Os oes gennych gynnwys tudalen neu sleid, neu rannau cyfan yr hoffech eu cadw rhag cael eu harddangos yn eich fideo terfynol, gadewch i ni wybod yma:',
  helpTipExclude2:
    "A) Sleidiau neu Dudalennau i Dawelu: Os oes gennych dudalennau o ddiagramau neu sleidiau sy'n cynnwys dim ond delweddau yr hoffech eu harddangos yn weledol heb unrhyw adrodd, rhestrwch nhw ym.",
  helpTipExclude3:
    "B) Sleidiau neu Dudalennau i Eithrio: y rhifau sleid neu dudalen i'w heithrio o'ch cynnwys fideo a gynhyrchir. Ni fyddant yn ymddangos yn weledol, ac ni fydd eu cynnwys yn cael ei adrodd. Meddyliwch am hyn fel tudalennau yr hoffech eu dileu o'ch dogfen/pwyslais.",
  helpTipExclude4:
    'Eithrio Testun o’r Sain: unrhyw destun, megis awduron dogfen, pennawdau neu droednodau, i’w heithrio o’r adrodd. Bydd y darllenydd yn mynd heibio iddynt yn y fideo a gynhyrchir.',
  helpTipDuration:
    "Dewiswch 'Fer' ar gyfer adrodd lleiaf, 'Cyfartalog' ar gyfer manylion safonol, neu 'Hir' ar gyfer sylwebaeth fanwl ar bob sleid",
  helpTipImageGen1:
    'Nodyn: Mae’r adran hon yn arbrofol a gall roi canlyniadau annisgwyl. Parhewch gyda gofal. Byddwch yn gallu dileu delweddau annymunol gan ddefnyddio’r golygydd amserlinell, ond bydd gwneud hynny hefyd yn dileu cynnwys eich sleid neu ddogfen o’r rhan honno o’r fideo.',
  helpTipImageGen2:
    "Symudwch y llithrydd i ddewis hyd y cyfnod y bydd y delweddau'n cael eu harddangos mewn perthynas â chynnwys y sleid neu'r ddogfen. Bydd dewis 100 yn dangos yn unig eich cynnwys, tra bydd 0 yn dangos yn unig ddelweddau AI i'r gwylwyr.",
  helpTipNarration:
    'Dewiswch lais y darllenydd, y iaith, a’r naws o’r dewisiadau sydd ar gael ar gyfer eich fideo',
  helpTitle: 'Cynigion tapybl',
  helpTipNarration2:
    'Nodyn: Mae cyfieithiadau yn effeithio ar sain eich fideo a gynhyrchir, ac nid ar yr elfennau gweledol',
  helpTipSummary:
    "Mae'r weithred hon yn costio credydau AI. Bydd eich credydau'n cael eu diweddaru ar ôl cynhyrchu'r fideo.",
};

export const generateAIVideosText: any = getLocalizationBasedOnLang(
  english,
  welsh,
);
