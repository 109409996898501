import {makeAutoObservable} from 'mobx';
import AuthRepository from '../../../data/repositories/AuthRepository';

class CookiePromptStore {
  private authRepo = new AuthRepository();

  constructor() {
    makeAutoObservable(this);
  }

  public async getCookeisEnabled() {
    await this.authRepo.getTestCookie();
    return this.authRepo.testCookiesEnabled();
  }
}

export default CookiePromptStore;
