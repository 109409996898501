import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const englishChallengeBrach = {
  title: 'Add an option for your challenge branch',
  inputTitle: 'Option',
  placeholder: 'Challenge branch option',
  cancel: 'Cancel',
  accept: 'Accept',
};

const welshChallengeBranchText = {
  title: 'Ychwanegwch opsiwn ar gyfer eich cangen her',
  inputTitle: 'Opsiwn',
  placeholder: 'Opsiwm cangen her',
  cancel: 'Canslo',
  accept: 'Derbyn',
};

export const addCHallengeBranchTextOptionText: any = getLocalizationBasedOnLang(
  englishChallengeBrach,
  welshChallengeBranchText,
);
