import {makeAutoObservable} from 'mobx';
import LessonContentRepository from '../../../../data/repositories/LessonContentRepository';
import {
  IMAGE_NODE_TYPE,
  LessonContentBase,
  LessonContentImageSource,
} from '../../../../data/models/LessonContent/LessonContentBase';
import {MediaFile} from '../../../../data/models/LibraryFile/MediaFile';
import LoadingModule from '../../../LoadingModule/LoadingModule';
import {ApiResponse} from '../../../../data/models/ApiResponse';

class AddImageNodeWizardStore {
  private lessonContentRepo = new LessonContentRepository();

  private showWizard = false;
  private parentId?: string;
  private onFinishCallback?: () => void;
  private nodeToReplace?: LessonContentBase;
  private childId?: string;
  private imageFile?: MediaFile;
  private loadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get isOpen() {
    return this.showWizard;
  }

  public get imageSource() {
    return this.imageFile;
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public openWizard(
    parentId: string,
    imageFile: MediaFile,
    onFinishCallback: () => void,
    nodeToReplace?: LessonContentBase,
    childId?: string,
  ) {
    this.imageFile = imageFile;
    this.showWizard = true;
    this.parentId = parentId;
    this.onFinishCallback = onFinishCallback;
    this.nodeToReplace = nodeToReplace;
    this.childId = childId;
  }

  public async createImageNode(
    organizationId: string,
    lessonId: string,
    duration: number,
    title: string,
  ) {
    if (this.imageFile === undefined) return;
    this.loadingModule.startLoading();
    const model = {
      data: {
        ivNodeType: IMAGE_NODE_TYPE,
        contentId: this.imageFile.storageFileName,
        durationInSeconds: duration,
        title,
      } as LessonContentImageSource,
      parentsIds: this.parentId ? [this.parentId] : undefined,
    };
    if (this.nodeToReplace) {
      const result = await this.lessonContentRepo.editLessonContent(
        organizationId,
        lessonId,
        {...model.data, internalId: this.nodeToReplace.internalId},
      );
      this.processResult(result);
    } else {
      const result = await this.lessonContentRepo.addLessonContent(
        organizationId,
        lessonId,
        model,
        this.childId,
      );
      this.processResult(result);
    }

    this.loadingModule.endLoading();
  }

  public closeWizard() {
    this.showWizard = false;
    this.parentId = undefined;
    this.onFinishCallback = undefined;
    this.nodeToReplace = undefined;
    this.childId = undefined;
  }

  private processResult = (result: ApiResponse<any>) => {
    if (result.success && this.onFinishCallback) {
      this.onFinishCallback();
      this.closeWizard();
    }
  };
}

export default AddImageNodeWizardStore;
