import React, {useState} from 'react';
import {
  alertTriangleIc,
  editorInfoIc,
  fiftyPercentHistory,
  gradableIc,
  lessonIc,
  rotateIc,
  supplementalArrowHistory,
} from '../../../assets/images';
import {lessonsText} from '../lessonsText';

const InfoModal = React.memo(() => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="LessonInfoModal">
      <div className="InfoOpener">
        <div
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}>
          <img src={editorInfoIc} />
        </div>
      </div>
      {showTooltip && (
        <div className="InfoBody">
          <div>
            <img src={gradableIc} />
            <span>{lessonsText.infoGradable}</span>
          </div>
          <div>
            <img src={fiftyPercentHistory} />
            <span>{lessonsText.infoPercent}</span>
          </div>
          <div>
            <img src={rotateIc} />
            <span>{lessonsText.retriesInfo}</span>
          </div>
          <div>
            <img src={supplementalArrowHistory} />
            <span>{lessonsText.supplementalArrowInfo}</span>
          </div>
          <div>
            <img src={alertTriangleIc} />
            <span>{lessonsText.alertInfo}</span>
          </div>
          <div>
            <img src={lessonIc} />
            <span>{lessonsText.lessonInfo}</span>
          </div>
        </div>
      )}
    </div>
  );
});

export default InfoModal;
