import React from 'react';
import ChoiceTextOptionOptionsUI from '../view/ChoiceTextOptionOptionsUI';
import {LessonContentChoiceTextOption} from '../../../../../data/models/LessonContent/LessonContentBase';
import {lessonEditorText} from '../../../lessonEditorText';
import BaseOptionsContainer, {
  BaseContainerProps,
} from '../../BaseOptionsContainer';

export default React.memo((props: BaseContainerProps) => (
  <BaseOptionsContainer
    {...props}
    deleteMessage={lessonEditorText.areYouSureDeleteChoiceTextOption}>
    {forwardProps => (
      <ChoiceTextOptionOptionsUI
        {...forwardProps}
        entityToEdit={
          forwardProps.entityToEdit as LessonContentChoiceTextOption
        }
      />
    )}
  </BaseOptionsContainer>
));
