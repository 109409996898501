import React, {useEffect, useRef, useState} from 'react';
import {fileUploadText} from '../fileUploadText';
import TipBox from '../../../uiToolkit/TipBox';
import './styles.css';
import Dropdown from '../../../uiToolkit/Inputs/Dropdown';
import {
  FILE_FHD,
  FILE_HD,
  FILE_SD_OPTION,
  FILE_UHD,
} from '../../../data/models/LibraryFile/MediaFile';
import {getVideoDimentions} from '../../../utils/getVideoDimentions';
import Button from '../../../uiToolkit/Buttons/Button';
import {loadingLottie} from '../../../assets/lottie';
import Lottie from 'lottie-react';

interface Props {
  file: File;
  onFileUpload: (duration: number, targetRes: number) => void;
  isLoading: boolean;
  nonVideoLoading: boolean;
  maxAllowedRes: number;
}

const HD_HEIGHT = 720;
const FHD_HEIGHT = 1080;
const UHD_HEIGHT = 2160;

const FileUploadModalBodyUI = React.memo(
  ({file, onFileUpload, isLoading, nonVideoLoading, maxAllowedRes}: Props) => {
    const [duration, setDuration] = useState(0);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [targetRes, setTargetRes] = useState('0');

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const videoEl = useRef<HTMLVideoElement>(null);

    useEffect(() => {
      if (file.type.includes('video')) {
        getVideoDimentions(file).then(res => {
          setWidth(res.width);
          setHeight(res.height);
        });
      }
    }, [file]);

    const handleLoadedMetadata = () => {
      const video = videoEl.current;
      if (!video) return;
      if (video.duration && !duration) {
        setDuration(Math.floor(video.duration));
      }
    };

    const getDropdownOptions = () => {
      const result = [FILE_SD_OPTION];
      if (height > HD_HEIGHT && maxAllowedRes >= parseInt(FILE_HD.key, 10)) {
        result.push(FILE_HD);
      }
      if (height > FHD_HEIGHT && maxAllowedRes >= parseInt(FILE_FHD.key, 10)) {
        result.push(FILE_FHD);
      }
      if (height > UHD_HEIGHT && maxAllowedRes >= parseInt(FILE_UHD.key, 10)) {
        result.push(FILE_UHD);
      }
      return result;
    };

    const onUpload = () => {
      onFileUpload(duration, parseInt(targetRes, 10));
    };

    return nonVideoLoading ? (
      <div className="d-flex flex-row justify-content-center">
        <Lottie
          animationData={loadingLottie}
          loop={true}
          style={{
            width: '40px',
            height: '40px',
            marginBottom: '10px',
          }}
        />
      </div>
    ) : (
      <div className="FileUploadModalBody">
        <div>
          <h6 className="SummaryText">{fileUploadText.summary}</h6>
          <div className="d-flex flex-row align-items-center">
            <video
              src={URL.createObjectURL(file)}
              ref={videoEl}
              onLoadedMetadata={handleLoadedMetadata}
            />
            <p className="FileTitle">{file?.name}</p>
          </div>
          <p className="FileTitle">
            {fileUploadText.resolution}
            {width}x{height}
          </p>
          <Dropdown
            heading={fileUploadText.selectHeader}
            options={getDropdownOptions()}
            selectedOption={targetRes}
            setSelectedOption={setTargetRes}
            onDropdownVisibilityToggle={setDropdownOpen}
            type="box"
          />
          <Button
            title={fileUploadText.upload}
            onClick={onUpload}
            className="UploadButton"
            isLoading={isLoading}
          />
          {dropdownOpen && <div className="DropdownPadding" />}
        </div>
        <div className="FileUploadTip">
          <TipBox tip={fileUploadText.tip} />
        </div>
      </div>
    );
  },
);

export default FileUploadModalBodyUI;
