import React from 'react';
import MChoiceOptionsUI from '../view/MChoiceOptionsUI';
import {LessonContentMChoice} from '../../../../../data/models/LessonContent/LessonContentBase';
import {lessonEditorText} from '../../../lessonEditorText';
import BaseOptionsContainer, {
  BaseContainerProps,
} from '../../BaseOptionsContainer';

export default React.memo((props: BaseContainerProps) => (
  <BaseOptionsContainer
    {...props}
    deleteMessage={lessonEditorText.areYouSureDeleteMChoice}
    softDeleteMessage={lessonEditorText.areYouSureDeleteMChoiceSoft}>
    {forwardProps => (
      <MChoiceOptionsUI
        {...forwardProps}
        entityToEdit={forwardProps.entityToEdit as LessonContentMChoice}
      />
    )}
  </BaseOptionsContainer>
));
