import {inject, observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import CookiePromptUI from '../view/CookiePromptUI';
import CookiePromptStore from '../store/CookiePromptStore';

interface Props {
  cookiePromptStore?: CookiePromptStore;
}

const CookiePromptContainer = inject('cookiePromptStore')(
  observer(({cookiePromptStore}: Props) => {
    const [thirdPartyEnabled, setThirdPartyEnabled] = useState(true);

    useEffect(() => {
      cookiePromptStore!.getCookeisEnabled().then(res => {
        if (res.success && res.data && !res.data.success) {
          setThirdPartyEnabled(false);
        }
      });
    }, []);

    const onClose = () => {
      setThirdPartyEnabled(true);
    };

    return !thirdPartyEnabled ? <CookiePromptUI onClose={onClose} /> : null;
  }),
);

export default CookiePromptContainer;
