import React from 'react';
import {TapyblPlayer} from '../../../../modules/TapyblPlayer';
import {LearnerProgressModel} from '../../../../data/models/LessonContent/CreateLearnerLessonModel';
import {LessonContentList} from '../../../../data/models/LessonContent/LessonContentList';

interface Props {
  isLoading: boolean;
  lessonContent: LessonContentList;
  onFinish: () => void;
  passingScore: number;
  isGradable: boolean;
  onFinishLesson: (progress: LearnerProgressModel) => void;
  navigateToLesson: (lessonId: string) => void;
}

const ScormPlayerUI = React.memo(
  ({
    lessonContent,
    onFinish,
    passingScore,
    isGradable,
    onFinishLesson,
    navigateToLesson,
    isLoading,
  }: Props) => {
    return (
      <div className="LTIPlayerContainer">
        <TapyblPlayer
          preview={false}
          isLoading={isLoading}
          lessonContent={lessonContent}
          onCourseHome={onFinish}
          onContinue={onFinish}
          passingScore={passingScore}
          isGradable={isGradable}
          nextLessonIsTheSame={false}
          onFinishedLesson={onFinishLesson}
          navigateToLesson={navigateToLesson}
          skipFinishButtons
          showScormFinish
          fitHeight
        />
      </div>
    );
  },
);

export default ScormPlayerUI;
