import React from 'react';
import ChallengeTextOptionOptionsUI from '../view/ChallengeTextOptionOptionsUI';
import {LessonContentChallengeBranchTextOption} from '../../../../../data/models/LessonContent/LessonContentBase';
import {lessonEditorText} from '../../../lessonEditorText';
import BaseOptionsContainer, {
  BaseContainerProps,
} from '../../BaseOptionsContainer';

export default React.memo((props: BaseContainerProps) => (
  <BaseOptionsContainer
    {...props}
    deleteMessage={lessonEditorText.areYouSureDeleteChoiceTextOption}>
    {forwardProps => (
      <ChallengeTextOptionOptionsUI
        {...forwardProps}
        entityToEdit={
          forwardProps.entityToEdit as LessonContentChallengeBranchTextOption
        }
      />
    )}
  </BaseOptionsContainer>
));
