import React from 'react';
import {CourseAccessGate} from '../../modules/CourseAccessGate';
import FoldersContainer from './container/FoldersContainer';

const Folders = () => {
  return (
    <CourseAccessGate>
      <FoldersContainer />
    </CourseAccessGate>
  );
};

export {Folders};
