import React, {useState} from 'react';
import ModalContainer from '../../../../uiToolkit/ModalContainer';
import {addChallengeQuestionText} from '../addChallengeQuestionText';
import './styles.css';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';
import Button from '../../../../uiToolkit/Buttons/Button';
import Dropdown from '../../../../uiToolkit/Inputs/Dropdown';
import {GradeWeights} from '../../../../data/models/LessonContent/GradeWeights';

interface Props {
  onClose: () => void;
  isLoading?: boolean;
  createQuestion: (question: string, gradeWeight: number) => void;
}

const AddChallengeBranchQuestionUI = React.memo(
  ({onClose, isLoading, createQuestion}: Props) => {
    const [question, setQuestion] = useState('');
    const [gradeWeight, setGradeWeight] = useState('1');

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const onSave = () => {
      createQuestion(question, parseInt(gradeWeight, 10));
    };

    return (
      <ModalContainer onClose={onClose}>
        <div className="AddChallengeQuestionWizardContainer">
          <p className="Header">{addChallengeQuestionText.title}</p>
          <TextInput
            value={question}
            onChange={setQuestion}
            heading={addChallengeQuestionText.inputTitle}
            placeholder={addChallengeQuestionText.placeholder}
            maxCharacters={150}
            isMandatory
          />
          <Dropdown
            heading={addChallengeQuestionText.questionWeight}
            options={GradeWeights}
            setSelectedOption={setGradeWeight}
            isMandatory
            selectedOption={gradeWeight}
            onDropdownVisibilityToggle={setDropdownOpen}
          />
          <div className="Footer">
            <Button
              title={addChallengeQuestionText.cancel}
              onClick={onClose}
              uiType="text"
            />
            <Button
              title={addChallengeQuestionText.accept}
              onClick={onSave}
              isLoading={isLoading}
              uiType="action"
              disabled={!question.length}
            />
          </div>
          {dropdownOpen && <div className="DropdownWhitespace" />}
        </div>
      </ModalContainer>
    );
  },
);

export default AddChallengeBranchQuestionUI;
