import UserApi from '../api/UserAPI';

class UserRepository {
  private userApi = new UserApi();

  public async getUserInfo() {
    return await this.userApi.getUserInfo();
  }

  public async editUserInfo(fullName: string, preferredLanguage: number) {
    return await this.userApi.editUserInfo(fullName, preferredLanguage);
  }

  public async uploadUserAvatar(image: File) {
    return await this.userApi.uploadUserAvatar(image);
  }

  public async changeUserPassword(
    oldPassword: string,
    newPassword: string,
    confirmPassword: string,
  ) {
    return await this.userApi.changeUserPassword(
      oldPassword,
      newPassword,
      confirmPassword,
    );
  }
}
export default UserRepository;
