import {ApiResponse} from '../models/ApiResponse';
import {EditStaffMemberModel} from '../models/Oganization/EditStaffMemberModel';
import {InviteOrgStaffModel} from '../models/Oganization/InviteOrgStaffModel';
import {StaffRecord} from '../models/Oganization/StaffRecord';
import {apiClient, withAuth} from './baseApiClient';

class OrganizationStaffApi {
  public async getStaffRecords(
    organizationId: string,
  ): Promise<ApiResponse<StaffRecord[]>> {
    const headers = await withAuth({});
    return apiClient.get(`/organizationStaff/${organizationId}/list`, {
      headers,
    });
  }

  public async deleteStaffMember(
    organizationId: string,
    memberId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.delete(
      `/organizationStaff/${organizationId}/member/${memberId}`,
      {
        headers,
      },
    );
  }

  public async getStaffRecord(
    organizationId: string,
    memberId: string,
  ): Promise<ApiResponse<StaffRecord>> {
    const headers = await withAuth({});
    return apiClient.get(
      `/organizationStaff/${organizationId}/member/${memberId}`,
      {
        headers,
      },
    );
  }

  public async editStaffMember(
    organizationId: string,
    model: EditStaffMemberModel,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.put(`/organizationStaff/${organizationId}/edit`, model, {
      headers,
    });
  }

  public async activateStaffMember(
    organizationId: string,
    memberId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/organizationStaff/${organizationId}/activate/${memberId}`,
      {},
      {
        headers,
      },
    );
  }

  public async inviteStaffUser(
    organizationId: string,
    model: InviteOrgStaffModel,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/organizationInvitation/${organizationId}/invite_staff_member`,
      model,
      {headers},
    );
  }

  public async deleteInvitation(
    organizationId: string,
    invitationId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.delete(
      `/organizationInvitation/${organizationId}/invitation/${invitationId}`,
      {headers},
    );
  }

  public async getInstructors(
    organizationId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.get(`/OrganizationStaff/${organizationId}/list`, {
      headers,
    });
  }

  public async saveInstructors(
    organizationStaffId: string,
    organizationId: string,
    courseId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/Course/${organizationId}/${courseId}/instructor/add/${organizationStaffId}`,
      {},
      {headers},
    );
  }

  public async removeInstructor(
    organizationStaffId: string,
    organizationId: string,
    courseId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/Course/${organizationId}/${courseId}/instructor/delete/${organizationStaffId}`,
      {},
      {headers},
    );
  }

  public async getInstructorCandidates(
    organizationId: string,
    courseId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.get(
      `/course/${organizationId}/${courseId}/instructor_candidates`,
      {headers},
    );
  }
}

export default OrganizationStaffApi;
