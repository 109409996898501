import React from 'react';
import './styles.css';
import {videoSourcesText} from '../../videoSourcesText';
import {libraryTitleIcon} from '../../../../assets/images';
import UploadVideoButton from '../../../../uiToolkit/Inputs/UploadVideoButton';
import {Col, Row} from 'react-bootstrap';
import PageTitle from '../../../../uiToolkit/PageTitle';
import {
  MEDIA_FILES_ALL,
  VimeoMediaFile,
} from '../../../../data/models/LibraryFile/MediaFile';
import VimeoFilesTable from '../../Root/components/VimeoFilesTable';
import Button from '../../../../uiToolkit/Buttons/Button';
import {TapyblLibrary} from '../../TapyblFiles';
import {AiFiles} from '../../AIFiles';

interface Props {
  onUpload: (video: File) => void;
  isLoading: boolean;
  storageLimit: number;
  vimeoVideos: VimeoMediaFile[];
  onGenerateAiButtonClicked: () => void;
  organizationId: string;
}

const LibraryUI = React.memo(
  ({
    onUpload,
    isLoading,
    storageLimit,
    vimeoVideos,
    onGenerateAiButtonClicked,
  }: Props) => {
    return (
      <div className="VideoSourcesContainer">
        <div className="HeaderContainer">
          <PageTitle title={videoSourcesText.title} icon={libraryTitleIcon} />
          <div className="SearchContainer">
            <div className="UploadContainer">
              <UploadVideoButton
                isLoading={isLoading}
                onVideoSelected={onUpload}
                type={MEDIA_FILES_ALL}
                maxFileSize={storageLimit}
              />
            </div>
            <div className="GenerateAiButtonContainer">
              <Button
                title={videoSourcesText.generateWithAI}
                onClick={() => {
                  onGenerateAiButtonClicked();
                }}
                uiType="action"
              />
            </div>
          </div>
        </div>
        <TapyblLibrary />
        <AiFiles />
        {vimeoVideos.length ? (
          <>
            <h5 className="AllFilsTitle">{videoSourcesText.vimeoFiles}</h5>
            <Row>
              <Col lg={12} xs={12}>
                <VimeoFilesTable
                  sources={vimeoVideos}
                  onPageChange={() => {
                    //TODO
                  }}
                  pages={1}
                />
              </Col>
            </Row>
          </>
        ) : null}
      </div>
    );
  },
);

export default LibraryUI;
