import React from 'react';
import './styles.css';
import {desktopIc} from '../../assets/images';
import {mobileRestrictionText} from './mobileRestrictionText';

const MobileRestriction = React.memo(() => {
  return (
    <div className="MobileRestrictionContainer">
      <img src={desktopIc} />
      <h2>{mobileRestrictionText.title}</h2>
      <span>{mobileRestrictionText.info}</span>
    </div>
  );
});

export default MobileRestriction;
