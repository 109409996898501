import React from 'react';
import {foldersText} from '../foldersText';
import TextInput from '../../../uiToolkit/Inputs/TextInput';
import {Folder} from '../../../data/models/Course/Folder';
import {getStructureFromKeySingular} from '../../../data/staticValues/courseStructure';
import TextAreaInput from '../../../uiToolkit/Inputs/TextAreaInput';
import BoxImagePickerInput from '../../../uiToolkit/Inputs/BoxImagePickerInput';
import EditorOptionsFooter from '../../../uiToolkit/Editor/EditorOptionsFooter';

interface Props {
  entityToEdit?: Folder;
  structure: number;
  onDelete: (folder: Folder) => void;
  onEditFolder: (field: string, value: any) => void;
  saveChanges: () => void;
  saveEnabled: boolean;
  isLoading: boolean;
  onDiscardChanges: () => void;
  contentForTab: string;
  optionsForType: string;
}

const FolderOptions = React.memo(
  ({
    entityToEdit,
    structure,
    onDelete,
    onEditFolder,
    saveChanges,
    saveEnabled,
    isLoading,
  }: Props) => {
    const deleteFolder = () => {
      if (entityToEdit) {
        onDelete(entityToEdit);
      }
    };
    return (
      <div className="FolderOptions">
        <TextInput
          value={entityToEdit?.name || ''}
          onChange={(value: string) => onEditFolder('name', value)}
          placeholder={foldersText.folderTitle}
          heading={foldersText.folderTitle}
          maxCharacters={40}
          isMandatory={true}
          uiType="box"
        />
        <TextAreaInput
          className="OptionsSection"
          value={entityToEdit?.description || ''}
          onChange={(value: string) => onEditFolder('description', value)}
          heading={foldersText.description}
          placeholder={foldersText.descriptionPlaceholder(
            getStructureFromKeySingular(structure.toString()),
          )}
          uiType="box"
        />
        <BoxImagePickerInput
          onChange={(value?: File) => onEditFolder('newImage', value)}
          image={entityToEdit?.newImage}
          existingImage={entityToEdit?.image}
          className="OptionsSection"
        />

        <EditorOptionsFooter
          saveEnabled={saveEnabled && entityToEdit?.name.trim() !== ''}
          onSave={saveChanges}
          isLoading={isLoading}
          deleteTitle={foldersText.deleteTitle(
            getStructureFromKeySingular(structure.toString()),
          )}
          onDelete={deleteFolder}
        />
      </div>
    );
  },
);

export default FolderOptions;
