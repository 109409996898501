import React, {useEffect, useState} from 'react';
import {LessonContentChallengeBranchQuestion} from '../../../../../data/models/LessonContent/LessonContentBase';
import TextInput from '../../../../../uiToolkit/Inputs/TextInput';
import {lessonEditorText} from '../../../lessonEditorText';
import EditorOptionsFooter from '../../../../../uiToolkit/Editor/EditorOptionsFooter';
import Dropdown from '../../../../../uiToolkit/Inputs/Dropdown';
import {GradeWeights} from '../../../../../data/models/LessonContent/GradeWeights';
import {BaseContainerForwardProps} from '../../BaseOptionsContainer';

interface Props extends BaseContainerForwardProps {
  entityToEdit?: LessonContentChallengeBranchQuestion;
}

const ChallengeQuestionOptionsUI = React.memo(
  ({entityToEdit, isLoading, onEdit, onDelete, isPublished}: Props) => {
    const [question, setQuestion] = useState('');
    const [gradeWeight, setGradeWeight] = useState('1');

    useEffect(() => {
      setQuestion(entityToEdit?.question || '');
      setGradeWeight(entityToEdit?.gradeWeight.toString() || '1');
    }, [entityToEdit]);

    const changesWasMade = () =>
      entityToEdit?.question !== question ||
      entityToEdit?.gradeWeight !== parseInt(gradeWeight, 10);

    const isValid = () => question.trim() !== '';

    const onSave = () => {
      if (isValid()) {
        onEdit({
          ...entityToEdit,
          question: question,
          gradeWeight: parseInt(gradeWeight, 10),
        } as LessonContentChallengeBranchQuestion);
      }
    };

    return (
      <div className="ChallengeBranchOptionsContainer">
        <TextInput
          heading={lessonEditorText.question}
          value={question}
          onChange={setQuestion}
          placeholder={lessonEditorText.question}
          fieldError={question.trim() === ''}
          maxCharacters={150}
          isMandatory={true}
          uiType="box"
        />
        <Dropdown
          options={GradeWeights}
          setSelectedOption={setGradeWeight}
          selectedOption={gradeWeight}
          heading={lessonEditorText.gradeWeight}
          type="box"
        />
        <EditorOptionsFooter
          onSave={onSave}
          saveEnabled={changesWasMade() && isValid()}
          onDelete={onDelete}
          isLoading={isLoading}
          deleteDisabled={isPublished}
        />
      </div>
    );
  },
);

export default ChallengeQuestionOptionsUI;
