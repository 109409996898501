import React, {useEffect, useState} from 'react';
import LibraryUI from '../view/LibraryUI';
import {inject, observer} from 'mobx-react';
import VideoSourcesStore from '../store/VideoSourcesStore';
import {useParams} from 'react-router-dom';
import {VimeoMediaFile} from '../../../../data/models/LibraryFile/MediaFile';
import NavigationStore from '../../../../navigation/store/NavigationStore';
import {GenerateAIVideos} from '../../../../modules/GenerateAIVideos';
import GenerateAIVideosStore from '../../../../modules/GenerateAIVideos/store/GenerateAIVideosStore';
import TapyblLibraryStore from '../../TapyblFiles/store/TapyblLibraryStore';
import AIFilesStore from '../../AIFiles/store/AiFilesStore';
import UploadFileLibraryModal from '../../UploadFileLibraryModal';

interface Props {
  videoSourcesStore?: VideoSourcesStore;
  navigationStore?: NavigationStore;
  generateAIVideosStore?: GenerateAIVideosStore;
  tapyblLibraryStore?: TapyblLibraryStore;
  aiFilesStore?: AIFilesStore;
}

const LibraryContanier = inject(
  'videoSourcesStore',
  'navigationStore',
  'generateAIVideosStore',
  'tapyblLibraryStore',
  'aiFilesStore',
)(
  observer(
    ({
      videoSourcesStore,
      navigationStore,
      generateAIVideosStore,
      tapyblLibraryStore,
      aiFilesStore,
    }: Props) => {
      const [vimeoVideos, setVimeoVideos] = useState<VimeoMediaFile[]>([]);
      const [uploadedFile, setUploadedFile] = useState<File>();
      const {organizationId} = useParams();

      useEffect(() => {
        if (organizationId) {
          videoSourcesStore!
            .getVimeoVideos(organizationId, 1, 10)
            .then(setVimeoVideos);
        }
      }, [videoSourcesStore]);

      const onUpload = (video: File) => {
        setUploadedFile(video);
      };

      const refetchFiles = () => {
        if (organizationId) {
          tapyblLibraryStore!.fetchRecentFiles(organizationId);
          tapyblLibraryStore!.fetchFiles(organizationId);
          navigationStore!.getOrganizationDetails(organizationId);
        }
      };

      const onCloseUpload = () => {
        if (organizationId) {
          setUploadedFile(undefined);
          refetchFiles();
        }
      };

      const onGenerateAIButton = () => {
        generateAIVideosStore?.startWizard(() => {
          if (organizationId) {
            aiFilesStore!.fetchFiles(organizationId);
          }
        });
      };

      return (
        <>
          <LibraryUI
            isLoading={videoSourcesStore!.isLoading}
            onUpload={onUpload}
            storageLimit={navigationStore!.getStorageUsed()}
            vimeoVideos={vimeoVideos}
            onGenerateAiButtonClicked={onGenerateAIButton}
            organizationId={organizationId || ''}
          />
          <GenerateAIVideos />
          {uploadedFile && (
            <UploadFileLibraryModal
              file={uploadedFile}
              onClose={onCloseUpload}
            />
          )}
        </>
      );
    },
  ),
);

export default LibraryContanier;
