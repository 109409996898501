export const adminPanelText = {
  title: 'General Stats',
  activeUsers: 'Active Users: ',
  workspacesAmount: 'Workspaces Amount: ',
  coursesAmount: 'Courses Amount: ',
  publicCoursesAmount: 'Public Courses Amount: ',
  logout: 'Logout',
  workspaceVisits: 'Workspace Visits',
  recentSignups: 'Recent Sign-Ups',
};
