import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

export const navConfig = [
  {
    title: getLocalizationBasedOnLang('Profile', 'Proffil'),
    route: `/profile/profile_settings`,
  },
  {
    title: getLocalizationBasedOnLang('Security', 'Diogelwch'),
    route: `/profile/profile_security`,
  },
  {
    title: getLocalizationBasedOnLang('Notifications', 'Hysbysiadau'),
    route: `/profile/notifications`,
  },
];
