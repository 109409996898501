import React from 'react';
import {CourseAccessGate} from '../../modules/CourseAccessGate';
import LessonEditorContainer from './container/LessonEditorContainer';

const LessonEditor = () => {
  return (
    <CourseAccessGate>
      <LessonEditorContainer />
    </CourseAccessGate>
  );
};

export {LessonEditor};
