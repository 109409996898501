import React from 'react';

interface Props {
  answer: string;
  selected: boolean;
  onClick: (answer: string) => void;
}

const AnswerBox = React.memo(({answer, selected, onClick}: Props) => {
  return (
    <div onClick={() => onClick(answer)} className="AnswerBox">
      <div className="Checkbox">
        {selected ? <div className="Checked" /> : <div />}
      </div>
      <span>{answer}</span>
    </div>
  );
});

export default AnswerBox;
