import React, {useState} from 'react';
import ModalContainer from '../../../../uiToolkit/ModalContainer';
import {addCHallengeBranchTextOptionText} from '../addChallengeBranchTextOptionText';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';
import Button from '../../../../uiToolkit/Buttons/Button';

interface Props {
  onClose: () => void;
  onCreateOption: (answer: string) => void;
  isLoading?: boolean;
}

const AddChallengeTextOptionUI = React.memo(
  ({onClose, onCreateOption, isLoading}: Props) => {
    const [answer, setAnswer] = useState('');
    const onSave = () => {
      onCreateOption(answer);
    };

    return (
      <ModalContainer onClose={onClose}>
        <div className="AddChallengeQuestionWizardContainer">
          <p className="Header">{addCHallengeBranchTextOptionText.title}</p>
          <TextInput
            value={answer}
            onChange={setAnswer}
            heading={addCHallengeBranchTextOptionText.inputTitle}
            placeholder={addCHallengeBranchTextOptionText.placeholder}
            maxCharacters={150}
            isMandatory
          />
          <div className="Footer">
            <Button
              title={addCHallengeBranchTextOptionText.cancel}
              onClick={onClose}
              uiType="text"
            />
            <Button
              title={addCHallengeBranchTextOptionText.accept}
              onClick={onSave}
              isLoading={isLoading}
              uiType="action"
              disabled={!answer.length}
            />
          </div>
        </div>
      </ModalContainer>
    );
  },
);

export default AddChallengeTextOptionUI;
