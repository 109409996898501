import React from 'react';
import NavBar from '../../../uiToolkit/NavBar';
import {Accordion, Col, Container, Row} from 'react-bootstrap';
import {UserInfo} from '../../../data/models/User/UserInfo';
import {courseHomeText} from '../courseHomeText';
import './styles.css';
import {
  CoursePresentationModel,
  CoursePresentationOrganization,
} from '../../../data/models/Course/CoursePresentation';
import Breadcrumbs from '../../../uiToolkit/Breadcrumbs';
import {
  checkGreenIcon,
  checkSuccessIc,
  courseActionsLessonsIc,
  courseActionsTimeIc,
  courseDividerIc,
  courseTimeIc,
  dividerWhiteIc,
  lessonListPlayButtonIc,
} from '../../../assets/images';
import {
  getMinutesFromSeconds,
  getTimeTextFromSeconds,
} from '../../../utils/getTimeTextFromSeconds';
import {LearnerCourseProgress} from '../../../data/models/Course/LearnerCourseProgress';
import Button from '../../../uiToolkit/Buttons/Button';
import OrganizationBadge from '../../../uiToolkit/OrganizationBadge';

interface Props {
  userInfo?: UserInfo;
  course?: CoursePresentationModel;
  organization?: CoursePresentationOrganization;
  courseLink: string;
  onLessonClick: (lessonId: string) => void;
  courseProgress?: LearnerCourseProgress;
  onContinue: (lessonId: string) => void;
  onSelfEnroll: () => void;
}

const CourseHomeUI = React.memo(
  ({
    userInfo,
    course,
    organization,
    courseLink,
    onLessonClick,
    courseProgress,
    onContinue,
    onSelfEnroll,
  }: Props) => {
    const calculateFolderDuration = (folderId: string) => {
      const folder = course?.folders.find(item => item.id == folderId);
      let duration = 0;
      folder?.lessons.forEach(lesson => {
        duration += lesson.duration;
      });
      return duration;
    };

    const calculateCourseDuration = () => {
      let duration = 0;
      course?.folders.forEach(folder => {
        duration += calculateFolderDuration(folder.id);
      });
      return duration;
    };

    const calculateDaysLeft = (dueDate: string) => {
      if (Date.parse(dueDate) < Date.now()) {
        return 0;
      }
      const diffTime = Math.abs(Date.parse(dueDate) - Date.now());
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };

    const calculateLessonsAmount = () => {
      let lessonsCount = 0;
      course?.folders.forEach(item => {
        if (item.lessons) {
          lessonsCount += item.lessons.length;
        }
      });
      return lessonsCount;
    };

    const getLessonEnabled = (lessonId: string) => {
      const lessonCompleted =
        courseProgress?.completedLessons.includes(lessonId);
      const lessonIsNext = lessonId === courseProgress?.nextLessonId;
      if (!lessonCompleted && !lessonIsNext) {
        return false;
      }
      return true;
    };

    return (
      <div>
        <NavBar />
        <Container fluid>
          <Row className="CourseSubheader">
            <Col xs={12}>
              <h2 className="CourseWelcomeText">
                {courseHomeText.welcome} {userInfo?.name}!
              </h2>
            </Col>
          </Row>
          {course && (
            <Row className="CourseDetailsContainer justify-content-center">
              <Col xs={12} lg={{span: 8}}>
                <Breadcrumbs
                  steps={[
                    {title: courseHomeText.courses, link: courseLink},
                    {title: course.title},
                  ]}
                />
                <h3 className="CourseTitle">{course.title}</h3>
                <OrganizationBadge
                  avatar={organization?.avatarUrl}
                  name={organization?.name}
                />
                <div className="Row CourseStats">
                  <img src={courseTimeIc} />
                  <span>
                    {getMinutesFromSeconds(calculateCourseDuration())}{' '}
                    {getMinutesFromSeconds(calculateCourseDuration()) === 1
                      ? courseHomeText.minute
                      : courseHomeText.minutes}
                  </span>
                </div>
                <p className="CourseDescription">{course.description}</p>
                <div className="FoldersHeadingContainer">
                  <p className="FoldersHeading">
                    {courseHomeText.inThisCourse}
                  </p>
                </div>
                <Accordion defaultActiveKey="0">
                  {course?.folders
                    .sort((a, b) => (a.order > b.order ? 1 : -1))
                    .map((item, index) => (
                      <Accordion.Item
                        eventKey={index.toString()}
                        key={index.toString()}>
                        <Accordion.Header>
                          <span className="accordion-title">{item.name}</span>
                          <img src={dividerWhiteIc} />
                          {item.lessons?.length} {courseHomeText.lessons}
                          <img src={dividerWhiteIc} />
                          {getMinutesFromSeconds(
                            calculateFolderDuration(item.id),
                          )}{' '}
                          {courseHomeText.minApprox}
                        </Accordion.Header>
                        <Accordion.Body>
                          {(item.lessons || [])
                            .sort((a, b) => (a.order > b.order ? 1 : -1))
                            .map(lesson => (
                              <div
                                key={lesson.id}
                                onClick={() => {
                                  if (getLessonEnabled(lesson.id)) {
                                    onLessonClick(lesson.id);
                                  }
                                }}
                                className={`LessonRow LessonContainer ${
                                  !getLessonEnabled(lesson.id)
                                    ? 'DisabledLesson'
                                    : ''
                                }`}>
                                <div className="LessonRow">
                                  <img src={lessonListPlayButtonIc} />
                                  <p>{lesson.title}</p>
                                  {courseProgress &&
                                    courseProgress.completedLessons.includes(
                                      lesson.id,
                                    ) && (
                                      <img
                                        className="CompletedLessonIndicator"
                                        src={checkSuccessIc}
                                      />
                                    )}
                                </div>
                                <p>{getTimeTextFromSeconds(lesson.duration)}</p>
                              </div>
                            ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                </Accordion>
              </Col>
              {courseProgress && (
                <Col xs={12} lg={3}>
                  <div className="CourseActionCard">
                    <img className="Poster" src={course.avatarUrl} alt="" />

                    <p>{courseHomeText.readyForIt}</p>
                    <div className="CourseActionInfo">
                      {courseProgress.dueDate && (
                        <div>
                          <img src={courseActionsTimeIc} />
                          <span className="Info">
                            {calculateDaysLeft(
                              courseProgress.dueDate.toString(),
                            )}{' '}
                            {courseHomeText.daysLeft(
                              calculateDaysLeft(
                                courseProgress.dueDate.toString(),
                              ),
                            )}
                          </span>
                          <img src={courseDividerIc} />
                        </div>
                      )}
                      <div>
                        <img src={courseActionsLessonsIc} />
                        <span className="Info">
                          {calculateLessonsAmount()}{' '}
                          {courseHomeText.lessonsAmount(
                            calculateLessonsAmount(),
                          )}
                        </span>
                      </div>
                    </div>
                    {courseProgress.isEnrolled && courseProgress.isCompleted ? (
                      <div className="CompletedCourse">
                        <img src={checkGreenIcon} />
                        <p>Completed</p>
                      </div>
                    ) : (
                      <Button
                        title={
                          courseProgress.isEnrolled &&
                          courseProgress.nextLessonId
                            ? courseHomeText.continue
                            : courseHomeText.start
                        }
                        onClick={() => {
                          courseProgress.isEnrolled &&
                          courseProgress.nextLessonId
                            ? onContinue(courseProgress.nextLessonId)
                            : onSelfEnroll();
                        }}
                        className="ContinueLearning"
                        uiType="action"
                      />
                    )}
                  </div>
                </Col>
              )}
            </Row>
          )}
        </Container>
      </div>
    );
  },
);

export default CourseHomeUI;
