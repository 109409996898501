import React, {useContext} from 'react';
import {EditorContext} from '../container/LessonEditorContainer';
import {LessonContentChallengeBranchImageOption} from '../../../data/models/LessonContent/LessonContentBase';
import {NodeData} from '../models';
import NodeHandles from './NodeHandles';

interface Props {
  data: NodeData;
}

const ChallengeBranchImageNode = ({data}: Props) => {
  const context = useContext(EditorContext);

  const onClick = (e: any) => {
    context.setSelectedNode(data.payload, data.type);
    e.stopPropagation();
  };

  return (
    <NodeHandles>
      <div className="EditorNodeContainer">
        <div>
          {(data.payload as LessonContentChallengeBranchImageOption)
            .isCorrect ? (
            <span className="icon-typeCheck ChallengeCorrect"></span>
          ) : (
            <span className="icon-typeClose ChallengeIncorrect"></span>
          )}
        </div>
        <div
          className={`EditorCard ${
            context.selectedNode?.internalId === data.payload.internalId
              ? 'SelectedEditorCard'
              : ''
          }`}
          onClick={onClick}>
          <div className="PlaceholderContainer">
            <img
              src={
                (data.payload as LessonContentChallengeBranchImageOption)
                  .contentUrl
              }
            />
          </div>
        </div>
      </div>
    </NodeHandles>
  );
};

export default ChallengeBranchImageNode;
