import React from 'react';
import {inject, observer} from 'mobx-react';
import AddAcknowledgmentWizardStore from '../store/AddAcknowledgmentWizardStore';
import AddAcknowledgmentWizardUI from '../view/AddAcknowledgmentWizardUI';
import {useParams} from 'react-router-dom';

interface Props {
  addAcknowledgmentWizardStore?: AddAcknowledgmentWizardStore;
}

const AddAcknowledgmentWizardContainer = inject('addAcknowledgmentWizardStore')(
  observer(({addAcknowledgmentWizardStore}: Props) => {
    const {organizationId, lessonId} = useParams();

    const onSave = (title: string, buttonTitle: string) => {
      if (organizationId && lessonId) {
        addAcknowledgmentWizardStore!.createNode(
          organizationId,
          lessonId,
          title,
          buttonTitle,
        );
      }
    };
    return addAcknowledgmentWizardStore!.showShowWizard ? (
      <AddAcknowledgmentWizardUI
        onClose={() => addAcknowledgmentWizardStore!.closeWizard()}
        onSave={onSave}
      />
    ) : null;
  }),
);

export default AddAcknowledgmentWizardContainer;
