import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import UserAvatar from '../../../uiToolkit/UserAvatar';
import UserInfoStore from '../../../stores/UserInfoStore';

interface Props {
  userInfoStore?: UserInfoStore;
}

const NavigationUserAvtarContainer = inject('userInfoStore')(
  observer(({userInfoStore}: Props) => {
    useEffect(() => {
      userInfoStore!.fetchUserInfo();
    }, []);

    return (
      <UserAvatar
        username={userInfoStore!.userInfo?.name}
        avatarUrl={userInfoStore!.userInfo?.avatarUrl}
      />
    );
  }),
);

export default NavigationUserAvtarContainer;
