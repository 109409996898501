import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import DownloadTranscriptButton from './DownloadTranscriptButton';
import DataTable from 'react-data-table-component';
import {videoSourcesText} from '../../videoSourcesText';
import {VideoTranscriptSegment} from '../../../../data/models/LibraryFile/MediaFile';
import ModalContainer from '../../../../uiToolkit/ModalContainer';

const transcriptTooltip = (
  <Tooltip>{videoSourcesText.downloadTranscript}</Tooltip>
);

const columns = [
  {
    name: 'Transcript Name',
    selector: (row: VideoTranscriptSegment) =>
      row.segmentStart + row.segmentEnd,
    cell: (row: VideoTranscriptSegment) => (
      <h5 className="TableText" data-tag="allowRowEvents">
        transcript_{row.segmentStart}seconds-{row.segmentEnd}seconds.csv
      </h5>
    ),
    id: 'size',
  },
  {
    name: 'Actions',
    cell: (row: VideoTranscriptSegment) => (
      <OverlayTrigger placement="bottom" overlay={transcriptTooltip}>
        <DownloadTranscriptButton
          data={row.transcript}
          filename={`transcript_${row.segmentStart}seconds-${row.segmentEnd}seconds`}
        />
      </OverlayTrigger>
    ),
    id: 'actions',
  },
];

interface Props {
  onClose: () => void;
  transcriptions: VideoTranscriptSegment[];
}

const DownloadTranscriptionModal = React.memo(
  ({transcriptions, onClose}: Props) => {
    return (
      <ModalContainer onClose={onClose}>
        <div className="VideoSourcesContainer">
          <h2 className="TranscriptTitle">
            {videoSourcesText.downloadVideoTranscription}
          </h2>
          <DataTable
            data={transcriptions}
            columns={columns}
            className="Table"
            striped
          />
        </div>
      </ModalContainer>
    );
  },
);

export default DownloadTranscriptionModal;
