import React from 'react';
import {courseEditTabs} from '../navConfig';
import NavigationTabs from '../../../../uiToolkit/NavigationTabs';
import {Outlet, useParams} from 'react-router-dom';
import {Row, Col} from 'react-bootstrap';
import Breadcrumbs from '../../../../uiToolkit/Breadcrumbs';
import './styles.css';
import {courseEditText} from '../../../../pages/CourseDetails/courseEditText';
import {Course} from '../../../../data/models/Course/Course';

interface Props {
  courseLink: string;
  permissions: string[];
  publishedCourse?: Course;
}

const CourseEditRootUI = React.memo(
  ({courseLink, permissions, publishedCourse}: Props) => {
    const [courseName, setCourseName] = React.useState('');
    const {organizationId, courseId} = useParams();

    const getTabs = () => {
      const tabs = courseEditTabs(permissions, organizationId, courseId);
      return tabs.filter(x => x !== null);
    };

    return (
      <div>
        <Row className="CourseEditRootContainer">
          <Col xs={10} lg={{span: 10}}>
            <Breadcrumbs
              steps={[
                {title: courseEditText.CourseEditRootCourse, link: courseLink},
                {title: courseName},
              ]}
            />
            {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
            {/*@ts-ignore*/}
            <NavigationTabs tabs={getTabs()} />
            <Row className="CourseEditRootRow">
              <Col lg={12} xs={12}>
                <Outlet
                  context={[courseName, setCourseName, publishedCourse]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  },
);

export default CourseEditRootUI;
