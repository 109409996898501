import React, {useState} from 'react';
import EditorLayout from '../../../uiToolkit/Editor/EditorLayout';
import EditorHeader from '../../../uiToolkit/Editor/EditorHeader';
import {lessonsText} from '../lessonsText';
import EditorOptions from '../../../uiToolkit/Editor/EditorOptions';
import LessonsBlock from '../components/LessonsBlock';
import {Folder} from '../../../data/models/Course/Folder';
import {
  Course,
  COURSE_PUBLISHED_STATUS,
} from '../../../data/models/Course/Course';
import {GRADABLE_COURSE_TYPE} from '../../../data/staticValues/courseStructure';
import {Lesson} from '../../../data/models/Course/Lesson';
import EditorDraggableList from '../../../uiToolkit/Editor/EditorDraggableList';
import EditorEntryLabel from '../../../uiToolkit/Editor/EditorEntryLabel';
import EditorCreateFirstEntry from '../../../uiToolkit/Editor/EditorCreateFirstEntry';
import EditorAddBelowButton from '../../../uiToolkit/Editor/EditorAddBelowButton';
import LessonsOptions from '../components/LessonsOptions';
import ConfirmationModal from '../../../uiToolkit/ConfirmationModal';
import './styles.css';
import AlertOptions from '../components/AlertOptions';
import InfoModal from '../components/InfoModal';
import {aIassistantSmallIc, lessonIc} from '../../../assets/images';
import {SidebarNavigation} from '../../../modules/Editor/SidebarNavigation';

interface Props {
  onBack: () => void;
  onNext: () => void;
  folder: Folder;
  course: Course;
  lessons: Lesson[];
  onCreate: () => void;
  onDelete: (id: string) => void;
  isLoading: boolean;
  onEdit: (lesson: Lesson) => void;
  onReorder: (lessons: Lesson[]) => void;
  onRetriesChange: (lesson: Lesson, retries: number) => void;
  onAddSupplementalLesson: (lesson: Lesson) => void;
  onAddSupplementalAlert: (lesson: Lesson) => void;
  onOpen: (lesson: Lesson) => void;
  onPublish: () => void;
  breadcrumbs: {title: string; link?: string}[];
  onExportScorm: () => void;
  onGenerateLesson: () => void;
  hideScorm: boolean;
}

interface LessonEditorSelectedItem {
  original?: Lesson;
  edit?: Lesson;
  type: string;
}

const OPTIONS_TAB = 'Options';

export const LESSON_OPTIONS = 'LESSON';
export const ALERT_OPTIONS = 'ALERT';

const LessonsUI = React.memo(
  ({
    onBack,
    onNext,
    folder,
    course,
    lessons,
    onCreate,
    isLoading,
    onEdit,
    onDelete,
    onReorder,
    onRetriesChange,
    onAddSupplementalLesson,
    onAddSupplementalAlert,
    onOpen,
    onPublish,
    breadcrumbs,
    onExportScorm,
    onGenerateLesson,
    hideScorm,
  }: Props) => {
    const [lessonToEdit, setLessonToEdit] = useState<LessonEditorSelectedItem>({
      original: undefined,
      edit: undefined,
      type: '',
    });
    const [showUnsavedChanges, setShowUnsavedChanges] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showDeletAlertConfirmation, setShowDeleteAlertConfirmation] =
      useState(false);
    const [errors, setErrors] = useState({
      title: false,
      description: false,
      passingScore: false,
    });
    const [showSidebar, setShowSidebar] = useState(true);

    const clearSelectedFolder = () => {
      if (editWasMade()) {
        setShowUnsavedChanges(true);
      } else {
        discardChanges();
      }
    };

    const editWasMade = () => {
      return (
        lessonToEdit.original?.title !== lessonToEdit.edit?.title ||
        lessonToEdit.original?.description !== lessonToEdit.edit?.description ||
        lessonToEdit.original?.isGradable !== lessonToEdit.edit?.isGradable ||
        lessonToEdit.original?.passingScore !==
          lessonToEdit.edit?.passingScore ||
        lessonToEdit.edit?.newImage !== undefined ||
        (lessonToEdit.original?.poster !== undefined &&
          lessonToEdit.edit?.poster === undefined)
      );
    };

    const dataIsValid = () => {
      let isValid = true;
      let newErrors = {
        ...errors,
      };
      if (lessonToEdit.edit?.title.trim() === '') {
        isValid = false;
        newErrors = {
          ...newErrors,
          title: true,
        };
      }
      if (lessonToEdit.edit?.description.trim() === '') {
        isValid = false;
        newErrors = {
          ...newErrors,
          description: true,
        };
      }
      if (
        lessonToEdit.edit?.isGradable &&
        lessonToEdit.edit.passingScore == undefined
      ) {
        isValid = false;
        newErrors = {
          ...newErrors,
          passingScore: true,
        };
      }
      setErrors(newErrors);
      return isValid;
    };

    const saveChanges = async () => {
      if (lessonToEdit.edit && dataIsValid()) {
        await onEdit(lessonToEdit.edit);
        discardChanges();
      }
    };

    const discardChanges = () => {
      setLessonToEdit({original: undefined, edit: undefined, type: ''});
      setShowUnsavedChanges(false);
    };

    const setLesson = (lesson: Lesson) => {
      setLessonToEdit({
        original: lesson,
        edit: {...lesson},
        type: LESSON_OPTIONS,
      });
    };

    const setAlert = (lesson: Lesson) => {
      setLessonToEdit({
        original: lesson,
        edit: {...lesson},
        type: ALERT_OPTIONS,
      });
    };

    const onEditLessonField = (field: string, value: any) => {
      if (lessonToEdit.original && lessonToEdit.edit) {
        clearErrors();
        setLessonToEdit({
          ...lessonToEdit,
          edit: {
            ...lessonToEdit.edit,
            [field]: value,
          },
        });
      }
    };

    const clearErrors = () => {
      setErrors({
        title: false,
        description: false,
        passingScore: false,
      });
    };

    const onDeleteLesson = () => {
      if (lessonToEdit.original) {
        onDelete(lessonToEdit.original.id);
      }
      discardChanges();
      setShowDeleteConfirmation(false);
    };

    const isCurrentLessonSupplemental = () => {
      if (lessonToEdit.original) {
        return (
          lessons.find(
            x => x.supplementalLessonId == lessonToEdit.original?.id,
          ) !== undefined
        );
      }
      return false;
    };

    const onDeleteAlert = async () => {
      if (lessonToEdit.edit) {
        const lessonWithoutAlert: Lesson = {
          ...lessonToEdit.edit,
          supplementalType: undefined,
        };
        await onEdit(lessonWithoutAlert);
        setShowDeleteAlertConfirmation(false);
        discardChanges();
      }
    };

    const showDeleteAlert = () => {
      setShowDeleteAlertConfirmation(true);
    };

    const getLessonsActions = () => [
      {
        icon: lessonIc,
        action: onCreate,
        tooltip: lessonsText.newLesson,
      },
      {
        icon: aIassistantSmallIc,
        action: onGenerateLesson,
        tooltip: lessonsText.generateLesson,
      },
    ];

    return (
      <div className="LessonsListRoot">
        <EditorLayout
          isPublished={course.publishStatus === COURSE_PUBLISHED_STATUS}
          showSidebar={showSidebar}
          sidebarSet={() => setShowSidebar(!showSidebar)}
          header={
            <EditorHeader
              breadcrumbs={breadcrumbs}
              onBack={onBack}
              onNext={onNext}
              nextTitle={lessonsText.next}
              onPublish={onPublish}
              onExportScorm={onExportScorm}
              canExportScorm={course?.publishStatus === COURSE_PUBLISHED_STATUS}
              hideScorm={hideScorm}
            />
          }
          sideBar={<SidebarNavigation />}
          isLoading={isLoading}
          onContentClick={clearSelectedFolder}
          content={
            <div className="LessonsInnerContainer">
              <InfoModal />
              <EditorEntryLabel label={folder.name} />
              {lessons.length ? (
                <EditorDraggableList<Lesson>
                  items={lessons.filter(x => !x.isSupplementalLesson)}
                  onItemsReordered={onReorder}
                  renderFooter={isDragging => (
                    <EditorAddBelowButton
                      isDragging={isDragging}
                      onClick={onCreate}
                      withArrow
                      actions={getLessonsActions()}
                    />
                  )}
                  renderItem={(item, isDragging) => (
                    <LessonsBlock
                      lesson={item}
                      isDragging={isDragging}
                      gradableCourse={
                        course.type.toString() === GRADABLE_COURSE_TYPE
                      }
                      onAddSupplementalAlert={onAddSupplementalAlert}
                      onSelect={setLesson}
                      selectedLesson={lessonToEdit.original}
                      onRetriesChange={onRetriesChange}
                      onAddSupplementalLesson={onAddSupplementalLesson}
                      onSelectAlert={setAlert}
                      selectedType={lessonToEdit.type}
                      onOpen={onOpen}
                    />
                  )}
                />
              ) : (
                <EditorCreateFirstEntry
                  onClick={onCreate}
                  actions={getLessonsActions()}
                />
              )}
            </div>
          }
          options={
            <EditorOptions
              tabs={[{tabName: OPTIONS_TAB}]}
              entityToEdit={lessonToEdit.edit}
              entityType={lessonToEdit.type}
              emptyStateText={lessonsText.optionsEmpty}>
              <LessonsOptions
                onEditLessonField={onEditLessonField}
                contentForTab={OPTIONS_TAB}
                optionsForType={LESSON_OPTIONS}
                isLoading={isLoading}
                errors={errors}
                isSupplemental={isCurrentLessonSupplemental()}
                onDiscardChanges={() => setShowUnsavedChanges(true)}
                saveEnabled={editWasMade()}
                onDeleteLesson={() => setShowDeleteConfirmation(true)}
                onSaveChanges={saveChanges}
                courseGradable={course.type.toString() === GRADABLE_COURSE_TYPE}
                isPublished={course?.publishStatus === COURSE_PUBLISHED_STATUS}
              />
              <AlertOptions
                onDeleteAlert={showDeleteAlert}
                contentForTab={OPTIONS_TAB}
                optionsForType={ALERT_OPTIONS}
              />
            </EditorOptions>
          }
        />
        <ConfirmationModal
          title={lessonsText.deleteConfirmation}
          onCancel={() => setShowDeleteConfirmation(false)}
          onAccept={onDeleteLesson}
          shown={showDeleteConfirmation}
          acceptText={lessonsText.delete}
        />
        <ConfirmationModal
          title={lessonsText.discardChangesConfirmation}
          onCancel={() => setShowUnsavedChanges(false)}
          onAccept={discardChanges}
          shown={showUnsavedChanges}
        />
        <ConfirmationModal
          title={lessonsText.deleteAlertConfirmation}
          onCancel={() => setShowDeleteAlertConfirmation(false)}
          onAccept={onDeleteAlert}
          shown={showDeletAlertConfirmation}
        />
      </div>
    );
  },
);

export default LessonsUI;
