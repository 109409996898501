import React from 'react';
import {lessonsText} from '../lessonsText';
import Button from '../../../uiToolkit/Buttons/Button';

interface Props {
  onDeleteAlert: () => void;
  contentForTab: string;
  optionsForType: string;
}

const AlertOptions = React.memo(({onDeleteAlert}: Props) => {
  return (
    <div className="LessonsOptions">
      <Button
        title={lessonsText.deleteAlert}
        onClick={onDeleteAlert}
        uiType="text_danger"
      />
    </div>
  );
});

export default AlertOptions;
