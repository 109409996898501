import {ApiResponse} from '../models/ApiResponse';
import {AddLessonContentModel} from '../models/LessonContent/AddLessonContentModel';
import {LessonContentBase} from '../models/LessonContent/LessonContentBase';
import {LessonContentList} from '../models/LessonContent/LessonContentList';
import {apiClient, withAuth} from './baseApiClient';

class LessonContentApi {
  public async addContentNode(
    organizationId: string,
    lessonId: string,
    model: AddLessonContentModel,
    childId?: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    if (childId) {
      return apiClient.post(
        `lessonContent/${organizationId}/${lessonId}/add?childId=${childId}`,
        model,
        {headers},
      );
    } else {
      return apiClient.post(
        `lessonContent/${organizationId}/${lessonId}/add`,
        model,
        {headers},
      );
    }
  }

  public async getLibraryContent(
    organizationId: string,
    lessonId: string,
  ): Promise<ApiResponse<LessonContentList>> {
    const headers = await withAuth({});
    return apiClient.get(`lessonContent/${organizationId}/${lessonId}`, {
      headers,
    });
  }

  public async getLessonContentPresentation(
    courseId: string,
    lessonId: string,
  ): Promise<ApiResponse<LessonContentList>> {
    const headers = await withAuth({});
    return apiClient.get(
      `/lessonContent/${courseId}/presentation/${lessonId}`,
      {
        headers,
      },
    );
  }

  public async getLessonContentPreview(
    organizationId: string,
    lessonId: string,
  ): Promise<ApiResponse<LessonContentList>> {
    const headers = await withAuth({});
    return apiClient.get(
      `/lessonContent/${organizationId}/preview/${lessonId}`,
      {
        headers,
      },
    );
  }

  public async editLessonContent(
    organizationId: string,
    lessonId: string,
    content: LessonContentBase,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.post(
      `lessonContent/${organizationId}/${lessonId}/edit`,
      content,
      {headers},
    );
  }

  public async deleteLessonContent(
    organizationId: string,
    lessonId: string,
    internalId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.delete(
      `lessonContent/${organizationId}/${lessonId}/delete/${internalId}`,
      {headers},
    );
  }

  public async softDeleteLessonContent(
    organizationId: string,
    lessonId: string,
    internalId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.delete(
      `lessonContent/${organizationId}/${lessonId}/soft_delete/${internalId}`,
      {headers},
    );
  }
}

export default LessonContentApi;
