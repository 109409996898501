import React from 'react';
import JumpToOptionsUI from '../view/JumpToOptionsUI';
import {LessonContentJumpTo} from '../../../../../data/models/LessonContent/LessonContentBase';
import {lessonEditorText} from '../../../lessonEditorText';
import {getListOfItemsFromContentList} from '../../../../../data/models/LessonContent/LessonContentList';
import BaseOptionsContainer, {
  BaseContainerProps,
} from '../../BaseOptionsContainer';

export default React.memo((props: BaseContainerProps) => (
  <BaseOptionsContainer
    {...props}
    deleteMessage={lessonEditorText.areYouSureDeleteJumpToOption}
    softDeleteMessage={lessonEditorText.areYouSureDeleteJumpToOptionSoft}>
    {forwardProps => (
      <JumpToOptionsUI
        {...forwardProps}
        entityToEdit={forwardProps.entityToEdit as LessonContentJumpTo}
        options={getListOfItemsFromContentList(forwardProps.lessonContent)}
      />
    )}
  </BaseOptionsContainer>
));
