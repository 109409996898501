import React, {useEffect, useState} from 'react';
import SidebarNavigationUI from '../view/SidebarNavigationUI';
import {inject, observer} from 'mobx-react';
import SidebarNavigationStore from '../store/SidebarNavigationStore';
import {useParams} from 'react-router-dom';

interface Props {
  sidebarNavigationStore?: SidebarNavigationStore;
}

const SidebarNavigationContainer = inject('sidebarNavigationStore')(
  observer(({sidebarNavigationStore}: Props) => {
    const [search, setSearch] = useState('');

    const {courseId, folderId, lessonId, organizationId} = useParams();

    useEffect(() => {
      if (organizationId && courseId) {
        sidebarNavigationStore!.fetchInitialPosition(organizationId, courseId);
      }
    }, []);

    const onPageChange = (page: number) => {
      sidebarNavigationStore!.setCurrentPage(page);
      if (organizationId) {
        sidebarNavigationStore!.getSidebarOptions(organizationId, search);
      }
    };

    const onSearchChange = (search: string) => {
      setSearch(search);
      if (organizationId) {
        sidebarNavigationStore!.searchSidebarOptions(organizationId, search);
      }
    };

    return (
      <SidebarNavigationUI
        sidebarOptions={sidebarNavigationStore!.courseLegend}
        onSearchKeyChange={onSearchChange}
        activeTabs={{courseId: courseId || '', folderId, lessonId}}
        currentPage={sidebarNavigationStore!.currentPage}
        pagesCount={sidebarNavigationStore!.pagesCount}
        onPageChange={onPageChange}
      />
    );
  }),
);

export default SidebarNavigationContainer;
