import {inject, observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import MarketplaceUI from '../view/MarketplaceUI';
import MarketplaceCheckOutUI from '../view/MarketplaceCheckOutUI';
import {useNavigate, useParams} from 'react-router-dom';
import MarketplaceStore from '../store/MarketplaceStore';
import {OrganizationSubscription} from '../../../../data/models/Subscription/Subscription';
import {
  SUBSCRIPTION_BUISNESS_BASIC,
  SUBSCRIPTION_BUISNESS_BASIC_TRIAL,
  SUBSCRIPTION_BUISNESS_PRO,
  SUBSCRIPTION_CREATOR,
  SUBSCRIPTION_CREATOR_TRIAL,
  SUBSCRIPTION_ENTERPRISE,
} from '../../config';
import {DashboardData} from '../../../../data/models/Workspace/Dashboard';
import {ADDON_CREDITS, ADDON_STORAGE} from '../marketplaceItems';

interface Props {
  marketplaceStore?: MarketplaceStore;
}

const MarketplaceContainer = inject('marketplaceStore')(
  observer(({marketplaceStore}: Props) => {
    const [selectedProduct, setSelectedProduct] = useState<string>();
    const [subscription, setSubscription] =
      useState<OrganizationSubscription>();
    const [orgStatisticData, setStatisticData] = useState<DashboardData>();
    const {organizationId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      if (organizationId) {
        marketplaceStore?.getSubscription(organizationId).then(res => {
          if (!res) {
            navigate(`/manage/${organizationId}/workspace`, {replace: true});
          } else {
            setSubscription(res);
          }
        });
        marketplaceStore?.fetchDashboardDetails(organizationId).then(data => {
          if (data) {
            setStatisticData(data);
          } else {
            navigate(`/manage/${organizationId}/workspace`, {replace: true});
          }
        });
      }
    }, []);

    const onSelectItem = (addon?: string, quote?: string) => {
      if (
        subscription?.type === SUBSCRIPTION_BUISNESS_BASIC_TRIAL ||
        subscription?.type === SUBSCRIPTION_CREATOR_TRIAL
      ) {
        if (addon === ADDON_CREDITS || addon === ADDON_STORAGE) {
          setSelectedProduct(addon);
        } else {
          navigate(`/manage/${organizationId}/settings/subscription`, {
            replace: true,
          });
        }
      } else if (addon) {
        setSelectedProduct(addon);
      } else if (quote) {
        window.open(quote, '_blank');
      }
    };

    const onGoBack = () => {
      setSelectedProduct(undefined);
    };

    const onPurchase = async (quantity: number) => {
      if (organizationId && selectedProduct && subscription) {
        if (
          selectedProduct === 'addons_learner_or_instructor' ||
          selectedProduct === 'addons_course_author'
        ) {
          const res = await marketplaceStore!.purchaseRecurringAddons(
            organizationId,
            {
              [selectedProduct]: quantity,
            },
            subscription.type,
          );
          if (res.success) {
            navigate(`/subscription/${organizationId}/success_update`);
          }
        } else {
          const link = await marketplaceStore!.purchaseAddons(organizationId, {
            [selectedProduct]: quantity,
          });
          if (link) {
            window.open(link, '_self');
          }
        }
      }
    };

    const getMaxLearnersForSubscription = () => {
      switch (subscription?.type) {
        case SUBSCRIPTION_CREATOR:
          return 19;
        case SUBSCRIPTION_BUISNESS_BASIC:
          return 250;
        case SUBSCRIPTION_BUISNESS_PRO:
          return 1000;
        case SUBSCRIPTION_ENTERPRISE:
          return 99999;
        default:
          return 0;
      }
    };

    if (!subscription) return null;

    return selectedProduct && orgStatisticData ? (
      <MarketplaceCheckOutUI
        onGoBack={onGoBack}
        productId={selectedProduct}
        organizationId={organizationId || ''}
        subscription={subscription}
        onPurchase={onPurchase}
        isLoading={marketplaceStore!.isLoading}
        maxLeanresAvailable={
          getMaxLearnersForSubscription() -
          orgStatisticData?.maxLearnersAvailable
        }
      />
    ) : (
      <MarketplaceUI
        onSelectItem={onSelectItem}
        currentSubscription={subscription}
      />
    );
  }),
);

export default MarketplaceContainer;
