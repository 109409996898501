import React, {useState} from 'react';
import {settingsText} from '../../settingsText';
import {StaffRecord} from '../../../../data/models/Oganization/StaffRecord';
import {Role} from '../../../../data/models/Oganization/Role';
import Dropdown from '../../../../uiToolkit/Inputs/Dropdown';
import Button from '../../../../uiToolkit/Buttons/Button';

interface Props {
  member: StaffRecord;
  roles: Role[];
  onCancel: () => void;
  onSubmit: (roleId: string) => void;
  isLoading: boolean;
}

const StaffEditUI = React.memo(
  ({member, roles, onCancel, onSubmit, isLoading}: Props) => {
    const [roleId, setRoleId] = useState(member.roleId);

    const buttonEnabled = () => {
      return roleId !== member.roleId;
    };

    return (
      <div className="StaffInviteContainer">
        <h2>
          {settingsText.edit} {member.name}
        </h2>
        <h5>{settingsText.staffEditSubtitle}</h5>
        <Dropdown
          options={roles.map(item => ({
            key: item.id,
            value: item.roleName,
          }))}
          selectedOption={roleId}
          setSelectedOption={setRoleId}
          heading={settingsText.role}
          placeholder={settingsText.selectRole}
          className="StaffEditSelect"
        />
        <div className="Footer">
          <Button
            onClick={onCancel}
            title={settingsText.cancel}
            className="Cancel"
            uiType="text"
          />
          <Button
            onClick={() => onSubmit(roleId)}
            title={settingsText.edit}
            disabled={!buttonEnabled()}
            isLoading={isLoading}
            uiType="action"
          />
        </div>
      </div>
    );
  },
);

export default StaffEditUI;
