import {makeAutoObservable} from 'mobx';
import LessonContentRepository from '../../../../data/repositories/LessonContentRepository';
import {
  JUMP_TO_NODE,
  LessonContentBase,
  LessonContentJumpTo,
} from '../../../../data/models/LessonContent/LessonContentBase';
import {ApiResponse} from '../../../../data/models/ApiResponse';

class AddJumpToWizardStore {
  private lessonContentRepo = new LessonContentRepository();
  private showWizard = false;
  private parentId?: string;
  private onCreateCallback?: () => void;
  private nodeToReplace?: LessonContentBase;

  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public startWizard(
    parentId: string,
    onCreateCallback: () => void,
    nodeToReplace?: LessonContentBase,
  ) {
    this.showWizard = true;
    this.parentId = parentId;
    this.onCreateCallback = onCreateCallback;
    this.nodeToReplace = nodeToReplace;
  }

  public isVisible() {
    return this.showWizard;
  }

  public cancelWizard() {
    this.showWizard = false;
    this.parentId = undefined;
    this.onCreateCallback = undefined;
  }

  public async createOption(
    organizationId: string,
    lessonId: string,
    jumpToId: string,
  ) {
    if (this.parentId) {
      this.isLoading = true;
      const model = {
        data: {
          ivNodeType: JUMP_TO_NODE,
          jumpToNodeId: jumpToId,
        } as LessonContentJumpTo,
        parentsIds: [this.parentId],
      };

      if (this.nodeToReplace) {
        const result = await this.lessonContentRepo.editLessonContent(
          organizationId,
          lessonId,
          {...model.data, internalId: this.nodeToReplace.internalId},
        );
        this.processResult(result);
      } else {
        const result = await this.lessonContentRepo.addLessonContent(
          organizationId,
          lessonId,
          model,
        );
        this.processResult(result);
      }
      this.isLoading = false;
    }
  }

  private processResult = (result: ApiResponse<any>) => {
    if (result.success && this.onCreateCallback) {
      this.onCreateCallback();
      this.cancelWizard();
    }
  };
}

export default AddJumpToWizardStore;
