import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  title: 'Optimizing Your Experience',
  info: "To ensure a smooth and enjoyable app experience, we advise using a tablet in landscape mode, notebook, or PC. The constraints of a mobile device's screen size may impact the app's functionality. Therefore, we recommend opting for a larger device for optimal use.",
};

const welsh = {
  title: "Optimïo'ch Profiad",
  info: 'I sicrhau profiad ap llyfn a chadarnhaol, rydym yn argymell defnyddio tabled mewn modd tirwedd, gliniadur, neu PC. Gall cyfyngiadau maint sgrin dyfais symudol effeithio ar weithrediad yr ap. Felly, rydym yn argymell dewis dyfais fwy ar gyfer defnydd gorau',
};

export const mobileRestrictionText: any = getLocalizationBasedOnLang(
  english,
  welsh,
);
