import LessonsApi from '../api/LessonsApi';
import {Lesson, LessonCreateModel} from '../models/Course/Lesson';
import {LessonGenerationModel} from '../models/Course/LessonGenerationModel';
import {
  CreateLearnerLessonModel,
  LearnerProgressModel,
} from '../models/LessonContent/CreateLearnerLessonModel';

class LessonsRepository {
  private lessonsApi = new LessonsApi();

  public createLesson(organizationId: string, lesson: LessonCreateModel) {
    return this.lessonsApi.createLesson(organizationId, lesson);
  }

  public getLessons(organizationId: string, folderId: string) {
    return this.lessonsApi.getLessons(organizationId, folderId);
  }

  public getAllCourseLessons(organizationId: string, courseId: string) {
    return this.lessonsApi.getAllCourseLessons(organizationId, courseId);
  }

  public async editLesson(organizationId: string, lesson: Lesson) {
    const result = await this.lessonsApi.editLesson(organizationId, lesson);
    if (result.success && lesson.newImage) {
      await this.lessonsApi.uploadPoster(
        organizationId,
        lesson.id,
        lesson.newImage,
      );
    }
    return result;
  }

  public deleteLesson(organizationId: string, lessonId: string) {
    return this.lessonsApi.deleteLesson(organizationId, lessonId);
  }

  public getLessonById(organizationId: string, lessonId: string) {
    return this.lessonsApi.getLessonById(organizationId, lessonId);
  }

  public getLessonIsPublishedById(organizationId: string, lessonId: string) {
    return this.lessonsApi.getLessonIsPublishedById(organizationId, lessonId);
  }

  public orderLessons(
    organizationId: string,
    folderId: string,
    lessons: Lesson[],
  ) {
    return this.lessonsApi.reorderLessons(organizationId, folderId, lessons);
  }

  public getLessonPresentation(courseId: string, lessonId: string) {
    return this.lessonsApi.getLessonPresentation(courseId, lessonId);
  }

  public getLessonPreview(
    organizationId: string,
    courseId: string,
    lessonId: string,
  ) {
    return this.lessonsApi.getLessonPreview(organizationId, courseId, lessonId);
  }

  public getLearnerProgress(courseId: string, lessonId: string) {
    return this.lessonsApi.getLearnerProgress(courseId, lessonId);
  }

  public createLessonLearner(
    courseId: string,
    model: CreateLearnerLessonModel,
  ) {
    return this.lessonsApi.addLearnerLesson(courseId, model);
  }

  public editLessonLearnerProgress(
    courseId: string,
    lessonId: string,
    model: LearnerProgressModel,
  ) {
    return this.lessonsApi.editLearnerLessonProgress(courseId, lessonId, model);
  }

  public getLessonBySecret(lessonSecret: string) {
    return this.lessonsApi.getLessonDataFromSecret(lessonSecret);
  }

  public getScormLessonData(lessonSecret: string, lessonId: string) {
    return this.lessonsApi.getScormLessonData(lessonSecret, lessonId);
  }

  public saveProgressWithSecret(
    lessonSecret: string,
    progress: LearnerProgressModel,
  ) {
    return this.lessonsApi.saveProgressWithSecret(lessonSecret, progress);
  }

  public getScoreForSCORM(
    lessonSecret: string,
    lessonId: string,
    progress: LearnerProgressModel,
  ) {
    return this.lessonsApi.getScoreForSCORM(lessonSecret, lessonId, progress);
  }

  public generateLesson(organizationId: string, model: LessonGenerationModel) {
    return this.lessonsApi.genrateLessonWithAI(organizationId, model);
  }
}

export default LessonsRepository;
