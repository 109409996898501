import React from 'react';

interface Props {
  actionsAvailable: number;
}

const AiActionsUsedCard = React.memo(({actionsAvailable}: Props) => {
  return (
    <div className="TwoDataCard AiActionsCard">
      <span>{actionsAvailable}</span>
    </div>
  );
});

export default AiActionsUsedCard;
