import React, {useEffect, useState} from 'react';
import {LessonContentChallengeBranchTextOption} from '../../../../../data/models/LessonContent/LessonContentBase';
import TextInput from '../../../../../uiToolkit/Inputs/TextInput';
import {lessonEditorText} from '../../../lessonEditorText';
import EditorOptionsFooter from '../../../../../uiToolkit/Editor/EditorOptionsFooter';
import YesNoOption from '../../../../../uiToolkit/Inputs/YesNoOption';
import {BaseContainerForwardProps} from '../../BaseOptionsContainer';
import ReplaceNode from '../../ReplaceNode';

interface Props extends BaseContainerForwardProps {
  entityToEdit: LessonContentChallengeBranchTextOption;
}

const ChallengeTextOptionOptionsUI = React.memo(
  ({entityToEdit, isLoading, onEdit, onDelete, isPublished}: Props) => {
    const [option, setOption] = useState('');
    const [isCorrect, setIsCorrect] = useState(false);

    useEffect(() => {
      setOption(entityToEdit?.answer || '');
      setIsCorrect(entityToEdit?.isCorrect || false);
    }, [entityToEdit]);

    const changesWasMade = () =>
      entityToEdit?.answer !== option || entityToEdit?.isCorrect !== isCorrect;

    const isValid = () => option.trim() !== '';

    const onSave = () => {
      if (isValid()) {
        onEdit({
          ...entityToEdit,
          answer: option,
          isCorrect,
        } as LessonContentChallengeBranchTextOption);
      }
    };

    return (
      <div>
        <TextInput
          heading={lessonEditorText.option}
          value={option}
          onChange={setOption}
          placeholder={lessonEditorText.option}
          fieldError={option.trim() === ''}
          maxCharacters={150}
          isMandatory
          uiType="box"
        />
        <YesNoOption
          value={isCorrect}
          onSelect={setIsCorrect}
          title={lessonEditorText.isCorrect}
          uiType="box"
        />
        <ReplaceNode currentNode={entityToEdit} />
        <EditorOptionsFooter
          onSave={onSave}
          saveEnabled={changesWasMade() && isValid()}
          onDelete={onDelete}
          isLoading={isLoading}
          deleteDisabled={isPublished}
        />
      </div>
    );
  },
);

export default ChallengeTextOptionOptionsUI;
