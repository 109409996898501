import React, {useEffect, useState} from 'react';
import {LessonContentJumpTo} from '../../../../../data/models/LessonContent/LessonContentBase';
import {lessonEditorText} from '../../../lessonEditorText';
import EditorOptionsFooter from '../../../../../uiToolkit/Editor/EditorOptionsFooter';
import {SelectOption} from '../../../../../data/models/UI/SelectOption';
import Dropdown from '../../../../../uiToolkit/Inputs/Dropdown';
import {BaseContainerForwardProps} from '../../BaseOptionsContainer';
import ReplaceNode from '../../ReplaceNode';

interface Props extends BaseContainerForwardProps {
  entityToEdit: LessonContentJumpTo;
  options: SelectOption[];
}

const JumpToOptionsUI = React.memo(
  ({
    entityToEdit,
    isLoading,
    onEdit,
    onDelete,
    onSoftDelete,
    isPublished,
    options,
  }: Props) => {
    const [jumpToId, setJumpToId] = useState('');

    useEffect(() => {
      setJumpToId(entityToEdit?.jumpToNodeId || '');
    }, [entityToEdit]);

    const changesWasMade = () => entityToEdit?.jumpToNodeId !== jumpToId;

    const isValid = () => jumpToId.trim() !== '';

    const onSave = () => {
      if (isValid()) {
        onEdit({
          ...entityToEdit,
          jumpToNodeId: jumpToId,
        } as LessonContentJumpTo);
      }
    };

    return (
      <div>
        <Dropdown
          options={options}
          heading={lessonEditorText.lessonNodes}
          setSelectedOption={setJumpToId}
          type="box"
          selectedOption={jumpToId}
        />
        <ReplaceNode currentNode={entityToEdit} />
        <EditorOptionsFooter
          onSave={onSave}
          saveEnabled={changesWasMade() && isValid()}
          onDelete={onDelete}
          isLoading={isLoading}
          deleteDisabled={isPublished}
          onSoftDelete={onSoftDelete}
        />
      </div>
    );
  },
);

export default JumpToOptionsUI;
