import React from 'react';
import './styles.css';
import {appText} from '../../assets/text/appText';
import UploadVideoButton from '../Inputs/UploadVideoButton';

interface Props {
  onVideoUpload: (video: File) => void;
  isLoading?: boolean;
  type?: number;
}

const LibraryEmptyState = React.memo(
  ({onVideoUpload, isLoading, type}: Props) => {
    return (
      <div className="EmptyStateContainer">
        <p className="Title">{appText.noLibraryTitle}</p>
        <p className="Subtitle">{appText.noLibrarySubtitle}</p>
        <UploadVideoButton
          onVideoSelected={onVideoUpload}
          isLoading={isLoading}
          type={type}
        />
      </div>
    );
  },
);

export default LibraryEmptyState;
