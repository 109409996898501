import {LessonContentItem} from '../models/LessonContent/LessonContentList';

export const mapLessonContent = (items: {[key: string]: LessonContentItem}) => {
  if (!items) return {};
  const keys = Object.keys(items);
  const newItems: {[key: string]: LessonContentItem} = {};
  keys.forEach(key => {
    newItems[key] = {
      childIds: items[key].childIds || [],
      parentsIds: items[key].parentsIds || [],
      data: items[key].data,
    };
  });
  return newItems;
};
