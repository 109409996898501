import React from 'react';
import './styles.css';

interface Props {
  label: string;
}

const EditorEntryLabel = React.memo(({label}: Props) => {
  return (
    <div className="EditorEntryLabel">
      <p>{label}</p>
    </div>
  );
});

export default EditorEntryLabel;
