import React from 'react';
import {gradableIc, lessonPlaceholderIc} from '../../../assets/images';
import {Lesson} from '../../../data/models/Course/Lesson';
import {loadingLottie} from '../../../assets/lottie';
import Lottie from 'lottie-react';

interface Props {
  lesson: Lesson;
  onSelect: (lesson: Lesson) => void;
  isSelcted: boolean;
  onOpen: (lesson: Lesson) => void;
}

const LessonCard = React.memo(
  ({lesson, onSelect, isSelcted, onOpen}: Props) => {
    const onClick = (e: any) => {
      onSelect(lesson);
      e.stopPropagation();
    };
    return (
      <div
        className={`LessonCard ${isSelcted ? 'SelectedLessonCard' : ''}`}
        onClick={onClick}
        onDoubleClick={() => !lesson.inProgress && onOpen(lesson)}>
        <p>{lesson.title}</p>
        <div className="ImageContainer">
          {lesson.inProgress ? (
            <Lottie
              animationData={loadingLottie}
              loop={true}
              style={{
                width: '20px',
                height: '20px',
                marginBottom: '10px',
              }}
            />
          ) : lesson.poster ? (
            <img className="Poster" src={lesson.poster} />
          ) : (
            <img src={lessonPlaceholderIc} />
          )}
        </div>
        <div className="BadgesContainer">
          {lesson.isGradable && (
            <div className="Badge">
              <img src={gradableIc} />
            </div>
          )}
          {lesson.isGradable && lesson.passingScore !== undefined && (
            <div className="Badge">
              <span>{lesson.passingScore} %</span>
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default LessonCard;
