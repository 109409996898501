export interface LtiTool {
  loginUrl: string;
  redirectionUrl: string;
  publicKeySetUrl: string;
}

export interface LtiPlatform {
  name: string;
  accessTokenUrl: string;
  authorizeUrl: string;
  issuer: string;
  clientId: string;
  jwkSetUrl: string;
  type: number;
  id?: string;
}

export const LMSPlatforms = [
  {
    key: '0',
    value: 'Moodle',
  },
];
