import React from 'react';
import {LessonContentAcknowledgment} from '../../../../../data/models/LessonContent/LessonContentBase';
import AcknowledgmentOptionsUI from '../view/AcknowledgmentOptionsUI';
import {lessonEditorText} from '../../../lessonEditorText';
import BaseOptionsContainer, {
  BaseContainerProps,
} from '../../BaseOptionsContainer';

export default React.memo((props: BaseContainerProps) => (
  <BaseOptionsContainer
    {...props}
    deleteMessage={lessonEditorText.areYouSureDeleteAcknowledgment}
    softDeleteMessage={lessonEditorText.areYouSureDeleteAcknowledgmentSoft}>
    {forwardProps => (
      <AcknowledgmentOptionsUI
        {...forwardProps}
        entityToEdit={forwardProps.entityToEdit as LessonContentAcknowledgment}
      />
    )}
  </BaseOptionsContainer>
));
