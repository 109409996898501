import React from 'react';
import AuthHeader from '../../../uiToolkit/AuthHeader';
import {onboardingText} from '../onboardingText';
import {onboardingOptions} from '../../../data/staticValues/onboardingOptions';
import RadioGroupOption from '../../../uiToolkit/RadioGroupOption';
import Button from '../../../uiToolkit/Buttons/Button';
import './styles.css';

interface Props {
  selectedAnswer?: string;
  setSelectedAnswer: (answer: string) => void;
  onContinue: () => void;
  onSkip: () => void;
}

const OnboardingUI = React.memo(
  ({setSelectedAnswer, selectedAnswer, onContinue, onSkip}: Props) => {
    return (
      <div>
        <div className="OnboardingFormContainer">
          <AuthHeader title={onboardingText.title} subtitle="" />
          <div className="OnboardingInputContainer">
            {onboardingOptions.map(item => (
              <RadioGroupOption
                title={item.value}
                subtitle={item.subtitle}
                value={item.key}
                onSelect={setSelectedAnswer}
                isSelected={item.key === selectedAnswer}
                key={item.key}
                className="OnboardingOption"
              />
            ))}
          </div>

          <div className="OnboardingSubmitButtonContainer">
            <button className="Skip" onClick={onSkip}>
              {onboardingText.skip}
            </button>
            <Button
              title={onboardingText.continue}
              onClick={onContinue}
              disabled={!selectedAnswer}
              uiType="action"
            />
          </div>
        </div>
      </div>
    );
  },
);

export default OnboardingUI;
