import {ApiResponse} from '../models/ApiResponse';
import {GradebookModel} from '../models/Course/Gradebook';
import {LearnerJourney} from '../models/LessonContent/LearnerJourney';
import {apiClient, withAuth} from './baseApiClient';

class GradebookAPI {
  public async getCourseGradebook(
    organizationId: string,
    courseId: string,
    page: number,
    pageSize: number,
    search: string,
  ): Promise<ApiResponse<GradebookModel>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/gradeBook/${organizationId}/${courseId}/lesson_grades`,
      {page, pageSize, search},
      {headers},
    );
  }

  public async getLearnerProgressDetails(
    courseId: string,
    lessonId: string,
    learnerId: string,
  ): Promise<ApiResponse<LearnerJourney>> {
    return apiClient.get(
      `lessons/course/${courseId}/lesson/${lessonId}/learner/${learnerId}/learnerLessonProgresDetails`,
    );
  }
}

export default GradebookAPI;
