import React from 'react';
import {inject, observer} from 'mobx-react';
import GenerateAIVideosUI from '../view/GenerateAIVideosUI';
import GenerateAIVideosStore from '../store/GenerateAIVideosStore';
import {useParams} from 'react-router-dom';

interface Props {
  generateAIVideosStore?: GenerateAIVideosStore;
}

const GenerateAIVideosContainer = inject('generateAIVideosStore')(
  observer(({generateAIVideosStore}: Props) => {
    const onCancel = () => {
      generateAIVideosStore!.cancelWizard();
    };

    const {organizationId} = useParams();

    const onSave = (
      file: File,
      aiImage: boolean,
      sliderValue: string | null,
      ignoreSlidesList: number[],
      ignoreSlidesAudioList: number[],
      ignoreSlidesAudioDuration: number,
      type: number,
      voiceGender: string,
      language: string,
      selectedDialect: string,
      phrasesToIgnore: string[],
      generateAiVideos: boolean,
    ) => {
      if (organizationId && file) {
        generateAIVideosStore!.generateAiVideosFromSlides(
          organizationId,
          file,
          aiImage,
          sliderValue,
          ignoreSlidesList,
          ignoreSlidesAudioList,
          ignoreSlidesAudioDuration,
          type,
          voiceGender,
          language,
          selectedDialect,
          phrasesToIgnore,
          generateAiVideos,
        );
      }
    };

    return generateAIVideosStore!.isVisible() ? (
      <GenerateAIVideosUI
        onCancel={onCancel}
        onSave={onSave}
        isLoading={generateAIVideosStore!.isLoading}
      />
    ) : null;
  }),
);

export default GenerateAIVideosContainer;
