import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const englishChallangeAI = {
  title: 'Challenge branch AI',
  generatedQuestion: 'Generated question:',
  result: 'This is your results',
  add: 'Insert into lesson',
  regenerate: 'Regenerate',
};

const welshChnallangeAI = {
  title: 'Cangen her AI',
  generatedQuestion: "Cwestiwn wedi'i gynhyrchu",
  result: 'Dyma eich canlyniadau',
  add: 'Mewnosod yn y wers',
  regenerate: 'Adfywio',
};

export const addChallangeAIWizardText: any = getLocalizationBasedOnLang(
  englishChallangeAI,
  welshChnallangeAI,
);
