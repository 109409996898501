import {inject, observer} from 'mobx-react';
import React from 'react';
import AddChallengeTextOptionUI from '../view/AddChallengeTextOptionUI';
import AddChallengeBranchTextOptionStore from '../store/AddChallengeBranchTextOptionStore';
import {useParams} from 'react-router-dom';

interface Props {
  addChallengeBranchTextOptionStore?: AddChallengeBranchTextOptionStore;
}

const AddChallengeTextOptionContainer = inject(
  'addChallengeBranchTextOptionStore',
)(
  observer(({addChallengeBranchTextOptionStore}: Props) => {
    const {organizationId, lessonId} = useParams();

    const onCreateOption = (answer: string) => {
      if (organizationId && lessonId) {
        addChallengeBranchTextOptionStore!.createOption(
          organizationId,
          lessonId,
          answer,
        );
      }
    };

    return addChallengeBranchTextOptionStore!.showWizard() ? (
      <AddChallengeTextOptionUI
        onClose={() => addChallengeBranchTextOptionStore!.closeWizard()}
        onCreateOption={onCreateOption}
        isLoading={addChallengeBranchTextOptionStore!.isLoading}
      />
    ) : null;
  }),
);

export default AddChallengeTextOptionContainer;
