import NotificationsApi from '../api/NotificationsAPI';

class NotificationsRepository {
  private notificationsApi = new NotificationsApi();

  public getMyNotifications() {
    return this.notificationsApi.getMyNotifications();
  }

  public denyInvitation(invitationId: string) {
    return this.notificationsApi.denyInvitation(invitationId);
  }

  public markAsSeen(notificationId: string) {
    return this.notificationsApi.markAsSeen(notificationId);
  }

  public markAsRead() {
    return this.notificationsApi.markAsRead();
  }

  public getSeenNotifications(page: number, pageSize: number) {
    return this.notificationsApi.getSeenNotifications(page, pageSize);
  }
}

export default NotificationsRepository;
