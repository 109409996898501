import {makeAutoObservable} from 'mobx';
import LibraryRepository from '../../../../data/repositories/LibraryRepository';
import {MEDIA_FILES_ALL} from '../../../../data/models/LibraryFile/MediaFile';
import {toast} from 'react-toastify';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import SubscriptionRepository from '../../../../data/repositories/SubscriptionRepository';
import LoadingModule from '../../../../modules/LoadingModule/LoadingModule';
import {videoSourcesText} from '../../videoSourcesText';

class VideoSourcesStore {
  private libraryRepo = new LibraryRepository();
  private subscriptionRepo = new SubscriptionRepository();
  private loadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public getStorageLimit = async (organizationId: string) => {
    const result =
      await this.subscriptionRepo.getSubscriptionPermissions(organizationId);
    if (result.success && result.data) {
      return result.data.storageLimit;
    }
    return 100;
  };

  public fetchMediaFile = async (organizationId: string, contentId: string) => {
    const result = await this.libraryRepo.getByContentId(
      organizationId,
      contentId,
    );
    if (result.success && result.data) {
      return result.data;
    }
  };

  public async getMyVideoSources(
    page: number,
    organizationId: string,
    pageSize: number,
    orderBy: string,
    search?: string,
    orderAsk?: boolean,
    origin?: number,
  ) {
    this.loadingModule.startLoading();
    const result = await this.libraryRepo.getMediaFiles(
      page,
      organizationId,
      pageSize,
      orderBy,
      MEDIA_FILES_ALL,
      search,
      orderAsk,
      origin,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
    return {
      data: [],
      pagesCount: 0,
    };
  }

  public async getVimeoVideos(
    organizationId: string,
    page: number,
    pageSize: number,
  ) {
    this.loadingModule.startLoading();
    const result = await this.libraryRepo.getVimeoVideos(
      organizationId,
      page,
      pageSize,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }

  public async deleteMediaFile(organizationId: string, mediaFileId: string) {
    this.loadingModule.startLoading();
    const result = await this.libraryRepo.deleteMediaFile(
      organizationId,
      mediaFileId,
    );
    this.loadingModule.endLoading();
    if (result.success) {
      toast.success(videoSourcesText.fileDeleted, toastConfig);
    }
    return result.success;
  }

  public async downloadTranscript(organizationId: string, mediaFileId: string) {
    this.loadingModule.startLoading();
    const result = await this.libraryRepo.downloadTranscript(
      organizationId,
      mediaFileId,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }
}

export default VideoSourcesStore;
