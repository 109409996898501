import React from 'react';
import {
  BRANCH_PARENT_DELETION_MODAL,
  CREATE_ORGANIZATION_MODAL,
  LOGOUT_CONFIRMATION_MODAL,
} from '../globalModals';
import {CreateOrganization} from '../../CreateOrganization';
import ConfirmationModal from '../../../uiToolkit/ConfirmationModal';
import {appText} from '../../../assets/text/appText';
import {BRANCH_VIDEO_PARENT_CONFIRMATION_ALIAS} from '../../../uiToolkit/ConfirmationModal/modalAliases';

interface Props {
  activeModal?: string;
  onClose: (payload?: any) => void;
}

const ModalProviderUI = React.memo(({activeModal, onClose}: Props) => {
  if (activeModal === CREATE_ORGANIZATION_MODAL) {
    return <CreateOrganization isOpen={true} onClose={onClose} />;
  }
  if (activeModal === LOGOUT_CONFIRMATION_MODAL) {
    return (
      <ConfirmationModal
        title={appText.logoutConfirm}
        onCancel={onClose}
        acceptText={appText.logout}
        onAccept={() => onClose(true)}
        shown
      />
    );
  }
  if (activeModal === BRANCH_PARENT_DELETION_MODAL) {
    return (
      <ConfirmationModal
        title={
          <>
            <p>{appText.replaceSourceTitle}</p>
            <p>{appText.replaceSourceConfirmation}</p>
          </>
        }
        onCancel={onClose}
        acceptText={appText.continue}
        onAccept={() => onClose(true)}
        shown
        cacheAliasForDontShow={BRANCH_VIDEO_PARENT_CONFIRMATION_ALIAS}
      />
    );
  }
  return null;
});

export default ModalProviderUI;
