import {
  BranchFreeform,
  ChoiceFreeformMapped,
  LessonContentChoiceAnswerMapped,
  LessonContentChoiceMapped,
} from '../../data/models/LessonContent/LessonConentMapped';
import {
  BRANCH_BACKGROUND_FREEFORM,
  BRANCH_IMAGE_FREEFORM,
  BRANCH_SHAPE_FREEFORM,
  BRANCH_TEXT_FREEFORM,
  CHALLENGE_BRANCH_AI,
  CHALLENGE_BRANCH_AI_CORRECT,
  CHALLENGE_BRANCH_AI_INCORRECT,
  CHALLENGE_BRANCH_HEADER,
  CHALLENGE_BRANCH_IMAGE_OPTION,
  CHALLENGE_BRANCH_QUESTION,
  CHALLENGE_BRANCH_TEXT_OPTION,
  CHOICE_HEADER_NODE_TYPE,
  CHOICE_IMAGE_OPTION,
  CHOICE_TEXT_OPTION,
  CHOICE_TEXT_QUESTION,
  LessonContentChallengeBranchAI,
  LessonContentChallengeBranchImageOption,
  LessonContentChallengeBranchQuestion,
  LessonContentChallengeBranchTextOption,
  LessonContentChoiceImageOption,
  LessonContentChoiceQuestion,
  LessonContentChoiceTextOption,
  LessonContentFreeformImageChoice,
  LessonContentFreeformShapeChoice,
  LessonContentFreeformTextChoice,
  LessonContentVideoBackgorund,
} from '../../data/models/LessonContent/LessonContentBase';
import {
  LessonContentItem,
  LessonContentList,
} from '../../data/models/LessonContent/LessonContentList';

export const getLessonRoot = (content: LessonContentList) => {
  if (
    !content.contentList ||
    !content.contentList.rootId ||
    !content.contentList.items
  ) {
    return null;
  }
  return content.contentList?.items[content.contentList.rootId];
};

export const getNextLessonNode = (
  content: LessonContentList,
  currentNode: LessonContentItem,
): LessonContentItem | null => {
  const childId = currentNode.childIds ? currentNode.childIds[0] : undefined;
  if (!childId) return null;
  const nextNode = content.contentList?.items[childId];
  return mapCurrentNode(nextNode, content);
};

export const getNodeById = (id: string, content: LessonContentList) => {
  const nextNode = content.contentList?.items[id];
  if (nextNode) {
    return mapCurrentNode(nextNode, content);
  } else return null;
};

const mapCurrentNode = (
  node: LessonContentItem,
  content: LessonContentList,
) => {
  if (!node) {
    return null;
  }
  switch (node.data.ivNodeType) {
    case CHOICE_HEADER_NODE_TYPE:
      return getChoiceBranchMappedNode(node, content);
    case CHALLENGE_BRANCH_HEADER:
      return getChallengeBranchMappedNode(node, content);
    case CHALLENGE_BRANCH_AI:
      return getChallengeBranchAIMapped(node, content);
    case CHOICE_IMAGE_OPTION:
    case CHOICE_TEXT_OPTION:
    case CHALLENGE_BRANCH_IMAGE_OPTION:
    case CHALLENGE_BRANCH_TEXT_OPTION:
      return getChoiceBranchFromOption(node, content);
    case CHOICE_TEXT_QUESTION:
    case CHALLENGE_BRANCH_QUESTION:
      return getChoiceBranchFromQuestion(node, content);
    default:
      return node;
  }
};

const getChoiceBranchFromOption = (
  node: LessonContentItem,
  content: LessonContentList,
) => {
  if (!node.parentsIds || !node.parentsIds.length) {
    return null;
  }
  const parentQuestion = content.contentList.items[node.parentsIds[0]];
  if (
    !parentQuestion ||
    !parentQuestion.parentsIds ||
    !parentQuestion.parentsIds.length
  ) {
    return null;
  }
  const parentHeader = content.contentList.items[parentQuestion.parentsIds[0]];
  if (!parentHeader) {
    return null;
  }
  return getChoiceBranchMappedNode(parentHeader, content);
};

const getChoiceBranchFromQuestion = (
  node: LessonContentItem,
  content: LessonContentList,
) => {
  if (!node.parentsIds || !node.parentsIds.length) {
    return null;
  }
  const parentHeader = content.contentList.items[node.parentsIds[0]];
  if (!parentHeader) {
    return null;
  }
  return getChoiceBranchMappedNode(parentHeader, content);
};

const getChoiceBranchMappedNode = (
  nextNode: LessonContentItem,
  content: LessonContentList,
) => {
  //question
  const choiceHeaderChildId =
    nextNode.childIds && nextNode.childIds[0] ? nextNode.childIds[0] : null;
  if (!choiceHeaderChildId) return null;
  const choiceQuestionNode = content.contentList?.items[choiceHeaderChildId];

  //answers
  const answersIds = choiceQuestionNode?.childIds;
  if (!answersIds || answersIds.length === 0) return null;

  let answersHaveFreeform = false;
  answersIds.forEach(answerId => {
    const answer = content.contentList?.items[answerId];
    if (
      answer &&
      (answer.data.ivNodeType === BRANCH_IMAGE_FREEFORM ||
        answer.data.ivNodeType === BRANCH_TEXT_FREEFORM ||
        answer.data.ivNodeType === BRANCH_BACKGROUND_FREEFORM ||
        answer.data.ivNodeType === BRANCH_SHAPE_FREEFORM)
    ) {
      answersHaveFreeform = true;
    }
  });

  const question =
    choiceQuestionNode.data.ivNodeType === CHOICE_TEXT_QUESTION
      ? (choiceQuestionNode?.data as LessonContentChoiceQuestion).title
      : (choiceQuestionNode?.data as LessonContentChallengeBranchQuestion)
          .question;

  if (answersHaveFreeform) {
    return mapFreeformChoiceAnswers(
      answersIds,
      nextNode.data.internalId,
      nextNode.data.ivNodeType,
      question,
      content,
    );
  } else {
    return mapOldChoiceBranchAnswers(
      answersIds,
      nextNode.data.internalId,
      nextNode.data.ivNodeType,
      question,
      content,
    );
  }
};

const mapOldChoiceBranchAnswers = (
  answersIds: string[],
  id: string,
  type: number,
  title: string,
  content: LessonContentList,
) => {
  const answersNodes: LessonContentChoiceAnswerMapped[] = [];
  answersIds.forEach(answerId => {
    const answer = content.contentList?.items[answerId];
    if (
      answer.data.ivNodeType !== BRANCH_IMAGE_FREEFORM &&
      answer.data.ivNodeType !== BRANCH_SHAPE_FREEFORM &&
      answer.data.ivNodeType !== BRANCH_TEXT_FREEFORM
    ) {
      if (answer && (answer.data as LessonContentChoiceTextOption)?.title) {
        answersNodes.push({
          type: answer.data.ivNodeType,
          text: (answer.data as LessonContentChoiceTextOption)?.title,
          childId:
            answer.childIds && answer.childIds.length > 0
              ? answer.childIds[0]
              : undefined,
          id: answer.data.internalId,
        });
      }
      if (
        answer &&
        (answer.data as LessonContentChoiceImageOption)?.contentUrl
      ) {
        answersNodes.push({
          type: answer.data.ivNodeType,
          imageSource: (answer.data as LessonContentChoiceImageOption)
            ?.contentUrl,
          childId:
            answer.childIds && answer.childIds.length > 0
              ? answer.childIds[0]
              : undefined,
          id: answer.data.internalId,
        });
      }
    }
  });

  const choiceMapped: LessonContentChoiceMapped = {
    internalId: id,
    ivNodeType: type,
    question: title,
    answers: answersNodes,
    isFreeform: false,
  };

  return {
    parentsIds: [],
    data: choiceMapped,
    childIds: [],
  };
};

const mapFreeformChoiceAnswers = (
  answersIds: string[],
  id: string,
  type: number,
  title: string,
  content: LessonContentList,
) => {
  const answersNodes: BranchFreeform[] = [];
  answersIds.forEach(answerId => {
    const answer = content.contentList?.items[answerId];
    if (answer.data.ivNodeType === BRANCH_IMAGE_FREEFORM) {
      answersNodes.push({...(answer.data as LessonContentFreeformImageChoice)});
    }
    if (answer.data.ivNodeType === BRANCH_SHAPE_FREEFORM) {
      answersNodes.push({...(answer.data as LessonContentFreeformTextChoice)});
    }
    if (answer.data.ivNodeType === BRANCH_TEXT_FREEFORM) {
      answersNodes.push({...(answer.data as LessonContentFreeformShapeChoice)});
    }
    if (answer.data.ivNodeType === BRANCH_BACKGROUND_FREEFORM) {
      answersNodes.push({...(answer.data as LessonContentVideoBackgorund)});
    }
  });
  const choiceMapped: ChoiceFreeformMapped = {
    isFreeform: true,
    internalId: id,
    ivNodeType: type,
    question: title,
    answers: answersNodes,
  };

  return {
    parentsIds: [],
    data: choiceMapped,
    childIds: [],
  };
};

const getChallengeBranchMappedNode = (
  nextNode: LessonContentItem,
  content: LessonContentList,
) => {
  //question
  const challengeHeaderChildId =
    nextNode.childIds && nextNode.childIds[0] ? nextNode.childIds[0] : null;
  if (!challengeHeaderChildId) return null;
  const challengeQuestionNode =
    content.contentList?.items[challengeHeaderChildId];

  //answers
  const answersIds = challengeQuestionNode?.childIds;
  if (!answersIds || answersIds.length === 0) return null;

  let answersHaveFreeform = false;
  answersIds.forEach(answerId => {
    const answer = content.contentList?.items[answerId];
    if (
      answer &&
      (answer.data.ivNodeType === BRANCH_IMAGE_FREEFORM ||
        answer.data.ivNodeType === BRANCH_TEXT_FREEFORM ||
        answer.data.ivNodeType === BRANCH_BACKGROUND_FREEFORM ||
        answer.data.ivNodeType === BRANCH_SHAPE_FREEFORM)
    ) {
      answersHaveFreeform = true;
    }
  });

  if (answersHaveFreeform) {
    return mapFreeformChoiceAnswers(
      answersIds,
      nextNode.data.internalId,
      nextNode.data.ivNodeType,
      (challengeQuestionNode?.data as LessonContentChallengeBranchQuestion)
        .question,
      content,
    );
  } else {
    return mapOldChallengeOptions(
      answersIds,
      nextNode.data.internalId,
      nextNode.data.ivNodeType,
      (challengeQuestionNode?.data as LessonContentChallengeBranchQuestion)
        .question,
      content,
    );
  }
};

const mapOldChallengeOptions = (
  answersIds: string[],
  id: string,
  type: number,
  question: string,
  content: LessonContentList,
) => {
  const answersNodes: LessonContentChoiceAnswerMapped[] = [];
  answersIds.forEach(answerId => {
    const answer = content.contentList?.items[answerId];
    if (
      answer &&
      (answer.data as LessonContentChallengeBranchTextOption)?.answer
    ) {
      answersNodes.push({
        type: answer.data.ivNodeType,
        text: (answer.data as LessonContentChallengeBranchTextOption)?.answer,
        childId:
          answer.childIds && answer.childIds.length > 0
            ? answer.childIds[0]
            : undefined,
        isCorrect: (answer.data as LessonContentChallengeBranchTextOption)
          ?.isCorrect,
        id: answer.data.internalId,
      });
    }
    if (
      answer &&
      (answer.data as LessonContentChallengeBranchImageOption)?.contentUrl
    ) {
      answersNodes.push({
        type: answer.data.ivNodeType,
        imageSource: (answer.data as LessonContentChallengeBranchImageOption)
          ?.contentUrl,
        childId:
          answer.childIds && answer.childIds.length > 0
            ? answer.childIds[0]
            : undefined,
        isCorrect: (answer.data as LessonContentChallengeBranchImageOption)
          ?.isCorrect,
        id: answer.data.internalId,
      });
    }
  });

  const choiceMapped: LessonContentChoiceMapped = {
    internalId: id,
    ivNodeType: type,
    question: question,
    answers: answersNodes,
    isFreeform: false,
  };

  return {
    parentsIds: [],
    data: choiceMapped,
    childIds: [],
  };
};

const getChallengeBranchAIMapped = (
  nextNode: LessonContentItem,
  content: LessonContentList,
) => {
  let correctId = '';
  let incorrectId = '';
  let correctChildId = '';
  let incorrectChildId = '';

  nextNode.childIds.forEach(id => {
    if (
      content.contentList.items[id] &&
      content.contentList.items[id].data.ivNodeType ===
        CHALLENGE_BRANCH_AI_CORRECT
    ) {
      correctId = id;
      if (content.contentList.items[id]?.childIds?.length) {
        correctChildId = content.contentList.items[id]?.childIds[0];
      }
    }
    if (
      content.contentList.items[id] &&
      content.contentList.items[id].data.ivNodeType ===
        CHALLENGE_BRANCH_AI_INCORRECT
    ) {
      incorrectId = id;
      if (content.contentList.items[id]?.childIds?.length) {
        incorrectChildId = content.contentList.items[id]?.childIds[0];
      }
    }
  });

  const result = {
    ...(nextNode.data as LessonContentChallengeBranchAI),
    incorrectAnswerPathId: incorrectId,
    correctAnswerPathId: correctId,
    correctChildId,
    incorrectChildId,
  };

  return {
    parentsIds: [],
    data: result,
    childIds: [],
  };
};
