import LessonsRepository from '../../../data/repositories/LessonsRepository';
import {
  CreateLearnerLessonModel,
  LearnerProgressModel,
} from '../../../data/models/LessonContent/CreateLearnerLessonModel';
import CoursesRepository from '../../../data/repositories/CoursesRepository';
import {makeAutoObservable} from 'mobx';
import LoadingModule from '../../../modules/LoadingModule/LoadingModule';

class VideoPlaybackStore {
  private lessonRepository = new LessonsRepository();
  private courseRepository = new CoursesRepository();
  private loadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async getPublishedLessonId(organizationId: string, lessonId: string) {
    const result = await this.lessonRepository.getLessonIsPublishedById(
      organizationId,
      lessonId,
    );
    if (result.success && result.data) {
      return result.data.publishedId;
    }
  }

  public async getLessonPresentation(courseId: string, lessonId: string) {
    this.loadingModule.startLoading();
    const result = await this.lessonRepository.getLessonPresentation(
      courseId,
      lessonId,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async getLessonPreview(
    organizationId: string,
    courseId: string,
    lessonId: string,
  ) {
    this.loadingModule.startLoading();
    const result = await this.lessonRepository.getLessonPreview(
      organizationId,
      courseId,
      lessonId,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async getLearnerProgress(courseId: string, lessonId: string) {
    this.loadingModule.startLoading();
    const result = await this.lessonRepository.getLearnerProgress(
      courseId,
      lessonId,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async createLessonLearner(
    courseId: string,
    model: CreateLearnerLessonModel,
  ) {
    this.loadingModule.startLoading();
    await this.lessonRepository.createLessonLearner(courseId, model);
    this.loadingModule.endLoading();
  }

  public async editLessonLearnerProgress(
    courseId: string,
    lessonId: string,
    model: LearnerProgressModel,
  ) {
    this.loadingModule.startLoading();
    const result = await this.lessonRepository.editLessonLearnerProgress(
      courseId,
      lessonId,
      model,
    );
    this.loadingModule.endLoading();
    return result.data;
  }

  public async getLearnerCourseProgress(courseId: string) {
    this.loadingModule.startLoading();
    const result =
      await this.courseRepository.getLearnerCourseProgress(courseId);
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
  }
}

export default VideoPlaybackStore;
