import React, {useEffect, useState} from 'react';
import SubscriptionFirstPaymentUI from '../view/SubscriptionFirstPaymentUI';
import {useNavigate, useParams} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import SubcsriptionFirstPaymentStore from '../store/SubscriptionFirstPaymentStore';
import {
  SUBSCRIPTION_BUISNESS_BASIC,
  SUBSCRIPTION_BUISNESS_BASIC_TRIAL,
  SUBSCRIPTION_CREATOR,
  SUBSCRIPTION_CREATOR_TRIAL,
} from '../../config';

interface Props {
  subcsriptionFirstPaymentStore?: SubcsriptionFirstPaymentStore;
}

const SubscriptionFirstPaymentContainer = inject(
  'subcsriptionFirstPaymentStore',
)(
  observer(({subcsriptionFirstPaymentStore}: Props) => {
    const [preSelectedTier, setPreSelectedTier] = useState<number>();
    const navigate = useNavigate();
    const {organizationId} = useParams();

    useEffect(() => {
      subcsriptionFirstPaymentStore!.getUserOrganization().then(result => {
        if (
          result &&
          result.selectedSubscriptionType !== undefined &&
          result.selectedSubscriptionType !== null
        ) {
          if (
            result.selectedSubscriptionType ===
              SUBSCRIPTION_BUISNESS_BASIC_TRIAL ||
            result.selectedSubscriptionType === SUBSCRIPTION_CREATOR_TRIAL
          ) {
            onStartTrial(result.selectedSubscriptionType);
          } else {
            setPreSelectedTier(result.selectedSubscriptionType);
          }
        } else {
          onChoosePlan();
        }
      });
    }, []);

    const onChoosePlan = () => {
      setPreSelectedTier(undefined);
      navigate('/subscription_tier', {
        state: {modifyForOrganizationId: organizationId},
      });
    };

    const onPay = async () => {
      if (!organizationId) return;
      if (
        preSelectedTier === SUBSCRIPTION_CREATOR ||
        preSelectedTier === SUBSCRIPTION_BUISNESS_BASIC
      ) {
        const stripeLink =
          await subcsriptionFirstPaymentStore!.getSubscriptionPaymentLink(
            organizationId,
            preSelectedTier,
          );
        window.open(stripeLink, '_self');
      }
    };

    const onStartTrial = async (tier: number) => {
      if (!organizationId) return;
      const trialSuccess =
        await subcsriptionFirstPaymentStore!.subscribeForTrial(
          organizationId,
          tier,
        );
      if (trialSuccess) {
        navigate(`/subscription/${organizationId}/trial_success`);
      }
    };

    return (
      <SubscriptionFirstPaymentUI
        onChoosePlan={onChoosePlan}
        tier={preSelectedTier}
        onPay={onPay}
        isLoading={subcsriptionFirstPaymentStore!.isLoading}
      />
    );
  }),
);

export default SubscriptionFirstPaymentContainer;
