import React from 'react';
import NavBar from '../../../../uiToolkit/NavBar';
import {marketImage} from '../../../../assets/images';
import {marketplaceText} from '../marketplaceText';
import {Col, Container, Row} from 'react-bootstrap';
import './styles.css';
import {marketplaceItems} from '../marketplaceItems';
import Button from '../../../../uiToolkit/Buttons/Button';
import {OrganizationSubscription} from '../../../../data/models/Subscription/Subscription';

interface Props {
  onSelectItem: (addon?: string, quote?: string) => void;
  currentSubscription: OrganizationSubscription;
}

const MarketplaceUI = React.memo(
  ({onSelectItem, currentSubscription}: Props) => {
    return (
      <div>
        <NavBar />
        <Container>
          <Row>
            <Col xs={12}>
              <div className="MarketplaceHeader">
                <img src={marketImage} />
                <div className="TextContainer">
                  <h2 className="Title">{marketplaceText.title}</h2>
                  <h6 className="Subtitle">{marketplaceText.subtitle}</h6>
                </div>
              </div>
            </Col>
            <Row>
              {marketplaceItems(currentSubscription.type).map((item, index) => (
                <Col
                  xs={12}
                  md={6}
                  xl={4}
                  key={index}
                  className="MarketplaceItemCol">
                  <div className="MarketplaceItem">
                    <img src={item.image} />
                    <p>{item.text}</p>
                    <Button
                      title={item.buttonText}
                      onClick={() => onSelectItem(item.addonId, item.quoteLink)}
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </Row>
        </Container>
      </div>
    );
  },
);

export default MarketplaceUI;
