import React, {useEffect, useRef} from 'react';
import {addChallangeAIWizardText} from '../addChallengeAiWizardText';

interface Props {
  mask: string;
  frame: string;
  question: string;
  isSelected?: boolean;
  onSelect: ({answer_mask, question}: any) => void;
}

const GeneratedAnswerItem = React.memo(
  ({mask, frame, question, isSelected, onSelect}: Props) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
      if (canvasRef.current) {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const image = new Image();
        image.onload = () => {
          canvas.width = image.width;
          canvas.height = image.height;
          context?.drawImage(image, 0, 0);
          if (context) {
            context.globalCompositeOperation = 'multiply';
            context.fillStyle = '#FF5858';
            context?.fillRect(0, 0, canvas.width, canvas.height);

            // Restore the transparency
            context.globalCompositeOperation = 'destination-atop';
            context.drawImage(image, 0, 0);
          }
        };
        image.src = `data:image/png;base64, ${mask}`;
      }
    }, [mask]);

    const onClickSelect = () => {
      onSelect({answer_mask: mask, question});
    };

    return (
      <div className="GeneratedAnswerContainer">
        <button className="Select" onClick={onClickSelect}>
          {isSelected && <div className="Selected" />}
        </button>
        <div className="GeneratedImageContainer">
          <img src={`data:image/png;base64, ${frame}`} />
          <canvas ref={canvasRef} width={200} height={200} />
        </div>
        <div className="GeneratedQuestionContainer">
          <p>{addChallangeAIWizardText.generatedQuestion}</p>
          <p>{question}</p>
        </div>
      </div>
    );
  },
);

export default GeneratedAnswerItem;
