export interface FileSourceShort {
  id: string;
  fileName: string;
  fileType: string;
  placeholderUrl?: string;
  fileUrl?: string;
  weight: number;
  dateCreated: Date;
  dateUpdated: Date;
}

export const FILE_MP4 = 'video/mp4';
export const FILE_QUICKTIME = 'video/quicktime';
export const FILE_IMAGE = 'image/jpeg';

export const isVideoFile = (type: string) => {
  return type === FILE_MP4 || type === FILE_QUICKTIME;
};

export const isImageFile = (type: string) => {
  return type.includes('image');
};

export const getVideoTypeText = (type: string) => {
  switch (type) {
    case FILE_MP4:
      return 'mp4';
    case FILE_QUICKTIME:
      return 'mov';
    default:
      return '';
  }
};
