import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  title: 'Add a question for your choice branch',
  inputTitle: 'Choice question',
  placeholder: 'Question',
  cancel: 'Cancel',
  accept: 'Accept',
};

const welsh = {
  title: 'Ychwanegwch gwestiwn ar gyfer eich dewis gangen',
  inputTitle: 'Cwestiwn dewis',
  placeholder: 'Cwestiwn',
  cancel: 'Canslo',
  accept: 'Derbyn',
};

export const addChoiceWizardText: any = getLocalizationBasedOnLang(
  english,
  welsh,
);
