import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  challengeBranch: 'Challenge Branch',
  choiceBranch: 'Choice Branch',
  showImage: 'Show Image',
  showQuestion: 'Show question',
  selectAll: 'Select all that apply',
  infoGradable: 'This question is assessable',
  infoPercent: 'Score needed to pass',
  total: 'Total',
  viewResults: 'View Results',
  back: 'Back',
  retry: 'Retry',
  courseHome: 'Course Home',
  continue: 'Continue',
  lessonCompleted: 'Lesson completed!',
  nextLesson: 'Next Lesson',
  tryAgain: 'Please try again!',
  finalScore: 'Your final score: ',
  wellDone: 'Well done!',
  multipleChoice: 'Multiple Choice',
  gradableLesson: 'This is an assessment-based lesson',
  grabableDescription: 'Your answers will count towards your final score.',
  informativeDescription:
    'This lesson is just for learning, without any grading involved.',
  imReady: "I'm ready",
  informativeLesson: 'Informative Lesson',
  hideQuestion: 'Hide question',
  lessonIsEmpty: 'Lesson is empty',
  finish: 'Finish',
};

const welsh = {
  challengeBranch: 'Cangen Her',
  choiceBranch: 'Cangen Dewis',
  showImage: 'Dangos Delwedd',
  showQuestion: 'Dangos Cwestiwn',
  selectAll: "Dewiswch bawb sy'n berthnasol",
  infoGradable: "Mae'r cwestiwn hwn yn asesadwy",
  infoPercent: 'Sgôr sydd ei hangen i basio',
  total: 'Cyfanswm',
  viewResults: 'Gweld Canlyniadau',
  back: 'Yn ôl',
  retry: 'Ceisio eto',
  courseHome: 'Cartref y Cwrs',
  continue: 'Parhewch',
  lessonCompleted: "Gwers wedi'i gorffen!",
  nextLesson: 'Gwers Nesaf',
  tryAgain: 'Pleidiwch geisio eto!',
  finalScore: 'Eich sgôr derfynol:',
  wellDone: 'Da iawn!',
  multipleChoice: 'Amlddewis',
  gradableLesson: "Mae hon yn wers wedi'i seilio ar asesiad",
  grabableDescription: 'Bydd eich atebion yn cyfrif tuag at eich sgôr derfynol',
  informativeDescription:
    "Mae'r wers hon dim ond ar gyfer dysgu, heb unrhyw raddio'n gysylltiedig",
  imReady: "Rwy'n barod",
  informativeLesson: 'Gwers Wybodaeth',
  hideQuestion: "Cuddio'r cwestiwn",
  lessonIsEmpty: "Mae'r gwers yn wag",
  finish: 'Gorffen',
};

export const tapyblPlayerText: any = getLocalizationBasedOnLang(english, welsh);
