import React from 'react';
import Checkbox from '../../../../uiToolkit/Inputs/Checkbox';
import DataTable from 'react-data-table-component';
import {Learner} from '../../../../data/models/Learners/Learner';
import TablePagination from '../../../../uiToolkit/TablePagination';
import './styles.css';
import {getFirstCapitals} from '../../../../utils/getFirstCapitals';
import {courseLearnerText} from '../courseLearnerText';
import Button from '../../../../uiToolkit/Buttons/Button';
import {ENROLLMENT_LIST_PAGE_SIZE} from '../container/CourseLearnerContainer';
import SearchInput from '../../../../uiToolkit/Inputs/SearchInput';
import CourseNotPublished from '../../components/CourseNotPublished';

interface Props {
  learnerData: Learner[];
  onSave: (selectedLearners: string[], dueDate?: Date) => void;
  pages: number;
  onPageChange: (page: number) => void;
  search: string;
  onSearchChange: (search: string) => void;
  publishedCourse?: any;
}

const columns = (
  checkedLearnerIds: string[],
  setCheckedLearnerIds: React.Dispatch<React.SetStateAction<string[]>>,
) => [
  {
    name: '',
    selector: (row: any) => row.learnerId,
    cell: (row: any) => (
      <div className="LearnerModalColumn">
        <Checkbox
          checked={checkedLearnerIds.includes(row.learnerId)}
          className="Checkbox"
          onCheck={() =>
            handleCheckboxChange(
              row.learnerId,
              checkedLearnerIds,
              setCheckedLearnerIds,
            )
          }
        />
      </div>
    ),
    id: 'CheckboxColumn',
    grow: 0.1,
  },
  {
    name: 'Name',
    selector: (row: Learner) => row.name,
    cell: (row: Learner) => (
      <div className="LearnerModalColumn">
        {row.avatarUrl ? (
          <img
            src={row.avatarUrl}
            alt="User Profile Picture"
            className="LearnerModalColumn"
          />
        ) : (
          <span className="Initials">{getFirstCapitals(row.name || '')}</span>
        )}
        <p className="DataTableText">{row.name}</p>
      </div>
    ),
    id: 'NameColumn',
  },
  {
    name: 'Email',
    selector: (row: any) => row.email,
    cell: (row: any) => <p className="DataTableText">{row.email}</p>,
  },
];

const handleCheckboxChange = (
  learnerId: string,
  checkedLearnerIds: string[],
  setCheckedLearnerIds: React.Dispatch<React.SetStateAction<string[]>>,
) => {
  if (!checkedLearnerIds.includes(learnerId)) {
    setCheckedLearnerIds(prevState => {
      const newState = [...prevState, learnerId];
      return newState;
    });
  } else {
    setCheckedLearnerIds(prevState => {
      const newState = prevState.filter(id => id !== learnerId);
      return newState;
    });
  }
};

const CourseLearnerUI = React.memo(
  ({
    onSave,
    learnerData,
    pages,
    onPageChange,
    search,
    onSearchChange,
    publishedCourse,
  }: Props) => {
    const [checkedLearnerIds, setCheckedLearnerIds] = React.useState<string[]>(
      [],
    );
    const [dueDate, setDueDate] = React.useState<Date | null>(null);

    const onSaveClick = () => {
      onSave(
        [...checkedLearnerIds],
        dueDate instanceof Date ? dueDate : undefined,
      );
      setCheckedLearnerIds([]);
      setDueDate(null);
    };

    return (
      <div className="CourseLearnerContainer">
        {publishedCourse ? (
          <div className="EnrollLearnersWizardContainer">
            <div className="DateSearchContainer">
              {/* <DatePickerComponent
              dueDate={dueDate}
              setDueDate={setDueDate}
              className="SearchInput"
            /> */}
              <SearchInput value={search} onChange={onSearchChange} />
              {/* <Button title={courseLearnerText.invite} onClick={onInvite} /> */}
            </div>
            <DataTable
              data={learnerData}
              columns={columns(checkedLearnerIds, setCheckedLearnerIds)}
              className="AddLearnerTable"
              pagination
              striped
              theme="custom-stripes"
              paginationPerPage={ENROLLMENT_LIST_PAGE_SIZE}
              paginationComponent={TablePagination}
              onChangePage={onPageChange}
              paginationServer
              paginationTotalRows={pages * ENROLLMENT_LIST_PAGE_SIZE}
              noDataComponent={
                <p className="NoLearnersText">
                  {courseLearnerText.cantFindLearners}
                </p>
              }
            />
            <Button
              title={courseLearnerText.enroll}
              onClick={onSaveClick}
              uiType="action"
              disabled={!checkedLearnerIds.length}
              className="SaveButton"
            />
          </div>
        ) : (
          <CourseNotPublished subtitle={courseLearnerText.courseNotPublished} />
        )}
      </div>
    );
  },
);

export default CourseLearnerUI;
