import React, {useState} from 'react';
import DataTable from 'react-data-table-component';
import {Role} from '../../../../data/models/Oganization/Role';
import {settingsText} from '../../settingsText';
import '../../../../uiToolkit/DataTable/styles.css';
import './styles.css';
import ConfirmationModal from '../../../../uiToolkit/ConfirmationModal';
import {getLocalizationBasedOnLang} from '../../../../data/models/User/UserInfo';

const rolesColumns = () =>
  // onDelete?: (role: Role) => void,
  // onEdit?: (id: string) => void,
  [
    {
      name: getLocalizationBasedOnLang('Role', 'Rôl'),
      selector: (row: Role) => row.roleName,
      cell: (row: Role) => <div className="DataTableText">{row.roleName}</div>,
      grow: 2,
    },
    {
      name: 'Users',
      selector: (row: Role) => row.usersAmount || '-',
      cell: (row: Role) => (
        <div className="DataTableTextUsers">{row.usersAmount || '-'}</div>
      ),
      id: 'users',
      grow: 1,
    },
    // {
    //   name: '',
    //   cell: (row: Role) => (
    //     <div>
    //       <button
    //         disabled={row.systemRole}
    //         onClick={() => onDelete(row)}
    //         className={`DataTableActionButton DataTableActionDelete ${
    //           row.systemRole ? 'DataTableActionDisabled' : ''
    //         }`}>
    //         Delete
    //       </button>
    //       <button
    //         onClick={() => onEdit(row.id)}
    //         className={`DataTableActionButton DataTableActionEdit ${
    //           row.systemRole ? 'DataTableActionDisabled' : ''
    //         }`}
    //         disabled={row.systemRole}>
    //         Edit
    //       </button>
    //     </div>
    //   ),
    //   grow: 2,
    // },
  ];

interface Props {
  roles: Role[];
  onCreate?: () => void;
  onEdit?: (id: string) => void;
  onDelete: (id: string) => void;
}

const RolesListUI = React.memo(({roles, onDelete}: Props) => {
  const [roleToDelete, setRoleToDelte] = useState<Role>();

  const onAcceptDelete = () => {
    if (roleToDelete) {
      onDelete(roleToDelete?.id);
      setRoleToDelte(undefined);
    }
  };

  return (
    <div className="RolesListContainer" style={{maxWidth: '60%'}}>
      <ConfirmationModal
        title={`${settingsText.areYouSure}${roleToDelete?.roleName}`}
        onCancel={() => setRoleToDelte(undefined)}
        onAccept={onAcceptDelete}
        shown={roleToDelete !== undefined}
      />
      {roles.length ? (
        <DataTable data={roles} columns={rolesColumns()} />
      ) : null}
      {/* (
         <Skeleton count={5} height={40} style={{marginBottom: '10px'}} />
      )} */}
    </div>
  );
});

export default RolesListUI;
