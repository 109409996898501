import React from 'react';
import ChoiceImageOptionOptionsUI from '../view/ChoiceImageOptionOptionsUI';
import {LessonContentChoiceImageOption} from '../../../../../data/models/LessonContent/LessonContentBase';
import {lessonEditorText} from '../../../lessonEditorText';
import BaseOptionsContainer, {
  BaseContainerProps,
} from '../../BaseOptionsContainer';

export default React.memo((props: BaseContainerProps) => (
  <BaseOptionsContainer
    {...props}
    deleteMessage={lessonEditorText.areYouSureDeleteChoiceImageOption}>
    {forwardProps => (
      <ChoiceImageOptionOptionsUI
        {...forwardProps}
        entityToEdit={
          forwardProps.entityToEdit as LessonContentChoiceImageOption
        }
      />
    )}
  </BaseOptionsContainer>
));
