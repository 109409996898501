import React, {useEffect, useState} from 'react';
import {LessonContentChoiceTextOption} from '../../../../../data/models/LessonContent/LessonContentBase';
import TextInput from '../../../../../uiToolkit/Inputs/TextInput';
import {lessonEditorText} from '../../../lessonEditorText';
import EditorOptionsFooter from '../../../../../uiToolkit/Editor/EditorOptionsFooter';
import {BaseContainerForwardProps} from '../../BaseOptionsContainer';
import ReplaceNode from '../../ReplaceNode';

interface Props extends BaseContainerForwardProps {
  entityToEdit: LessonContentChoiceTextOption;
}

const ChoiceTextOptionOptionsUI = React.memo(
  ({entityToEdit, isLoading, onEdit, onDelete, isPublished}: Props) => {
    const [option, setOption] = useState('');

    useEffect(() => {
      setOption(entityToEdit?.title || '');
    }, [entityToEdit]);

    const changesWasMade = () => entityToEdit?.title !== option;

    const isValid = () => option.trim() !== '';

    const onSave = () => {
      if (isValid()) {
        onEdit({
          ...entityToEdit,
          title: option,
        } as LessonContentChoiceTextOption);
      }
    };

    return (
      <div>
        <TextInput
          heading={lessonEditorText.option}
          value={option}
          onChange={setOption}
          placeholder={lessonEditorText.option}
          fieldError={option.trim() === ''}
          maxCharacters={150}
          isMandatory
          uiType="box"
        />
        <ReplaceNode currentNode={entityToEdit} />
        <EditorOptionsFooter
          onSave={onSave}
          saveEnabled={changesWasMade() && isValid()}
          onDelete={onDelete}
          isLoading={isLoading}
          deleteDisabled={isPublished}
        />
      </div>
    );
  },
);

export default ChoiceTextOptionOptionsUI;
