import React, {useState} from 'react';
import OnboardingUI from '../view/OnboardingUI';
import {useNavigate} from 'react-router-dom';

const OnboardingContainer = React.memo(() => {
  const [selectedAnswer, setSelectedAnswer] = useState<string>();
  const navigate = useNavigate();

  const onContinue = () => {
    navigate(`/home?rp=${selectedAnswer}`);
  };

  const onSkip = () => {
    navigate('/home');
  };

  return (
    <OnboardingUI
      setSelectedAnswer={setSelectedAnswer}
      selectedAnswer={selectedAnswer}
      onContinue={onContinue}
      onSkip={onSkip}
    />
  );
});

export default OnboardingContainer;
