import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

const consoleWarn = console.warn;
const SUPPRESSED_WARNINGS = [
  'styled-components: it looks like an unknown prop "grow" is being sent through to the DOM, which will likely trigger a React console error.',
  'The React Flow parent container needs a width and a height to render the graph',
];

console.warn = function filterWarnings(msg, ...args) {
  if (!SUPPRESSED_WARNINGS.some(entry => msg.includes(entry))) {
    consoleWarn(msg, ...args);
  }
};
