import {
  SUBSCRIPTION_BUISNESS_BASIC,
  SUBSCRIPTION_BUISNESS_BASIC_TRIAL,
  SUBSCRIPTION_BUISNESS_PRO,
  SUBSCRIPTION_CREATOR,
  SUBSCRIPTION_CREATOR_TRIAL,
  SUBSCRIPTION_ENTERPRISE,
} from '../../../pages/Subscription/config';

export const CLASSROOM_NOT_GRADABLE = 0;
export const CLASSROOM_GRADABLE = 1;
export const SCHOOL_NOT_GRADABLE = 2;
export const SCHOOL_GRADABLE = 3;

export interface OrganizationSubscription {
  organizationSubscriptionStatus: number;
  currentPeriodEndDate: string;
  trialStart?: string;
  trialEnd?: string;
  last4?: string;
  qty: number;
  type: number;
  cancelAtPeriodEnd: boolean;
}

export const getSubscriptionNameFromTier = (tier: number) => {
  switch (tier) {
    case SUBSCRIPTION_CREATOR_TRIAL:
      return 'Creator Trial';
    case SUBSCRIPTION_CREATOR:
      return 'Creator';
    case SUBSCRIPTION_BUISNESS_BASIC:
      return 'Business Basic';
    case SUBSCRIPTION_BUISNESS_BASIC_TRIAL:
      return 'Business Trial';
    case SUBSCRIPTION_BUISNESS_PRO:
      return 'Business Pro';
    case SUBSCRIPTION_ENTERPRISE:
      return 'Enterprise';
  }
};

export const SUBSCRIPTION_STATUS_ACTIVE = 0;
export const SUBSCRIPTION_STATUS_CANCELLED = 1;
export const SUBSCRIPTION_STATUS_ENDED = 2;
