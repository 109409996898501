import React, {useContext, useEffect, useRef} from 'react';
import NodeHandles from './NodeHandles';
import {EditorContext} from '../container/LessonEditorContainer';
import {NodeData} from '../models';
import {LessonContentChallengeBranchAI} from '../../../data/models/LessonContent/LessonContentBase';
import {aIassistantSmallIc} from '../../../assets/images';
import {lessonEditorText} from '../lessonEditorText';
import Tooltip from '../../../uiToolkit/Tooltip';

interface Props {
  data: NodeData;
}

const ChallengeBranchAINode = React.memo(({data}: Props) => {
  const context = useContext(EditorContext);

  const onClick = (e: any) => {
    context.setSelectedNode(data.payload, data.type);
    e.stopPropagation();
  };

  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      const image = new Image();
      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        context?.drawImage(image, 0, 0);
        if (context) {
          context.globalCompositeOperation = 'multiply';
          context.fillStyle = '#FF5858';
          context?.fillRect(0, 0, canvas.width, canvas.height);

          // Restore the transparency
          context.globalCompositeOperation = 'destination-atop';
          context.drawImage(image, 0, 0);
        }
      };
      image.src = `data:image/png;base64, ${
        (data.payload as LessonContentChallengeBranchAI).answerMask
      }`;
    }
  }, [data]);

  return (
    <NodeHandles>
      <div className="EditorNodeContainer">
        <div
          onClick={onClick}
          className={`ChallengeBranchAICard ${
            context.selectedNode?.internalId === data.payload.internalId
              ? 'SelectedEditorCard'
              : ''
          }`}>
          <div className="ChallengeBranchAIHeader">
            <span className="icon-typeChallenge-Branch ChallengeIcon"></span>
            <span className="Question">
              {lessonEditorText.challangeBranchHeaderText}
            </span>
            <img src={aIassistantSmallIc} />
          </div>
          <Tooltip
            tooltipText={
              (data.payload as LessonContentChallengeBranchAI).question
            }
            greenTooltip>
            <p>{(data.payload as LessonContentChallengeBranchAI).question}</p>
          </Tooltip>
          <div className="MaskPreview">
            <img
              src={`data:image/png;base64, ${
                (data.payload as LessonContentChallengeBranchAI).frameMask
              }`}
            />
            <canvas ref={canvasRef} />
          </div>
        </div>
      </div>
    </NodeHandles>
  );
});

export default ChallengeBranchAINode;
