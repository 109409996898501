export const downloadBlobFile = (
  blob: any,
  filename: string,
  extrnsion: string,
) => {
  const fileName = filename + extrnsion;
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
