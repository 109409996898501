import React, {useEffect, useState} from 'react';
import ModalContainer from '../../../../uiToolkit/ModalContainer';
import {MediaFile} from '../../../../data/models/LibraryFile/MediaFile';
import TimeInput from '../../../../uiToolkit/Inputs/TimeInput/indes';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';
import Button from '../../../../uiToolkit/Buttons/Button';
import {getSecondsFromTimeText} from '../../../../utils/getTimeTextFromSeconds';
import {addImageNodeWizardText} from '../addImageNodeWizardText';
import './styles.css';
import {MAX_IMAGE_DURATION_SECONDS} from '../../../../data/models/LessonContent/LessonContentBase';

interface Props {
  onClose: () => void;
  image?: MediaFile;
  onSave: (duration: number, title: string) => void;
}

const AddImageNodeUI = React.memo(({onClose, image, onSave}: Props) => {
  const [duration, setDuration] = useState('00:00:01');
  const [title, setTitle] = useState('');

  useEffect(() => {
    setTitle(image?.fileName || 'Image Source');
  }, [image]);

  const saveImage = () => {
    onSave(getSecondsFromTimeText(duration), title);
  };

  const durationIsValid = () => {
    return (
      duration !== '00:00:00.000' &&
      getSecondsFromTimeText(duration) <= MAX_IMAGE_DURATION_SECONDS
    );
  };

  return (
    <ModalContainer onClose={onClose}>
      <div className="AddImageSourceContainer">
        <p className="ImageOptionsHeader">{addImageNodeWizardText.title}</p>
        <div className="d-flex flex-row">
          <img src={image?.fileUrl} className="ImageSource" />
          <div className="AddImageOptionsContainer">
            <TextInput
              value={title}
              onChange={setTitle}
              heading={addImageNodeWizardText.titleInputHeading}
              placeholder={addImageNodeWizardText.titleInputPlaceholder}
              uiType="box"
              fieldError={title.trim() === ''}
              maxCharacters={100}
            />
            <TimeInput
              label={addImageNodeWizardText.durationInputHeading}
              labelTop
              value={duration}
              onChange={setDuration}
              isValid
              className="DurationInput"
              fullWidth
              fieldError={!durationIsValid()}
            />
            <span className="MaxDuration">
              {addImageNodeWizardText.maxDuration}
            </span>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-around mt-4">
          <Button
            uiType="text"
            onClick={onClose}
            title={addImageNodeWizardText.cancel}
          />
          <Button
            uiType="action"
            onClick={saveImage}
            title={addImageNodeWizardText.save}
            disabled={title.trim() === '' || !durationIsValid()}
          />
        </div>
      </div>
    </ModalContainer>
  );
});

export default AddImageNodeUI;
