import React from 'react';
import {Lesson} from '../../../data/models/Course/Lesson';
import TextInput from '../../../uiToolkit/Inputs/TextInput';
import {lessonsText} from '../lessonsText';
import TextAreaInput from '../../../uiToolkit/Inputs/TextAreaInput';
import BoxImagePickerInput from '../../../uiToolkit/Inputs/BoxImagePickerInput';
import YesNoOption from '../../../uiToolkit/Inputs/YesNoOption';
import NumbersInput from '../../../uiToolkit/Inputs/NumbersInput';
import EditorOptionsFooter from '../../../uiToolkit/Editor/EditorOptionsFooter';

interface Props {
  entityToEdit?: Lesson;
  onEditLessonField: (field: string, value: any) => void;
  contentForTab: string;
  onDeleteLesson: () => void;
  saveEnabled: boolean;
  onDiscardChanges: () => void;
  onSaveChanges: () => void;
  isLoading: boolean;
  errors: any;
  courseGradable?: boolean;
  isSupplemental: boolean;
  optionsForType: string;
  isPublished?: boolean;
}

const MAX_TITLE_CHARACTERS = 40;

const LessonsOptions = React.memo(
  ({
    entityToEdit,
    onEditLessonField,
    onDeleteLesson,
    saveEnabled,
    onSaveChanges,
    isLoading,
    errors,
    courseGradable,
    isSupplemental,
  }: Props) => {
    const onPassingScoreChange = (value?: number) => {
      if (value !== undefined) {
        if (value >= 0 && value <= 100) {
          onEditLessonField('passingScore', value);
        }
      } else {
        onEditLessonField('passingScore', undefined);
      }
    };

    return (
      <div className="LessonsOptions">
        <TextInput
          value={entityToEdit?.title || ''}
          onChange={(value: string) => onEditLessonField('title', value)}
          placeholder={lessonsText.lessonTitlePlaceholder}
          heading={lessonsText.lessonTitle}
          fieldError={errors.title}
          maxCharacters={MAX_TITLE_CHARACTERS}
          isMandatory={true}
          uiType="box"
        />
        <TextAreaInput
          className="OptionsSection"
          value={entityToEdit?.description || ''}
          onChange={(value: string) => onEditLessonField('description', value)}
          heading={lessonsText.description}
          placeholder={lessonsText.descriptionPlaceholder}
          fieldError={errors.description}
          isMandatory={true}
          uiType="box"
        />
        <BoxImagePickerInput
          onChange={(value?: File) => onEditLessonField('newImage', value)}
          image={entityToEdit?.newImage}
          existingImage={entityToEdit?.poster}
          className="OptionsSection"
        />
        {courseGradable && !isSupplemental && (
          <>
            <YesNoOption
              onSelect={(value: boolean) =>
                onEditLessonField('isGradable', value)
              }
              title={lessonsText.gradableTitle}
              className="OptionsSection"
              value={entityToEdit?.isGradable || false}
              uiType="box"
              disabled={entityToEdit?.isPublished}
            />
            {entityToEdit?.isGradable && (
              <NumbersInput
                heading={lessonsText.passingScore}
                isMandatory
                value={entityToEdit?.passingScore}
                setValue={onPassingScoreChange}
                fieldError={errors.passingScore}
                className="OptionsSection"
                uiType="box"
                disabled={entityToEdit?.isPublished}
              />
            )}
            {entityToEdit?.isPublished && (
              <p className="GradeDisclaimer">
                {lessonsText.cantChangeScoreOfPublishedLesson}
              </p>
            )}
          </>
        )}
        <EditorOptionsFooter
          onSave={onSaveChanges}
          saveEnabled={saveEnabled}
          deleteTitle={lessonsText.deleteLesson}
          onDelete={onDeleteLesson}
          isLoading={isLoading}
        />
      </div>
    );
  },
);

export default LessonsOptions;
