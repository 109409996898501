export interface UserInfo {
  id: string;
  name: string;
  email: string;
  password: string;
  avatarUrl?: string;
  preferredLanguage: number;
}

export const LANGUAGE_ENGLISH = '0';
export const LANGUAGE_WELSH = '1';

export const LANGUAGE_OPTIONS = [
  {
    key: '0',
    value: 'English',
  },
  {
    key: '1',
    value: 'Welsh',
  },
];

export const LANGUAGE_STORAGE_KEY = 'user_lang';

export const getLocalizationBasedOnLang = (english: any, welsh: any) => {
  if (localStorage.getItem(LANGUAGE_STORAGE_KEY) === LANGUAGE_ENGLISH) {
    return english;
  } else if (localStorage.getItem(LANGUAGE_STORAGE_KEY) === LANGUAGE_WELSH) {
    return welsh;
  }
  return english;
};
