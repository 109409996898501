import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import EditVideoSourceUI from '../view/EditVideoSourceUI';
import EditVideoSourceStore from '../store/EditVideoSourceStore';
import {useNavigate, useParams} from 'react-router-dom';
import {GeneratedVideoChunk} from '../../../data/models/LibraryFile/GeneratedVideoChunk';
import MediaPreview from '../../../modules/MediaPreview';
import ChunksFullPreview from '../components/ChunksFullPreview';
import ConfirmationModal from '../../../uiToolkit/ConfirmationModal';
import {editVideoSourceText} from '../editVideoSourceText';
import {appText} from '../../../assets/text/appText';
import {MediaFile} from '../../../data/models/LibraryFile/MediaFile';

interface Props {
  editVideoSourceStore?: EditVideoSourceStore;
}

const EditVideoSourceContainer = inject('editVideoSourceStore')(
  observer(({editVideoSourceStore}: Props) => {
    const [chunks, setChunks] = useState<GeneratedVideoChunk[]>([]);
    const [chunkToPreview, setChunkToPreview] = useState<GeneratedVideoChunk>();
    const [showFullPreview, setShowFullPreview] = useState(false);
    const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
    const [meidaFile, setMediaFile] = useState<MediaFile>();
    const {organizationId, mediaFileId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      fetchChunks();
      if (organizationId && mediaFileId) {
        editVideoSourceStore!
          .getMediaFile(organizationId, mediaFileId)
          .then(setMediaFile);
      }
    }, []);

    const fetchChunks = () => {
      if (organizationId && mediaFileId) {
        editVideoSourceStore!
          .getVideoChunks(organizationId, mediaFileId)
          .then(chunks => {
            setChunks(chunks.sort((x, y) => (x.order > y.order ? 1 : -1)));
          });
      }
    };

    const onBack = () => {
      navigate(-1);
    };

    const onPublish = () => {
      if (organizationId && mediaFileId) {
        editVideoSourceStore!
          .compileVideoChunks(organizationId, mediaFileId)
          .then(success => {
            if (success) {
              onBack();
            }
          });
      }
    };

    const onClosePreview = () => {
      setChunkToPreview(undefined);
    };

    const onPreviewFull = () => {
      setShowFullPreview(true);
    };

    const onCloseFullPreview = () => {
      setShowFullPreview(false);
    };

    const toggleIncludeChunk = (chunk: GeneratedVideoChunk) => {
      const updatedChunk: GeneratedVideoChunk = {
        ...chunk,
        include: !chunk.include,
      };
      if (organizationId && mediaFileId) {
        editVideoSourceStore!
          .updateVideoChunksForGeneratedContent(organizationId, mediaFileId, [
            updatedChunk,
          ])
          .then(fetchChunks);
      }
    };

    const onChunksReorder = async (chunks: GeneratedVideoChunk[]) => {
      const newChunks = chunks.map((chunk, index) => ({
        ...chunk,
        order: index,
      }));
      setChunks([...newChunks]);
      if (organizationId && mediaFileId) {
        editVideoSourceStore!.updateVideoChunksForGeneratedContent(
          organizationId,
          mediaFileId,
          newChunks,
        );
      }
    };

    return (
      <>
        <EditVideoSourceUI
          onBack={onBack}
          onPublish={() => setShowSaveConfirmation(true)}
          chunks={chunks}
          toggleIncludeChunk={toggleIncludeChunk}
          onChunksReorder={onChunksReorder}
          onPreview={setChunkToPreview}
          isLoading={editVideoSourceStore!.isLoading}
          onPreviewFull={onPreviewFull}
          meidaFile={meidaFile}
        />
        {chunkToPreview && (
          <MediaPreview
            fileType="video/mp4"
            fileUrl={chunkToPreview.uri}
            onClose={onClosePreview}
          />
        )}
        <ChunksFullPreview
          show={showFullPreview}
          chunks={chunks}
          onClose={onCloseFullPreview}
        />
        <ConfirmationModal
          title={editVideoSourceText.areYouSure}
          acceptText={appText.accept}
          onAccept={onPublish}
          onCancel={() => setShowSaveConfirmation(false)}
          shown={showSaveConfirmation}
        />
      </>
    );
  }),
);

export default EditVideoSourceContainer;
