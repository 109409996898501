import React from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';

interface Indexable {
  id: string;
}

interface Props<T extends Indexable> {
  items: T[];
  renderHeader?: (isGragging: boolean) => React.ReactElement;
  renderFooter?: (isGragging: boolean) => React.ReactElement;
  renderItem: (item: T, isDragging?: boolean) => React.ReactElement;
  onItemsReordered: (items: T[]) => void;
}

function FoldersDraggableList<T extends Indexable>({
  items,
  renderFooter,
  renderHeader,
  onItemsReordered,
  renderItem,
}: Props<T>) {
  const handleDrop = (droppedItem: DropResult) => {
    if (!droppedItem.destination) return;
    const itemIndex = items.findIndex(x => x.id === droppedItem.draggableId);
    if (itemIndex === droppedItem.destination.index) return;
    const updatedList = [...items];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    onItemsReordered(updatedList);
  };

  return (
    <div className="EditorDraggableList">
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="ListContainer">
          {(provided, snapshot) => (
            <div
              className="ListContainer"
              {...provided.droppableProps}
              ref={provided.innerRef}>
              {renderHeader && renderHeader(snapshot.isUsingPlaceholder)}
              {items.map((item, index) => (
                <div key={item.id}>
                  <Draggable draggableId={item.id || ''} index={index}>
                    {provided => (
                      <>
                        <div
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}>
                          {renderItem(item, snapshot.isUsingPlaceholder)}
                        </div>
                      </>
                    )}
                  </Draggable>
                </div>
              ))}
              {provided.placeholder}
              {renderFooter && renderFooter(snapshot.isUsingPlaceholder)}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default FoldersDraggableList;
