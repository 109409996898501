import React from 'react';
import AddVideoSourceWizardStore from '../store/AddVideoSourceWizardStore';
import {inject, observer} from 'mobx-react';
import CropVideoStepUI from '../view/CropVideoStepUI';
import {useParams} from 'react-router-dom';

interface Props {
  addVideoSourceWizardStore?: AddVideoSourceWizardStore;
}

const AddVideoSourceWizardContainer = inject('addVideoSourceWizardStore')(
  observer(({addVideoSourceWizardStore}: Props) => {
    const {organizationId, lessonId} = useParams();

    const onSave = async (start: number, end: number) => {
      const file = addVideoSourceWizardStore!.getSelectedFile();
      if (organizationId && lessonId && file) {
        await addVideoSourceWizardStore!.addLessonVideoNode(
          organizationId,
          lessonId,
          file,
          start,
          end,
        );
      }
      const vimeoFile = addVideoSourceWizardStore!.getSelectedVimeoFile();
      if (organizationId && lessonId && vimeoFile) {
        await addVideoSourceWizardStore!.addLessonVimeoNode(
          organizationId,
          lessonId,
          vimeoFile,
          start,
          end,
        );
      }
    };

    const onCancel = () => {
      addVideoSourceWizardStore!.cancelWizard();
    };

    return addVideoSourceWizardStore!.showWizard ? (
      <CropVideoStepUI
        selectedFile={addVideoSourceWizardStore!.getSelectedFile()}
        selectedVimeoFile={addVideoSourceWizardStore!.getSelectedVimeoFile()}
        onCancel={onCancel}
        onSave={onSave}
        isLoading={addVideoSourceWizardStore!.isLoading}
      />
    ) : null;
  }),
);

export default AddVideoSourceWizardContainer;
