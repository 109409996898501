import {makeAutoObservable, runInAction} from 'mobx';
import {
  BRANCH_IMAGE_FREEFORM,
  BRANCH_SHAPE_FREEFORM,
  BRANCH_TEXT_FREEFORM,
  LessonContentBase,
  LessonContentFreeformImageChoice,
  LessonContentFreeformShapeChoice,
  LessonContentFreeformTextChoice,
} from '../../../../data/models/LessonContent/LessonContentBase';
import {getImageMeta} from '../../../../utils/getImageMeta';
import {addFreeformChoiceText} from '../addFreeformChoiceText';
import LessonContentRepository from '../../../../data/repositories/LessonContentRepository';
import LoadingModule from '../../../LoadingModule/LoadingModule';
import {LessonContentList} from '../../../../data/models/LessonContent/LessonContentList';
import {getParentIsChallenge} from '../utlis';
import {ApiResponse} from '../../../../data/models/ApiResponse';

class AddFreeformChoiceStore {
  private lessonContentRepo = new LessonContentRepository();

  private loadingModule = new LoadingModule();

  private wizardActive = false;
  private isEditing = false;
  private parentId?: string = undefined;
  private onFinishedCallback?: () => void = undefined;
  private nodeToReplace?: LessonContentBase;
  public currentNode?:
    | LessonContentFreeformImageChoice
    | LessonContentFreeformShapeChoice
    | LessonContentFreeformTextChoice = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  public get wizardShown() {
    return this.wizardActive;
  }

  public get parentNodeId() {
    return this.parentId;
  }

  public get inEditMode() {
    return this.isEditing;
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public get nodeToReplaceId() {
    return this.nodeToReplace?.internalId;
  }

  public showWizard(
    parentId: string,
    nodeType: number,
    onFinishedCallback: () => void,
    lessonContent: LessonContentList,
    imageSource?: {id: string; url: string},
    nodeToReplace?: LessonContentBase,
  ) {
    this.wizardActive = true;
    this.parentId = parentId;
    this.onFinishedCallback = onFinishedCallback;
    this.nodeToReplace = nodeToReplace;
    this.scaffoldNode(nodeType, lessonContent, imageSource);
  }

  public showWizardForEdit(
    node:
      | LessonContentFreeformImageChoice
      | LessonContentFreeformShapeChoice
      | LessonContentFreeformTextChoice,
    onFinishedCallback: () => void,
  ) {
    this.wizardActive = true;
    this.onFinishedCallback = onFinishedCallback;
    this.currentNode = node;
    this.isEditing = true;
  }

  public closeWizard() {
    this.wizardActive = false;
    this.parentId = undefined;
    this.currentNode = undefined;
    this.onFinishedCallback = undefined;
    this.isEditing = false;
  }

  public async saveNode(
    organizationId: string,
    lessonId: string,
    node: LessonContentBase,
  ) {
    if (this.isEditing) {
      this.editNode(organizationId, lessonId, node);
    } else {
      this.createNode(organizationId, lessonId, node);
    }
  }

  private async editNode(
    organizationId: string,
    lessonId: string,
    node: LessonContentBase,
  ) {
    this.loadingModule.startLoading();
    const result = await this.lessonContentRepo.editLessonContent(
      organizationId,
      lessonId,
      node,
    );
    this.loadingModule.endLoading();
    if (result.success && this.onFinishedCallback) {
      this.onFinishedCallback();
      this.closeWizard();
    }
  }

  private async createNode(
    organizationId: string,
    lessonId: string,
    node: LessonContentBase,
  ) {
    if (!this.parentId) return;
    this.loadingModule.startLoading();
    const model = {
      parentsIds: [this.parentId],
      data: node,
    };
    if (this.nodeToReplace) {
      const result = await this.lessonContentRepo.editLessonContent(
        organizationId,
        lessonId,
        {...model.data, internalId: this.nodeToReplace.internalId},
      );
      this.processResult(result);
    } else {
      const result = await this.lessonContentRepo.addLessonContent(
        organizationId,
        lessonId,
        model,
      );
      this.processResult(result);
    }
    this.loadingModule.endLoading();
  }

  private async scaffoldNode(
    type: number,
    lessonContent: LessonContentList,
    imageSource?: {id: string; url: string},
  ) {
    if (!this.parentId) return;
    const parentIsChallenge = getParentIsChallenge(
      this.parentId,
      lessonContent,
    );
    if (type === BRANCH_IMAGE_FREEFORM && imageSource) {
      const imageMeta = await getImageMeta(imageSource.url);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const node: LessonContentFreeformImageChoice = {
        ivNodeType: type,
        contentId: imageSource.id,
        contentUrl: imageSource.url,
        x: 100,
        y: 100,
        width: 100,
        aspect: imageMeta.naturalWidth / imageMeta.naturalHeight,
        title: addFreeformChoiceText.imageChoice,
        encodedAtWidth: 0,
        isCorrect: parentIsChallenge ? false : null,
      };
      runInAction(() => {
        this.currentNode = node;
      });
    }
    if (type === BRANCH_TEXT_FREEFORM) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const node: LessonContentFreeformTextChoice = {
        ivNodeType: type,
        text: addFreeformChoiceText.freeformText,
        fontSize: 14,
        x: 100,
        y: 100,
        color: '#078482',
        encodedAtWidth: 0,
        isCorrect: parentIsChallenge ? false : null,
      };
      runInAction(() => {
        this.currentNode = node;
      });
    }
    if (type === BRANCH_SHAPE_FREEFORM) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const node: LessonContentFreeformShapeChoice = {
        ivNodeType: type,
        overlayCoordinates: [],
        title: addFreeformChoiceText.freeformShape,
        overlayVisible: false,
        encodedAtWidth: 0,
        isCorrect: parentIsChallenge ? false : null,
      };
      runInAction(() => {
        this.currentNode = node;
      });
    }
  }

  private processResult = (result: ApiResponse<any>) => {
    if (result.success && this.onFinishedCallback) {
      this.onFinishedCallback();
      this.closeWizard();
    }
  };
}

export default AddFreeformChoiceStore;
