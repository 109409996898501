import {ApiResponse} from '../models/ApiResponse';
import {Folder, FolderCreateModel} from '../models/Course/Folder';
import {apiClient, withAuth} from './baseApiClient';

class FoldersApi {
  public async getCourseFolders(
    organizationId: string,
    courseId: string,
  ): Promise<ApiResponse<Folder[]>> {
    const headers = await withAuth({});
    return apiClient.get(
      `/folders/${organizationId}/list?courseId=${courseId}`,
      {headers},
    );
  }

  public async editFolder(
    organizationId: string,
    folder: Folder,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.put(`/folders/${organizationId}/edit`, folder, {headers});
  }

  public async deleteFolder(
    organizationId: string,
    courseId: string,
    folderId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.delete(
      `/folders/${organizationId}/delete/${courseId}?folderId=${folderId}`,
      {headers},
    );
  }

  public async uploadFolderPoster(
    organizationId: string,
    poster: File,
    courseId: string,
    folderId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    const form = new FormData();
    form.append('poster', poster);
    form.append('courseId', courseId);
    form.append('folderId', folderId);
    return apiClient.post(
      `/folders/${organizationId}/poster?folderId=${folderId}`,
      form,
      {headers},
    );
  }

  public async getFolderById(
    organizationId: string,
    folderId: string,
  ): Promise<ApiResponse<Folder>> {
    const headers = await withAuth({});
    return apiClient.get(
      `/folders/${organizationId}/get?folderId=${folderId}`,
      {
        headers,
      },
    );
  }

  public async createFolder(
    organizationId: string,
    folder: FolderCreateModel,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.post(`/folders/${organizationId}/create`, folder, {
      headers,
    });
  }

  public async reorderFolders(
    organizationId: string,
    courseId: string,
    folders: Folder[],
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.put(
      `/folders/${organizationId}/reorder/${courseId}`,
      folders,
      {
        headers,
      },
    );
  }
}

export default FoldersApi;
