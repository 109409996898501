import React, {useContext} from 'react';
import Tooltip from '../../../uiToolkit/Tooltip';
import NodeHandles from './NodeHandles';
import {EditorContext} from '../container/LessonEditorContainer';
import {NodeData} from '../models';
import {
  BRANCH_BACKGROUND_FREEFORM,
  LessonContentVideoBackgorund,
} from '../../../data/models/LessonContent/LessonContentBase';
import {lessonEditorText} from '../lessonEditorText';
import {LessonContentList} from '../../../data/models/LessonContent/LessonContentList';

interface Props {
  data: NodeData;
}

const ChoiceVideoBackground = React.memo(({data}: Props) => {
  const context = useContext(EditorContext);

  const onClick = (e: any) => {
    context.setSelectedNode(data.payload, data.type);
    e.stopPropagation();
  };

  const getNode = () => {
    return data.payload as LessonContentVideoBackgorund;
  };

  const checkForOtherBackground = () => {
    let hasAnotherBackgorund = false;
    const content: LessonContentList = context.lessonContent;
    if (!content || !content.contentList || !content.contentList.items) {
      return hasAnotherBackgorund;
    }
    const parent = Object.keys(content.contentList.items).find(
      key =>
        content.contentList.items[key]?.childIds?.includes(
          data.payload.internalId,
        ),
    );
    if (parent) {
      const parentNode = content.contentList.items[parent];
      parentNode?.childIds.forEach(id => {
        if (
          id !== data.payload.internalId &&
          content.contentList.items[id].data.ivNodeType ===
            BRANCH_BACKGROUND_FREEFORM
        ) {
          hasAnotherBackgorund = true;
        }
      });
    }
    return hasAnotherBackgorund;
  };

  return (
    <NodeHandles>
      <div className="EditorNodeContainer">
        {getNode().isCorrect !== null && (
          <div>
            {getNode().isCorrect ? (
              <span className="icon-typeCheck ChallengeCorrect"></span>
            ) : (
              <span className="icon-typeClose ChallengeIncorrect"></span>
            )}
          </div>
        )}
        <div
          onClick={onClick}
          className={`EditorCard ${
            context.selectedNode?.internalId === data.payload.internalId
              ? 'SelectedEditorCard'
              : ''
          }`}>
          <Tooltip tooltipText={lessonEditorText.videoBackground} greenTooltip>
            <p className="ChoiceQuestionNode">
              {lessonEditorText.videoBackgroundShort}
            </p>
          </Tooltip>
          {checkForOtherBackground() && (
            <div className="DuplicateBackgorundError">
              <span className="icon-typeWarning"></span>
              <span>{lessonEditorText.duplicateBackgorundOption}</span>
            </div>
          )}
        </div>
      </div>
    </NodeHandles>
  );
});

export default ChoiceVideoBackground;
