import {makeAutoObservable} from 'mobx';
import LearnerRepository from '../../../../data/repositories/LearnerRepository';
import {toast} from 'react-toastify';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';

class CourseLearnerStore {
  private learnerRepository = new LearnerRepository();
  private callback?: () => void;

  constructor() {
    makeAutoObservable(this);
  }

  public async saveLearners(
    selectedLearners: string[],
    organizationId: string,
    courseId: string,
    dueDate?: Date,
  ) {
    const result = await this.learnerRepository.addLearnersToCourse(
      selectedLearners,
      organizationId,
      courseId,
      dueDate,
    );
    if (result.success) {
      toast.success('Learner(s) added successfully', toastConfig);

      if (this.callback) {
        this.callback();
      }
      return result.success;
    } else {
      toast.error('There was an issue adding learner(s)', toastConfig);
      return result.success;
    }
  }

  public async getOrganizationLearners(
    organizationId: string,
    courseId: string,
    page: number,
    pageSize: number,
    search?: string,
  ) {
    const result = await this.learnerRepository.getEnrollmentCandidates(
      organizationId,
      courseId,
      page,
      pageSize,
      search,
    );

    if (result.success && result.data) {
      return result.data;
    }

    return {
      data: [],
      pagesCount: 0,
    };
  }
}

export default CourseLearnerStore;
