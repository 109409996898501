import React, {useEffect, useState} from 'react';
import AnswerBox from '../../components/AnswerBox';
import {
  LessonContentMChoice,
  LessonContentMChoiceAnswer,
} from '../../../../data/models/LessonContent/LessonContentBase';
import AnswerCircle from '../../components/AnswerCircle';
import Button from '../../../../uiToolkit/Buttons/Button';
import './styles.css';
import ChallengeInfoHeader from '../ChallengeInfoHeader/ChallengeInfoHeader';
import {tapyblPlayerText} from '../../tapyblPlayerText';

interface Props {
  mChoice: LessonContentMChoice;
  onSelectChoice: (choices: LessonContentMChoiceAnswer[]) => void;
  passingScore?: number;
}

const MCHoiceOverlay = React.memo(
  ({mChoice, onSelectChoice, passingScore}: Props) => {
    const [selectedAnswers, setSelectedAnswers] = useState<
      LessonContentMChoiceAnswer[]
    >([]);

    useEffect(() => {
      setSelectedAnswers([]);
    }, [mChoice]);

    const onClick = () => {
      onSelectChoice(selectedAnswers);
    };

    const onSelectAnswer = (answer: LessonContentMChoiceAnswer) => {
      if (selectedAnswers.find(item => answer.value === item.value)) {
        const newAnswers = [...selectedAnswers];
        newAnswers.splice(
          selectedAnswers.findIndex(item => answer.value === item.value),
          1,
        );
        setSelectedAnswers(newAnswers);
      } else {
        const newAnswers = [...selectedAnswers];
        newAnswers.push(answer);
        setSelectedAnswers(newAnswers);
      }
    };

    const onSelectSingleAnswer = (answer: LessonContentMChoiceAnswer) => {
      setSelectedAnswers([answer]);
    };

    const haveOnlyOneAnswer = () => {
      let amountOfRightAnswers = 0;
      mChoice.answers.forEach(answer => {
        if (answer.isCorrect) {
          amountOfRightAnswers++;
        }
      });
      return amountOfRightAnswers === 1;
    };

    return (
      <div className="MChoiceOverlay">
        <ChallengeInfoHeader
          passingScore={passingScore}
          typeIcon="icon-typeMultiple-choice"
          typeTitle={tapyblPlayerText.multipleChoice}
          haveMultipleAnswers={!haveOnlyOneAnswer()}
          isGradable
        />
        <div className="Content">
          <p className="Question">{mChoice.question}</p>
          <div className="Answers">
            {mChoice.answers.map((item, index) =>
              haveOnlyOneAnswer() ? (
                <AnswerCircle
                  answer={item.value}
                  selected={
                    selectedAnswers.find(
                      answer => answer.value === item.value,
                    ) !== undefined
                  }
                  key={index.toString()}
                  onClick={() => onSelectSingleAnswer(item)}
                />
              ) : (
                <AnswerBox
                  answer={item.value}
                  selected={
                    selectedAnswers.find(
                      answer => answer.value === item.value,
                    ) !== undefined
                  }
                  key={index.toString()}
                  onClick={() => onSelectAnswer(item)}
                />
              ),
            )}
          </div>
          <Button
            title={tapyblPlayerText.continue}
            onClick={onClick}
            className="AnswersSubmit"
            disabled={selectedAnswers.length === 0}
            uiType="secondary"
          />
        </div>
      </div>
    );
  },
);

export default MCHoiceOverlay;
