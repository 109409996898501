import React from 'react';
import './styles.css';

interface Props {
  text?: string | React.ReactElement;
  onCheck: () => void;
  checked: boolean;
  slim?: boolean;
  className?: string;
  color?: string;
  radio?: boolean;
}

const Checkbox = React.memo(
  ({text, onCheck, checked, slim, className, color, radio}: Props) => {
    return (
      <div
        className={`CheckboxContainer ${className ? className : ''}`}
        onClick={onCheck}>
        <div
          className={radio ? 'CheckboxOuterCircle' : 'CheckboxOuterBox'}
          style={color ? {borderColor: color} : undefined}>
          {checked ? (
            <div
              className={radio ? 'CheckboxCircleChecked' : 'CheckboxChecked'}
            />
          ) : (
            <div />
          )}
        </div>
        {text && (
          <p
            className={`CheckboxText ${slim ? 'Slim' : ''}`}
            style={color ? {color} : undefined}>
            {text}
          </p>
        )}
      </div>
    );
  },
);

export default Checkbox;
