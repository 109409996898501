import React from 'react';
import DataTable from 'react-data-table-component';
import {
  LearnerListItem,
  LearnerListItemCourse,
} from '../../../../data/models/Learners/Learner';
import TablePagination from '../../../../uiToolkit/TablePagination';
import {listLearnersText} from '../listLearnersText';
import IconButton from '../../../../uiToolkit/Buttons/IconButton';
import {deleteIcon} from '../../../../assets/images';
import './styles.css';
import Tooltip from '../../../../uiToolkit/Tooltip';

interface Props {
  learners: LearnerListItem[];
  onDelete: (id: string) => void;
  onDeleteFromCourse: (learnerId: string, courseId: string) => void;
  pages: number;
  onPageChange: (page: number) => void;
  courseFilterSelected?: boolean;
  onActivateLearner: (learnerId: string) => void;
  onReEnroll: (courseId: string, learnerId: string) => void;
  onCopyEmail: (email: string) => void;
}

const columns = (
  onDelete: (id: string) => void,
  onActivate: (id: string) => void,
  onCopyEmail: (email: string) => void,
) => [
  {
    name: 'Name',
    selector: (row: LearnerListItem) => row.name,
    cell: (row: LearnerListItem) => (
      <div className="TableNameContainer" data-tag="allowRowEvents">
        {row.avatar ? (
          <img src={row.avatar} alt={'Profile'} data-tag="allowRowEvents" />
        ) : (
          <div className="LearnerAvatarPlaceholder">
            <span>
              {row.name && row.name.length ? row.name[0].toUpperCase() : ''}
            </span>
          </div>
        )}
        <h5 className="TableText" data-tag="allowRowEvents">
          {row.name}
        </h5>
      </div>
    ),
  },
  {
    name: 'Email',
    selector: (row: LearnerListItem) => row.email,
    cell: (row: LearnerListItem) => (
      <div
        style={{maxWidth: '100%', cursor: 'pointer'}}
        onClick={() => onCopyEmail(row.email)}>
        <Tooltip tooltipText={listLearnersText.copyEmail}>
          <div className="DataTableText">{row.email}</div>
        </Tooltip>
      </div>
    ),
  },
  {
    name: 'Status',
    selector: (row: LearnerListItem) => row.status,
    cell: (row: LearnerListItem) => (
      <h5
        className={`TableText ${
          row.status === 0
            ? 'ActiveLearner'
            : row.status === 1
            ? 'InactiveLearner'
            : 'InvitedStatus'
        }`}
        data-tag="allowRowEvents">
        {row.status === 0
          ? listLearnersText.active
          : row.status === 1
          ? listLearnersText.inactive
          : listLearnersText.invited}
      </h5>
    ),
    id: 'learner_status',
  },

  {
    name: 'Actions',
    cell: (row: LearnerListItem) => (
      <div className="Actions">
        {row.status === 1 && (
          <div className="ActivateContainer">
            <IconButton
              onClick={() => onActivate(row.learnerId || '')}
              icon="icon-typeAdd-learner"
              tooltip={listLearnersText.activate}
            />
          </div>
        )}
        {row.status === 0 && (
          <IconButton
            onClick={() => onDelete(row.learnerId || '')}
            icon={deleteIcon}
            tooltip={listLearnersText.deleteLearner}
          />
        )}
      </div>
    ),
    id: 'course_actions',
    grow: 2,
  },
];

interface CourseStatsTableProps {
  data: LearnerListItem;
  onDelete: (id: string) => void;
  onReEnroll: (courseId: string, learnerId: string) => void;
}

const CourseStatusTable = ({
  data,
  onDelete,
  onReEnroll,
}: CourseStatsTableProps) => {
  const columns = [
    {
      name: 'Course Name',
      selector: (row: LearnerListItemCourse) => row.name,
      cell: (row: LearnerListItemCourse) => (
        <Tooltip tooltipText={row.name}>
          <h5 className={'TableText'} data-tag="allowRowEvents">
            {row.name}
          </h5>
        </Tooltip>
      ),
    },
    {
      name: 'Progress',
      selector: (row: LearnerListItemCourse) => row.progress + '%',
      id: 'course_progress',
    },
    {
      name: 'Status',
      selector: (row: LearnerListItemCourse) => row.status,
      cell: (row: LearnerListItemCourse) => (
        <h5
          className={`TableText ${
            row.status === 0 ? 'ActiveLearner' : 'InactiveLearner' //Replace !row.isVerified with row.learner.isVerified when ready
          }`}
          data-tag="allowRowEvents">
          {row.status === 0
            ? listLearnersText.active
            : listLearnersText.inactive}
        </h5>
      ),
      id: 'course_progress',
    },
    {
      name: 'Completed Lessons',
      selector: (row: LearnerListItemCourse) => row.finishedLessons,
      id: 'course_finished_lessons',
    },
    {
      name: 'Actions',
      cell: (row: LearnerListItemCourse) =>
        row.status === 0 ? (
          <div>
            <IconButton
              onClick={() => onDelete(row.courseId)}
              icon={deleteIcon}
              tooltip={listLearnersText.removeFromCourse}
            />
          </div>
        ) : (
          <div className="ActivateContainer">
            <IconButton
              onClick={() => onReEnroll(row.courseId, data.learnerId)}
              icon="icon-typeAdd-learner"
              tooltip={
                data.status === 1
                  ? listLearnersText.activateLearnerToActivateEnrollment
                  : listLearnersText.activateEnrollment
              }
              disabled={data.status === 1}
            />
          </div>
        ),
      id: 'course_actions',
    },
  ];

  return (
    <DataTable
      data={data.courses.sort((x: any, y: any) =>
        x.name.toLowerCase() > y.name.toLowerCase() ? 1 : -1,
      )}
      columns={columns}
      className="SubTable"
      noDataComponent={
        <p className="NoCoursesText">{listLearnersText.learnerNotEnrolled}</p>
      }
    />
  );
};

const StudentsTable = React.memo(
  ({
    learners,
    onDelete,
    pages,
    onPageChange,
    onDeleteFromCourse,
    courseFilterSelected,
    onActivateLearner,
    onReEnroll,
    onCopyEmail,
  }: Props) => {
    return (
      <DataTable
        data={learners}
        columns={columns(onDelete, onActivateLearner, onCopyEmail)}
        className="Table"
        onChangePage={onPageChange}
        pagination
        paginationServer
        paginationPerPage={10}
        paginationTotalRows={pages * 10}
        paginationComponent={TablePagination}
        expandableRows
        expandableRowsComponent={({data}: any) => (
          <CourseStatusTable
            data={data}
            onDelete={(courseId: string) =>
              onDeleteFromCourse(data.learnerId, courseId)
            }
            onReEnroll={onReEnroll}
          />
        )}
        striped
        noDataComponent={
          <p className="NoLearners">
            {courseFilterSelected
              ? listLearnersText.noLearnersInCourse
              : listLearnersText.noLearners}
          </p>
        }
      />
    );
  },
);

export default StudentsTable;
