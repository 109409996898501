import React from 'react';
import {settingsText} from '../../settingsText';
import {Col, Row} from 'react-bootstrap';
import './styles.css';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';
import {formatDateTimeTextFormat} from '../../../../utils/notificationTimeTextConvertor';
import {
  getSubscriptionNameFromTier,
  OrganizationSubscription,
  SUBSCRIPTION_STATUS_ACTIVE,
} from '../../../../data/models/Subscription/Subscription';
import InvoicesTable from '../components/InvoicesTable';
import ActionsSection from '../components/ActionsSection';
import SubscriptionPlan from '../../../../uiToolkit/SubscriptionPlanCard';
import {settingsSubscriptionPlans} from '../config';
import {
  SUBSCRIPTION_BUISNESS_BASIC_TRIAL,
  SUBSCRIPTION_BUISNESS_PRO,
  SUBSCRIPTION_CREATOR_TRIAL,
  SUBSCRIPTION_ENTERPRISE,
} from '../../../Subscription/config';

interface Props {
  onUpgradeSubscription: (tier?: number) => void;
  subscription: OrganizationSubscription;
  onCancelSubscription: () => void;
  onChangePaymentMethod: () => void;
  invoices: any[];
  isLoading: boolean;
  onShowFullList: () => void;
}

const SubscriptionSettingsUI = React.memo(
  ({
    subscription,
    onCancelSubscription,
    onChangePaymentMethod,
    onUpgradeSubscription,
    invoices,
    isLoading,
    onShowFullList,
  }: Props) => {
    return (
      <div className="SubscriptionSettingsContainer">
        <Row>
          <Col xs={12}>
            <div className="d-flex flex-row">
              {settingsSubscriptionPlans(subscription.type).map(
                (plan, index) => (
                  <SubscriptionPlan
                    {...plan}
                    onClick={onUpgradeSubscription}
                    key={index.toString()}
                    onShowFullList={onShowFullList}
                    onRenew={onUpgradeSubscription}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    currentPlan={plan.id === subscription.type}
                    expired={
                      subscription.organizationSubscriptionStatus !==
                      SUBSCRIPTION_STATUS_ACTIVE
                    }
                  />
                ),
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6}>
            <TextInput
              value={getSubscriptionNameFromTier(subscription.type) || ''}
              heading={settingsText.subscriptionTier}
              onChange={() => {
                //nothing
              }}
              placeholder=""
              className="SubscriptionField"
              noEdit
            />
            <TextInput
              value={formatDateTimeTextFormat(
                subscription.currentPeriodEndDate,
              )}
              heading={settingsText.expirationDate}
              onChange={() => {
                //nothing
              }}
              placeholder=""
              className="SubscriptionField"
              noEdit
            />
            {subscription?.last4 && (
              <TextInput
                value={`****_****_****_${subscription.last4}`}
                heading={settingsText.cardDetails}
                onChange={() => {
                  //nothing
                }}
                placeholder=""
                className="SubscriptionField"
                noEdit
              />
            )}
          </Col>
          <Col xs={12}>
            <InvoicesTable invoices={invoices} isLoading={isLoading} />
            <ActionsSection
              onCancelSubscription={
                (!subscription.cancelAtPeriodEnd ||
                  subscription.type === SUBSCRIPTION_CREATOR_TRIAL ||
                  subscription.type === SUBSCRIPTION_BUISNESS_BASIC_TRIAL) &&
                subscription.type !== SUBSCRIPTION_BUISNESS_PRO &&
                subscription.type !== SUBSCRIPTION_ENTERPRISE &&
                subscription.organizationSubscriptionStatus === 0
                  ? onCancelSubscription
                  : undefined
              }
              onChangePaymentMethod={
                !subscription.cancelAtPeriodEnd &&
                subscription.type !== SUBSCRIPTION_BUISNESS_PRO &&
                subscription.type !== SUBSCRIPTION_ENTERPRISE &&
                subscription.organizationSubscriptionStatus === 0
                  ? onChangePaymentMethod
                  : undefined
              }
            />
          </Col>
        </Row>
      </div>
    );
  },
);

export default SubscriptionSettingsUI;
