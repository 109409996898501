import React from 'react';
import {inject, observer} from 'mobx-react';
import AddChallengeBranchQuestionUI from '../view/AddChallangeBranchQuestionUI';
import AddChallengeBranchQuestionStore from '../store/AddChallengeBranchQuestionStore';
import {useParams} from 'react-router-dom';

interface Props {
  addChallengeBranchQuestionStore?: AddChallengeBranchQuestionStore;
}

const AddChallengeBranchQuestionContainer = inject(
  'addChallengeBranchQuestionStore',
)(
  observer(({addChallengeBranchQuestionStore}: Props) => {
    const {organizationId, lessonId} = useParams();

    const onCreateQuestion = (question: string, gradeWeight: number) => {
      if (organizationId && lessonId) {
        addChallengeBranchQuestionStore!.createQuestion(
          organizationId,
          lessonId,
          question,
          gradeWeight,
        );
      }
    };

    return addChallengeBranchQuestionStore!.isWizardOpen() ? (
      <AddChallengeBranchQuestionUI
        onClose={() => addChallengeBranchQuestionStore?.closeWizard()}
        isLoading={addChallengeBranchQuestionStore?.isLoading}
        createQuestion={onCreateQuestion}
      />
    ) : null;
  }),
);

export default AddChallengeBranchQuestionContainer;
