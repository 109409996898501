import * as React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
import {Login} from '../pages/Auth/Login';
import {Home} from '../pages/Home';
import {
  confirmEmailLoader,
  createSubscriptionLoader,
  exploreRouteLoader,
  featureRouteLoader,
  homeLoader,
  landingLoader,
  permissionRouteLoader,
  rootLoader,
  secureRouteLoader,
  workspaceRootRouteLoader,
  workspaceRouteLoader,
} from './loaders';
import {Library} from '../pages/Library/Root';
import {VideoPlayback} from '../pages/VideoPlayback';
import {DashboardRoot} from './rootElements/DashboardRoot';
import AuthRoot from './rootElements/AuthRoot';
import {CoursePreview, CoursesList, CreateCourse} from '../pages/Courses';
import {CreateStudent} from '../pages/Learners/Create';
import {ListLearners, CreateLearners} from '../pages/Learners';
import {Signup} from '../pages/Auth/Signup';
import {ConfirmEmail} from '../pages/Auth/ConfirmEmail';
import {ResetPassword, SendForgotPassword} from '../pages/Auth/ForgotPassword';
import PermissionError from '../pages/PermissionError';
import {APP_PERMISSIONS} from '../data/permissions';
import DashboardSettingsRoot from './rootElements/DashboardSettingsRoot';
import {GeneralSettings} from '../pages/Settings/GeneralSettings';
import {RoleList} from '../pages/Settings/RoleSettings';
import {
  StaffEdit,
  StaffInvite,
  StaffList,
} from '../pages/Settings/StaffSettings';
import {Onboarding} from '../pages/Onboarding';
import {Folders} from '../pages/Folders';
import EditorRoot from './rootElements/EditorRoot';
import {UserProfileRoot} from './rootElements/UserProfileRoot';
import {SettingsUserProfile} from '../pages/UserProfile/SettingsUserProfile';
import {NotificationsUserProfile} from '../pages/UserProfile/NotificationsUserProfile';
import {Lessons} from '../pages/Lessons';
import {LessonEditor} from '../pages/LessonEditor';
import {CourseHome} from '../pages/CourseHome';
import {CourseEditRoot} from './rootElements/CourseEditRoot';
import {GeneralCourseEdit} from '../pages/CourseDetails/CourseEdit';
import {CourseLearner} from '../pages/CourseDetails/CourseLearners';
import {CourseInstructor} from '../pages/CourseDetails/CourseInstructors';
import {CourseGradebook} from '../pages/CourseDetails/CourseGradebook';
import {AdminPanel} from '../pages/AdminPanel';
import NotFound from '../modules/NotFound';
import LearnersRoot from './rootElements/LearnersRoot';
import {LTIPlayer} from '../pages/Integrations/LTIPlayer';
import {LtiSettings} from '../pages/Settings/LtiSettings';
import {SubscriptionSettings} from '../pages/Settings/SubscriptionSettings';
import {Workspace} from '../pages/Workspace';
import SubscriptionSuccess from '../pages/Subscription/SubscriptionSuccess';
import {StorageIntegration} from '../pages/Settings/StorageIntergrationSettings';
import {Explore} from '../pages/Explore';
import {isSandbox} from '../utils/envHelper';
import {ScormPlayer} from '../pages/Integrations/SCORMPlayer';
import GoogleOAuth from '../pages/Auth/GoogleOAuth';
import {SecurityUserProfile} from '../pages/UserProfile/SecurityUserProfile';
import {EditVideoSource} from '../pages/EditVideoSource';
import {inject, observer} from 'mobx-react';
import NavigationStore from './store/NavigationStore';
import {
  ORGANIZATION_SUBSCRIPTION_ACTIVE,
  OrganizationDetails,
} from '../data/models/Oganization/Organization';
import {SelectSubscription} from '../pages/Subscription/SelectSubscription';
import {SubscriptionFirstPayment} from '../pages/Subscription/SubscriptionFirstPayment';
import {
  LTI_SUB_FEATURE,
  STORAGE_INTEGRATION_SUB_FEATURE,
} from '../data/models/Subscription/SubscriptionPermission';
import {Marketplace} from '../pages/Subscription/Marketplace';

const router = (
  permissions: string[],
  subscriptionState: number,
  userOrganization?: OrganizationDetails,
  features?: any,
) =>
  createBrowserRouter(
    createRoutesFromElements([
      <Route
        path="/scorm/:lessonId/:lessonSecret"
        element={<ScormPlayer />}
        key="scorm"
      />,
      <Route path="/" key="/" loader={rootLoader} />,
      <Route
        path="/subscription_tier"
        element={<SelectSubscription />}
        key="/subscription_tier"
      />,
      <Route
        path="/permission_error"
        element={<PermissionError />}
        key="/permission_error"
      />,
      <Route
        path="/admin_panel"
        element={<AdminPanel />}
        key="admin_panel"
        loader={secureRouteLoader}
      />,
      <Route path="/auth" element={<AuthRoot />} key="/auth">
        <Route path="google-oauth" element={<GoogleOAuth />} />
        <Route path="login" loader={landingLoader} element={<Login />} />
        {!isSandbox && (
          <Route path="signup" loader={landingLoader} element={<Signup />} />
        )}
        {!isSandbox && (
          <Route
            path="confirm_email"
            loader={confirmEmailLoader}
            element={<ConfirmEmail />}
          />
        )}
        <Route
          path="forgot_password"
          loader={landingLoader}
          element={<SendForgotPassword />}
        />
        <Route
          path="reset_password"
          loader={landingLoader}
          element={<ResetPassword />}
        />
        <Route path="onboarding" element={<Onboarding />} />
      </Route>,
      <Route
        path="/home"
        loader={homeLoader(permissions, userOrganization)}
        element={<Home />}
        key="/home"
      />,
      <Route
        path="/explore"
        loader={exploreRouteLoader(userOrganization)}
        element={<Explore />}
        key={'/explore'}
      />,
      <Route
        path="/course/:courseId"
        element={<CourseHome />}
        key="/course"
        loader={secureRouteLoader}
      />,
      <Route
        path="/course/:courseId/lesson/:lessonId"
        element={<VideoPlayback />}
        loader={secureRouteLoader}
        key="/course"
      />,
      <Route path="/subscription" key="subscription">
        <Route
          path=":organizationId/success"
          element={<SubscriptionSuccess />}
          key="/sub_success"
        />
        <Route
          path=":organizationId/success_update"
          element={<SubscriptionSuccess forUpdate />}
          key="/sub_success_update"
        />
        <Route
          path=":organizationId/payment_success"
          element={<SubscriptionSuccess forPayment />}
          key="/payment_success"
        />
        <Route
          path=":organizationId/trial_success"
          element={<SubscriptionSuccess forTrial />}
          key="/trial_success"
        />
        <Route
          path=":organizationId/first_payment"
          element={<SubscriptionFirstPayment />}
          key="/first_payment"
          loader={createSubscriptionLoader(
            permissions,
            subscriptionState,
            userOrganization?.id,
          )}
        />
      </Route>,
      <Route
        path=":organizationId/marketplace"
        key="/marketplace"
        element={<Marketplace />}
        loader={permissionRouteLoader(
          APP_PERMISSIONS.ORG_MANAGE_SETTINGS,
          permissions,
          subscriptionState === ORGANIZATION_SUBSCRIPTION_ACTIVE,
          userOrganization?.id,
        )}
      />,
      <Route
        path="/manage/:organizationId"
        loader={workspaceRouteLoader(subscriptionState, userOrganization)}
        element={<DashboardRoot />}
        key="/manage">
        <Route
          path="workspace"
          element={<Workspace />}
          loader={workspaceRootRouteLoader(permissions, userOrganization)}
        />
        <Route
          path="library"
          loader={permissionRouteLoader(
            APP_PERMISSIONS.ORG_LIST_FILES,
            permissions,
            subscriptionState === ORGANIZATION_SUBSCRIPTION_ACTIVE,
            userOrganization?.id,
          )}
          element={<Library />}
        />
        <Route path="editVideo/:mediaFileId" element={<EditVideoSource />} />
        <Route
          path="course/:courseId"
          element={<CourseEditRoot />}
          loader={permissionRouteLoader(
            APP_PERMISSIONS.ORG_EDIT_COURSES,
            permissions,
            subscriptionState === ORGANIZATION_SUBSCRIPTION_ACTIVE,
            userOrganization?.id,
          )}
          key="/course">
          <Route path="general" element={<GeneralCourseEdit />} />
          <Route path="learners" element={<CourseLearner />} />
          <Route path="instructors" element={<CourseInstructor />} />
          <Route path="results" element={<CourseGradebook />} />
        </Route>
        <Route
          path="courses"
          loader={permissionRouteLoader(
            APP_PERMISSIONS.ORG_LIST_COURSES,
            permissions,
            subscriptionState === ORGANIZATION_SUBSCRIPTION_ACTIVE,
            userOrganization?.id,
          )}
          element={<CoursesList />}
        />
        <Route
          path="courses/create"
          loader={permissionRouteLoader(
            APP_PERMISSIONS.ORG_CREATE_COURSES,
            permissions,
            subscriptionState === ORGANIZATION_SUBSCRIPTION_ACTIVE,
            userOrganization?.id,
          )}
          element={<CreateCourse />}
        />
        {!isSandbox && (
          <Route
            path="learners/create"
            loader={permissionRouteLoader(
              APP_PERMISSIONS.ORG_LIST_LEARNERS,
              permissions,
              subscriptionState === ORGANIZATION_SUBSCRIPTION_ACTIVE,
              userOrganization?.id,
            )}
            element={<CreateLearners />}
          />
        )}
        <Route
          path="learners"
          loader={permissionRouteLoader(
            APP_PERMISSIONS.ORG_LIST_LEARNERS,
            permissions,
            subscriptionState === ORGANIZATION_SUBSCRIPTION_ACTIVE,
            userOrganization?.id,
          )}
          element={<LearnersRoot />}>
          <Route
            path="general"
            loader={permissionRouteLoader(
              APP_PERMISSIONS.ORG_LIST_LEARNERS,
              permissions,
              subscriptionState === ORGANIZATION_SUBSCRIPTION_ACTIVE,
              userOrganization?.id,
            )}
            element={<ListLearners />}
          />
        </Route>
        <Route
          path="students/create"
          loader={permissionRouteLoader(
            APP_PERMISSIONS.ORG_CREATE_LEARNERS,
            permissions,
            subscriptionState === ORGANIZATION_SUBSCRIPTION_ACTIVE,
            userOrganization?.id,
          )}
          element={<CreateStudent />}
        />
        <Route
          path="settings"
          loader={permissionRouteLoader(
            APP_PERMISSIONS.ORG_MANAGE_SETTINGS,
            permissions,
            true,
            userOrganization?.id,
          )}
          element={<DashboardSettingsRoot />}>
          <Route path="general" element={<GeneralSettings />} />
          <Route path="roles" element={<RoleList />} />
          <Route path="users" element={<StaffList />} />
          {!isSandbox && (
            <Route path="invite_users" element={<StaffInvite />} />
          )}
          <Route path="edit_user/:memberId" element={<StaffEdit />} />
          <Route
            path="lti_settings"
            element={<LtiSettings />}
            loader={featureRouteLoader(
              LTI_SUB_FEATURE,
              features || {},
              subscriptionState === ORGANIZATION_SUBSCRIPTION_ACTIVE,
            )}
          />
          <Route
            path="storage_integration"
            element={<StorageIntegration />}
            loader={featureRouteLoader(
              STORAGE_INTEGRATION_SUB_FEATURE,
              features || {},
              subscriptionState === ORGANIZATION_SUBSCRIPTION_ACTIVE,
            )}
          />
          <Route path="subscription" element={<SubscriptionSettings />} />
        </Route>
      </Route>,
      <Route
        path="preview/:organizationId"
        loader={permissionRouteLoader(
          APP_PERMISSIONS.ORG_EDIT_COURSES,
          permissions,
          subscriptionState === ORGANIZATION_SUBSCRIPTION_ACTIVE,
          userOrganization?.id,
        )}
        key={'preview'}
        errorElement={<div>ERROR</div>}>
        <Route
          path="course/:courseId"
          key="course_preview"
          element={<CoursePreview />}
          errorElement={<div>ERROR</div>}
        />
        <Route
          path="course/:courseId/lesson/:lessonId"
          element={<VideoPlayback preview />}
          key={Math.random().toString()}
        />
      </Route>,
      <Route
        path="/editor/:organizationId"
        key="editor"
        element={<EditorRoot />}
        loader={permissionRouteLoader(
          APP_PERMISSIONS.ORG_EDIT_COURSES,
          permissions,
          subscriptionState === ORGANIZATION_SUBSCRIPTION_ACTIVE,
          userOrganization?.id,
        )}>
        <Route path="courses/:courseId/folders" element={<Folders />} />
        <Route
          path="courses/:courseId/folders/:folderId/lessons"
          element={<Lessons />}
        />
        <Route
          path="courses/:courseId/folders/:folderId/lessons/:lessonId/edit"
          element={<LessonEditor />}
        />
      </Route>,
      <Route
        path="/player/:lessonId"
        loader={secureRouteLoader}
        element={<VideoPlayback />}
        key="/playback"
      />,
      <Route
        path="/profile"
        element={<UserProfileRoot />}
        loader={secureRouteLoader}
        key="/profile">
        <Route
          path="profile_settings"
          loader={secureRouteLoader}
          element={<SettingsUserProfile />}
        />
        <Route
          path="profile_security"
          loader={secureRouteLoader}
          element={<SecurityUserProfile />}
        />
        <Route
          path="notifications"
          loader={secureRouteLoader}
          element={<NotificationsUserProfile />}
        />
      </Route>,
      <Route
        path="/lti_player/:lessonSecret"
        element={<LTIPlayer />}
        key="lti_player"
      />,
      <Route path="*" element={<NotFound />} key="not_found" />,
    ]),
  );

interface Props {
  navigationStore?: NavigationStore;
}

const AppRouter = inject('navigationStore')(
  observer(({navigationStore}: Props) => {
    const [loadingData, setLoadingData] = React.useState(true);

    React.useEffect(() => {
      initPermissionData();
    }, [navigationStore?.userOrganization]);

    const initPermissionData = async () => {
      const user = navigationStore!.getStoredUser();
      if (!user) {
        setLoadingData(false);
        return;
      }
      if (navigationStore!.userOrganization) {
        navigationStore!.applyThemeColors();
        navigationStore!.getOrganizationDetails(
          navigationStore!.userOrganization.id,
        );
      } else {
        await navigationStore!.tryGetMyOrganization();
      }
      setLoadingData(false);
    };

    return !loadingData ? (
      <RouterProvider
        router={router(
          navigationStore!.userPermissions,
          navigationStore!.subscriptionStatus === undefined
            ? -1
            : navigationStore!.subscriptionStatus,
          navigationStore!.userOrganization,
          navigationStore!.subscriptionPermissions,
        )}
      />
    ) : null;
  }),
);

export default AppRouter;
