import React from 'react';
import {FileUploadModalBody} from '../../../modules/FileUploadModalBody';
import ModalContainer from '../../../uiToolkit/ModalContainer';
import {videoSourcesText} from '../videoSourcesText';
import './styles.css';

interface Props {
  onClose: () => void;
  file: File;
}

const UploadFileLibraryModal = React.memo(({file, onClose}: Props) => {
  return (
    <ModalContainer onClose={onClose}>
      <>
        <h3 className="UploadingTitle">{videoSourcesText.uploadingFile}</h3>
        <FileUploadModalBody file={file} onSuccess={onClose} />
      </>
    </ModalContainer>
  );
});

export default UploadFileLibraryModal;
