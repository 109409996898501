import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  logOut: 'Log out',
  accountSettings: 'Profile Settings',
};

const welsh = {logOut: 'Allgofnodi', accountSettings: 'Gosodiadau Proffil'};

export const userMenuPopupText = getLocalizationBasedOnLang(english, welsh);
