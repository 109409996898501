import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {adminPanelText} from '../adminPanelText';
import NavBar from '../../../uiToolkit/NavBar';
import './styles.css';
import {OrgLastAccessedModel} from '../../../data/models/Oganization/OrgLastAccessedModel';
import WorkspaceVisitsTable from '../components/WorkspaceVisitsTable';
import SignupsTable from '../components/SignupsTable';

interface Props {
  statistic?: any;
  logout: () => void;
  lastAccess: OrgLastAccessedModel[];
  pages: number;
  onPageChange: (page: number) => void;
  signups: any[];
  onSignupPageChange: (page: number) => void;
  signupPages: number;
}

const AdminPanelUI = React.memo(
  ({
    statistic,
    lastAccess,
    pages,
    onPageChange,
    signupPages,
    onSignupPageChange,
    signups,
  }: Props) => {
    return statistic ? (
      <div>
        <NavBar />
        <Container className="pb-4">
          <Row>
            <Col xs={12}>
              <div className="AdminPanelContainer">
                <h3>{adminPanelText.title}</h3>
              </div>
              <ul>
                <li>
                  {adminPanelText.activeUsers} {statistic?.activeUsersAmount}
                </li>
                <li>
                  {adminPanelText.workspacesAmount}{' '}
                  {statistic?.workspacesAmount}
                </li>
                <li>
                  {adminPanelText.coursesAmount} {statistic?.coursesAmount}
                </li>
                <li>
                  {adminPanelText.publicCoursesAmount}{' '}
                  {statistic?.publicCoursesAmount}
                </li>
              </ul>
            </Col>
            <Col xs={6}>
              <div className="AdminPanelContainer">
                <h3>{adminPanelText.workspaceVisits}</h3>
              </div>
              <WorkspaceVisitsTable
                lastAccess={lastAccess}
                pages={pages}
                onPageChange={onPageChange}
              />
            </Col>
            <Col xs={6}>
              <div className="AdminPanelContainer">
                <h3>{adminPanelText.recentSignups}</h3>
              </div>
              <SignupsTable
                signups={signups}
                pages={signupPages}
                onPageChange={onSignupPageChange}
              />
            </Col>
          </Row>
        </Container>
      </div>
    ) : null;
  },
);

export default AdminPanelUI;
