import React, {useEffect} from 'react';
import UserProfileRootUI from '../view/UserProfileRootUI';
import {inject, observer} from 'mobx-react';
import UserInfoStore from '../../../../stores/UserInfoStore';

interface Props {
  userInfoStore?: UserInfoStore;
}

const UserProfileRootContainer = inject('userInfoStore')(
  observer(({userInfoStore}: Props) => {
    useEffect(() => {
      userInfoStore!.fetchUserInfo();
    }, []);

    return <UserProfileRootUI profileData={userInfoStore!.userInfo} />;
  }),
);

export default UserProfileRootContainer;
