import {inject, observer} from 'mobx-react';
import React from 'react';
import AddChoiceQuestionWizardUI from '../view/AddChoiceQuestionWizardUI';
import AddChoiceQuestionStore from '../store/AddChoiceQuestionStore';
import {useParams} from 'react-router-dom';

interface Props {
  addChoiceQuestionStore?: AddChoiceQuestionStore;
}

const AddChoiceQuestionWizardContainer = inject('addChoiceQuestionStore')(
  observer(({addChoiceQuestionStore}: Props) => {
    const {organizationId, lessonId} = useParams();

    const onCancel = () => {
      addChoiceQuestionStore!.cancelWizard();
    };

    const onCreate = (question: string) => {
      if (organizationId && lessonId) {
        addChoiceQuestionStore!.createQuestion(
          organizationId,
          lessonId,
          question,
        );
      }
    };
    return addChoiceQuestionStore!.isVisible() ? (
      <AddChoiceQuestionWizardUI onCancel={onCancel} onCreate={onCreate} />
    ) : null;
  }),
);

export default AddChoiceQuestionWizardContainer;
