import React, {useEffect, useState} from 'react';
import SubscriptionSettingsUI from '../view/SubscriptionSettingsUI';
import {inject, observer} from 'mobx-react';
import {useNavigate, useParams} from 'react-router-dom';
import SubscriptionSettingsStore from '../store/SubscriptionSettingsStore';
import {OrganizationSubscription} from '../../../../data/models/Subscription/Subscription';
import ConfirmationModal from '../../../../uiToolkit/ConfirmationModal';
import {settingsText} from '../../settingsText';
import {InvoiceListItem} from '../../../../data/models/Subscription/Invoice';
import FeaturesTableModal from '../components/FeaturesTableModal';
import {
  SUBSCRIPTION_BUISNESS_BASIC,
  SUBSCRIPTION_CREATOR,
} from '../../../Subscription/config';

interface Props {
  subscriptionSettingsStore?: SubscriptionSettingsStore;
}

const SubscriptionSettingsContainer = inject('subscriptionSettingsStore')(
  observer(({subscriptionSettingsStore}: Props) => {
    const [subscription, setSubscription] =
      useState<OrganizationSubscription>();
    const {organizationId} = useParams();
    const [showConfirmCancel, setShowConfirmCancel] = useState(false);
    const [invoices, setInvoices] = useState<InvoiceListItem[]>([]);
    const [showFeatures, setShowFeatures] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
      if (organizationId) {
        subscriptionSettingsStore!
          .getOrganizationSubscription(organizationId)
          .then(setSubscription);
        subscriptionSettingsStore!
          .getInvoicesList(organizationId)
          .then(setInvoices);
      }
    }, []);

    const onUpgradeSubscription = (tier?: number) => {
      if (tier && organizationId) {
        if (subscription?.organizationSubscriptionStatus !== 0) {
          if (
            subscription?.type === SUBSCRIPTION_CREATOR ||
            subscription?.type === SUBSCRIPTION_BUISNESS_BASIC
          ) {
            subscriptionSettingsStore!
              .renewSubscription(organizationId)
              .then(link => {
                window.open(link, '_self');
              });
          } else {
            subscriptionSettingsStore!
              .buySubscription(organizationId, tier)
              .then(link => {
                window.open(link, '_self');
              });
          }
        } else {
          subscriptionSettingsStore!
            .upgradeTier(organizationId, tier)
            .then(res => {
              if (res?.sessionLink) {
                window.open(res.sessionLink, '_self');
              } else if (res?.success) {
                navigate(`/subscription/${organizationId}/success_update`);
              }
            });
        }
      } else {
        window.open(
          'https://help.tapybl.com/support/tickets/new?ticket_form=upgrade_plan',
          '_blank',
        );
      }
    };

    const onChangePaymentMethod = () => {
      if (organizationId) {
        subscriptionSettingsStore!
          .getPaymentMethodSession(organizationId)
          .then(res => {
            if (res) {
              const win = window.open(res, '_blank');
              if (win != null) {
                win.focus();
              }
            }
          });
      }
    };

    const onCancelSubscription = () => {
      if (organizationId) {
        subscriptionSettingsStore
          ?.cancelSubscription(organizationId)
          .then(success => {
            if (success) {
              window.location.reload();
            }
          });
      }
    };

    return subscription ? (
      <>
        <SubscriptionSettingsUI
          onUpgradeSubscription={onUpgradeSubscription}
          subscription={subscription}
          onCancelSubscription={() => setShowConfirmCancel(true)}
          onChangePaymentMethod={onChangePaymentMethod}
          invoices={invoices}
          isLoading={subscriptionSettingsStore!.isLoading}
          onShowFullList={() => setShowFeatures(true)}
        />
        <ConfirmationModal
          title={settingsText.areYouSureCancelSubscription}
          onAccept={onCancelSubscription}
          onCancel={() => setShowConfirmCancel(false)}
          shown={showConfirmCancel}
        />
        {showFeatures && (
          <FeaturesTableModal onClose={() => setShowFeatures(false)} />
        )}
      </>
    ) : null;
  }),
);

export default SubscriptionSettingsContainer;
