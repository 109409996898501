import {makeAutoObservable} from 'mobx';
import {
  MediaFile,
  VimeoMediaFile,
} from '../../../../data/models/LibraryFile/MediaFile';
import {VideoOverlay} from '../../../../data/models/LessonContent/ContentFreeform';
import {AddLessonContentModel} from '../../../../data/models/LessonContent/AddLessonContentModel';
import {
  LessonContentBase,
  LessonContentOverlayVideo,
  LessonContentVimeoOverlayVideo,
  OVERLAY_VIDEO_TYPE,
  OVERLAY_VIMEO_VIDEO_TYPE,
} from '../../../../data/models/LessonContent/LessonContentBase';
import LessonContentRepository from '../../../../data/repositories/LessonContentRepository';
import {LessonContentList} from '../../../../data/models/LessonContent/LessonContentList';
import LessonsRepository from '../../../../data/repositories/LessonsRepository';
import {ApiResponse} from '../../../../data/models/ApiResponse';

class AddVideoOverlayStore {
  private lessonContentRepo = new LessonContentRepository();
  private lessonRepo = new LessonsRepository();

  private isShown = false;
  private parentId?: string;
  private selectedFile: MediaFile | null = null;
  private selectedVimeoFile: VimeoMediaFile | null = null;
  private lessonContent: LessonContentList | null = null;
  private lessonIsGradable = false;
  private successCallback?: () => void;
  private nodeToReplace?: LessonContentBase;
  private childId?: string;
  private existingOverlayNode?:
    | LessonContentOverlayVideo
    | LessonContentVimeoOverlayVideo = undefined;

  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public openWizard(
    parentId: string,
    mediaFile: MediaFile | null,
    vimeoFile: VimeoMediaFile | null,
    successCallback: () => void,
    lessonContent: LessonContentList,
    lessonIsGradable: boolean,
    existingOverlayNode?:
      | LessonContentOverlayVideo
      | LessonContentVimeoOverlayVideo,
    nodeToReplace?: LessonContentBase,
    childId?: string,
  ) {
    this.selectedFile = mediaFile;
    this.selectedVimeoFile = vimeoFile;
    this.parentId = parentId;
    this.successCallback = successCallback;
    this.isShown = true;
    this.existingOverlayNode = existingOverlayNode;
    this.lessonContent = lessonContent;
    this.lessonIsGradable = lessonIsGradable;
    this.nodeToReplace = nodeToReplace;
    this.childId = childId;
  }

  public async getLessonsList(organizationId: string, courseId: string) {
    const result = await this.lessonRepo.getAllCourseLessons(
      organizationId,
      courseId,
    );
    if (result && result.success && result.data) {
      return result.data;
    }
    return [];
  }

  public get showWizard() {
    return this.isShown;
  }

  public getIsGradable() {
    return this.lessonIsGradable;
  }

  public closeWizard() {
    this.selectedFile = null;
    this.selectedVimeoFile = null;
    this.parentId = undefined;
    this.successCallback = undefined;
    this.isShown = false;
    this.existingOverlayNode = undefined;
    this.lessonContent = null;
  }

  public getVideoSource() {
    return this.selectedFile;
  }

  public getVimeoSource() {
    return this.selectedVimeoFile;
  }

  public getExistingOverlayNode() {
    return this.existingOverlayNode;
  }

  public getLessonContent() {
    if (this.lessonContent) {
      return this.lessonContent;
    }
  }

  public async saveOverlayNode(
    organizationId: string,
    lessonId: string,
    start: number,
    end: number,
    overlays: VideoOverlay[],
  ) {
    if (this.selectedFile) {
      this.isLoading = true;
      const model: AddLessonContentModel = {
        data: {
          ivNodeType: OVERLAY_VIDEO_TYPE,
          startInSeconds: start,
          endInSeconds: end,
          durationInseconds: end - start,
          contentId: this.selectedFile.storageFileName,
          title: this.selectedFile.fileName,
          overlays,
        } as LessonContentOverlayVideo,
        parentsIds: this.parentId ? [this.parentId] : undefined,
      };
      if (this.nodeToReplace) {
        const result = await this.lessonContentRepo.editLessonContent(
          organizationId,
          lessonId,
          {...model.data, internalId: this.nodeToReplace.internalId},
        );
        this.processResult(result);
      } else {
        const result = await this.lessonContentRepo.addLessonContent(
          organizationId,
          lessonId,
          model,
          this.childId,
        );
        this.processResult(result);
      }
      this.isLoading = false;
    }

    if (this.selectedVimeoFile) {
      this.isLoading = true;
      const model: AddLessonContentModel = {
        data: {
          ivNodeType: OVERLAY_VIMEO_VIDEO_TYPE,
          startInSeconds: start,
          endInSeconds: end,
          durationInSeconds: end - start,
          contentUrl: this.selectedVimeoFile.url,
          title: this.selectedVimeoFile.title,
          placeholderUrl: this.selectedVimeoFile.placeholderUrl,
          overlays,
        } as LessonContentVimeoOverlayVideo,
        parentsIds: this.parentId ? [this.parentId] : undefined,
      };
      if (this.nodeToReplace) {
        const result = await this.lessonContentRepo.editLessonContent(
          organizationId,
          lessonId,
          {...model.data, internalId: this.nodeToReplace.internalId},
        );
        this.processResult(result);
      } else {
        const result = await this.lessonContentRepo.addLessonContent(
          organizationId,
          lessonId,
          model,
          this.childId,
        );
        this.processResult(result);
      }
      this.isLoading = false;
    }
  }

  public async editOverlayNode(
    organizationId: string,
    lessonId: string,
    start: number,
    end: number,
    overlays: VideoOverlay[],
  ) {
    if (this.existingOverlayNode) {
      const updatedOverlay = {
        ...this.existingOverlayNode,
        overlays,
        startInSeconds: start,
        endInSeconds: end,
        durationInseconds: end - start,
      };
      const result = await this.lessonContentRepo.editLessonContent(
        organizationId,
        lessonId,
        updatedOverlay,
      );
      this.processResult(result);
    }
  }

  private processResult = (result: ApiResponse<any>) => {
    if (result.success && this.successCallback) {
      this.successCallback();
      this.closeWizard();
    }
  };
}

export default AddVideoOverlayStore;
