import {makeAutoObservable} from 'mobx';
import QuestionGenerationRepository from '../../../../data/repositories/QuestionGenerationRepository';
import LessonContentRepository from '../../../../data/repositories/LessonContentRepository';
import {
  CHALLENGE_BRANCH_AI,
  CHALLENGE_BRANCH_AI_CORRECT,
  CHALLENGE_BRANCH_AI_INCORRECT,
  LessonContentChallengeBranchAI,
} from '../../../../data/models/LessonContent/LessonContentBase';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../../data/errorCodes';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';

class AddChallengeBranchAIStore {
  private questionGenerationRepo = new QuestionGenerationRepository();
  private lessonContentRepository = new LessonContentRepository();
  private parentId?: string = undefined;
  private isOpen = false;
  private onFinishedCallback?: () => void = undefined;

  public isLoading = false;
  public isGenerating = false;

  constructor() {
    makeAutoObservable(this);
  }

  public closeWizard() {
    this.parentId = undefined;
    this.isOpen = false;
    this.onFinishedCallback = undefined;
  }

  public async generateChallengeBranchAI(
    organizationId: string,
    lessonId: string,
  ) {
    if (this.parentId) {
      this.isGenerating = true;
      const result =
        await this.questionGenerationRepo.generateChallengeBranchAI(
          organizationId,
          lessonId,
          this.parentId,
        );
      this.isGenerating = false;
      if (result.success && result.data) {
        return result.data;
      }
    }
  }

  public async createChallengeAINode(
    organizationId: string,
    lessonId: string,
    question: string,
    answer: string,
    frame: string,
  ) {
    if (this.parentId) {
      this.isLoading = true;
      const result = await this.lessonContentRepository.addLessonContent(
        organizationId,
        lessonId,
        {
          data: {
            ivNodeType: CHALLENGE_BRANCH_AI,
            question,
            answerMask: answer,
            frameMask: frame,
            gradeWeight: 1,
          } as LessonContentChallengeBranchAI,
          parentsIds: [this.parentId],
        },
      );
      if (!result.success) {
        toast(getErrorFromCode(''), toastConfig);
        return;
      }
      const content = await this.lessonContentRepository.getLessonContent(
        organizationId,
        lessonId,
      );
      if (!content.success || !content.data) {
        toast(getErrorFromCode(''), toastConfig);
        return;
      }
      const contentKeys = Object.keys(content.data.contentList.items);
      const nodeKey = contentKeys.find(
        key =>
          content.data!.contentList.items[key].parentsIds &&
          content.data!.contentList.items[key].parentsIds[0] === this.parentId,
      );
      if (nodeKey) {
        const node = content.data!.contentList.items[nodeKey];
        await this.lessonContentRepository.addLessonContent(
          organizationId,
          lessonId,
          {
            data: {
              ivNodeType: CHALLENGE_BRANCH_AI_CORRECT,
            } as LessonContentChallengeBranchAI,
            parentsIds: [node.data.internalId],
          },
        );
        await this.lessonContentRepository.addLessonContent(
          organizationId,
          lessonId,
          {
            data: {
              ivNodeType: CHALLENGE_BRANCH_AI_INCORRECT,
            } as LessonContentChallengeBranchAI,
            parentsIds: [node.data.internalId],
          },
        );
      }

      this.isLoading = false;
      if (this.onFinishedCallback) {
        this.onFinishedCallback();
        this.closeWizard();
      }
    }
  }

  public startWizard(parentId: string, onFinish?: () => void) {
    this.isOpen = true;
    this.parentId = parentId;
    this.onFinishedCallback = onFinish;
  }

  public isShown() {
    return this.isOpen && this.parentId;
  }
}

export default AddChallengeBranchAIStore;
