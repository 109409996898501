import {SUBSCRIPTION_BUISNESS_BASIC, SUBSCRIPTION_CREATOR} from '../config';

export const getTierSummaryConfig = (tier?: number) => {
  switch (tier) {
    case SUBSCRIPTION_CREATOR:
      return creatorTierSummary;
    case SUBSCRIPTION_BUISNESS_BASIC:
      return buisnessTierSummary;
  }
};

const creatorTierSummary = {
  title: 'Creator',
  features: [
    'Tapybl AI',
    'Core features',
    '200,000 AI credits',
    '5 GB Storage',
    'Includes 1 creator license',
    'Expandable up to 19 users',
  ],
  price: '€899',
};

const buisnessTierSummary = {
  title: 'Business',
  features: [
    'Tapybl AI',
    'Core features',
    '400,000 AI Credits',
    '50 GB Storage',
    'White label / customization',
    'Advanced analytics & visual reporting',
    'LTI 1.3 support',
    'Includes 1 creator license (scalable to your needs)',
    'Includes 20 users (expandable up to 250 users)',
  ],
  price: '€3,777',
};
