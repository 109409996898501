import React, {ReactNode} from 'react';
import './styles.css';
import Button from '../Buttons/Button';

interface Props {
  title: string;
  img?: any;
  icon?: any;
  element: ReactNode;
  buttonText?: string;
  onClick?: () => void;
}

const DashboardCard = ({
  title,
  img,
  icon,
  element,
  onClick,
  buttonText,
}: Props) => {
  return (
    <div className="Dashboard">
      <div className="PageTitleContainer">
        {img && <img src={img} alt="img" />}
        {icon && <span className={`${icon}`}></span>}
        <h4>{title}</h4>
      </div>
      <div className="DashboardElements">
        <div className="DashboardCardContainer">
          <div className="DashboardCard">
            {element}

            {buttonText && onClick && (
              <Button
                title={buttonText}
                uiType="hollow"
                onClick={() => onClick()}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
