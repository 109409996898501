import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  title: 'Library',
  recent: 'Recent files',
  allFiles: 'Uploaded files',
  vimeoFiles: 'Vimeo files',
  delete: 'Delete',
  fileIsUsed:
    'This video is used as part of a lesson in a course and cannot be deleted',
  fileIsUsedEdit:
    'This video is used as part of a lesson in a course and cannot be edited',
  fileIsGenerating: 'This video is being generated',
  confirmDelete: 'Are you sure you want to delete this file from your library?',
  fileDeleted: 'File deleted',
  generateWithAI: 'Generate with AI Assistant',
  aiFiles: 'AI Generated Files',
  videoUploaded: 'File Uploaded Successfully',
  downloadTranscript: 'Download Transcipt',
  downloadVideoTranscription: 'Download Video Transcription',
  uploadingFile: 'Uploading file',
};

const welsh = {
  title: 'Llyfrgell',
  recent: 'Ffeiliau diweddar',
  allFiles: "Ffeiliau wedi'u uwchlwytho",
  vimeoFiles: 'Ffeiliau Vimeo',
  delete: 'Dileu',
  fileIsUsed:
    "Mae'r fideo hwn yn cael ei ddefnyddio fel rhan o wers yn y cwrs ac ni ellir ei ddileu",
  fileIsUsedEdit:
    "Mae'r fideo hwn yn cael ei ddefnyddio fel rhan o wers yn y cwrs ac ni ellir ei olygu",
  fileIsGenerating: "Mae'r fideo hwn yn cael ei gynhyrchu",
  confirmDelete:
    "Ydych chi'n siŵr eich bod am ddileu'r ffeil hon o'ch llyfrgell?",
  fileDeleted: "Ffeil wedi'i ddileu",
  generateWithAI: 'Cynhyrchwch  gyda Cynorthwy-ydd  AI',
  aiFiles: 'Ffeiliau a Gynhyrchwyd gan AI',
  videoUploaded: "Ffeil wedi'i Wwchlwytho'n Llwyddiannus",
  downloadTranscript: "Lawrlwytho'r Trawsgrifiad",
  downloadVideoTranscription: "Lawrlwytho'r Trawsgrifiad Fideo",
};

export const videoSourcesText: any = getLocalizationBasedOnLang(english, welsh);
