import {makeAutoObservable} from 'mobx';
import OrganizationRepository from '../../../../data/repositories/OrganizationRepository';
import LoadingModule from '../../../../modules/LoadingModule/LoadingModule';
import SubscriptionRepository from '../../../../data/repositories/SubscriptionRepository';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../../data/errorCodes';

class SubcsriptionFirstPaymentStore {
  private organizationRepository = new OrganizationRepository();
  private subscriptionRepository = new SubscriptionRepository();

  private loadingModule = new LoadingModule(true);

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async getUserOrganization() {
    const result = await this.organizationRepository.getUserOrganization();
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
    return null;
  }

  public async getSubscriptionPaymentLink(
    organizationId: string,
    tier: number,
  ) {
    this.loadingModule.startLoading();
    const result = await this.subscriptionRepository.getSubscribeSession(
      organizationId,
      tier,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    } else {
      toast.error(getErrorFromCode(''));
    }
  }

  public async subscribeForTrial(organizationId: string, tier: number) {
    this.loadingModule.startLoading();
    const result = await this.subscriptionRepository.subscribeForTrial(
      organizationId,
      tier,
    );
    this.loadingModule.endLoading();
    if (result.success) {
      return result.success;
    } else {
      toast.error(getErrorFromCode(''));
    }
  }
}

export default SubcsriptionFirstPaymentStore;
