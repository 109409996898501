import React from 'react';
import DataTable from 'react-data-table-component';
import {settingsText} from '../../settingsText';
import UserActivityIndicator from '../../../../uiToolkit/UserActivityIndicator';
import IconButton from '../../../../uiToolkit/Buttons/IconButton';
import {deleteIcon, editIcon} from '../../../../assets/images';
import '../../../../uiToolkit/DataTable/styles.css';
import './styles.css';
import {StaffRecord} from '../../../../data/models/Oganization/StaffRecord';
import Button from '../../../../uiToolkit/Buttons/Button';
import Tooltip from '../../../../uiToolkit/Tooltip';
import {isSandbox} from '../../../../utils/envHelper';
import {
  USER_STATUS_ACTIVE,
  USER_STATUS_INACTIVE,
  USER_STATUS_INV_SENT,
} from '../../../../data/staticValues/userStatuses';

const ORG_STAFF_ACTIVE = 0;
const ORG_STAFF_INACTIVE = 1;
const ORG_STAFF_INVITED = 2;
const ORG_STAFF_INVITED_INACTIVE = 3;

const getMappedStatus = (staffStatus: number) => {
  switch (staffStatus) {
    case ORG_STAFF_ACTIVE:
      return USER_STATUS_ACTIVE;
    case ORG_STAFF_INACTIVE:
    case ORG_STAFF_INVITED_INACTIVE:
      return USER_STATUS_INACTIVE;
    case ORG_STAFF_INVITED:
      return USER_STATUS_INV_SENT;
    default:
      return USER_STATUS_INACTIVE;
  }
};

const staffMembersColumns = (
  onDelete: (memberId: string) => void,
  onEdit: (memberId: string) => void,
  onDeleteInvitation: (invitationId: string) => void,
  activateMemeber: (memebrId: string) => void,
  onCopyEmail: (email: string) => void,
) => [
  {
    name: settingsText.username,
    selector: (row: StaffRecord) => row.name,
    cell: (row: StaffRecord) => (
      <Tooltip tooltipText={row.name}>
        <div className="DataTableText" style={{maxWidth: '150px'}}>
          {row.name}
        </div>
      </Tooltip>
    ),
    sortable: true,
  },
  {
    name: settingsText.email,
    selector: (row: StaffRecord) => row.email,
    cell: (row: StaffRecord) => (
      <div
        style={{maxWidth: '100%', cursor: 'pointer'}}
        onClick={() => onCopyEmail(row.email)}>
        <Tooltip tooltipText={settingsText.copyEmail}>
          <div className="DataTableText">{row.email}</div>
        </Tooltip>
      </div>
    ),
    sortable: true,
  },
  {
    name: settingsText.type,
    selector: (row: StaffRecord) => row.roleName || '-',
    cell: (row: StaffRecord) => (
      <div className="DataTableText">{row.roleName || '-'}</div>
    ),
    id: 'type',
    sortable: true,
  },
  {
    name: settingsText.status,
    selector: (row: StaffRecord) => row.status || '-',
    cell: (row: StaffRecord) => (
      <UserActivityIndicator status={getMappedStatus(row.status)} />
    ),
    id: 'status',
    sortable: true,
  },
  {
    name: settingsText.actions,
    cell: (row: StaffRecord) => (
      <div>
        {row.staffMemberId &&
        !row.isOwner &&
        !(
          row.status === ORG_STAFF_INACTIVE ||
          row.status === ORG_STAFF_INVITED_INACTIVE
        ) ? (
          <>
            {row.roleName !== 'Workspace Instructor' && (
              <IconButton
                onClick={() => onEdit(row.staffMemberId || '')}
                icon={editIcon}
                tooltip={settingsText.editRole}
              />
            )}
            <IconButton
              onClick={() => onDelete(row.staffMemberId || '')}
              icon={deleteIcon}
              tooltip={settingsText.deleteUser}
            />
          </>
        ) : null}
        {(row.status === ORG_STAFF_INACTIVE ||
          row.status === ORG_STAFF_INVITED_INACTIVE) && (
          <div className="ActivateContainer">
            <IconButton
              onClick={() => activateMemeber(row.staffMemberId || '')}
              icon={'icon-typeAdd-learner'}
              tooltip={settingsText.activateUser}
            />
          </div>
        )}
        {row.invitationId && (
          <IconButton
            onClick={() => onDeleteInvitation(row.invitationId || '')}
            icon={deleteIcon}
          />
        )}
      </div>
    ),
    id: 'actions',
  },
];

interface Props {
  staffMembers: StaffRecord[];
  onInvite: () => void;
  onDelete: (memberId: string) => void;
  onEdit: (memberId: string) => void;
  onDeleteInvitation: (invitationID: string) => void;
  activateMemeber: (memebrId: string) => void;
  onCopyEmail: (email: string) => void;
  dataLoaded: boolean;
}

const StaffListUI = React.memo(
  ({
    staffMembers,
    onInvite,
    onDelete,
    onEdit,
    onDeleteInvitation,
    activateMemeber,
    onCopyEmail,
    dataLoaded,
  }: Props) => {
    return (
      <div>
        {dataLoaded ? (
          <DataTable
            data={staffMembers}
            columns={staffMembersColumns(
              onDelete,
              onEdit,
              onDeleteInvitation,
              activateMemeber,
              onCopyEmail,
            )}
            className="DataTable"
            theme="custom-stripes"
            striped
          />
        ) : null}
        {/* (
          <div style={{marginTop: '20px'}}>
            <Skeleton count={5} height={40} style={{marginBottom: '10px'}} />
          </div>
        )} */}
        <div className="StaffListFooter">
          <Button
            title={settingsText.invite}
            onClick={onInvite}
            uiType="action"
            disabled={isSandbox}
          />
        </div>
      </div>
    );
  },
);

export default StaffListUI;
