import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const englishOrgData = {
  nameTitle: 'Workspace Name',
  namePlaceholder: 'Name',
  avatarTitle: 'Workspace Logo',
  avatarPlaceholder: 'Upload your workspace logo',
  primaryColorTitle: 'Workspace Primary color',
  primaryColorPlaceholder: '#011E1E',
  secondaryColorTitle: 'Workspace Secondary color',
  secondaryColorPlaceholder: '#B8EF36',
  description: 'Description',
  desscriptionPlaceholder: 'Description for your Workspace',
  cancel: 'Cancel',
  type: 'Workspace Type',
  selectType: 'Select workspace type',
  reset: 'Reset',
};

const welshOrgData = {
  nameTitle: 'Enw Gweithle',
  namePlaceholder: 'Enw',
  avatarTitle: 'Logo Gweithle',
  avatarPlaceholder: 'Llwythwch i fyny eich logo Gweithle',
  primaryColorTitle: 'Lliw cynradd Gweithle',
  secondaryColorTitle: 'Lliw eilaidd gweithle',
  description: 'Disgrifiad',
  desscriptionPlaceholder: "Disgrifiad o'ch Gweithle",
  cancel: 'Canslo',
  type: 'Math o le gwaith',
  selectType: 'Dewiswch y math o weithle',
  reset: 'Ailosod',
};

export const orgDataFormText: any = getLocalizationBasedOnLang(
  englishOrgData,
  welshOrgData,
);
