import React, {useContext, useEffect, useState} from 'react';
import {EditorContext} from '../../container/LessonEditorContainer';
import {LessonContentList} from '../../../../data/models/LessonContent/LessonContentList';
import {
  CHALLENGE_BRANCH_HEADER,
  CHOICE_HEADER_NODE_TYPE,
  getNextStepsForNodeByType,
  JUMP_TO_NODE,
  LessonContentBase,
  nodeIsASourceNode,
} from '../../../../data/models/LessonContent/LessonContentBase';
import {getIconByType, getTooltipByType} from '../../config';
import {lessonEditorText} from '../../lessonEditorText';
import {sourceHasBranchChild} from '../../nodeTreeUtils';
import {BRANCH_PARENT_DELETION_MODAL} from '../../../../modules/ModalProvider/globalModals';
import {inject, observer} from 'mobx-react';
import ModalProviderStore from '../../../../modules/ModalProvider/store/ModalProviderStore';
import Button from '../../../../uiToolkit/Buttons/Button';
import EditorTooltipContent from '../../../../uiToolkit/EditorTooltopButton/EditorTooltipContent';

interface Props {
  currentNode: LessonContentBase;
  modalProviderStore?: ModalProviderStore;
}

const ReplaceNode = inject('modalProviderStore')(
  observer(({currentNode, modalProviderStore}: Props) => {
    const [showActions, setShowActions] = useState(false);
    const context = useContext(EditorContext);

    useEffect(() => {
      document.addEventListener('click', closeActions);
      return () => {
        document.removeEventListener('click', closeActions);
      };
    }, []);

    const closeActions = () => {
      setShowActions(false);
    };

    const getCurrentNodeParent = () => {
      const lessonContent: LessonContentList = context.lessonContent;
      if (
        !lessonContent ||
        !lessonContent.contentList ||
        !lessonContent.contentList.items
      )
        return;
      const parents =
        lessonContent.contentList.items[currentNode.internalId]?.parentsIds;
      if (!parents || !parents.length) {
        return undefined;
      }
      return lessonContent.contentList.items[parents[0]]?.data;
    };

    const getCurrentNodeChild = () => {
      const lessonContent: LessonContentList = context.lessonContent;
      if (
        !lessonContent ||
        !lessonContent.contentList ||
        !lessonContent.contentList.items
      )
        return;
      const children =
        lessonContent.contentList.items[currentNode.internalId]?.childIds;
      if (!children || !children.length) {
        return undefined;
      }
      return lessonContent.contentList.items[children[0]]?.data;
    };

    const getActionsByParent = () => {
      const parent = getCurrentNodeParent();
      let availableActions = getNextStepsForNodeByType(
        parent?.ivNodeType,
        context.isGradable,
        parent?.internalId !== undefined,
      );
      availableActions = availableActions.filter(
        a => a !== CHALLENGE_BRANCH_HEADER && a !== CHOICE_HEADER_NODE_TYPE,
      );
      const child = getCurrentNodeChild();
      if (child) {
        availableActions = availableActions.filter(a => a !== JUMP_TO_NODE);
      }
      return availableActions.map(item => ({
        icon: getIconByType(item),
        action: () => context.addNewNode(parent?.internalId, item, currentNode),
        tooltip: getTooltipByType(item),
      }));
    };

    const onAction = (action: () => void) => {
      if (
        context.lessonContent &&
        sourceHasBranchChild(context.lessonContent, currentNode.internalId) &&
        nodeIsASourceNode(currentNode.ivNodeType)
      ) {
        modalProviderStore!.openModal(
          BRANCH_PARENT_DELETION_MODAL,
          (accepted: boolean) => {
            if (accepted === true) {
              action();
            }
          },
        );
      } else {
        action();
      }
    };

    const onToggleActions = (e: any) => {
      e.stopPropagation();
      setShowActions(!showActions);
    };

    return !context.isPublished ? (
      <div className="ReplaceWithContainer">
        <Button
          title={lessonEditorText.replace}
          onClick={onToggleActions}
          uiType="box"
          className="w-100"
        />
        {showActions && (
          <div className="ReplaceActionsContainer">
            <EditorTooltipContent
              actions={getActionsByParent()}
              onAction={onAction}
            />
          </div>
        )}
      </div>
    ) : null;
  }),
);

export default ReplaceNode;
