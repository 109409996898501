import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  emptyState: 'Select element to edit',
  source: 'Source:',
  startTime: 'Start time',
  endTime: 'End time',
  title: 'Title:',
  save: 'Save',
  delete: 'Delete',
  question: 'Question:',
  questionPlaceholder: 'Choice question',
  option: 'Option:',
  deleteAnswer: 'Delete answer',
  gradeWeight: 'Score Weight',
  isCorrect: 'Correct Answer?',
  areYouSureDeleteVideo:
    'Delete this video source and its child nodes? (Cannot be undone)',
  areYouSureDeleteImageSource:
    'Delete this image source and its child nodes? (Cannot be undone)',
  areYouSureDeleteVideoSoft:
    'Delete this video source only and keep its child nodes? (Cannot be undone)',
  areYouSureDeleteImageSourceSoft:
    'Delete this image source only and keep its child nodes? (Cannot be undone)',
  areYouSureDeleteVideoSoftBranchParent:
    'Deleting the current source will require revising any existing child nodes linked to it. Do you want to continue knowing these changes are necessary?',
  areYouSureDeleteChoiceQuestion:
    'Delete this choice question and its child nodes? (Cannot be undone)',
  areYouSureDeleteChoiceTextOption:
    'Delete this choice text option and its child nodes? (Cannot be undone)',
  areYouSureDeleteVideoBackgorundOption:
    'Delete this video backgorund choice and its child nodes? (Cannot be undone)',
  areYouSureDeleteChoiceImageOption:
    'Delete this choice image option and its child nodes? (Cannot be undone)',
  areYouSureDeleteChoiceShapeOption:
    'Delete this choice shape option and its child nodes? (Cannot be undone)',
  areYouSureDeleteChallengeImageOption:
    'Delete this challenge image option and its child nodes? (Cannot be undone)',
  areYouSureDeleteChoiceHeaderOption:
    'Delete this choice branch and its child nodes? (Cannot be undone)',
  areYouSureDeleteChallengeHeaderOption:
    'Delete this challenge branch and its child nodes? (Cannot be undone)',
  areYouSureDeleteAcknowledgment:
    'Delete this acknowledgment and its child nodes? (Cannot be undone)',
  areYouSureDeleteAcknowledgmentSoft:
    'Delete this acknowledgment only and keep its child nodes? (Cannot be undone)',
  areYouSureDeleteJumpToOption:
    'Delete this jump to and its child nodes? (Cannot be undone)',
  areYouSureDeleteJumpToOptionSoft:
    'Delete this jump to only and keep its child nodes? (Cannot be undone)',
  choiceBranchHeaderText: 'Choice Branch',
  challangeBranchHeaderText: 'Challenge Branch',
  editSource: 'Edit Source',
  editSourceWithOverlay: 'Edit Source with overlay',
  editOverlay: 'Edit overlay',
  preview: 'Preview',
  answerTitle: 'Answers:',
  answerPlaceholder: 'Add multiple choice answer',
  areYouSureDeleteMChoice:
    'Delete this multiple choice question and its child nodes? (Cannot be undone)',
  areYouSureDeleteMChoiceSoft:
    'Delete this multiple choice question only and keep its child nodes? (Cannot be undone)',
  videoNodeTooltip: 'Video Source',
  choiceImageNodeTooltip: 'Choice Option Image',
  choiceTextNodeTooltip: 'Choice Option Text',
  choiceShapeNodeTooltip: 'Choice Option Shape',
  videoBackground: 'Choice Video Background',
  videoBackgroundShort: 'Video Background',
  choiceNodeTooltip: 'Choice Branch',
  choiceQuestionNodeTooltip: 'Choice Branch Question',
  mChoiceNodeTooltip: 'Multiple Choice Question',
  disclaimer:
    '*Maximum 4, with at least 1 answer correct. No duplicate answers',
  addAnswer: 'Add answer',
  challengeBranchQuestionTooltip: 'Challenge branch question',
  challengeBranchTextOption: 'Challenge text answer',
  challengeBranchImageOption: 'Challenge image answer',
  challengeBranchAITooltip: 'Challenge Branch AI',
  cantProceedTooltip: 'New nodes cannot be added to a published lesson',
  videoOverlay: 'Video Source with Overlay',
  titlePlaceholder: 'Title',
  jumpToNodeTooltip: 'Jump To',
  lessonNodes: 'Lesson Nodes:',
  duplicateBackgorundOption: 'Duplicate Background Option Found',
  acknowledgmentNode: 'Acknowledgment',
  acknowledgmentText: 'Acknowledgment text',
  acknowledgmentTextPlaceholder:
    'Input your text that describes the terms and conditions of this acknowledgment node',
  acknowledgmentButton: 'Accept button title',
  acknowledgmentButtonPlaceholder:
    'Set the title of the button i.e. Accept, OK, Acknowledge, Confirm, etc.',
  replace: 'Replace Node',
  maxImageDuration: '*Maximum: 3 minutes.',
};

const welsh = {
  emptyState: "Dewiswch elfen i'w olygu",
  source: 'Ffynhonnel:',
  startTime: 'Amser dechrau',
  endTime: 'Amser gorffen',
  title: 'Teitl:',
  save: 'Cadw',
  delete: 'Dileu',
  question: 'Cwestiwn:',
  questionPlaceholder: 'Cwestiwn dewis',
  option: 'Opsiwn',
  deleteAnswer: 'Dileu ateb',
  gradeWeight: 'Pwysau sgôr',
  isCorrect: 'Ateb Cywir?',
  areYouSureDeleteVideo:
    "Dileu'r ffynhonnell fideo hon a'i nodau plant? (Ni ellir ei adfer)",
  areYouSureDeleteImageSource:
    "Dileu'r ffynhonnell ddelwedd hon a'i nodau plant? (Ni ellir ei adfer)",
  areYouSureDeleteVideoSoft:
    "Dileu'r ffynhonnell fideo hon yn unig a chadw ei nodau plant? (Ni ellir ei adfer)",
  areYouSureDeleteImageSourceSoft:
    "Dileu'r ffynhonnell ddelwedd hon yn unig a chadw ei nodau plant? (Ni ellir ei adfer)",
  areYouSureDeleteChoiceQuestion:
    "Dileu'r cwestiwn dewis hwn a'i nodau plant? (Ni ellir ei adfer)",
  areYouSureDeleteChoiceTextOption:
    "Dileu'r opsiwn testun dewis hwn a'i nodau plant? (Ni ellir ei adfer)",
  areYouSureDeleteVideoBackgorundOption:
    "Dileu'r dewis cefndir fideo hwn a'i nodau plant? (Ni ellir ei adfer)",
  areYouSureDeleteChoiceImageOption:
    "Dileu'r opsiwn ddelwedd dewis hwn a'i nodau plant? (Ni ellir ei adfer)",
  areYouSureDeleteChoiceShapeOption:
    "Dileu'r opsiwn siâp dewis hwn a'i nodau plant? (Ni ellir ei adfer)",
  areYouSureDeleteChallengeImageOption:
    "Dileu'r opsiwn ddelwedd her hwn a'i nodau plant? (Ni ellir ei adfer)",
  areYouSureDeleteChoiceHeaderOption:
    "Dileu'r gangen dewis hon a'i nodau plant? (Ni ellir ei adfer)",
  areYouSureDeleteChallengeHeaderOption:
    "Dileu'r gangen her hon a'i nodau plant? (Ni ellir ei adfer)",
  areYouSureDeleteAcknowledgment:
    "Dileu'r cydnabyddiaeth hon a'i nodau plant? (Ni ellir ei adfer)",
  areYouSureDeleteAcknowledgmentSoft:
    "Dileu'r cydnabyddiaeth hon yn unig a chadw ei nodau plant? (Ni ellir ei adfer)",
  areYouSureDeleteJumpToOption:
    "Dileu'r 'neidi at' hwn a'i nodau plant? (Ni ellir ei adfer)",
  areYouSureDeleteJumpToOptionSoft:
    "Dileu'r 'neidio at' hwn yn unig a chadw ei nodau plant? (Ni ellir ei adfer)",
  choiceBranchHeaderText: 'Cangen Dewis',
  challangeBranchHeaderText: 'Cangen Her',
  editSource: 'Golygu Ffynhonnell',
  editSourceWithOverlay: 'Golygu ffynhonnell gyda throshaen',
  editOverlay: 'Golygu troshaen',
  preview: 'Rhagolwg',
  answerTitle: 'Atebion:',
  answerPlaceholder: 'Ychwanegwch ateb amlddewis',
  areYouSureDeleteMChoice:
    "Dileu'r cwestiwn amlddewis hwn a'i nodau plant? (Ni ellir ei adfer)",
  areYouSureDeleteMChoiceSoft:
    "Dileu'r cwestiwn amlddewis hwn yn unig a chadw ei nodau plant? (Ni ellir ei adfer)",
  videoNodeTooltip: 'Fffynhonnell fideo',
  choiceImageNodeTooltip: 'Delwedd opsiwn dewis',
  choiceTextNodeTooltip: 'Testun opsiwn dewis',
  choiceShapeNodeTooltip: 'Siâp opsiwn dewis',
  videoBackground: 'Cefndir Fideo Opsiwn',
  videoBackgroundShort: 'Cefndir Fideo',
  choiceNodeTooltip: 'Gangen Dewis',
  choiceQuestionNodeTooltip: 'Cwestiwn Gangen Dewis',
  mChoiceNodeTooltip: 'Cwestiwn Amlddewis',
  disclaimer: 'Uchafswm o 4, gyda o leiaf 1 ateb cywir. Dim atebion dyblyg',
  addAnswer: 'Ychwanegwch ateb',
  challengeBranchQuestionTooltip: 'Cwestiwn Cangen Her',
  challengeBranchTextOption: 'Cwestiwn Testun Her',
  challengeBranchImageOption: 'Cwestiwn Delwedd Her',
  challengeBranchAITooltip: 'Cangen Her AI',
  cantProceedTooltip:
    'Ni ellir ychwanegu nodau newydd at wers sydd wedi’i chyhoeddi.',
  videoOverlay: 'Ffynhonnell Fideo gyda Throshaen',
  titlePlaceholder: 'Teitl',
  jumpToNodeTooltip: 'Neidio At',
  lessonNodes: 'Nodau Gwers',
  duplicateBackgorundOption: "Dewis Cefndir Dyblyg Wedi'i Ganfod",
  acknowledgmentNode: 'Cydnabyddiaeth',
  acknowledgmentText: 'Testun cydnabyddiaeth',
  acknowledgmentTextPlaceholder:
    "Nodwch eich testun sy'n disgrifio telerau ac amodau'r nod cydnabyddiaeth hwn",
  acknowledgmentButton: 'Teitl botwm derbyn',
  acknowledgmentButtonPlaceholder:
    'Setiwch deitl y botwm, e.e. Derbyn, Iawn, Cydnabod, Cadarnhau, ac ati.',
  replace: 'Cyfnewid Nod',
  maxImageDuration: '*Uchafswm: 3 munud',
};

export const lessonEditorText: any = getLocalizationBasedOnLang(english, welsh);
