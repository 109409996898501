import React, {useEffect, useState} from 'react';
import ModalContainer from '../../../../uiToolkit/ModalContainer';
import AILoader from '../../../../uiToolkit/AILoader';
import {aIassistantSmallIc} from '../../../../assets/images';
import {addChallangeAIWizardText} from '../addChallengeAiWizardText';
import './styles.css';
import GeneratedAnswerItem from '../components/GeneratedAnswerItem';
import Button from '../../../../uiToolkit/Buttons/Button';
import {
  GeneratedChallengeBranch,
  GeneratedChallengeBranchAnswer,
} from '../../../../data/models/QuestionGeneration/GeneratedQuestions';

interface Props {
  onClose: () => void;
  questions?: GeneratedChallengeBranch;
  isLoading: boolean;
  onRegenerate: () => void;
  onCreateChallengeBranch: (
    answer: string,
    question: string,
    frame: string,
  ) => void;
  isGenerating: boolean;
}

const AddChallengeBranchAIWizardUI = React.memo(
  ({
    onClose,
    questions,
    isLoading,
    onRegenerate,
    onCreateChallengeBranch,
    isGenerating,
  }: Props) => {
    const [selectedQuestion, setSelectedQuestion] =
      useState<GeneratedChallengeBranchAnswer>();

    useEffect(() => {
      if (questions && questions.question_answers?.length === 1) {
        setSelectedQuestion(questions.question_answers[0]);
      } else {
        setSelectedQuestion(undefined);
      }
    }, [questions]);

    const onCreate = () => {
      if (selectedQuestion && questions) {
        onCreateChallengeBranch(
          selectedQuestion?.answer_mask,
          selectedQuestion?.question,
          questions?.frame,
        );
      }
    };

    return (
      <ModalContainer onClose={onClose}>
        <div>
          <div className="ChallengeBranchAiHeader">
            <span className="icon-typeChallenge-Branch"></span>
            <img src={aIassistantSmallIc} />
            <p>{addChallangeAIWizardText.title}</p>
          </div>
          {isGenerating ? (
            <AILoader />
          ) : (
            <div className="ChallengeBranchAIContent">
              <p className="Result">{addChallangeAIWizardText.result}</p>
              {questions?.question_answers?.map((item: any, index: number) => (
                <GeneratedAnswerItem
                  mask={item.answer_mask}
                  question={item.question}
                  frame={questions.frame}
                  key={index.toString()}
                  isSelected={
                    selectedQuestion?.answer_mask === item.answer_mask &&
                    selectedQuestion?.question === item.question
                  }
                  onSelect={setSelectedQuestion}
                />
              ))}
              <div className="Footer">
                <Button
                  title={addChallangeAIWizardText.add}
                  onClick={onCreate}
                  disabled={!selectedQuestion}
                  isLoading={isLoading}
                />
                <Button
                  title={addChallangeAIWizardText.regenerate}
                  onClick={onRegenerate}
                  uiType="text"
                  icon="icon-typeReload"
                />
              </div>
            </div>
          )}
        </div>
      </ModalContainer>
    );
  },
);

export default AddChallengeBranchAIWizardUI;
