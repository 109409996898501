import React from 'react';
import './styles.css';
import {breadcrumbIc} from '../../assets/images';
import {Link} from 'react-router-dom';

interface Props {
  steps: {title: string; link?: string}[];
}

const MAX_TITLE_CHARACTERS = 35;

const Breadcrumbs = React.memo(({steps}: Props) => {
  return (
    <div className="Breadcrumbs">
      {steps.map((item, index) => (
        <Link
          to={item.link || '#'}
          key={index.toString()}
          style={item.link ? {cursor: 'pointer'} : undefined}>
          <p className={index === steps.length - 1 ? 'LastItem' : ''}>
            {item.title.length > MAX_TITLE_CHARACTERS
              ? item.title.substring(0, MAX_TITLE_CHARACTERS) + '...'
              : item.title}
          </p>
          {index !== steps.length - 1 && <img src={breadcrumbIc} />}
        </Link>
      ))}
    </div>
  );
});

export default Breadcrumbs;
