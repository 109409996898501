import React from 'react';
import {videoSourcesText} from '../../videoSourcesText';
import {Col, Row} from 'react-bootstrap';
import AiGeneratedFilesTable from '../components/AiGeneratedFilesTable';
import {MediaFile} from '../../../../data/models/LibraryFile/MediaFile';

interface Props {
  files: MediaFile[];
  pages: number;
  onPageChange: (page: number) => void;
  onDelete: (id: string) => void;
  onSort: (selectedColumn: string, sortDirection: string) => void;
  onEditVideo: (file: MediaFile) => void;
  onPreview: (file: MediaFile) => void;
  onDownloadTranscript: (id: string) => void;
  permissions: any;
}

const AIFilesUI = React.memo(
  ({
    files,
    pages,
    onDelete,
    onPageChange,
    onSort,
    permissions,
    onEditVideo,
    onPreview,
    onDownloadTranscript,
  }: Props) => {
    return (
      <>
        <h5 className="AllFilsTitle">{videoSourcesText.aiFiles}</h5>
        <Row>
          <Col lg={12} xs={12}>
            <AiGeneratedFilesTable
              sources={files}
              onPageChange={onPageChange}
              onDownloadTranscript={onDownloadTranscript}
              pages={pages}
              onDelete={onDelete}
              onSort={onSort}
              onPreview={onPreview}
              onEditVideo={onEditVideo}
              workspacePermissions={permissions}
            />
          </Col>
        </Row>
      </>
    );
  },
);

export default AIFilesUI;
