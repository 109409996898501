import {makeAutoObservable} from 'mobx';
import LessonContentRepository from '../../../data/repositories/LessonContentRepository';
import {LessonContentBase} from '../../../data/models/LessonContent/LessonContentBase';
import LessonsRepository from '../../../data/repositories/LessonsRepository';
import {AddLessonContentModel} from '../../../data/models/LessonContent/AddLessonContentModel';
import CoursesRepository from '../../../data/repositories/CoursesRepository';
import FoldersRepository from '../../../data/repositories/FoldersRepository';
import LocalStorage from '../../../data/storage/LocalStorage';

class LessonEditorStore {
  private lessonContentRepository = new LessonContentRepository();
  private lessonRepository = new LessonsRepository();
  private courseRepo = new CoursesRepository();
  private foldersRepo = new FoldersRepository();
  private localStorage = new LocalStorage();

  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public async getLesson(organizationId: string, lessonId: string) {
    const result = await this.lessonRepository.getLessonById(
      organizationId,
      lessonId,
    );
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async getLessonIsPublished(organizationId: string, lessonId: string) {
    const result = await this.lessonRepository.getLessonIsPublishedById(
      organizationId,
      lessonId,
    );
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async getCourse(organizationId: string, courseId: string) {
    const result = await this.courseRepo.getCourse(organizationId, courseId);
    return result.data;
  }

  public async exportAsScorm(
    organizationId: string,
    courseId: string,
    courseName: string,
  ) {
    this.isLoading = true;
    await this.courseRepo.exportAsScorm(organizationId, courseId, courseName);
    this.isLoading = false;
  }

  public async getLessonFolder(organizationId: string, folderId: string) {
    const result = await this.foldersRepo.getFolderById(
      organizationId,
      folderId,
    );

    return result.data;
  }

  public async getLessonContent(organizationId: string, lessonId: string) {
    const result = await this.lessonContentRepository.getLessonContent(
      organizationId,
      lessonId,
    );
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async addLessonNode(
    organizationId: string,
    lessonId: string,
    parentId: string,
    payload: LessonContentBase,
  ) {
    this.isLoading = true;
    const model: AddLessonContentModel = {
      data: payload,
      parentsIds: [parentId],
    };
    const result = await this.lessonContentRepository.addLessonContent(
      organizationId,
      lessonId,
      model,
    );
    this.isLoading = false;
    return result.success;
  }

  public async editContentNode(
    organizationId: string,
    lessonId: string,
    content: LessonContentBase,
  ) {
    this.isLoading = true;
    const result = await this.lessonContentRepository.editLessonContent(
      organizationId,
      lessonId,
      content,
    );
    this.isLoading = false;
    return result.success;
  }

  public async deleteContentNode(
    organizationId: string,
    lessonId: string,
    internalId: string,
  ) {
    this.isLoading = true;
    const result = await this.lessonContentRepository.deleteLessonContent(
      organizationId,
      lessonId,
      internalId,
    );
    this.isLoading = false;
    return result.success;
  }

  public async softDeleteContentNode(
    organizationId: string,
    lessonId: string,
    internalId: string,
  ) {
    this.isLoading = true;
    const result = await this.lessonContentRepository.softDeleteLessonContent(
      organizationId,
      lessonId,
      internalId,
    );
    this.isLoading = false;
    return result.success;
  }
}

export default LessonEditorStore;
