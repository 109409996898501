import React, {useEffect, useState} from 'react';
import ModalContainer from '../../../uiToolkit/ModalContainer';
import {aIassistantSmallIc, logoTextWhite} from '../../../assets/images';
import {aiLessonGeneratorText} from '../aiLessonGeneratorText';
import YesNoOption from '../../../uiToolkit/Inputs/YesNoOption';
import './styles.css';
import NumbersInput from '../../../uiToolkit/Inputs/NumbersInput';
import Button from '../../../uiToolkit/Buttons/Button';
import {MediaFile} from '../../../data/models/LibraryFile/MediaFile';
import AILoader from '../../../uiToolkit/AILoader';

interface Props {
  onClose: () => void;
  videoFile?: MediaFile;
  generateLesson: (
    includeBranching: boolean,
    includeGrading: boolean,
    passingScore?: number,
  ) => void;
  isLoading: boolean;
  isGradable: boolean;
}

const AILessonGeneratorUI = React.memo(
  ({onClose, videoFile, generateLesson, isLoading, isGradable}: Props) => {
    const [includeBranching, setIncludeBranching] = useState(true);
    const [includeGrading, setIncludeGrading] = useState(true);
    const [passingScore, setPassingScore] = useState<number | undefined>(50);

    useEffect(() => {
      setIncludeGrading(isGradable);
    }, [isGradable]);

    const onGenerate = () => {
      generateLesson(
        includeBranching,
        includeGrading,
        includeGrading ? passingScore : undefined,
      );
    };

    return (
      <ModalContainer onClose={onClose}>
        <div>
          {isLoading ? (
            <AILoader />
          ) : (
            <>
              <div className="LessonGenHeading">
                <img src={aIassistantSmallIc} />
                <p>{aiLessonGeneratorText.title}</p>
              </div>
              <p className="LessonGenVideoTitle">
                {aiLessonGeneratorText.source}
              </p>
              <div className="LessonGenVideoInfo">
                {videoFile?.placeholderUrl ? (
                  <img src={videoFile?.placeholderUrl} />
                ) : (
                  <img src={logoTextWhite} className="PlaceholderLogo" />
                )}
                <h5>{videoFile?.fileName}</h5>
              </div>
              <YesNoOption
                title={aiLessonGeneratorText.includeBranching}
                value={includeBranching}
                onSelect={setIncludeBranching}
                className="LessonGenOption"
              />
              <YesNoOption
                title={aiLessonGeneratorText.includeGrading}
                value={includeGrading}
                onSelect={setIncludeGrading}
                className="LessonGenOption"
                disabled={!isGradable}
              />
              <NumbersInput
                heading={aiLessonGeneratorText.passingScore}
                isMandatory
                value={passingScore}
                setValue={setPassingScore}
                disabled={!includeGrading}
                className="LessonGenOption"
              />
              <Button
                title={aiLessonGeneratorText.generate}
                onClick={onGenerate}
                className="LessonGenOption"
              />
            </>
          )}
        </div>
      </ModalContainer>
    );
  },
);

export default AILessonGeneratorUI;
