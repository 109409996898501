import React, {useEffect} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Outlet, useLocation} from 'react-router-dom';
import {loginBackground, welcomeBanner} from '../../../assets/images';
import {appText} from '../../../assets/text/appText';
import './styles.css';

const AuthRoot = React.memo(() => {
  const location = useLocation();

  useEffect(() => {
    document.body.classList.add('authPage');

    return () => {
      document.body.classList.remove('authPage');
    };
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col lg={5} className="AuthOutletContainer">
          <Outlet />
        </Col>
        {location.pathname !== '/auth/onboarding' ? (
          <Col
            lg={7}
            style={{backgroundImage: `url(${loginBackground})`}}
            className="AuthImage">
            <div className="AuthImageContainer">
              <div className="AuthInfoModal">
                <h2 className="AuthInfoModalTitle">
                  {appText.authInfoModalTitle}
                </h2>
                <h4 className="AuthInfoModalText">
                  {appText.autnInfoModelBody}
                </h4>
              </div>
            </div>
          </Col>
        ) : (
          <Col
            lg={7}
            style={{backgroundImage: `url(${welcomeBanner})`}}
            className="AuthImage">
            <div className="AuthImageContainer SignUpSuccess">
              <div className="WelcomeAuthContainer">
                <h2 className="AuthInfoModalTitle">{appText.welcomeTitle}</h2>
                <h4 className="AuthInfoModalText">{appText.welcomeSubtitle}</h4>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </Container>
  );
});

export default AuthRoot;
