import React from 'react';
import {Link} from 'react-router-dom';
import './styles.css';
import NavBar from '../../uiToolkit/NavBar';
import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const PermissionError = React.memo(() => {
  return (
    <div>
      <NavBar />
      <div className="PermissionErrorContainer">
        <h2>
          {getLocalizationBasedOnLang(
            "You don't have permission to view this page",
            'Nid oes gennych ganiatad i weld y dudalen hon',
          )}
        </h2>
        <Link to={'/home'}>{getLocalizationBasedOnLang('Home', 'Hafan')}</Link>
      </div>
    </div>
  );
});

export default PermissionError;
