import React, {useEffect} from 'react';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';
import ImagePickerInput from '../../../../uiToolkit/Inputs/ImagePickerInput';
import {resizeImage} from '../../../../utils/resizeImage';
import {userProfileText} from '../../userProfileText';
import {
  LANGUAGE_OPTIONS,
  UserInfo,
} from '../../../../data/models/User/UserInfo';
import Button from '../../../../uiToolkit/Buttons/Button';
import './styles.css';
import Dropdown from '../../../../uiToolkit/Inputs/Dropdown';

const ORG_AVATAR_WIDTH = 300;
const ORG_AVATAR_HEIGHT = 300;

const MIN_NAME_LENGTH = 3;

interface Props {
  onSave: (fullName: string, preferredLanguage: number, image?: File) => void;
  userProfile: UserInfo;
  isLoading: boolean;
}

const SettingsUserProfileUI = React.memo(
  ({onSave, userProfile, isLoading}: Props) => {
    const [fullName, setFullName] = React.useState(userProfile.name);
    const [email, setEmail] = React.useState(userProfile.email);
    const [profilePicture, setProfilePicture] = React.useState<File>();
    const [preferredLanguage, setPreferredLanguage] = React.useState('0');

    useEffect(() => {
      if (userProfile) {
        setFullName(userProfile.name);
        setEmail(userProfile.email);
        setProfilePicture(undefined);
        setPreferredLanguage(userProfile.preferredLanguage.toString());
      }
    }, [userProfile]);

    const onInitiateSave = () => {
      onSave(fullName.trim(), parseInt(preferredLanguage, 10), profilePicture);
    };

    const changesWasMade = () =>
      fullName.trim() !== userProfile.name ||
      profilePicture !== undefined ||
      preferredLanguage !== userProfile.preferredLanguage.toString();

    const onChangeImage = async (file?: File) => {
      if (file) {
        const compressed = await resizeImage(
          file,
          ORG_AVATAR_WIDTH,
          ORG_AVATAR_HEIGHT,
        );
        setProfilePicture(compressed);
      } else {
        setProfilePicture(undefined);
      }
    };

    const changesAreValid = () => {
      return fullName.trim().length >= MIN_NAME_LENGTH;
    };

    return (
      <div className="SettingsUserProfileContainer">
        <h5>{userProfileText.profileSettingsTitle}</h5>
        <span className="Subtitle">
          {userProfileText.profileSettingsSubtitle}
        </span>
        <TextInput
          value={email}
          onChange={setEmail}
          placeholder={userProfileText.emailPlaceholder}
          heading={userProfileText.emailTitle}
          className="SettingsInput"
          disabled={true}
        />
        <TextInput
          value={fullName}
          onChange={(value: string) => setFullName(value)}
          placeholder={userProfileText.namePlaceholder}
          heading={userProfileText.nameTitle}
          maxCharacters={50}
          className="SettingsInput"
          isMandatory
          fieldError={fullName.trim().length < MIN_NAME_LENGTH}
        />
        <ImagePickerInput
          image={profilePicture}
          onChange={onChangeImage}
          heading={userProfileText.imageTitle}
          placeholder={userProfileText.imageSubtitle}
          existingImage={userProfile.avatarUrl}
          className="SettingsInput"
        />
        <Dropdown
          setSelectedOption={setPreferredLanguage}
          selectedOption={preferredLanguage}
          options={LANGUAGE_OPTIONS}
          heading={userProfileText.language}
        />
        <Button
          title={userProfileText.saveChanges}
          onClick={onInitiateSave}
          uiType="action"
          disabled={!changesWasMade() || !changesAreValid()}
          isLoading={isLoading}
        />
      </div>
    );
  },
);

export default SettingsUserProfileUI;
