import React from 'react';
import {
  gradablePlayerIc,
  lessonCompletedIc,
  lessonFailedIc,
} from '../../../../assets/images';
import {GradeResult} from '../../models';
import Button from '../../../../uiToolkit/Buttons/Button';
import './styles.css';
import {tapyblPlayerText} from '../../tapyblPlayerText';

interface Props {
  gradable: boolean;
  onStart: () => void;
  showResults: () => void;
  gradeResult?: GradeResult;
}

const StartingOverlay = React.memo(
  ({gradable, onStart, showResults, gradeResult}: Props) => {
    const renderAnswersSection = (result: GradeResult) => (
      <div className="QuestionsListContainer">
        <div className="Total">
          <span>{tapyblPlayerText.total}</span>
          <span>
            {result.answers.filter(a => a.correct).length}/
            {result.answers.length}
          </span>
        </div>
        <div className="Actions">
          <Button
            title={tapyblPlayerText.viewResults}
            onClick={showResults}
            className="ResultsButton"
            uiType="hollow"
          />
          <Button
            title={tapyblPlayerText.retry}
            onClick={onStart}
            uiType="secondary"
          />
        </div>
      </div>
    );

    if (gradeResult && gradeResult.finished && gradeResult.totalQuestions > 0) {
      return (
        <div className="EndingOverlay">
          {gradeResult.passingScore > gradeResult.earnedScore ? (
            <>
              <img src={lessonFailedIc} />
              <p className="Title" style={{marginBottom: '0px'}}>
                {tapyblPlayerText.tryAgain}
              </p>
              <span className="Description">
                {tapyblPlayerText.finalScore}{' '}
                <span className="Fail">
                  {Math.round(gradeResult.earnedScore)}%
                </span>
              </span>
              {renderAnswersSection(gradeResult)}
            </>
          ) : (
            <>
              <img src={lessonCompletedIc} />
              <p className="Title" style={{marginBottom: '0px'}}>
                {tapyblPlayerText.wellDone}
              </p>
              <span className="Description">
                {tapyblPlayerText.finalScore}{' '}
                <span className="Success">
                  {Math.round(gradeResult.earnedScore)}%
                </span>
              </span>
              {renderAnswersSection(gradeResult)}
            </>
          )}
        </div>
      );
    }

    return (
      <div className="StartingOverlay">
        {gradable ? (
          <>
            <img src={gradablePlayerIc} className="d-none d-lg-block" />
            <p className="Title">{tapyblPlayerText.gradableLesson}</p>
            <p className="Description">
              {tapyblPlayerText.grabableDescription}
            </p>
            <Button
              title={tapyblPlayerText.imReady}
              onClick={onStart}
              uiType="secondary"
            />
          </>
        ) : (
          <>
            <p className="Title">{tapyblPlayerText.informativeLesson}</p>
            <p className="Description">
              {tapyblPlayerText.informativeDescription}
            </p>
            <Button
              title={tapyblPlayerText.imReady}
              onClick={onStart}
              uiType="secondary"
            />
          </>
        )}
      </div>
    );
  },
);

export default StartingOverlay;
