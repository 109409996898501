export const ORGANIZATION_TYPE_OPTIONS = [
  {
    key: '2',
    value: 'Corporate Learning and Development (L&D)',
  },
  {
    key: '1',
    value: 'Continuous Personal Development (CPD)',
  },
  {
    key: '0',
    value: 'Educational Institution',
  },
];
