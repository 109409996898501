import {inject, observer} from 'mobx-react';
import React from 'react';
import AILessonGeneratorUI from '../view/AILessonGeneratorUI';
import AILessonGeneratorStore from '../store/AILessonGeneratorStore';
import {useParams} from 'react-router-dom';

interface Props {
  aiLessonGeneratorStore?: AILessonGeneratorStore;
}

const AILessonGeneratorContainer = inject('aiLessonGeneratorStore')(
  observer(({aiLessonGeneratorStore}: Props) => {
    const {organizationId} = useParams();
    const onClose = () => {
      aiLessonGeneratorStore?.closeWizard();
    };

    const generateLesson = (
      includeBranching: boolean,
      includeGrading: boolean,
      passingScore?: number,
    ) => {
      if (organizationId) {
        aiLessonGeneratorStore!.generateLesson(
          organizationId,
          includeBranching,
          includeGrading,
          passingScore,
        );
      }
    };

    return aiLessonGeneratorStore!.showModal ? (
      <AILessonGeneratorUI
        onClose={onClose}
        isGradable={aiLessonGeneratorStore!.getIsGradable()}
        videoFile={aiLessonGeneratorStore!.sourceFile}
        generateLesson={generateLesson}
        isLoading={aiLessonGeneratorStore!.isLoading}
      />
    ) : null;
  }),
);

export default AILessonGeneratorContainer;
