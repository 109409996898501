import React from 'react';
import './styles.css';
import Tooltip from '../../Tooltip';

interface Props {
  icon: string;
  onClick: () => void;
  tooltip?: string;
  disabled?: boolean;
}

const IconButton = React.memo(({icon, onClick, tooltip, disabled}: Props) => {
  const renderIcon = () => {
    if (icon.startsWith('icon')) {
      return <span className={icon}></span>;
    } else {
      return <img src={icon} alt="icon_button" />;
    }
  };
  return (
    <button
      onClick={onClick}
      className={`IconButton ${disabled && 'IconButtonDisabled'}`}
      disabled={disabled}>
      {tooltip ? (
        <Tooltip tooltipText={tooltip}>{renderIcon()}</Tooltip>
      ) : (
        renderIcon()
      )}
    </button>
  );
});

export default IconButton;
