import React, {useEffect, useState} from 'react';
import StorageIntegrationSettingsUI from '../view/StorageIntegrationSettingsUI';
import StorageIntegrationStore from '../store/StorageIntegrationStore';
import {inject, observer} from 'mobx-react';
import {useParams} from 'react-router-dom';
import ConfirmationModal from '../../../../uiToolkit/ConfirmationModal';
import {settingsText} from '../../settingsText';

interface Props {
  storageIntegrationStore?: StorageIntegrationStore;
}

const StorageIntegrationContainer = inject('storageIntegrationStore')(
  observer(({storageIntegrationStore}: Props) => {
    const [hasIntegration, setHasIntegration] = useState<boolean>();
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const {organizationId} = useParams();

    useEffect(() => {
      fetchIntegration();
    }, []);

    const fetchIntegration = () => {
      if (organizationId) {
        storageIntegrationStore!
          .getHaveVimeoIntegration(organizationId)
          .then(res => {
            setHasIntegration(res.vimeoConfigured);
          });
      }
    };

    const onSaveSecret = (secret: string) => {
      if (organizationId) {
        storageIntegrationStore!
          .addVimeoSecret(organizationId, secret)
          .then(fetchIntegration);
      }
    };

    const onRemoveIntegration = () => {
      if (organizationId) {
        storageIntegrationStore!
          .removeVimeoIntegration(organizationId)
          .then(success => {
            setShowDeleteConfirmation(false);
            if (success) {
              fetchIntegration();
            }
          });
      }
    };

    return (
      <>
        <StorageIntegrationSettingsUI
          onSaveSecret={onSaveSecret}
          hasIntegration={hasIntegration}
          onRemoveIntegration={() => setShowDeleteConfirmation(true)}
        />
        <ConfirmationModal
          title={
            <div>
              <span>{settingsText.removeVimeoIntegration}</span>
              <p className="WarningText">
                {settingsText.removeVimeoIntegrationWarning}
              </p>
            </div>
          }
          onAccept={onRemoveIntegration}
          onCancel={() => setShowDeleteConfirmation(false)}
          shown={showDeleteConfirmation}
        />
      </>
    );
  }),
);

export default StorageIntegrationContainer;
