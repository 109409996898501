import {makeAutoObservable} from 'mobx';
import GradebookRepository from '../../../../data/repositories/GradebookRepository';
import CoursesRepository from '../../../../data/repositories/CoursesRepository';

class CourseGradebookStore {
  private gradebookRepo = new GradebookRepository();
  private courseRepo = new CoursesRepository();

  constructor() {
    makeAutoObservable(this);
  }

  public async getGradebook(
    organizationId: string,
    courseId: string,
    page: number,
    pageSize: number,
    search: string,
  ) {
    const result = await this.gradebookRepo.getCourseGradebook(
      organizationId,
      courseId,
      page,
      pageSize,
      search,
    );
    if (result.success && result.data) {
      return result.data;
    }
    return {
      learners: [],
      lessons: [],
      grades: {},
      pagesCount: 0,
    };
  }

  public async getCourse(organizationId: string, courseId: string) {
    const result = await this.courseRepo.getCourse(organizationId, courseId);
    if (result.success && result.data) {
      return result.data;
    }
  }
}

export default CourseGradebookStore;
