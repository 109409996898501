import React from 'react';

interface Props {
  width: number;
  aspectRatio: number;
  x: number;
  y: number;
  setWidth: (width: number) => void;
}

const ImageSvgHandles = React.memo(
  ({width, aspectRatio, x, y, setWidth}: Props) => {
    const [position, setPosition] = React.useState({
      x: x + width,
      y: 200,
      active: false,
      offset: {x: 0},
    });

    const getRightLineProps = () => {
      const y1 = y;
      const y2 = y + width / aspectRatio;
      return {y1, y2};
    };

    const handlePointerDown = (e: any) => {
      const el = e.target;
      const bbox = e.target.getBoundingClientRect();
      const x = e.clientX - bbox.left;
      el.setPointerCapture(e.pointerId);
      setPosition({
        ...position,
        active: true,
        offset: {
          x,
        },
      });
    };

    const handlePointerMove = (e: any) => {
      const bbox = e.target.getBoundingClientRect();
      const x = e.clientX - bbox.left;
      if (position.active) {
        setWidth(width - (position.offset.x - x));
      }
    };

    const handlePointerUp = () => {
      setPosition({
        ...position,
        active: false,
      });
    };

    return (
      <g>
        <line
          {...getRightLineProps()}
          x1={x + width}
          x2={x + width}
          onPointerDown={handlePointerDown}
          onPointerUp={handlePointerUp}
          onPointerMove={handlePointerMove}
          stroke="#B8EF36"
          cursor="col-resize"
          strokeWidth={4}
        />
      </g>
    );
  },
);

export default ImageSvgHandles;
