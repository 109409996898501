export interface CreateLearnerLessonModel {
  lessonId: string;
}

export interface LearnerProgressItem {
  type: number;
  answers: string[];
  isCorrect?: boolean;
}

export interface LearnerProgressModel {
  [key: string]: LearnerProgressItem;
}

export const PROGRESS_ITEM_CHOICE = 0;
export const PROGRESS_ITEM_MCHOICE = 1;
export const PROGRESS_ITEM_CHALLENGE = 2;
export const PROGRESS_ITEM_CHALLENGE_AI = 3;
export const PROGRESS_ACKNOWLEDGMENT = 4;
export const PROGRESS_INFO_RECORD = 5;
