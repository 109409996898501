import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const englishChallengeQuestionText = {
  title: 'Add a question for your challenge branch',
  placeholder: 'Challenge branch question',
  accept: 'Accept',
  cancel: 'Cancel',
  inputTitle: 'Question',
  questionWeight: 'Question Weight',
};

const welshChallengeQuestionText = {
  title: 'Ychwanegwch gwestiwn ar gyfer eich cangen her',
  placeholder: 'Herio cwestiwn cangen',
  accept: 'Derbyn',
  cancel: 'Canslo',
  inputTitle: 'Cwestiwn',
  questionWeight: 'Pwysau Cwestiwn',
};

export const addChallengeQuestionText: any = getLocalizationBasedOnLang(
  englishChallengeQuestionText,
  welshChallengeQuestionText,
);
