import React, {useEffect, useState} from 'react';
import NavBar from '../../../uiToolkit/NavBar';
import {Col, Container, Row} from 'react-bootstrap';
import {Outlet, useNavigate, useParams} from 'react-router-dom';
import './styles.css';
import MobileRestriction from '../../../modules/MobileRestriction';
import NavigationStore from '../../store/NavigationStore';
import {inject, observer} from 'mobx-react';

interface Props {
  navigationStore?: NavigationStore;
}

const EditorRoot = inject('navigationStore')(
  observer(({navigationStore}: Props) => {
    const [navHeight, setNavHeight] = useState(0);
    const [hasAccess, setHasAccess] = useState(false);
    const navigate = useNavigate();
    const {courseId} = useParams();

    useEffect(() => {
      if (courseId) {
        navigationStore?.checkCourseEditAccess(courseId).then(access => {
          if (!access) {
            navigate('/home');
          } else {
            setHasAccess(access);
          }
        });
      }
    }, []);

    return (
      <div className="EditorRootContainer">
        <NavBar onHeightCalculated={setNavHeight} />
        <Container fluid>
          {hasAccess && (
            <Row>
              <Col
                xs={12}
                style={{height: `calc(100vh - ${navHeight}px)`}}
                className=" d-none d-lg-block">
                <Outlet />
              </Col>
              <Col
                xs={12}
                className="d-lg-none d-block"
                style={{height: `calc(100vh - ${navHeight}px)`}}>
                <MobileRestriction />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    );
  }),
);

export default EditorRoot;
