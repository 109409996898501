import React, {useState} from 'react';
import {inject, observer} from 'mobx-react';
import CourseLearnerUI from '../view/CourseLearnerUI';
import CourseLearnerStore from '../store/CourseLearnerStore';
import {useEffect} from 'react';
import {useOutletContext, useParams} from 'react-router-dom';
import {Course} from '../../../../data/models/Course/Course';

interface Props {
  courseLearnerStore?: CourseLearnerStore;
}

export const ENROLLMENT_LIST_PAGE_SIZE = 10;

const CourseLearnerContainer = inject('courseLearnerStore')(
  observer(({courseLearnerStore}: Props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState(0);
    const {organizationId} = useParams();
    const [learnerData, setLearnerData] = React.useState<any[]>([]);
    const [search, setSearch] = useState('');

    const context = useOutletContext() as [any, any, Course];

    useEffect(() => {
      fetchLeaners();
    }, [courseLearnerStore, currentPage, search, context]);

    const fetchLeaners = () => {
      if (organizationId && context[2]) {
        courseLearnerStore!
          .getOrganizationLearners(
            organizationId,
            context[2].id || '',
            currentPage - 1,
            ENROLLMENT_LIST_PAGE_SIZE,
            search,
          )
          .then(res => {
            setLearnerData(res.data || []);
            setPages(res.pagesCount);
          });
      }
    };

    const onSave = async (selectedLearners: string[], dueDate?: Date) => {
      if (organizationId && context[2]) {
        const success = await courseLearnerStore!.saveLearners(
          selectedLearners,
          organizationId,
          context[2].id || '',
          dueDate,
        );
        if (success) {
          resetData();
          fetchLeaners();
        }
      }
    };

    const resetData = () => {
      setCurrentPage(1);
      setLearnerData([]);
    };

    const onPageChange = (page: number) => {
      setCurrentPage(page);
    };

    return (
      <CourseLearnerUI
        learnerData={learnerData}
        onSave={onSave}
        pages={pages}
        onPageChange={onPageChange}
        search={search}
        onSearchChange={setSearch}
        publishedCourse={context[2]}
      />
    );
  }),
);
export default CourseLearnerContainer;
