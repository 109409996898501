import {makeAutoObservable, runInAction} from 'mobx';
import {
  FILE_ORIGIN_UPLOADED,
  MEDIA_FILES_ALL,
  MediaFile,
} from '../../../../data/models/LibraryFile/MediaFile';
import LoadingModule from '../../../../modules/LoadingModule/LoadingModule';
import LibraryRepository from '../../../../data/repositories/LibraryRepository';

const PAGE_SIZE = 10;

class TapyblLibraryStore {
  private libraryRepo = new LibraryRepository();

  private _recentFiles: MediaFile[] = [];
  private _amountOfPages = 0;
  private _files: MediaFile[] = [];
  private _sortOptions = {
    column: 'file_name',
    order: 'desc',
    page: 0,
  };

  private loadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get amountOfPages() {
    return this._amountOfPages;
  }

  public get currentPage() {
    return this._sortOptions.page;
  }

  public get tapyblFiles() {
    return this._files;
  }

  public get recentFiles() {
    return this._recentFiles;
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public get sortOptions() {
    return this._sortOptions;
  }

  public setCurrentPage(page: number) {
    this._sortOptions = {
      ...this._sortOptions,
      page,
    };
  }

  public setSortOptions(column: string, order: string) {
    this._sortOptions = {...this._sortOptions, column, order};
  }

  public setPageAndOptions(page: number, column: string, order: string) {
    this._sortOptions = {
      page,
      column,
      order,
    };
  }

  public async fetchFiles(organizationId: string, search?: string) {
    this.loadingModule.startLoading();
    const result = await this.libraryRepo.getMediaFiles(
      this._sortOptions.page,
      organizationId,
      PAGE_SIZE,
      this._sortOptions.column,
      MEDIA_FILES_ALL,
      search,
      this._sortOptions.order === 'asc',
      FILE_ORIGIN_UPLOADED,
    );
    this.loadingModule.endLoading();

    runInAction(() => {
      if (result.success && result.data) {
        this._files = result.data.data;
        this._amountOfPages = result.data.pagesCount;
      }
    });
  }

  public async fetchRecentFiles(organizationId: string) {
    const result = await this.libraryRepo.getMediaFiles(
      0,
      organizationId,
      PAGE_SIZE,
      'created_date',
      MEDIA_FILES_ALL,
      '',
      false,
    );

    runInAction(() => {
      if (result.success && result.data) {
        this._recentFiles = result.data.data;
      }
    });
  }
}

export default TapyblLibraryStore;
