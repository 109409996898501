import React from 'react';
import {Handle, Position} from 'reactflow';
import {CommonNode} from '../view/nodeMapper';
import {handleStyle} from '../../EditorSettings/settings';
import Tooltip from '../../../uiToolkit/Tooltip';
import {nodeIsASourceNode} from '../../../data/models/LessonContent/LessonContentBase';
import {logoTextWhite} from '../../../assets/images';

interface Props {
  data: {
    payload: CommonNode;
    visited: boolean;
    type: number;
  };
}

const JourneyNode = React.memo(({data}: Props) => {
  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        style={handleStyle}
        isConnectable={false}
      />
      <div className="JourneyNodeContainer">
        {data.payload.isCorrect !== undefined ? (
          <div>
            {data.payload.isCorrect ? (
              <span className="icon-typeCheck ChallengeCorrect"></span>
            ) : (
              <span className="icon-typeClose ChallengeIncorrect"></span>
            )}
          </div>
        ) : null}
        <div className={`JourneyCard ${data.visited ? 'VisitedCard' : ''}`}>
          <Tooltip tooltipText={data.payload.title || ''}>
            {data.payload.title ? <p>{data.payload.title}</p> : <div />}
          </Tooltip>
          {data.payload.imageUrl ? (
            <div className="PlaceholderContainer">
              <img src={data.payload.imageUrl} />
            </div>
          ) : nodeIsASourceNode(data.type) ? (
            <div className="LogoPlaceholderContainer">
              <img src={logoTextWhite} />
            </div>
          ) : null}
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        style={handleStyle}
        isConnectable={false}
      />
    </>
  );
});

export default JourneyNode;
