import {Course, CourseViewModel} from '../models/Course/Course';

export const mapCourse = (model: CourseViewModel): Course => {
  const result: Course = {
    title: model.title,
    description: model.description || '',
    subjectId: model.subject,
    isPublic: model.isPublic,
    structure: parseInt(model.structure, 10),
    structureAmount: parseInt(model.structureAmount, 10),
    type: model.type,
    passingScore: model.passingScore,
  };
  return result;
};
