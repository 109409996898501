import React from 'react';
import './styles.css';
import {copyIc} from '../../assets/images';
import {toast} from 'react-toastify';
import {appText} from '../../assets/text/appText';
import {toastConfig} from '../Toast/toastConfig';

interface Props {
  title: string;
  data: string;
}

const CopyField = React.memo(({title, data}: Props) => {
  const onSaveToClipboard = async () => {
    await navigator.clipboard.writeText(data);
    toast.success(appText.copiedToClipboard, toastConfig);
  };

  return (
    <div className="CopyFieldContainer" onClick={onSaveToClipboard}>
      <p className="Heading">{title}</p>
      <div className="DataBox">
        <p>{data}</p>
      </div>
      <img src={copyIc} />
    </div>
  );
});

export default CopyField;
