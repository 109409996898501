import React, {useState} from 'react';
import Lottie from 'lottie-react';
import {loadingLottie} from '../../../../assets/lottie';
import {downloadIc} from '../../../../assets/images';

interface Props {
  fileUrl: string;
  fileName: string;
}

const VideoDownloadButton = React.memo(({fileName, fileUrl}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const download = () => {
    setIsLoading(true);
    fetch(fileUrl, {
      method: 'GET',
    })
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        setIsLoading(false);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };

  return (
    <button onClick={download} className="VideoDownloadButton">
      {isLoading ? (
        <Lottie
          animationData={loadingLottie}
          loop={true}
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      ) : (
        <img src={downloadIc} />
      )}
    </button>
  );
});

export default VideoDownloadButton;
