import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import {MediaFile} from '../../../../data/models/LibraryFile/MediaFile';
import VideoSourcesStore from '../store/VideoSourcesStore';
import MediaPreview from '../../../../modules/MediaPreview';
import {useParams} from 'react-router-dom';

interface Props {
  fileToPreview?: MediaFile;
  videoSourcesStore?: VideoSourcesStore;
  onClose: () => void;
}

const FilePreviewContainer = inject('videoSourcesStore')(
  observer(({fileToPreview, onClose, videoSourcesStore}: Props) => {
    const [refreshedFile, setRefreshedFile] = useState<MediaFile>();

    const {organizationId} = useParams();

    useEffect(() => {
      if (fileToPreview && organizationId) {
        videoSourcesStore
          ?.fetchMediaFile(organizationId, fileToPreview.id)
          .then(setRefreshedFile);
      } else {
        setRefreshedFile(undefined);
      }
    }, [fileToPreview]);

    return refreshedFile ? (
      <MediaPreview
        fileType={refreshedFile.fileType}
        fileUrl={refreshedFile.fileUrl || ''}
        onClose={onClose}
      />
    ) : null;
  }),
);

export default FilePreviewContainer;
