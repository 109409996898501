import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import NotificationCentreStore from '../../../../modules/NotificationCentre/store/NotificationCentreStore';
import NotificationsUserProfileUI from '../view/NotificationsUserProfileUI';
import {Notification} from '../../../../data/models/Notifications/Invitation';
import {useNavigate} from 'react-router-dom';

interface Props {
  notificationCentreStore?: NotificationCentreStore;
}

export const NOTICIATIONS_PAGE_SIZE = 20;

const NotificationsUserProfileContainer = inject('notificationCentreStore')(
  observer(({notificationCentreStore}: Props) => {
    const [seenNotifications, setSeenNotifications] = useState<Notification[]>(
      [],
    );
    const [seenNotificationsPage, setSeenNotificationsPage] = useState(0);
    const [seenNotificationsPages, setSeenNotificationsPages] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
      fetchNotifications();
    }, [notificationCentreStore]);

    useEffect(() => {
      notificationCentreStore!
        .getSeenNotifications(seenNotificationsPage, NOTICIATIONS_PAGE_SIZE)
        .then(res => {
          setSeenNotifications(res.data);
          setSeenNotificationsPages(res.pagesCount);
        });
    }, [seenNotificationsPage]);

    const onDeny = (invitationId: string, notificationId: string) => {
      notificationCentreStore
        ?.denyInvitation(invitationId, notificationId)
        .then(fetchNotifications);
    };

    const fetchNotifications = () => {
      notificationCentreStore?.getMyNotifications();
    };

    const onSeenNotification = (notificationId: string) => {
      notificationCentreStore!
        .markAsSeen(notificationId)
        .then(fetchNotifications);
    };

    const onViewCourse = async (courseId: string, notificationId: string) => {
      await notificationCentreStore!.markAsSeen(notificationId);
      navigate(`/course/${courseId}`);
    };

    const onViewWorkspace = async (
      workspaceId: string,
      notificationId: string,
    ) => {
      await notificationCentreStore!.markAsSeen(notificationId);
      navigate(`/manage/${workspaceId}/workspace`);
    };

    const onViewCoursePreview = async (
      courseId: string,
      workspaceId: string,
      notificationId: string,
    ) => {
      await notificationCentreStore!.markAsSeen(notificationId);
      navigate(`/preview/${workspaceId}/course/${courseId}`);
    };

    return (
      <NotificationsUserProfileUI
        notifications={notificationCentreStore!.getNotifications()}
        onDeny={onDeny}
        onSeenNotification={onSeenNotification}
        onViewCourse={onViewCourse}
        onViewWorkspace={onViewWorkspace}
        seenNotifications={seenNotifications}
        onViewCoursePreview={onViewCoursePreview}
        currentPage={seenNotificationsPage}
        onPageChange={setSeenNotificationsPage}
        pagesCount={seenNotificationsPages}
      />
    );
  }),
);
export default NotificationsUserProfileContainer;
