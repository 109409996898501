import React from 'react';
import {appText} from '../../../assets/text/appText';
import './styles.css';

interface Props {
  onChange: (text: string) => void;
  value: string;
  className?: string;
  onEnter?: (text: string) => void;
}

const SearchInput = React.memo(
  ({onChange, value, className, onEnter}: Props) => {
    return (
      <div className={`SearchInputOuterContainer ${className}`}>
        <div className={`SearchInputContainer ${className}`}>
          <input
            placeholder={appText.search}
            value={value}
            onChange={e => onChange((e.target as HTMLInputElement).value)}
            onKeyDown={e => {
              if (e.key === 'Enter' && onEnter) {
                onEnter((e.target as HTMLInputElement).value);
              }
            }}
          />
          {value ? (
            //<Tooltip tooltipText={appText.clearSearch}>
            <span
              className="icon-type-bolder_x ClearIcon"
              onClick={() => onChange('')}></span>
          ) : (
            //</Tooltip>
            <span className="icon-typeSearch"></span>
          )}
        </div>
      </div>
    );
  },
);

export default SearchInput;
