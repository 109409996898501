import React, {useEffect, useState} from 'react';
import {appText} from '../../../assets/text/appText';
import './styles.css';

interface Props {
  onRetry: () => void;
  title: string;
}

const SECONDS_UNTIL_NEXT_RETRY = 60;

const RetryButton = React.memo(({onRetry, title}: Props) => {
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const onStartRetry = () => {
    if (!timeLeft) {
      onRetry();
      setTimeLeft(SECONDS_UNTIL_NEXT_RETRY);
    }
  };

  return (
    <button className="RetryButton" onClick={onStartRetry}>
      {timeLeft ? appText.nextAttempt(timeLeft) : title}
    </button>
  );
});

export default RetryButton;
