import React from 'react';
import FeaturesTable from '../../../Subscription/SelectSubscription/components/FeaturesTable';
import {settingsText} from '../../settingsText';
import {closeModalIcon} from '../../../../assets/images';

interface Props {
  onClose: () => void;
}

const FeaturesTableModal = React.memo(({onClose}: Props) => {
  return (
    <div className="FeaturesTableModal">
      <div className="FeaturesTableBody">
        <div className="d-flex flex-row justify-content-between align-items-start">
          <div />
          <p className="Title">{settingsText.featuresList}</p>
          <button className="CloseButton" onClick={onClose}>
            <img src={closeModalIcon} />
          </button>
        </div>
        <FeaturesTable />
      </div>
    </div>
  );
});

export default FeaturesTableModal;
