import {MarkerType} from 'reactflow';
import {
  CHOICE_TEXT_QUESTION,
  CHOICE_HEADER_NODE_TYPE,
  CHALLENGE_BRANCH_HEADER,
  CHALLENGE_BRANCH_QUESTION,
  CHALLENGE_BRANCH_AI,
} from '../../data/models/LessonContent/LessonContentBase';

export const defaultEdgeOptions = (
  parentType?: number,
  progressVisited?: boolean,
  finisherEdge?: boolean,
) => ({
  style: {
    stroke: progressVisited ? '#2290CE' : '#1D7777',
    strokeWidth: progressVisited ? 2 : 1,
  },
  type: getIsAnimated(parentType) || finisherEdge ? 'step' : 'buttonedge',
  markerEnd: getArrowMarker(parentType, progressVisited),
  animated: getIsAnimated(parentType),
  ...getLabel(parentType),
});

const getIsAnimated = (parentType?: number) => {
  if (
    parentType === CHOICE_TEXT_QUESTION ||
    parentType === CHOICE_HEADER_NODE_TYPE ||
    parentType === CHALLENGE_BRANCH_HEADER ||
    parentType === CHALLENGE_BRANCH_QUESTION ||
    parentType === CHALLENGE_BRANCH_AI
  ) {
    return true;
  }
  return false;
};

const getLabel = (parentType?: number) => {
  if (parentType === CHOICE_HEADER_NODE_TYPE) {
    return {
      label: 'Choice',
      labelStyle: {
        fontFamily: 'Walsheim',
        fontSize: '12px',
        fill: '#98BDBD',
        fontWeight: 'normal',
      },
    };
  }
  if (parentType === CHALLENGE_BRANCH_HEADER) {
    return {
      label: 'Challenge',
      labelStyle: {
        fontFamily: 'Walsheim',
        fontSize: '12px',
        fill: '#98BDBD',
        fontWeight: 'normal',
      },
    };
  }
  return {};
};

const getArrowMarker = (parentType?: number, progressVisited?: boolean) => {
  if (
    parentType === CHOICE_TEXT_QUESTION ||
    parentType === CHOICE_HEADER_NODE_TYPE ||
    parentType === CHALLENGE_BRANCH_HEADER ||
    parentType === CHALLENGE_BRANCH_QUESTION ||
    parentType === CHALLENGE_BRANCH_AI
  ) {
    return undefined;
  }
  return {
    type: MarkerType.ArrowClosed,
    color: progressVisited ? '#2290CE' : '#1D7777',
  };
};

export const defaultNodeOptions = {
  draggable: false,
  connectable: false,
  position: {x: 0, y: 0},
};

export const handleStyle = {
  background: 'transparent',
  border: 'none',
};
