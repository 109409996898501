import React from 'react';
import {courseIcon} from '../../../../assets/images';
import Checkbox from '../../../../uiToolkit/Inputs/Checkbox';
import Button from '../../../../uiToolkit/Buttons/Button';
import {appText} from '../../../../assets/text/appText';
import './styles.css';

interface Props {
  courses: any[];
  applyFiltersButton: (filters: string[]) => void;
  selectedFilters: string[];
}

const handleCheckboxChange = (
  courseId: string,
  checkedCoursesIds: string[],
  setCheckedCoursesIds: React.Dispatch<React.SetStateAction<string[]>>,
) => {
  if (!checkedCoursesIds.includes(courseId)) {
    setCheckedCoursesIds(prevState => {
      const newState = [...prevState, courseId];
      return newState;
    });
  } else {
    setCheckedCoursesIds(prevState => {
      const newState = prevState.filter(id => id !== courseId);
      return newState;
    });
  }
};

const FilterCourses = React.memo(
  ({courses, applyFiltersButton, selectedFilters}: Props) => {
    const [lastSelectedFilters, setLastSelectedFilters] = React.useState<
      string[]
    >(selectedFilters || []);
    const [checkedCoursesIds, setCheckedCoursesIds] = React.useState<string[]>(
      lastSelectedFilters || [],
    );

    const handleApplyFilterClick = () => {
      setLastSelectedFilters(checkedCoursesIds);
      applyFiltersButton(checkedCoursesIds);
    };

    const clearAllFilters = () => {
      setLastSelectedFilters([]);
      applyFiltersButton([]);
    };

    return (
      <div className="FilterWizardContainer">
        <div className="BoxHeader">
          <h3>Filter</h3>
          <span
            className="icon-typeClose"
            onClick={() => clearAllFilters()}></span>
        </div>
        <div className="BoxSubHeader">
          <img src={courseIcon} alt="course" />
          <h3>Courses</h3>
        </div>
        <div className="BoxContent">
          {courses?.map((course: any) => (
            <div key={course.id} className="BoxContentRow">
              <Checkbox
                checked={checkedCoursesIds.includes(course.id)}
                className="Checkbox"
                onCheck={() =>
                  handleCheckboxChange(
                    course.id,
                    checkedCoursesIds,
                    setCheckedCoursesIds,
                  )
                }
                radio
                text={course.title}
              />
            </div>
          ))}
        </div>
        <div className="BoxFooter">
          <Button
            title={appText.apply}
            onClick={handleApplyFilterClick}
            uiType="action"
          />
        </div>
      </div>
    );
  },
);

export default FilterCourses;
