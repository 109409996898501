import {makeAutoObservable, runInAction} from 'mobx';
import CoursesRepository from '../../../../data/repositories/CoursesRepository';
import AuthRepository from '../../../../data/repositories/AuthRepository';
import LoadingModule from '../../../../modules/LoadingModule/LoadingModule';
import {CourseListItem} from '../../../../data/models/Course/Course';
import {toast} from 'react-toastify';
import {coursesListText} from '../coursesListText';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import {getErrorFromCode} from '../../../../data/errorCodes';

export const COURSES_PAGE_SIZE = 15;

class ListCoursesStore {
  private coursesRepo = new CoursesRepository();
  private authRepo = new AuthRepository();
  private loadingModule = new LoadingModule();

  private _currentPage = 0;
  private _courses: CourseListItem[] = [];
  private _pagesCount = 0;

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public get courses() {
    return this._courses;
  }

  public get pagesCount() {
    return this._pagesCount;
  }

  public setCurrentPage(page: number) {
    this._currentPage = page;
  }

  public async getOrganizationCourses(organizationId: string, search?: string) {
    this.loadingModule.startLoading();
    const result = await this.coursesRepo.getOrganizationCourses(
      organizationId,
      this._currentPage,
      COURSES_PAGE_SIZE,
      search,
    );
    this.loadingModule.endLoading();
    runInAction(() => {
      if (result.success && result.data) {
        this._courses = result.data.data;
        this._pagesCount = result.data.pagesCount;
      }
    });
  }

  public async deleteCourse(organizationId: string, courseId: string) {
    this.loadingModule.startLoading();
    const result = await this.coursesRepo.deleteCourse(
      organizationId,
      courseId,
    );
    this.loadingModule.endLoading();
    return result.success;
  }

  public async copyCourse(organizationId: string, courseId: string) {
    this.loadingModule.startLoading();
    const result = await this.coursesRepo.copyCourse(organizationId, courseId);
    this.loadingModule.endLoading();
    if (result.success) {
      toast.success(coursesListText.copySuccess, toastConfig);
    } else if (result.errors && result.errors.length) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
  }
}

export default ListCoursesStore;
