import React, {useState} from 'react';
import './styles.css';
import {ChoiceFreeformMapped} from '../../../../data/models/LessonContent/LessonConentMapped';
import {
  BRANCH_BACKGROUND_FREEFORM,
  BRANCH_IMAGE_FREEFORM,
  BRANCH_SHAPE_FREEFORM,
  BRANCH_TEXT_FREEFORM,
  CHALLENGE_BRANCH_HEADER,
  CHOICE_HEADER_NODE_TYPE,
  LessonContentFreeformImageChoice,
  LessonContentFreeformShapeChoice,
  LessonContentFreeformTextChoice,
} from '../../../../data/models/LessonContent/LessonContentBase';
import EditorSvgImage from '../../../Editor/FreeformElements/EditorSvgImage';
import {
  scaleImageOptionsForDisplay,
  scaleShapeOptionsForDisplay,
  scaleTextOptionsForDisplay,
} from '../../../Editor/AddFreeformChoiceOption/utlis';
import EditorSvgText from '../../../Editor/FreeformElements/EditorSvgText';
import EditorSvgPath from '../../../Editor/FreeformElements/EditorSvgPath';
import './styles.css';
import Tooltip from '../../../../uiToolkit/Tooltip';
import {tapyblPlayerText} from '../../tapyblPlayerText';
import ChallengeInfoHeader from '../ChallengeInfoHeader/ChallengeInfoHeader';

interface Props {
  width: number;
  overlayNode: ChoiceFreeformMapped;
  onSelectFreeformChoice: (choiceId: string) => void;
  passingScore?: number;
}

const ChoiceFreeformOverlay = React.memo(
  ({width, overlayNode, onSelectFreeformChoice, passingScore}: Props) => {
    const [showHeader, setShowHeader] = useState(true);

    const renderOverlays = () => {
      //TODO extract svg's into diiferent module
      return overlayNode.answers.map((answer, index) => {
        if (answer.ivNodeType === BRANCH_IMAGE_FREEFORM) {
          const imageNode = answer as LessonContentFreeformImageChoice;
          return (
            <EditorSvgImage
              image={scaleImageOptionsForDisplay(
                imageNode.encodedAtWidth,
                width,
                imageNode,
              )}
              key={index}
              source={imageNode.contentUrl || ''}
              aspect={imageNode.aspect}
              onClick={() => {
                onSelectFreeformChoice(answer.internalId);
              }}
            />
          );
        }
        if (answer.ivNodeType === BRANCH_TEXT_FREEFORM) {
          const textNode = answer as LessonContentFreeformTextChoice;
          return (
            <EditorSvgText
              position={scaleTextOptionsForDisplay(
                textNode.encodedAtWidth,
                width,
                {x: textNode.x, y: textNode.y},
              )}
              text={textNode.text}
              fontSize={textNode.fontSize}
              color={textNode.color}
              key={index}
              onClick={() => {
                onSelectFreeformChoice(answer.internalId);
              }}
            />
          );
        }
        if (answer.ivNodeType === BRANCH_SHAPE_FREEFORM) {
          const shapeNode = answer as LessonContentFreeformShapeChoice;
          return (
            <EditorSvgPath
              shapePoints={scaleShapeOptionsForDisplay(
                shapeNode.encodedAtWidth,
                width,
                shapeNode.overlayCoordinates,
              )}
              key={index}
              onClick={() => {
                onSelectFreeformChoice(answer.internalId);
              }}
              hideShape={!shapeNode.overlayVisible}
            />
          );
        }
        return <g key={index}></g>;
      });
    };

    const getVideoBackgroundAction = () => {
      const action = overlayNode.answers.find(
        item => item.ivNodeType === BRANCH_BACKGROUND_FREEFORM,
      );
      if (action) {
        return action.internalId;
      }
      return null;
    };

    const onClickCanvas = () => {
      const actionId = getVideoBackgroundAction();
      if (actionId) {
        onSelectFreeformChoice(actionId);
      }
    };

    return (
      <div>
        <div
          style={{width, height: width / 1.77}}
          className="ChoiceFreeformOverlayCanvas">
          {showHeader ? (
            <div className="FreeformOverlayQuestionHeader">
              <ChallengeInfoHeader
                passingScore={passingScore}
                typeIcon={
                  overlayNode.ivNodeType === CHOICE_HEADER_NODE_TYPE
                    ? 'icon-typeChoice-Branch'
                    : 'icon-typeChallenge-Branch'
                }
                typeTitle={
                  overlayNode.ivNodeType === CHOICE_HEADER_NODE_TYPE
                    ? tapyblPlayerText.choiceBranch
                    : tapyblPlayerText.challengeBranch
                }
                haveMultipleAnswers={false}
                className="ChoiceInfo"
                question={overlayNode.question}
                isGradable={overlayNode.ivNodeType === CHALLENGE_BRANCH_HEADER}
              />
              <Tooltip tooltipText={tapyblPlayerText.hideQuestion}>
                <button
                  className="CollapseFreeformHeaderButton"
                  onClick={() => setShowHeader(false)}>
                  <span className="icon-typeChvron-Down"></span>
                </button>
              </Tooltip>
            </div>
          ) : (
            <div>
              <Tooltip tooltipText={tapyblPlayerText.showQuestion}>
                <button
                  className="ExpandFreeformHeaderButton"
                  onClick={() => setShowHeader(true)}>
                  <span className="icon-typeChvron-Down"></span>
                </button>
              </Tooltip>
            </div>
          )}

          <svg width={width} height={width / 1.77} onClick={onClickCanvas}>
            {renderOverlays()}
          </svg>
        </div>
      </div>
    );
  },
);

export default ChoiceFreeformOverlay;
