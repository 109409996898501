import React, {useState} from 'react';
import SecurityUserProfileUI from '../view/SecurityUserProfileUI';
import {inject, observer} from 'mobx-react';
import SecurityUserProfileStore from '../store/SecurityUserProfileStore';
import {toast} from 'react-toastify';
import {securityUserProfileText} from '../securityUserProfileText';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';

interface Props {
  securityUserProfileStore?: SecurityUserProfileStore;
}

const SecurityUserProfileContainer = inject('securityUserProfileStore')(
  observer(({securityUserProfileStore}: Props) => {
    const [updated, setUpdated] = useState(false);

    const onChangePassword = async (
      pass: string,
      newPass: string,
      confirmPass: string,
    ) => {
      const success = await securityUserProfileStore!.editUserPassword(
        pass,
        newPass,
        confirmPass,
      );
      if (success) {
        setUpdated(!updated);
        toast.success(
          securityUserProfileText.passwordUpdateSuccessful,
          toastConfig,
        );
      }
    };

    return (
      <SecurityUserProfileUI
        onChangePassword={onChangePassword}
        updated={updated}
      />
    );
  }),
);

export default SecurityUserProfileContainer;
