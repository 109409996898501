import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  lastVideoFrame: 'Last Video Frame',
  options: 'Options',
  imageChoice: 'Image Choice',
  freeformText: 'Text Choice',
  freeformShape: 'Shape Choice',
  imageChoiceTitle: 'Image Choice Title',
  freeformTextInput: 'Text Choice:',
  title: 'Title',
  cancel: 'Cancel',
  save: 'Save',
  fontSize: 'Font Size:',
  textColor: 'Text Color:',
  color: 'Color',
  addPoint: 'Add Point',
  removePoint: 'Remove Point',
  showOverlay: 'Show Overlay For Learners?',
};

const welsh = {
  lastVideoFrame: 'Ffrâm fideo olaf',
  options: 'Opsiynau',
  imageChoice: 'Dewis Delwedd',
  freeformText: 'Dewis Testun',
  freeformShape: 'Dewis Siâp',
  imageChoiceTitle: 'Teitl Dewis Delwedd',
  freeformTextInput: 'Dewis Testun',
  title: 'Teitl',
  cancel: 'Canslo',
  save: 'Cadw',
  fontSize: 'Maint y ffont',
  textColor: 'Lliw Testun',
  color: 'Lliw',
  addPoint: 'Ychwanegwch Pwynt',
  removePoint: 'Dileu Pwynt',
  showOverlay: 'Dangos Troshaen i  Ddysgwyr?',
};

export const addFreeformChoiceText: any = getLocalizationBasedOnLang(
  english,
  welsh,
);
