import React, {useEffect, useState} from 'react';
import SettingsProfileUI from '../view/SettingsUserProfileUI';
import {inject, observer} from 'mobx-react';
import {toast} from 'react-toastify';
import SettingsUserProfileStore from '../store/SettingsUserProfileStore';
import {UserInfo} from '../../../../data/models/User/UserInfo';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import {userProfileText} from '../../userProfileText';
import UserInfoStore from '../../../../stores/UserInfoStore';

interface Props {
  settingsUserProfileStore?: SettingsUserProfileStore;
  userInfoStore?: UserInfoStore;
}

const SettingsUserProfileContainer = inject(
  'settingsUserProfileStore',
  'userInfoStore',
)(
  observer(({settingsUserProfileStore, userInfoStore}: Props) => {
    const [profile, setProfile] = useState<UserInfo | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      fetchProfile();
    }, []);

    const fetchProfile = async () => {
      const userProfile = await settingsUserProfileStore!.getUserProfile();
      setProfile(userProfile);
    };

    const onSave = async (
      fullName: string,
      preferredLanguage: number,
      avatar?: File,
    ) => {
      setIsLoading(true);
      const nameEditSuccess = await settingsUserProfileStore!.editUserProfile(
        fullName,
        preferredLanguage,
      );

      if (!nameEditSuccess) {
        setIsLoading(false);
        toast.error(userProfileText.nameUpdateFailed, toastConfig);
        return;
      }

      if (avatar) {
        const avatarEditSuccess =
          await settingsUserProfileStore!.editUserAvatar(avatar);

        if (!avatarEditSuccess) {
          setIsLoading(false);
          fetchProfile();
          toast.error(userProfileText.profilePictureUpdateFailed, toastConfig);
          return;
        }
      }

      setIsLoading(false);
      fetchProfile();
      await userInfoStore!.fetchUserInfo(true);
      toast.success(userProfileText.profileDataUpdateSuccessful, toastConfig);
    };

    return profile ? (
      <SettingsProfileUI
        onSave={onSave}
        userProfile={profile}
        isLoading={isLoading}
      />
    ) : null;
  }),
);

export default SettingsUserProfileContainer;
