import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {subscriptionFirstPaymentText} from '../subscriptionFirstPaymentText';
import './styles.css';
import {getTierSummaryConfig} from '../tierSummaryConfig';
import Lottie from 'lottie-react';
import {loadingLottie} from '../../../../assets/lottie';
import {subscriptionArrowBack} from '../../../../assets/images';

interface Props {
  onChoosePlan: () => void;
  tier?: number;
  onPay: () => void;
  isLoading: boolean;
}

const SubscriptionFirstPaymentUI = React.memo(
  ({onChoosePlan, tier, onPay, isLoading}: Props) => {
    if (isLoading) {
      return (
        <div className="LoaderContainer">
          <Lottie
            animationData={loadingLottie}
            loop={true}
            style={{width: '48px', height: '48px'}}
          />
        </div>
      );
    }
    return (
      <Container>
        <Row>
          <Col xl={3} xs={12}>
            <button
              className="FirstSubPaymentBackButton"
              onClick={onChoosePlan}>
              <img src={subscriptionArrowBack} />
              {subscriptionFirstPaymentText.goBack}
            </button>
          </Col>
          <Col xl={7} xs={12}>
            <div className="SubFirstPaymentContainer">
              <p className="PlanTitle">{getTierSummaryConfig(tier)?.title}</p>
              <h2 className="Title">{subscriptionFirstPaymentText.title}</h2>
              <h4 className="Subtitle">
                {subscriptionFirstPaymentText.subtitle}
              </h4>
              <p className="InfoTitle">{subscriptionFirstPaymentText.info1}</p>

              <ul className="InfoList">
                <li className="InfoText">
                  {subscriptionFirstPaymentText.point1(
                    getTierSummaryConfig(tier)?.price || '',
                  )}
                </li>
                <li className="InfoText">
                  {subscriptionFirstPaymentText.point2}
                </li>
                <li className="InfoText">
                  {subscriptionFirstPaymentText.point3}{' '}
                  <a
                    href="https://tapybl.com/full-terms-and-conditions/"
                    target="_blank"
                    rel="noreferrer">
                    {subscriptionFirstPaymentText.terms}
                  </a>
                </li>
              </ul>

              <div className="Divider" />

              <h6 className="SummaryText">
                {subscriptionFirstPaymentText.summary}
              </h6>
              <div className="SummaryContainer">
                <div>
                  <p className="SummarySectionTitle">
                    {subscriptionFirstPaymentText.selectedTier}
                  </p>
                  <p className="SummarySectionTitle">
                    {getTierSummaryConfig(tier)?.title}
                  </p>
                </div>
                <div>
                  <p className="SummarySectionTitle">
                    {subscriptionFirstPaymentText.planDescription}
                  </p>
                  <div className="d-flex flex-row">
                    <ul className="Features">
                      {getTierSummaryConfig(tier)?.features.map(feature => (
                        <li key={feature}>- {feature}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div>
                  <p className="SummarySectionTitle">
                    {subscriptionFirstPaymentText.price}
                  </p>
                  <p className="SummarySectionTitle">
                    {getTierSummaryConfig(tier)?.price}
                  </p>
                </div>
              </div>
              <div className="Divider" />
              <p className="PriceTotal">
                {subscriptionFirstPaymentText.total}
                {getTierSummaryConfig(tier)?.price}
              </p>
              <div className="Footer">
                <button onClick={onPay} className="PaySubButton">
                  {subscriptionFirstPaymentText.proceed}
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  },
);

export default SubscriptionFirstPaymentUI;
