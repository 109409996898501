import {downloadBlobFile} from '../../utils/downloadBlobFile';
import CoursesApi from '../api/CoursesApi';
import {mapCourse} from '../mappers/courseMapper';
import {Course, CourseViewModel} from '../models/Course/Course';

class CoursesRepository {
  private coursesApi = new CoursesApi();

  public getLearingStandarts(organizationId: string) {
    return this.coursesApi.getLearningStandarts(organizationId);
  }

  public async createCourse(model: CourseViewModel, organizationId: string) {
    const courseMapped = mapCourse(model);
    const result = await this.coursesApi.createCourse(
      courseMapped,
      organizationId,
    );
    if (result.success && result.data && result.data.id && model.image) {
      await this.coursesApi.uploadCourseAvatar(
        model.image,
        result.data.id,
        organizationId,
      );
    }
    return result;
  }

  public async exportAsScorm(
    organizationId: string,
    courseId: string,
    courseName: string,
  ) {
    const result = await this.coursesApi.exportAsScorm(
      organizationId,
      courseId,
    );
    if (result.success && result.data) {
      downloadBlobFile(result.data, courseName, '.zip');
    }
    return result;
  }

  public getOrganizationCourses(
    organizationId: string,
    page: number,
    pageSize: number,
    search?: string,
  ) {
    return this.coursesApi.getOrganizationCourses(
      organizationId,
      page,
      pageSize,
      search,
    );
  }

  public getPublishedOrganizationCourses(organizationId: string) {
    return this.coursesApi.getPublishedOrganizationCourses(organizationId);
  }

  public getCourseInstructor(organizationId: string, courseId: string) {
    return this.coursesApi.getCourseInstructor(organizationId, courseId);
  }

  public deleteCourse(organizationId: string, courseId: string) {
    return this.coursesApi.deleteCourse(organizationId, courseId);
  }

  public getCourse(organizationId: string, courseId: string) {
    return this.coursesApi.getCourse(organizationId, courseId);
  }

  public getCourseList(page: number, pageSize: number) {
    return this.coursesApi.getCoursesList(page, pageSize);
  }

  public getPublicCourseList(
    page: number,
    pageSize: number,
    subjectId?: string,
  ) {
    return this.coursesApi.getPublicCoursesList(page, pageSize, subjectId);
  }

  public editCourse(organizationId: string, course: Course) {
    return this.coursesApi.editCourse(organizationId, course);
  }

  public getCoursePresentation(courseId: string) {
    return this.coursesApi.getCoursePresentation(courseId);
  }

  public getCoursePreview(organizationId: string, courseId: string) {
    return this.coursesApi.getCoursePreview(organizationId, courseId);
  }

  public getLearnerCourseProgress(courseId: string) {
    return this.coursesApi.getLearnerCourseProgress(courseId);
  }

  public selfEnroll(courseId: string) {
    return this.coursesApi.selfEnrollInTheCourse(courseId);
  }

  public uploadCourseAvatar(
    image: File,
    courseId: string,
    organizationId: string,
  ) {
    return this.coursesApi.uploadCourseAvatar(image, courseId, organizationId);
  }

  public getSubjects() {
    return this.coursesApi.getCourseSubjects();
  }

  public editCoursePoster(
    organizationId: string,
    courseId: string,
    image: File,
  ) {
    return this.coursesApi.uploadCourseAvatar(image, courseId, organizationId);
  }

  public publishCourse(organizationId: string, courseId: string) {
    return this.coursesApi.publishCourse(organizationId, courseId);
  }

  public republishCourse(organizationId: string, courseId: string) {
    return this.coursesApi.rePublishCourse(organizationId, courseId);
  }

  public getSidebarOptions(
    organizationId: string,
    page: number,
    pageSize: number,
    search?: string,
  ) {
    return this.coursesApi.getSidebarOptions(
      organizationId,
      page,
      pageSize,
      search,
    );
  }

  public async getSidebarCoursePosition(
    organizationId: string,
    courseId: string,
    pageSize: number,
  ) {
    return this.coursesApi.getSidebarCoursePosition(
      organizationId,
      courseId,
      pageSize,
    );
  }

  public async getPublishedCourseVersion(
    organizationId: string,
    courseId: string,
  ) {
    return this.coursesApi.getPublishedCourseVersion(organizationId, courseId);
  }

  public async copyCourse(organizationId: string, courseId: string) {
    return this.coursesApi.copyCourse(organizationId, courseId);
  }

  public async checkCoursePreviewAccess(
    organizationId: string,
    courseId: string,
  ) {
    return this.coursesApi.checkCoursePreviewAccess(organizationId, courseId);
  }

  public async checkCoursePresentationAccess(courseId: string) {
    return this.coursesApi.checkCoursePresentationAccess(courseId);
  }
}

export default CoursesRepository;
