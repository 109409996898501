import React from 'react';
import {LessonContentAcknowledgment} from '../../../../data/models/LessonContent/LessonContentBase';
import Button from '../../../../uiToolkit/Buttons/Button';
import './styles.css';

interface Props {
  acknowledgment: LessonContentAcknowledgment;
  onAcknowledge: (id: string) => void;
}

const AcknowledgmentOverlay = React.memo(
  ({acknowledgment, onAcknowledge}: Props) => {
    return (
      <div className="AcknowledgmentOverlay">
        <p>{acknowledgment.title}</p>
        <Button
          uiType="action"
          title={acknowledgment.buttonTitle}
          onClick={() => {
            onAcknowledge(acknowledgment.internalId);
          }}
        />
      </div>
    );
  },
);

export default AcknowledgmentOverlay;
