import React from 'react';
import '../view/styles.css';

interface Props {
  notificationsCount: number;
  onClick: (e: any) => void;
}

const NotificationsOpenerButton = React.memo(
  ({notificationsCount, onClick}: Props) => {
    return (
      <button className="NavBarNotificationsButton" onClick={onClick}>
        {notificationsCount ? (
          <span className="icon-typeNotification---Filled"></span>
        ) : (
          <span className="icon-typeNotification---Empty"></span>
        )}

        {notificationsCount ? (
          <div className="NotificationsCounter">
            <span>{notificationsCount}</span>
          </div>
        ) : null}
      </button>
    );
  },
);

export default NotificationsOpenerButton;
