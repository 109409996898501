export interface Organization {
  id: string;
  name: string;
  description: string;
  primaryColor: string;
  secondaryColor: string;
  avatar?: string;
  avatarName?: string;
  totalLibraryMediaFileSizesInMb: number;
  type: number;
  trialSubscriptionUsed?: boolean;
  selectedSubscriptionType?: number;
}

export interface OrganizationSettings {
  id: string;
  name: string;
  description: string;
  primaryColor?: string;
  secondaryColor?: string;
  avatar?: string;
  avatarName?: string;
  type: number;
}

export interface OrganizationDetails {
  id: string;
  name: string;
  description: string;
  primaryColor?: string;
  secondaryColor?: string;
  avatar?: string;
  trialSubscriptionUsed: boolean;
  userType: number;
}

export interface UserOrganizationDetails {
  organization: OrganizationDetails;
  permissions: {[key: string]: boolean};
  userRolePermissions: string[];
  subscriptionState: number;
}

export const ORGANIZATION_NO_SUBSCRIPTION = 0;
export const ORGANIZATION_SUBSCRIPTION_ENDED = 1;
export const ORGANIZATION_SUBSCRIPTION_ACTIVE = 2;

export const ORGANIZATION_USER_LEARNER = 0;
export const ORGANIZATION_USER_STAFF = 1;
