import GradebookAPI from '../api/GradebookAPI';

class GradebookRepository {
  private gradebookAPI = new GradebookAPI();

  public getCourseGradebook(
    organizationId: string,
    courseId: string,
    page: number,
    pageSize: number,
    search: string,
  ) {
    return this.gradebookAPI.getCourseGradebook(
      organizationId,
      courseId,
      page,
      pageSize,
      search,
    );
  }

  public getLearningJourney(
    learnerId: string,
    lessonId: string,
    courseId: string,
  ) {
    return this.gradebookAPI.getLearnerProgressDetails(
      courseId,
      lessonId,
      learnerId,
    );
  }
}

export default GradebookRepository;
