import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  cropTitle: 'Add a start and end for video',
  startTime: 'Start',
  endTime: 'End',
  seekToTimestamp: 'Seek to time (HH:MM:SS.MS)',
  cancel: 'Cancel',
  save: 'Save',
  overlayTitle: 'Overlay Settings',
  addOverlay: 'Add Overlay',
  overlayError: 'Invalid Overlay Settings',
  nodeDestination: 'Node Destination',
  lessonDestination: 'Lesson Destination',
  showOverlay: 'Show Overlay For Learners?',
  deleteOverlay: 'Delete Overlay',
};

const welsh = {
  cropTitle: 'Ychwanegwch dechrau a diwedd ar gyfer fideo',
  startTime: 'Dechrau',
  endTime: 'Diwedd',
  seekToTimestamp: 'Ceisio amser (HH:MM:SS.MS)',
  cancel: 'Canslo',
  save: 'Cadw',
  overlayTitle: 'Gosodiadau Troshaen',
  addOverlay: 'Ychwanegwch Troshaen',
  overlayError: 'Gosodiadau Troshaen Annilys',
  nodeDestination: 'Cyrchfan Nôd',
  lessonDestination: 'Cyrchfan y Wers',
  showOverlay: 'Dangos Troshaen i  Ddysgwyr?',
  deleteOverlay: 'Dileu Troshaen',
};

export const addVideoOverlayWizardText: any = getLocalizationBasedOnLang(
  english,
  welsh,
);
