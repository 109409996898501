import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {selectSubscriptionText} from '../selectSubscriptionText';
import {
  subAiIcon,
  subscriptionGreenCheck,
  subStorageIc,
  subUsersIc,
} from '../../../../assets/images';

interface Props {
  plan: any;
  onSelect: (tier?: number) => void;
}

const SubscriptionDetails = React.memo(({plan, onSelect}: Props) => {
  return (
    <Container>
      <Row>
        <Col lg={{span: 10, offset: 1}} xs={12}>
          <Row className="SubscriptionDetailsContainer">
            <Col xs={12} className="Header">
              <p className="SelectSubtitle">{plan.header}</p>
            </Col>
            <Col md={7} lg={6} xs={12} style={{padding: 0}}>
              <div className="PriceContainer">
                <h3>{plan.title}</h3>
                <span className="PerYear">
                  <h2 className="YearPrice">{plan.pricePerYear}</h2>
                  {plan.usersPerMonth ? selectSubscriptionText.perYear : ''}
                </span>
              </div>
              <div className="FeaturesContainer d-block d-md-none">
                <h6>{selectSubscriptionText.features}</h6>
                <div>
                  {plan.features.map((feature: string) => (
                    <div key={feature} className="FeatureItem">
                      <img src={subscriptionGreenCheck} />
                      {feature}
                    </div>
                  ))}
                </div>
              </div>
              <div className="ResourcesContainer">
                <p>{selectSubscriptionText.whatIsIncluded}</p>
                <div className="ResourceContainer">
                  <img src={subAiIcon} />
                  <span>{plan.aiCredits}</span>
                </div>
                <div className="ResourceContainer">
                  <img src={subStorageIc} />
                  <span>{plan.storage}</span>
                </div>
                <div className="ResourceContainer">
                  <img src={subUsersIc} />
                  <span>{plan.amountOfUsers}</span>
                </div>
              </div>
              <div className="AddUsersContainer">
                {plan.usersPerMonth && <p>{selectSubscriptionText.addUsers}</p>}
                {plan.usersPerMonth && (
                  <span className="PerMonth">
                    <h2 className="MonthPrice">{plan.price}</h2>
                    <span>{selectSubscriptionText.perUserPerMonth}</span>
                  </span>
                )}
                <button
                  className="SignupButton"
                  onClick={() => onSelect(plan.id)}>
                  {plan.buttonText}
                </button>
                <button
                  className="TrialButton"
                  onClick={() => onSelect(plan.trialTier)}>
                  {plan.trialText}
                </button>
              </div>
            </Col>
            <Col
              md={5}
              lg={6}
              xs={12}
              className="FeaturesContainer d-none d-md-block">
              <h6>{selectSubscriptionText.features}</h6>
              <div>
                {plan.features.map((feature: string) => (
                  <div key={feature} className="FeatureItem">
                    <img src={subscriptionGreenCheck} />
                    {feature}
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
});

export default SubscriptionDetails;
