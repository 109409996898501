import React, {useState} from 'react';
import {generateAIVideosText} from '../generateAIVideosText';
import Dropdown from '../../../uiToolkit/Inputs/Dropdown';
import {
  LANGUAGE,
  VOICE_GENDER,
} from '../../../data/models/QuestionGeneration/AIGenerationDropdownOptions';
import {SelectOption} from '../../../data/models/UI/SelectOption';
import {Col} from 'react-bootstrap';

interface Props {
  language: string;
  setLanguage: (value: string) => void;
  dialectOptions: SelectOption[];
  selectedDialect: string;
  setSelectedDialect: (value: string) => void;
  voiceGender: string;
  setVoiceGender: (value: string) => void;
}

const VoiceNarrationStep = React.memo(
  ({
    language,
    selectedDialect,
    setLanguage,
    dialectOptions,
    setSelectedDialect,
    voiceGender,
    setVoiceGender,
  }: Props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    return (
      <Col xs={12} lg={6}>
        <div>
          <h5>{generateAIVideosText.narrationCustomization}</h5>
          <Dropdown
            options={LANGUAGE}
            selectedOption={language}
            setSelectedOption={setLanguage}
            heading={generateAIVideosText.languageDropdownHeading}
            className="LanguageDropdown"
          />
          <Dropdown
            options={VOICE_GENDER}
            selectedOption={voiceGender}
            setSelectedOption={setVoiceGender}
            heading={generateAIVideosText.voiceGenderHeading}
            className="LanguageDropdown"
          />
          <Dropdown
            options={dialectOptions}
            selectedOption={selectedDialect}
            setSelectedOption={setSelectedDialect}
            heading={generateAIVideosText.dialectDropdownHeading}
            className="LanguageDropdown"
            onDropdownVisibilityToggle={setDropdownOpen}
          />
          {dropdownOpen && <div className="DropdownPadding" />}
        </div>
      </Col>
    );
  },
);

export default VoiceNarrationStep;
