import {makeAutoObservable} from 'mobx';
import {
  MediaFile,
  VimeoMediaFile,
} from '../../../../data/models/LibraryFile/MediaFile';
import {VideoOverlay} from '../../../../data/models/LessonContent/ContentFreeform';
import {
  LessonContentBase,
  LessonContentOverlayVideo,
  LessonContentVimeoOverlayVideo,
  OVERLAY_VIDEO_TYPE,
  OVERLAY_VIMEO_VIDEO_TYPE,
} from '../../../../data/models/LessonContent/LessonContentBase';
import LessonContentRepository from '../../../../data/repositories/LessonContentRepository';
import {LessonContentList} from '../../../../data/models/LessonContent/LessonContentList';
import LessonsRepository from '../../../../data/repositories/LessonsRepository';

class EditVideoOverlayStore {
  private libraryContentRepo = new LessonContentRepository();
  private lessonRepo = new LessonsRepository();

  private isShown = false;
  private selectedFile: MediaFile | null = null;
  private selectedVimeoFile: VimeoMediaFile | null = null;
  private lessonContent: LessonContentList | null = null;
  private lessonIsGradable = false;
  private successCallback?: () => void;
  private existingOverlayNodeId?: string | null = null;

  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public openWizard(
    mediaFile: MediaFile | null,
    vimeoFile: VimeoMediaFile | null,
    successCallback: () => void,
    lessonContent: LessonContentList,
    lessonIsGradable: boolean,
    existingOverlayNodeId: string,
  ) {
    this.selectedFile = mediaFile;
    this.selectedVimeoFile = vimeoFile;
    this.successCallback = successCallback;
    this.isShown = true;
    this.existingOverlayNodeId = existingOverlayNodeId;
    this.lessonContent = lessonContent;
    this.lessonIsGradable = lessonIsGradable;
  }

  public async getLessonsList(organizationId: string, courseId: string) {
    const result = await this.lessonRepo.getAllCourseLessons(
      organizationId,
      courseId,
    );
    if (result && result.success && result.data) {
      return result.data;
    }
    return [];
  }

  public get showWizard() {
    return this.isShown;
  }

  public getIsGradable() {
    return this.lessonIsGradable;
  }

  public closeWizard() {
    this.selectedFile = null;
    this.selectedVimeoFile = null;
    this.successCallback = undefined;
    this.isShown = false;
    this.existingOverlayNodeId = undefined;
    this.lessonContent = null;
  }

  public getVideoSource() {
    return this.selectedFile;
  }

  public getVimeoSource() {
    return this.selectedVimeoFile;
  }

  public getLessonContent() {
    if (this.lessonContent) {
      return this.lessonContent;
    }
  }

  public async editOverlayNode(
    organizationId: string,
    lessonId: string,
    start: number,
    end: number,
    overlays: VideoOverlay[],
  ) {
    if (!this.existingOverlayNodeId || !this.lessonContent) {
      return;
    }
    const exisingNode =
      this.lessonContent.contentList.items[this.existingOverlayNodeId];
    if (!exisingNode) {
      return;
    }
    let newNodeModel: LessonContentBase = {
      ivNodeType: OVERLAY_VIDEO_TYPE,
      internalId: this.existingOverlayNodeId,
    };
    if (this.selectedFile) {
      this.isLoading = true;
      newNodeModel = {
        ivNodeType: OVERLAY_VIDEO_TYPE,
        startInSeconds: start,
        endInSeconds: end,
        durationInseconds: end - start,
        contentId: this.selectedFile.storageFileName,
        title: this.selectedFile.fileName,
        internalId: this.existingOverlayNodeId,
        overlays,
      } as LessonContentOverlayVideo;
    }

    if (this.selectedVimeoFile) {
      this.isLoading = true;
      newNodeModel = {
        ivNodeType: OVERLAY_VIMEO_VIDEO_TYPE,
        startInSeconds: start,
        endInSeconds: end,
        durationInSeconds: end - start,
        contentUrl: this.selectedVimeoFile.url,
        title: this.selectedVimeoFile.title,
        placeholderUrl: this.selectedVimeoFile.placeholderUrl,
        internalId: this.existingOverlayNodeId,
        overlays,
      } as LessonContentVimeoOverlayVideo;
    }
    const result = await this.libraryContentRepo.editLessonContent(
      organizationId,
      lessonId,
      newNodeModel,
    );
    this.isLoading = false;
    if (result.success && this.successCallback) {
      this.successCallback();
      this.closeWizard();
    }
  }
}

export default EditVideoOverlayStore;
