import React, {useState} from 'react';
import ModalContainer from '../../../../uiToolkit/ModalContainer';
import {addAcknowledgmentWizardText} from '../addAcknowledgmentWizardText';
import './styles.css';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';
import Button from '../../../../uiToolkit/Buttons/Button';

interface Props {
  onClose: () => void;
  onSave: (title: string, buttonTitle: string) => void;
}

const AddAcknowledgmentWizardUI = React.memo(({onClose, onSave}: Props) => {
  const [title, setTitle] = useState('');
  const [buttonText, setButtonText] = useState('');

  const save = () => {
    if (title.trim() !== '' && buttonText.trim() !== '') {
      onSave(title, buttonText);
    }
  };

  return (
    <ModalContainer onClose={onClose}>
      <div className="AddAcknowledgmentContainer">
        <p className="Title">{addAcknowledgmentWizardText.title}</p>
        <TextInput
          heading={addAcknowledgmentWizardText.titleInput}
          value={title}
          onChange={setTitle}
          placeholder={addAcknowledgmentWizardText.placeholder}
          isMandatory
          className="Input"
          maxCharacters={500}
        />
        <TextInput
          heading={addAcknowledgmentWizardText.buttonInput}
          value={buttonText}
          onChange={setButtonText}
          placeholder={addAcknowledgmentWizardText.buttonPlaceholder}
          isMandatory
          maxCharacters={40}
        />
        <div className="d-flex flex-row justify-content-between mt-4">
          <Button
            uiType="hollow"
            title={addAcknowledgmentWizardText.cancel}
            onClick={onClose}
          />
          <Button
            uiType="action"
            title={addAcknowledgmentWizardText.save}
            onClick={save}
            disabled={title.trim() === '' || buttonText.trim() === ''}
          />
        </div>
      </div>
    </ModalContainer>
  );
});

export default AddAcknowledgmentWizardUI;
