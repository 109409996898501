import React, {useEffect, useRef, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import './styles.css';
import Lottie from 'lottie-react';
import {loadingLottie} from '../../assets/lottie';
import {appText} from '../../assets/text/appText';

interface Props {
  content: React.ReactElement;
  options: React.ReactElement;
  header?: React.ReactElement;
  sideBar?: React.ReactElement;
  onContentClick: () => void;
  customContent?: boolean;
  isLoading?: boolean;
  isPublished?: boolean;
  showSidebar?: boolean;
  sidebarSet?: () => void;
}

const PADDING_BOTTOM = 30;

const EditorLayout = React.memo(
  ({
    content,
    options,
    header,
    sideBar,
    onContentClick,
    customContent,
    isLoading,
    isPublished,
    showSidebar,
    sidebarSet,
  }: Props) => {
    const [headerHeight, setHeaderHeight] = useState(0);
    const [contentHeight, setContentHeight] = useState(0);

    const rootRef = useRef<any>(null);
    const headerRef = useRef<any>(null);

    useEffect(() => {
      setTimeout(() => {
        setLayoutComponentsSize();
      }, 100);
      setLayoutComponentsSize();
      window.addEventListener('resize', setLayoutComponentsSize);
      return () => {
        window.removeEventListener('resize', setLayoutComponentsSize);
      };
    }, [rootRef, headerRef]);

    const setLayoutComponentsSize = () => {
      if (rootRef && rootRef.current) {
        setContentHeight(rootRef.current.clientHeight);
      }
      if (headerRef && headerRef.current) {
        setHeaderHeight(headerRef.current.clientHeight);
      }
    };

    const getHeightCalc = () => {
      return `calc(100vh - (100vh - ${contentHeight}px) - ${headerHeight}px - ${PADDING_BOTTOM}px)`;
    };

    const renderPublishedBadge = () => (
      <div className="PublishedCourseBadge">
        <span>{appText.publishedCourse}</span>
      </div>
    );

    return (
      <div className="EditorLayoutContainer" ref={rootRef}>
        <Container fluid>
          <div ref={headerRef}>{header}</div>
          <Row className="EditorColumnsContainer">
            <Col xs={9} onClick={onContentClick}>
              <div className="d-flex flex-row">
                <div className="EditorSidebarButtonContainer">
                  {showSidebar && (
                    <div
                      className="EditorSidebarContainer"
                      style={{
                        height: showSidebar
                          ? `calc(${getHeightCalc()} - 80px)`
                          : '100%',
                        transform: showSidebar
                          ? 'translateX(0px)'
                          : 'translateX(10px)',
                      }}>
                      {sideBar}
                    </div>
                  )}
                  {showSidebar ? (
                    <div className="EditorCollapseButtonContainer">
                      <button
                        className="SidebarColapseButton"
                        onClick={sidebarSet}>
                        <span
                          className={`icon-typeChvron-Down ${
                            showSidebar ? 'expand' : ''
                          }`}></span>
                      </button>
                    </div>
                  ) : (
                    <div className="EditorCollapseButtonContainer">
                      <button
                        className="SidebarColapseButton"
                        onClick={sidebarSet}>
                        <span
                          className={`icon-typeChvron-Down ${
                            showSidebar ? 'expand' : ''
                          }`}></span>
                      </button>
                    </div>
                  )}
                </div>
                {customContent ? (
                  <div
                    className={`EditorCustomContentContainer ${
                      isPublished ? 'EditorPublished' : ''
                    }`}
                    style={{
                      height: getHeightCalc(),
                    }}>
                    {isPublished && renderPublishedBadge()}
                    {content}
                  </div>
                ) : (
                  <div
                    className={`EditorContentContainer ${
                      isPublished ? 'EditorPublished' : ''
                    }`}
                    style={{
                      height: getHeightCalc(),
                    }}>
                    {isPublished && renderPublishedBadge()}
                    {content}
                    {isLoading && (
                      <div className="LoaderContainer">
                        <Lottie
                          animationData={loadingLottie}
                          loop={true}
                          style={{width: '14px', height: '14px'}}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Col>
            <Col xs={3}>
              <div
                className="EditorOptionsContainer"
                style={{height: getHeightCalc()}}>
                {options}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  },
);

export default EditorLayout;
