import React, {useEffect} from 'react';
import SelectSubscriptionUI from '../view/SelectSubcsriptionUI';
import {useLocation, useNavigate} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import SelectSubcsriptionStore from '../store/SelectSubscriptionStore';

interface Props {
  selectSubcsriptionStore?: SelectSubcsriptionStore;
}

const SelectSubscriptionContainer = inject('selectSubcsriptionStore')(
  observer(({selectSubcsriptionStore}: Props) => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      console.log(location.state);
    }, []);

    const onSelectTier = async (tier?: number) => {
      if (selectSubcsriptionStore!.isLoading) return;
      if (tier !== undefined) {
        if (location.state && location.state.modifyForOrganizationId) {
          await selectSubcsriptionStore!.editPreSelectedSubscription(
            location.state.modifyForOrganizationId,
            tier,
          );
          navigate(`/home`);
        } else {
          navigate(`/auth/signup?selected_tier=${tier}`);
        }
      } else {
        window.open('https://tapybl.com/request-demo/', '_blank');
      }
    };

    return <SelectSubscriptionUI onSelect={onSelectTier} />;
  }),
);

export default SelectSubscriptionContainer;
