import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  deny: 'Ignore',
  notifications: 'Notifications',
  viewAll: 'View All Notifications',
  noNotifications: 'No New Notifications',
  viewCourse: 'View course',
  markAsRead: 'Delete',
  viewWorkspace: 'View Workspace',
  newNotifications: 'Recent notifications',
  seenNotifications: 'Seen notifications',
};

const welsh = {
  deny: 'Anwybyddu',
  notifications: 'Hysbysiadau',
  viewAll: 'Gwedl Pawb',
  noNotifications: 'Dim Hysbysiadau N4ewydd',
  markAsRead: 'Dileu',
  viewCourse: 'Gweld y cwrs',
  viewWorkspace: 'Gweld y gweithle',
};

export const notificationsText: any = getLocalizationBasedOnLang(
  english,
  welsh,
);
