import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  title: 'New Course',
  subtitle:
    "Let's kick things off by adding some key details about your course!",
  titleTextPlaceholder: 'Give your course a name',
  titleTextTitle: 'Course Name',
  subjectSelectTitle: 'Course Category',
  subjectSelectPlaceholder: 'Select a category for this course',
  descriptionTextPlaceholder: 'Describe your course',
  descriptionTextTitle: 'Course Description',
  iconPlaceholder: 'Upload a course icon',
  saveAndExit: 'Save and close',
  iconHeading: 'Course Icon',
  type: 'Assessable Course',
  publicListing: 'Allow public listing with self-enrollment',
  structureSelectPlaceholder: 'Select course structure',
  structureSelectTitle: 'Structure course according to:',
  sizePlaceholder: 'Select number',
  nextStep: 'Next Step',
  cancel: 'Cancel',
  wantGradable: 'Want assessable courses?',
  passingScore: 'Passing score',
};

const welsh = {
  title: 'Cwrs Newydd',
  subtitle: 'Dechreuwch trwy ychwanegu rhai manylion allweddol am eich cwrs!',
  titleTextPlaceholder: "Rhowch enw i'ch cwrs",
  titleTextTitle: "Enw'r Cwrs",
  subjectSelectTitle: "Categori'r Cwrs",
  subjectSelectPlaceholder: 'Dewiswch gategori ar gyfer y cwrs hwn',
  descriptionTextPlaceholder: 'Disgrifiwch eich cwrs',
  descriptionTextTitle: 'Disgrifiad y Cwrs',
  iconPlaceholder: 'Uwchlwythwch eicon y cwrs',
  saveAndExit: 'Cadw a chau',
  iconHeading: 'Eicon y Cwrs',
  type: "Cwrs Sy'n Efallai i'w Asesadwy",
  publicListing: 'Caniatewch rhestru cyhoeddus gyda chofrestru eich hun',
  structureSelectPlaceholder: 'Dewis strwythur y cwrs',
  structureSelectTitle: "Strwythuro'r cwrs yn unol â",
  sizePlaceholder: 'Dewis rhif',
  nextStep: 'Cam Nesaf',
  cancel: 'Canslo',
  wantGradable: "Ydych chi eisiau cyrsiau sy'n efallai i'w asesadwy?",
  passingScore: "Sgôr i'r Basio",
};

export const createCourseText: any = getLocalizationBasedOnLang(english, welsh);
