import React from 'react';
import {CourseAccessGate} from '../../../modules/CourseAccessGate';
import CourseInstructorContainer from './container/CourseInstructorContainer';

const CourseInstructor = () => {
  return (
    <CourseAccessGate>
      <CourseInstructorContainer />
    </CourseAccessGate>
  );
};

export {CourseInstructor};
