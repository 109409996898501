import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  summary: 'Summary of your upload',
  resolution: 'Video resolution: ',
  selectHeader: 'Choose a target resolution for your video',
  tip: 'Some resolutions may not be available based on the video you uploaded. To access higher resolutions, the original video needs to be of higher quality',
  videoUploaded: 'File Uploaded Successfully',
  fileName: 'Selected File: ',
  upload: 'Upload',
};

const welsh = {
  summary: "Crynodeb o'ch llwytho i fyny.",
  resolution: 'Resolfa fideo',
  selectHeader: 'Dewiswch resolfa darged ar gyfer eich fideo',
  tip: "Efallai na fydd rhai resolfeydd ar gael yn seiliedig ar y fideo a lwythwyd gennych. I gael gafael ar resolfeydd uwch, mae angen i'r fideo gwreiddiol fod o ansawdd uwch",
  videoUploaded: "Mae'r ffeil wedi'i llwytho i fyny'n llwyddiannus",
  fileName: 'Ffeil a ddewiswyd',
  upload: 'Llwythwch i fyny',
};

export const fileUploadText = getLocalizationBasedOnLang(english, welsh);
