import React, {useEffect} from 'react';
import './styles.css';
import ReactSpeedometer from 'react-d3-speedometer';

interface Props {
  lowerLimit: number;
  upperLimit: number;
  currentCount: number;
}

const MeterDashboardCard = ({lowerLimit, upperLimit, currentCount}: Props) => {
  useEffect(() => {
    const elements = document.querySelectorAll('.segment-value');
    elements.forEach(element => {
      const text = element.textContent;

      if (text === `0 GB`) {
        element.setAttribute('transform', 'rotate(0) translate(-55, 15)');
      } else if (text === `${upperLimit} GB`) {
        element.setAttribute('transform', 'rotate(0) translate(55, 15)');
      }
    });
  }, [currentCount, upperLimit]);

  return (
    <div className="MeterContainer">
      <ReactSpeedometer
        maxValue={upperLimit}
        value={Math.round(currentCount * 1000) / 1000}
        minValue={lowerLimit}
        needleColor="none"
        startColor="#D9D9D9"
        segmentColors={['#B8EF36', '#D9D9D9']}
        height={120}
        width={180}
        customSegmentStops={[
          0,
          Math.round(currentCount * 1000) / 1000,
          upperLimit,
        ]}
        ringWidth={35}
        labelFontSize="12px"
        segmentValueFormatter={value => `${value} GB`}
        currentValueText=""
      />
    </div>
  );
};

export default MeterDashboardCard;
