export interface Role {
  id: string;
  organizationId: string;
  roleName: string;
  usersAmount?: number;
  systemRole?: boolean;
  rolePermissions?: {
    id: string;
    permission: string;
  }[];
}

export const WORKSPACE_ADMIN = 'Workspace Admin';
