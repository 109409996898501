import {makeAutoObservable} from 'mobx';
import AuthRepository from '../../../data/repositories/AuthRepository';

class UserMenuPopUpStore {
  private authRepo = new AuthRepository();

  constructor() {
    makeAutoObservable(this);
  }

  public async logout() {
    return await this.authRepo.logout();
  }
}

export default UserMenuPopUpStore;
