import React from 'react';
import EditorEntryLabel from '../../../uiToolkit/Editor/EditorEntryLabel';
import {Handle, Position} from 'reactflow';
import {handleStyle} from '../../../modules/EditorSettings/settings';

interface Props {
  data: {
    lessonTitle: string;
  };
}

const EntryNode = React.memo(({data}: Props) => {
  return (
    <div>
      <div className="EditorNodeContainer">
        <EditorEntryLabel label={data.lessonTitle} />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        style={handleStyle}
        isConnectable={false}
      />
    </div>
  );
});

export default EntryNode;
