import {ApiResponse, PaginatedApiReponse} from '../models/ApiResponse';
import {Notification} from '../models/Notifications/Invitation';
import {apiClient, withAuth} from './baseApiClient';

class NotificationsApi {
  public async getMyNotifications(): Promise<ApiResponse<Notification[]>> {
    const headers = await withAuth({});
    return apiClient.get('notifications/list', {headers});
  }

  public async denyInvitation(invitationId: string): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.post(
      'organizationInvitation/deny_staff_invitation',
      {invitationId},
      {headers},
    );
  }

  public async markAsSeen(notificationId: string): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.post('notifications/seen', {notificationId}, {headers});
  }

  public async markAsRead(): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.post('Notifications/seen/all', {}, {headers});
  }

  public async getSeenNotifications(
    page: number,
    pageSize: number,
  ): Promise<PaginatedApiReponse<Notification>> {
    const headers = await withAuth({});
    return apiClient.post(
      'notifications/seen/list',
      {page, pageSize},
      {headers},
    );
  }
}

export default NotificationsApi;
