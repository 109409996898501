import React from 'react';
import Button from '../../../../uiToolkit/Buttons/Button';
import TimeInput from '../../../../uiToolkit/Inputs/TimeInput/indes';
import {
  getMilisecondsFromTimeText,
  getTimeMilisecondsTextFromSeconds,
} from '../../../../utils/getTimeTextFromSeconds';
import {
  OVERLAY_ACTION_JUMP,
  OVERLAY_ACTION_NAVIGATION,
  OVERLAY_ACTION_SEEK,
  OVERLAY_ACTION_URL,
  VideoOverlay,
} from '../../../../data/models/LessonContent/ContentFreeform';
import {addVideoOverlayWizardText} from '../addVideoOverlayWizardText';
import Dropdown from '../../../../uiToolkit/Inputs/Dropdown';
import {SelectOption} from '../../../../data/models/UI/SelectOption';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';
import Checkbox from '../../../../uiToolkit/Inputs/Checkbox';
import {
  MAX_SHAPE_POINTS,
  MIN_SHAPE_POINTS,
} from '../../FreeformElements/EditorSvgPath/utils';

interface Props {
  onAddPoint: () => void;
  onRemovePoint: () => void;
  overlay: VideoOverlay;
  videoTimeStart: number;
  videoTimeEnd: number;
  updateOverlay: (overlay: VideoOverlay) => void;
  actionOptions: SelectOption[];
  lessonContentOptions: SelectOption[];
  courseLessonsOptions: SelectOption[];
  onDropDownToggle: (isOpen: boolean) => void;
  onDeleteOverlay: () => void;
}

export const videoOverlayValidators = (
  overlay: VideoOverlay,
  videoTimeStart: number,
  videoTimeEnd: number,
) => ({
  overlayStartTimeIsValid: () => {
    return (
      overlay.overlayStartTime >= videoTimeStart &&
      overlay.overlayStartTime < overlay.overlayEndTime
    );
  },
  overlayEndTimeIsValid: () => {
    return overlay.overlayEndTime <= videoTimeEnd;
  },
  overlaySeekTimeIsValid: () => {
    if (overlay.actionType === OVERLAY_ACTION_SEEK && overlay.payload) {
      const seconds = getMilisecondsFromTimeText(overlay.payload);
      return seconds <= videoTimeEnd && seconds >= videoTimeStart;
    }
    return true;
  },
});

const OverlayOptions = React.memo(
  ({
    onAddPoint,
    onRemovePoint,
    overlay,
    videoTimeStart,
    videoTimeEnd,
    updateOverlay,
    actionOptions,
    lessonContentOptions,
    courseLessonsOptions,
    onDropDownToggle,
    onDeleteOverlay,
  }: Props) => {
    const updateOverlayField = (field: string, value: any) => {
      const updatedOverlay = {...overlay, [field]: value};
      if (field === 'actionType') {
        updatedOverlay.payload = undefined;
      }
      updateOverlay(updatedOverlay);
    };

    return (
      <>
        <div className="d-flex flex-row">
          <Button
            uiType="box"
            title="Add Point"
            onClick={onAddPoint}
            disabled={overlay.overlayCoordinates.length === MAX_SHAPE_POINTS}
          />
          <Button
            uiType="box"
            title="Remove Point"
            onClick={onRemovePoint}
            className="RemovePointButton"
            disabled={overlay.overlayCoordinates.length === MIN_SHAPE_POINTS}
          />
        </div>
        <div className="OverlayTimeInputsContainer">
          <TimeInput
            value={getTimeMilisecondsTextFromSeconds(overlay.overlayStartTime)}
            onChange={(value: string) => {
              updateOverlayField(
                'overlayStartTime',
                getMilisecondsFromTimeText(value),
              );
            }}
            label={addVideoOverlayWizardText.startTime}
            isValid={videoOverlayValidators(
              overlay,
              videoTimeStart,
              videoTimeEnd,
            ).overlayStartTimeIsValid()}
            labelTop
            includeMiliseconds
          />
          <TimeInput
            value={getTimeMilisecondsTextFromSeconds(overlay.overlayEndTime)}
            onChange={(value: string) =>
              updateOverlayField(
                'overlayEndTime',
                getMilisecondsFromTimeText(value),
              )
            }
            label={addVideoOverlayWizardText.endTime}
            isValid={videoOverlayValidators(
              overlay,
              videoTimeStart,
              videoTimeEnd,
            ).overlayEndTimeIsValid()}
            labelTop
            includeMiliseconds
          />
        </div>
        <Dropdown
          type="box"
          options={actionOptions}
          selectedOption={overlay.actionType.toString()}
          setSelectedOption={(value: string) => {
            updateOverlayField('actionType', parseInt(value, 10));
          }}
          heading="Overlay Action"
          onDropdownVisibilityToggle={onDropDownToggle}
        />
        {overlay.actionType === OVERLAY_ACTION_URL && (
          <TextInput
            value={overlay.payload || ''}
            onChange={(value: string) => updateOverlayField('payload', value)}
            heading="URL Destination"
            className="DestinationInput"
            uiType="box"
            placeholder="URL"
            fieldError={!overlay.payload}
          />
        )}
        {overlay.actionType === OVERLAY_ACTION_SEEK && (
          <TimeInput
            value={overlay.payload || '00:00:00.000'}
            onChange={(value: string) => updateOverlayField('payload', value)}
            label={addVideoOverlayWizardText.seekToTimestamp}
            isValid={videoOverlayValidators(
              overlay,
              videoTimeStart,
              videoTimeEnd,
            ).overlaySeekTimeIsValid()}
            className="ActionTimePayloadInput"
            labelTop
            includeMiliseconds
          />
        )}
        {overlay.actionType === OVERLAY_ACTION_JUMP && (
          <Dropdown
            className="DestinationInput"
            options={lessonContentOptions}
            selectedOption={overlay.payload}
            setSelectedOption={(value: string) =>
              updateOverlayField('payload', value)
            }
            heading={addVideoOverlayWizardText.nodeDestination}
            type="box"
            onDropdownVisibilityToggle={onDropDownToggle}
          />
        )}
        {overlay.actionType === OVERLAY_ACTION_NAVIGATION && (
          <Dropdown
            className="DestinationInput"
            options={courseLessonsOptions}
            selectedOption={overlay.payload}
            setSelectedOption={(value: string) =>
              updateOverlayField('payload', value)
            }
            heading={addVideoOverlayWizardText.lessonDestination}
            type="box"
            onDropdownVisibilityToggle={onDropDownToggle}
          />
        )}
        <Checkbox
          text={addVideoOverlayWizardText.showOverlay}
          checked={overlay.overlayVisible}
          onCheck={() =>
            updateOverlayField('overlayVisible', !overlay.overlayVisible)
          }
          slim
          color="#555555"
          className="VisibleCheck"
        />
        <Button
          title={addVideoOverlayWizardText.deleteOverlay}
          uiType="text_danger"
          onClick={onDeleteOverlay}
          className="OverlayDeleteButton"
        />
      </>
    );
  },
);

export default OverlayOptions;
