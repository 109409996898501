import {makeAutoObservable} from 'mobx';
import OrganizationRepository from '../../../data/repositories/OrganizationRepository';
import LoadingModule from '../../LoadingModule/LoadingModule';

class CreateOrganizationStore {
  private orgRepo = new OrganizationRepository();
  private loadingModule = new LoadingModule();

  public errors: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async createOrganization(
    id: string,
    name: string,
    type: number,
    image?: File,
  ) {
    this.loadingModule.startLoading();
    const result = await this.orgRepo.editOrganization(
      {id, name, type, description: ''},
      image,
    );
    this.loadingModule.endLoading();
    if (!result.success && result.errors) {
      this.setErrors(result.errors);
    }
    return result.data;
  }

  public clearErrors() {
    this.errors = [];
  }

  private setErrors(errors: string[]) {
    this.errors = errors;
  }
}

export default CreateOrganizationStore;
