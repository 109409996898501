import React, {useContext} from 'react';
import NodeHandles from './NodeHandles';
import {lessonEditorText} from '../lessonEditorText';
import {choiceBranchIc} from '../../../assets/images';
import {NodeData} from '../models';
import {EditorContext} from '../container/LessonEditorContainer';

interface Props {
  data: NodeData;
}

const ChoiceHeader = React.memo(({data}: Props) => {
  const context = useContext(EditorContext);

  const onClick = (e: any) => {
    context.setSelectedNode(data.payload, data.type);
    e.stopPropagation();
  };

  return (
    <div className="EditorNodeContainer" onClick={onClick}>
      <NodeHandles>
        <div
          className={`ChoiceHeader ${
            context.selectedNode?.internalId === data.payload.internalId
              ? 'ChoiceHeaderSelected'
              : ''
          }`}>
          <img src={choiceBranchIc} />
          <span>{lessonEditorText.choiceBranchHeaderText}</span>
        </div>
      </NodeHandles>
    </div>
  );
});

export default ChoiceHeader;
