import React from 'react';
import {editorAddItemIc, lessonArrowDown} from '../../assets/images';
import Tooltip from '../Tooltip';
import EditorTooltipButton from '../EditorTooltopButton';

interface Props {
  onClick: () => void;
  tooltip?: string;
  actions?: {icon: string; action: () => void}[];
}

const EditorCreateFirstEntry = React.memo(
  ({onClick, tooltip, actions}: Props) => {
    const getButton = () => {
      if (actions) {
        return <EditorTooltipButton actions={actions} />;
      } else {
        return (
          <button onClick={onClick}>
            <img src={editorAddItemIc} />
          </button>
        );
      }
    };
    return (
      <div className="EditorCreateFirstEntry">
        <img src={lessonArrowDown} className="Arrow" />
        {tooltip ? (
          <Tooltip tooltipText={tooltip} greenTooltip>
            {getButton()}
          </Tooltip>
        ) : (
          getButton()
        )}
      </div>
    );
  },
);

export default EditorCreateFirstEntry;
