import React from 'react';
import {Col} from 'react-bootstrap';
import {
  LANGUAGE,
  VIDEO_LENGTH_OPTIONS,
  VOICE_GENDER,
} from '../../../data/models/QuestionGeneration/AIGenerationDropdownOptions';
import {generateAIVideosText} from '../generateAIVideosText';

interface Props {
  filename: string;
  excludeSlides?: string;
  muteDuration?: string;
  muteSlides?: string;
  excludePhrases?: string;
  duration: string;
  generateImages: boolean;
  imageRatio?: number;
  language: string;
  dialect: string;
  voiceGender: string;
}

const SummaryStep = React.memo(
  ({
    filename,
    excludePhrases,
    muteDuration,
    muteSlides,
    excludeSlides,
    duration,
    generateImages,
    imageRatio,
    language,
    dialect,
    voiceGender,
  }: Props) => {
    const getExceptions = () => {
      const exceptions = [];
      if (excludeSlides) {
        exceptions.push(generateAIVideosText.summaryExclude + excludeSlides);
      }
      if (muteSlides && muteDuration) {
        exceptions.push(
          generateAIVideosText.summaryMute +
            muteSlides +
            generateAIVideosText.summaryFor +
            muteDuration,
        );
      }
      if (excludePhrases) {
        exceptions.push(
          generateAIVideosText.summaryExcludePhrases + ': ' + excludePhrases,
        );
      }
      if (exceptions.length) {
        return exceptions.reduce((prev, curr) => (prev += ', ' + curr));
      }
      return '';
    };

    return (
      <Col lg={6} xs={12} className="SummaryStep">
        <h3>{generateAIVideosText.summaryReady}</h3>
        <p>{generateAIVideosText.summaryInfo}</p>
        <h4>{generateAIVideosText.summary}</h4>
        <ul>
          <li>
            {generateAIVideosText.summaryFile}
            {filename}
          </li>
          {getExceptions() !== '' && (
            <li>
              {generateAIVideosText.summaryExceptions}
              {getExceptions()}
            </li>
          )}
          <li>
            {generateAIVideosText.summaryVideoDuration}
            {VIDEO_LENGTH_OPTIONS.find(x => x.key === duration)?.value}
          </li>
          <li>
            {generateAIVideosText.summaryMedia}
            {generateImages
              ? `${generateAIVideosText.summaryGenImagesWith}${imageRatio}/${
                  100 - (imageRatio || 0)
                }${generateAIVideosText.summaryRatio}`
              : generateAIVideosText.summaryNoImages}
          </li>
          <li>
            {generateAIVideosText.summaryNarration}
            {LANGUAGE.find(x => x.key === language)?.value}, {dialect},{' '}
            {VOICE_GENDER.find(x => x.key === voiceGender)?.value}
          </li>
        </ul>
      </Col>
    );
  },
);

export default SummaryStep;
