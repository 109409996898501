import {makeAutoObservable} from 'mobx';
import AuthRepository from '../../../../data/repositories/AuthRepository';
import LoadingModule from '../../../../modules/LoadingModule/LoadingModule';

class LoginStore {
  private authRepo = new AuthRepository();
  private loadingModule = new LoadingModule();

  public errors: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async loginUser(email: string, password: string) {
    this.loadingModule.startLoading();
    const result = await this.authRepo.loginUser({email, password});
    this.loadingModule.endLoading();
    if (result.success) {
      return result.success;
    } else if (result.errors) {
      this.setErrors(result.errors);
    }
  }

  public async loginWithGoogle(idToken: string) {
    this.loadingModule.startLoading();
    const result = await this.authRepo.loginUserWithGoogle({
      accessToken: idToken,
    });
    this.loadingModule.endLoading();
    if (!result.success && result.errors && result.errors.length) {
      this.setErrors(result.errors);
    } else {
      return result.success;
    }
  }

  public async sendEmailVerification() {
    this.loadingModule.startLoading();
    const result = await this.authRepo.sendEmailVerification();
    this.loadingModule.endLoading();
    if (result.success) {
      return result.success;
    } else if (result.errors) {
      this.setErrors(result.errors);
    }
  }

  public getUser() {
    return this.authRepo.getUser();
  }

  public isUserEmailConfirmed() {
    return this.authRepo.getUser()?.emailConfirmed;
  }

  public clearErrors() {
    this.errors = [];
  }

  private setErrors(errors: string[]) {
    this.errors = errors;
  }
}

export default LoginStore;
