import React from 'react';
import {lessonEditorText} from '../../../lessonEditorText';
import ChoiceHeaderOptionsUI from '../view/ChoiceHeaderOptionsUI';
import BaseOptionsContainer, {
  BaseContainerProps,
} from '../../BaseOptionsContainer';

export default React.memo(
  (props: BaseContainerProps & {isChallenge: boolean}) => (
    <BaseOptionsContainer
      {...props}
      deleteMessage={
        props.isChallenge
          ? lessonEditorText.areYouSureDeleteChallengeHeaderOption
          : lessonEditorText.areYouSureDeleteChoiceHeaderOption
      }>
      {forwardProps => <ChoiceHeaderOptionsUI {...forwardProps} />}
    </BaseOptionsContainer>
  ),
);
