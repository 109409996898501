import React from 'react';
import './styles.css';

interface Props {
  data1: string;
  data2?: string;
}

const TrackerDashboardCard = ({data1, data2}: Props) => {
  return (
    <div className="TwoDataCard">
      <span className="HighlighedData">
        {data1}
        {data2 && <span>/{data2}</span>}
      </span>
    </div>
  );
};

export default TrackerDashboardCard;
