import React, {useState} from 'react';
import {inject, observer} from 'mobx-react';
import ChoiceImageOptionOptionsUI from '../view/ImageSourceOptionsUI';
import {LessonContentImageSource} from '../../../../../data/models/LessonContent/LessonContentBase';
import {
  MEDIA_FILES_IMAGE,
  MediaFile,
} from '../../../../../data/models/LibraryFile/MediaFile';
import {lessonEditorText} from '../../../lessonEditorText';
import BaseOptionsContainer, {
  BaseContainerForwardProps,
  BaseContainerProps,
} from '../../BaseOptionsContainer';

const ImageSourceOptionsContainer = inject('lessonEditorStore')(
  observer(
    ({
      entityToEdit,
      isPublished,
      onEdit,
      onDelete,
      onSoftDelete,
      isLoading,
      onOpenLibrary,
    }: BaseContainerForwardProps) => {
      const [newFile, setNewFile] = useState<MediaFile>();

      const onEditImage = (duration: number, title: string) => {
        onEdit({
          ...entityToEdit,
          contentId: newFile
            ? newFile.storageFileName
            : (entityToEdit as LessonContentImageSource)?.contentId,
          durationInSeconds: duration,
          title,
        } as LessonContentImageSource);
      };

      const onOpenImagePicker = () => {
        onOpenLibrary(MEDIA_FILES_IMAGE, setNewFile);
      };

      return entityToEdit ? (
        <>
          <ChoiceImageOptionOptionsUI
            entityToEdit={entityToEdit as LessonContentImageSource}
            openImagePicker={onOpenImagePicker}
            newImage={newFile}
            isLoading={isLoading}
            onSave={onEditImage}
            onDelete={onDelete}
            isPublished={isPublished}
            onSoftDelete={onSoftDelete}
          />
        </>
      ) : null;
    },
  ),
);

export default React.memo((props: BaseContainerProps) => (
  <BaseOptionsContainer
    {...props}
    deleteMessage={lessonEditorText.areYouSureDeleteImageSource}
    softDeleteMessage={lessonEditorText.areYouSureDeleteImageSourceSoft}>
    {forwardProps => <ImageSourceOptionsContainer {...forwardProps} />}
  </BaseOptionsContainer>
));
