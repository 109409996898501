import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  title: 'Password',
  subtitle: 'Change your password regularly to keep your account secure.',
  currentPasswordTitle: 'Current Password',
  currentPasswordPlaceholder: 'Please Enter Your Current Password',
  newPasswordTitle: 'New Password',
  newPasswordPlaceholder: 'Please Enter Your New Password',
  confirmPasswordTitle: 'Confirm Password',
  confirmPasswordPlaceholder: 'Please Enter Your New Password Again',
  saveChanges: 'Save Changes',
  passwordUpdateFailed:
    'Oops! The current password you entered is incorrect. Please double-check and try again.',
  passwordUpdateSuccessful: 'Successfully Updated Password',
};

const welsh = {
  title: 'Cyfrinair',
  subtitle: "Newidwch eich cyfrinair yn rheolaidd i gadw'ch cyfrif yn ddiogel",
  currentPasswordTitle: 'Cyfrinair Presennol',
  currentPasswordPlaceholder: 'Rhowch Eich Cyfrinair Presennol',
  newPasswordTitle: 'Cyfrinair Newydd',
  newPasswordPlaceholder: 'Rhowch Eich Cyfrinair Newydd',
  confirmPasswordTitle: 'Cadarnhau Cyfrinair',
  confirmPasswordPlaceholder: 'Rhowch Eich Cyfrinair Newydd Eto',
  saveChanges: 'Cadw Newidiadau',
  passwordUpdateFailed:
    "Wps! Mae'r cyfrinair presennol a gawsoch ei fewnforio yn anghywir. Rhowch yn ofalus a cheisiwch eto.",
  passwordUpdateSuccessful: "Cyfrinair wedi'i ddiweddaru'n llwyddiannus",
};

export const securityUserProfileText: any = getLocalizationBasedOnLang(
  english,
  welsh,
);
